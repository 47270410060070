import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateRangeFilterComponent } from './date-range-filter.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { FormGroupModule } from '../form-group/form-group.module';
import { NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { DateRangeService } from './date-range.service';
import { ComponentsModule } from '../../components/components.module';
import { DateRangeFilterCalendarComponent } from './date-range-filter-calendar/date-range-filter-calendar.component';


@NgModule({
  declarations: [DateRangeFilterComponent, DateRangeFilterCalendarComponent],
  providers: [DateRangeService],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    FormGroupModule,
    ComponentsModule,
    NgbDropdownModule,
    NgbDatepickerModule,
  ],
  exports: [DateRangeFilterComponent]
})
export class DateRangeFilterModule {
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../../../core/guards/auth-guard.service';
import { UserNewComponent } from './user-new/user-new.component';
import { UserPopupComponent } from './user-popup.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UserResolve } from './user.resolve';
import { PermissionsGuard } from '../../../core/guards/permissions.guard';
import { PERMISSIONS } from '../../../core/services/permission.service';

const routes: Routes = [
  {
    path: 'user',
    outlet: 'popup',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: UserNewComponent,
        canActivate: [PermissionsGuard],
        data: {
          permissions: [PERMISSIONS.USER_CREATE]
        }
      },
      {
        path: ':id',
        component: UserPopupComponent,
        resolve: {
          user: UserResolve,
        },
        canActivate: [PermissionsGuard],
        data: {
          permissions: [PERMISSIONS.USER_VIEW]
        },
        children: [
          {
            path: 'details',
            component: UserDetailsComponent
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'details'
          },
        ]
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserPopupRoutingModule {
}

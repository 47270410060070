<app-filters-panel [popupView]="true" [withoutMargin]="true" [selected]="selectedFilters">

    <ng-container filters-right>
        <ng-content></ng-content>
    </ng-container>
    <ng-container filters-sidebar>
        <div [formGroup]="filterForm" class="row">


            <div class="col-12  mb-m">
                <app-select-input
                        formControlName="SUM_WORK_RIDE_COUNT"
                        [value]="value('SUM_WORK_RIDE_COUNT')"
                        [items]="rideStatsType"
                        [multiple]="false"
                        bindLabel="label"
                        bindValue="value"
                ><label for="" translate>BUSINESS_PRIVATE</label></app-select-input>
            </div>
            <div class="col-7 mb-m">
                <app-range-input
                        formControlName="SUM_RIDE_DISTANCE"
                ><label translate>RIDE_MILEAGE</label></app-range-input>
            </div>
            <div class="col-7 mb-m">
                <app-range-input
                        formControlName="SUM_PARK_DURATION"
                ><label translate>PARK_DURATION</label></app-range-input>
            </div>
            <div class="col-7 mb-m">
                <app-range-input
                        formControlName="AVG_FUEL_CONSUMPTION"
                ><label translate>FUEL_CONSUMPTION</label></app-range-input>
            </div>
            <div class="col-7 mb-m">
                <app-range-input
                        formControlName="TOTAL_MAX_SPEED"
                ><label translate>MAX_SPEED</label></app-range-input>
            </div>
            <div class="col-auto min-width">
              <app-select-input
                      formControlName="PARK_TYPE"
                      [value]="value('PARK_TYPE')"
                      [items]="parkType"
                      bindLabel="label"
                      bindValue="value"
                      [multiple]="false"
              ><label for="">Status</label></app-select-input>
          </div>
            <div class="col-12 mt-m">
                <button class="btn btn-primary btn-border w-100" (click)="clear()" translate>CLEAR_FILTERS</button>

            </div>
        </div>
    </ng-container>
</app-filters-panel>

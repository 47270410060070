import { VehicleAvatar, VehicleExtensions } from '../../interfaces/vehicle';
import { EventEmitter, Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Vehicle as VehicleClass } from '../../classes/vehicle';
import { WarehouseResponseData, WarehouseOutputField } from '../../interfaces/warehouse';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TelemetryService } from './telemetry.service';
import { observableWithCatchError } from '../../utils/utils';
import { VehicleWithTelemetryAndRide } from '../../interfaces/telemetry';
import { AuthService } from './auth.service';
import { Vehicle } from 'src/app/classes/vehicle';
import { ApiServiceWithSub } from '../../classes/api-service-with-sub';
import { VehicleGroup } from '../../interfaces/group';
import { UserSettingsService } from './user-settings.service';

export type WorkVehicleWarehouse = {
  [key in WarehouseOutputField]: WarehouseResponseData[]
};

@Injectable({
  providedIn: 'root',
})
export class PartnerVehiclesService extends ApiServiceWithSub<Vehicle> {
  private favoriteSet: Set<string>;
  public favoriteChange = new EventEmitter();
  private activeFilter$ = new BehaviorSubject(null);
  private filterChange$ = new BehaviorSubject(null);
  protected inludeList = ['groups', 'driver'];

  constructor(config: ConfigService,
              private telemetry: TelemetryService,
              private userSettings: UserSettingsService,
              authService: AuthService,
              http: HttpClient) {
    super('/partner/vehicles', config, http, authService);
    this.initList();
  }


  list(parameters = {}): Observable<Vehicle[]> {
    const params = this.httpParams({include: ['clientId', 'extensions', 'device'], ...parameters});
    return this.http.get<Vehicle[]>(this.baseUrl, {params})
      .pipe(map(res => res.map(veh => new VehicleClass(veh))));
  }

  get filter() {
    return this.filterChange$.asObservable().pipe(filter(res => res !== null));
  }

  getVehicleAvatarList(): VehicleAvatar[] {
    return [
      { label: 'Maszyna', value: 'https://prodfcstorage.blob.core.windows.net/vehicle-thumbnails/bobcat.jpg'},
      { label: 'Motor', value: 'https://prodfcstorage.blob.core.windows.net/vehicle-thumbnails/dacia.jpg'},
    ];
  }

  updateGroup(id: string, groupIds: string[]) {
    return of([]);
  }

  updateVehicleExtensions(vehicleId: string, payload: any): Observable<any> {
    return this.http.patch(`${this.baseUrl}/${vehicleId}/extensions`, payload);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'warehouseValue'
})
export class WarehouseValuePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value.map(item => item.v);
  }

}

<th></th>
<th style="width: 100px"> {{'BEGIN'|translate}}<br>{{ 'END'|translate}} </th>
<th translate>LOCATION</th>
<th>{{'DURATION'|translate}}<br>
    {{'BUSINESS'|translate}}<br>
    {{'PRIVATE'|translate}}
</th>
<th>{{'DISTANCE'|translate}}<br>
    {{'BUSINESS'|translate}}<br>
    {{'PRIVATE'|translate}}
</th>
<th>{{'SPEED'|translate}}<br>{{'AVG'|translate}}<br><span class="hide-by-theme">max</span>
</th>
<th style="width:50px" class="hide-by-theme">{{ fuelLabel|translate }} </th>
<th class="hide-by-theme">EcoScore</th>
<th class="hide-by-theme" translate>VISITS_COUNT</th>
<th>{{(isDriver ? 'VEHICLE' : 'DRIVER')|translate}}</th>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-selected-tags',
  templateUrl: './selected-tags.component.html',
  styleUrls: ['./selected-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedTagsComponent implements OnInit {
  @Input() items: any;
  @Input() bindLabel;
  @Input() max = 5;
  @Input() length: number;
  @Input() showMoreBtn = true;
  @HostBinding('class.disabled')
  @Input() disabled = false;
  @Input() removable = true;
  @Output() removeItem: EventEmitter<any> = new EventEmitter();
  @Output() clickItem: EventEmitter<any> = new EventEmitter();
  @Output() removeAll: EventEmitter<any> = new EventEmitter();
  public isShowMore = true;

  constructor(private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  get showMoreLabel() {
    return this.isShowMore ? '+ ' + this.left : '-';
  }

  showMoreToggle() {
    this.isShowMore = !this.isShowMore;
    this.cd.detectChanges();
  }

  get showMore() {
    return this.showMoreBtn && this.items && this.items.length > this.max;
  }

  get showMoreLengthLabel() {
    return this.length - this.items.length;
  }

  get showMoreLength() {
    return this.length && this.showMoreBtn && !this.isShowMore && this.items && this.items.length < this.length;
  }

  handlerRemoveItem(item) {
    if (!this.disabled) {
      this.removeItem.emit(item);
    }
  }

  handlerRemoveAll() {
    if (!this.disabled) {
      this.removeAll.emit();
    }
  }

  handleClickItem(item) {
    this.clickItem.emit(item);
  }

  get left() {
    return this.length ? this.length - this.max : this.items ? this.items.length - this.max : 0;
  }

  get itemsArray() {
    return this.items ? this.showMoreBtn && this.isShowMore ? this.items.slice(0, this.max) : this.items : [];
  }

  label(item) {
    return typeof this.bindLabel === 'function' ? this.bindLabel(item) : item[this.bindLabel] || item.toString();
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input, OnDestroy, OnInit,
  Output
} from '@angular/core';
import { VehiclesService } from '../../../core/services/vehicles.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-favorite-vehicle',
  templateUrl: './favorite-vehicle.component.html',
  styleUrls: ['./favorite-vehicle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavoriteVehicleComponent implements OnInit, OnDestroy {
  @Input() size = 20;
  @Input() id: string;
  @Input() disabled = false;
  @HostBinding('class')
  @Input() color: string;
  @Input() favorite: boolean;
  @Output() toggleFavorite = new EventEmitter();
  public subscription: Subscription;

  @HostListener('click') favoriteToggle() {
    if (this.id && !this.disabled) {
      this.vehicleService.favoriteToggle(this.id);
    } else {
      this.toggleFavorite.next(this.favorite);
    }
  }

  constructor(private vehicleService: VehiclesService, private cd: ChangeDetectorRef) {
  }

  get isFavorite() {
    return this.favorite || this.id && this.vehicleService.isFavorite(this.id);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription = this.vehicleService.favoriteChange.subscribe(() => {
      this.cd.detectChanges();
    });
  }

}

import { FuelType } from 'src/app/interfaces/vehicle';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-plate',
  templateUrl: './plate.component.html',
  styleUrls: ['./plate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlateComponent implements OnInit {
  @Input() numberPlate: string;
  @Input() fuelType: FuelType = FuelType.petrol;
  constructor() { }

  ngOnInit() {
  }

  get background() {
    return this.fuelType == FuelType.electric ? "#B0D6B5" : "white";
  }

}

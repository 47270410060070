import { DateTime } from 'luxon';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { WorkSchedule, WorkScheduleEntityType, WorkScheduleTimeType } from '../../../interfaces/work-schedule';
import { NewEditClass } from '../../../classes/new-edit.class';
import { PERMISSIONS, PermissionService } from '../../../core/services/permission.service';
import { ActivatedRoute } from '@angular/router';
import { PopupViewService } from '../../../shared/components/popup-view/popup-view.service';
import { ApiValidatorService } from '../../../core/services/api-validator.service';
import { RouterHelperService } from '../../../core/services/router-helper.service';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { shareReplay, switchMap, tap } from 'rxjs/operators';
import { WorkScheduleService } from '../../../core/services/work-schedule.service';
import { of } from 'rxjs';
import { PerspectiveMenuService } from '../../../shared/modules/perspective-menu/perspective-menu.service';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DriversService } from '../../../core/services/drivers.service';
import { DriversGroupsService } from '../../../core/services/drivers-groups.service';
import { VehiclesGroupsService } from '../../../core/services/vehicles-groups.service';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'app-work-schedules-popup',
  templateUrl: './work-schedules-popup.component.html',
  styleUrls: ['./work-schedules-popup.component.scss'],
})
export class WorkSchedulesPopupComponent extends NewEditClass implements OnInit, OnDestroy {
  public workSchedule: WorkSchedule;
  protected newPermission: PERMISSIONS = PERMISSIONS.WEB_ZONES;
  protected editPermission: PERMISSIONS = PERMISSIONS.WEB_ZONES;
  private dateFieldKeys = ['dateFrom', 'dateTo'];
  readonly entityTypes = WorkScheduleEntityType;
  public drivers$ = this.driverService.list$().pipe(shareReplay());
  public driversGroups$ = this.driverGroupService.list();
  public vehiclesGroups$ = this.vehicleGroups.list$();
  private clienId: string;
  public entityTypesKeys = Object.keys(WorkScheduleEntityType).map(value => ({name: value, id: WorkScheduleEntityType[value]}));
  public types = Object.keys(WorkScheduleTimeType).map(value => ({name: value, id: WorkScheduleTimeType[value]}));

  constructor(private route: ActivatedRoute,
              popupService: PopupViewService,
              private dateAdapter: NgbDateParserFormatter,
              private service: WorkScheduleService,
              private cd: ChangeDetectorRef,
              private authService: AuthService,
              private perspectiveMenu: PerspectiveMenuService,
              apiValidator: ApiValidatorService,
              private driverService: DriversService,
              private driverGroupService: DriversGroupsService,
              private vehicleGroups: VehiclesGroupsService,
              private routerHelper: RouterHelperService,
              permissionService: PermissionService,
              fb: UntypedFormBuilder) {
    super(popupService, apiValidator, fb, permissionService);
  }

  dateToNgb(date) {
    return this.dateAdapter.parse(date);
  }

  addRouteSub() {
    this.subscriptions.add(this.authService.isLoggedIn$.subscribe(res => {
      if (res) {
        this.clienId = this.authService.tokenInfo.user.clientId;
      }
    }));
    this.subscriptions.add(this.route.params.pipe(
      tap(() => this.pending = true),
      switchMap(({id}) => id ? this.service.get(id) : of(null)),
    ).subscribe(res => {
      if (res) {
        this.workSchedule = res;
        this.isNewView = false;
      } else {
        this.isNewView = true;
        this.isEditMode = true;
        this.workSchedule = null;
      }
      this.pending = false;
      this.resetForm();
    }));
  }

  buildForm() {
    this.forms.main = this.fb.group({
      name: [null, Validators.required],
      entityType: [null, Validators.required],
      entityId: [null, Validators.required],
      dateFrom: [null, Validators.required],
      dateTo: [null],
      timetable: this.periodArrray(),
      timeType: [null, Validators.required],
      priority: [null, Validators.min(1)],
    });
    this.subscriptions.add(this.forms.main.get('entityType').valueChanges.subscribe(res => {
      const entityType = this.forms.main.get('entityId');
      if (res === WorkScheduleEntityType.CLIENT) {
        entityType.patchValue(this.clienId);
      } else {
        entityType.setValue(null);
      }
      entityType.updateValueAndValidity();
    }));
  }

  periodArrray() {
    return this.fb.array(new Array(7)
      .fill(null)
      .map((value, index) => this.fb.control(null, this.apiValidator.periodValidator)));
  }

  resetForm() {
    this.forms.main.reset();
    if (this.workSchedule) {
      this.forms.main.patchValue(this.workSchedule, {
        emitEvent: false,
      });
      this.apiValidator.setDateFields(this.dateFieldKeys, this.forms.main as UntypedFormGroup, this.workSchedule);
    }
    this.cd.detectChanges();
  }

  save() {
    const data = this.getFormData();
    this.service.add(data).subscribe(res => {
      this.routerHelper.navigatePopup(['work-schedule', res.id]);
    }, this.errorHandler.bind(this));
  }

  stopEvents(input: HTMLInputElement) {
    input.blur();
  }

  get timetable(): UntypedFormArray {
    return this.forms.main.get('timetable') as UntypedFormArray;
  }

  update() {
    const data = this.getFormData();
    this.service.partialUpdate(this.workSchedule.id, data).subscribe(res => {
      this.pending = false;
      this.isEditMode = false;
      this.toggleDisableForm();
      this.cd.detectChanges();
    }, this.errorHandler.bind(this));
  }

  private getFormData() {
    const data = this.forms.main.value;
    data.dateFrom = DateTime.fromISO(data.dateFrom.toISOString()).startOf('day').toUTC().toString();
    data.dateTo = data.dateTo ? DateTime.fromISO(data.dateTo.toISOString()).endOf('day').plus(-1).toUTC().toString() : null;
    // const dateTime = DateTime.fromISO(data.dateFrom.toISOString()).startOf('day');
    // console.log(`getFormData: ${data.dateFrom.toISOString()} luxon: ${dateTime.toISO().toString()}`);
    if (Array.isArray(data.entityId)) {
      data.entityId = data.entityId[0];
    }
    return data;
  }
}

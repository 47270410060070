import { Component } from '@angular/core';
import { NewEditClass } from '../../../../../classes/new-edit.class';
import { VehicleGroup } from '../../../../../interfaces/group';
import { ActivatedRoute } from '@angular/router';
import { PopupViewService } from '../../../../../shared/components/popup-view/popup-view.service';
import { ApiValidatorService } from '../../../../../core/services/api-validator.service';
import { RouterHelperService } from '../../../../../core/services/router-helper.service';
import { VehiclesGroupsService } from '../../../../../core/services/vehicles-groups.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import { PERMISSIONS, PermissionService } from '../../../../../core/services/permission.service';

@Component({
  selector: 'app-vehicle-group-details',
  templateUrl: './vehicle-group-details.component.html',
  styleUrls: ['./vehicle-group-details.component.scss']
})
export class VehicleGroupDetailsComponent extends NewEditClass {
  public vehicleGroup: VehicleGroup;
  protected editPermission = PERMISSIONS.VEHICLE_GROUP_UPDATE;
  protected newPermission = PERMISSIONS.VEHICLE_GROUP_CREATE;

  constructor(private route: ActivatedRoute,
              popupService: PopupViewService,
              apiValidator: ApiValidatorService,
              permissionService: PermissionService,
              private routerHelper: RouterHelperService,
              private vehicleGroupService: VehiclesGroupsService,
              fb: UntypedFormBuilder) {
    super(popupService, apiValidator, fb, permissionService);
  }

  addRouteSub() {
    this.subscriptions.add(
      this.route.parent.data
        .subscribe(res => {
          this.vehicleGroup = res.vehicleGroup;
          this.resetForm();
          this.pending = false;
        }));
  }

  resetForm() {
    this.forms.main.patchValue(this.vehicleGroup);
    this.formGroup();
  }

  buildForm() {
    this.forms.main = this.fb.group({
      name: [null, Validators.required]
    });
  }

  formGroup() {
    this.forms['group'] = this.fb.control(null);
    this.toggleDisableForm();
    this.vehicleGroupService.vehiclesInGroup(this.vehicleGroup.id).subscribe(res => {
      this.forms.group.setValue(res.map(({id}) => id));
    });
  }

  save() {
    this.vehicleGroupService.add(this.forms.main.value).subscribe(() => {
      this.pending = false;
      this.popupService.close();
    }, this.errorHandler.bind(this));
  }

  update() {
    this.vehicleGroupService.sync(this.vehicleGroup.id, this.forms.group.value || [])
      .pipe(switchMap(res => this.vehicleGroupService.partialUpdate(this.vehicleGroup.id, this.forms.main.value)))
      .subscribe((res) => {
        this.pending = false;
        this.isEditMode = false;
        this.toggleDisableForm();
      }, this.errorHandler.bind(this));
  }
}

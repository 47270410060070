export const hereMapEvents = `function Bo(a) {
  var b = a.ownerDocument;
  b = b.documentElement || b.body.parentNode || b.body;
  try {
    var c = a.getBoundingClientRect()
  } catch (d) {
    c = {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      height: 0,
      width: 0
    }
  }
  return {
    x: c.left + ("number" === typeof window.pageXOffset ? window.pageXOffset : b.scrollLeft),
    y: c.top + ("number" === typeof window.pageYOffset ? window.pageYOffset : b.scrollTop)
  }
}
var Co = function() {
  return this
}
  .apply(null);
function Do(a, b, c, d, e, f) {
  if (isNaN(a))
    throw Error("x needs to be a number");
  if (isNaN(b))
    throw Error("y needs to be a number");
  if (isNaN(c))
    throw Error("pointer must have an id");
  this.viewportX = a;
  this.viewportY = b;
  this.target = null;
  this.id = c;
  this.type = d;
  this.dragTarget = null;
  this.button = vc(e) ? e : -1;
  this.buttons = vc(f) ? f : 0;
  this.a = this.button
}
t("H.mapevents.Pointer", Do);
function Eo(a, b, c) {
  if (isNaN(b))
    throw Error("x needs to be a number");
  if (isNaN(c))
    throw Error("y needs to be a number");
  a.viewportX = b;
  a.viewportY = c
}
Do.prototype.tm = function() {
  return this.a
}
;
Do.prototype.getLastChangedButton = Do.prototype.tm;
function Fo(a, b) {
  a.a = b;
  a.buttons |= Go[+b] || 0
}
function Ho(a, b) {
  a.a = b;
  a.buttons &= ~(Go[+b] || 0)
}
var Go = [1, 4, 2]
  , Io = {
  NONE: -1,
  LEFT: 0,
  MIDDLE: 1,
  RIGHT: 2
};
Do.Button = Io;
function Jo(a, b, c, d, e, f, g) {
  Jo.j.constructor.call(this, a);
  this.pointers = b;
  this.changedPointers = c;
  this.targetPointers = d;
  this.currentPointer = e;
  this.originalEvent = g;
  this.target = f
}
u(Jo, ud);
t("H.mapevents.Event", Jo);
function Ko(a) {
  this.a = a instanceof Array ? a.slice(0) : []
}
p = Ko.prototype;
p.clear = function() {
  this.a.splice(0, this.a.length)
}
;
p.length = function() {
  return this.a.length
}
;
p.indexOf = function(a) {
  for (var b = this.a.length; b--; )
    if (this.a[b].id === a)
      return b;
  return -1
}
;
function Lo(a, b) {
  b = a.indexOf(b);
  return -1 !== b ? a.a[b] : null
}
p.remove = function(a) {
  a = this.indexOf(a);
  return -1 !== a ? this.a.splice(a, 1)[0] : null
}
;
function Mo(a, b) {
  for (var c = a.a.length, d = []; c--; )
    a.a[c].type !== b && d.push(a.a[c]);
  a.a = d
}
function No(a, b) {
  for (var c = a.a.length; c--; )
    if (a.a[c].dragTarget === b)
      return !0;
  return !1
}
p.push = function(a) {
  if (a instanceof Do)
    return this.a.push(a);
  throw Error("list needs a pointer");
}
;
p.La = function() {
  return this.a
}
;
p.clone = function() {
  return new Ko(this.a)
}
;
function Oo(a, b, c) {
  c = c || {};
  if (!(a instanceof U))
    throw Error("events: map instance required");
  if (!(b instanceof Array))
    throw Error("events: map array required");
  qd.call(this);
  this.Tg = c.Tg || 300;
  this.gj = c.gj || 50;
  this.Zk = c.Zk || 50;
  this.$k = c.$k || 500;
  this.Ch = c.Ch || 900;
  this.Bh = c.Bh || 8;
  this.map = a;
  this.m = this.map.b;
  this.f = this.m.element;
  this.D = b;
  this.a = new Ko;
  this.b = new Ko;
  this.l = {};
  this.c = null;
  this.u = !0;
  this.U = {};
  this.g = {};
  this.s = null;
  this.te = A(this.te, this);
  this.P = {
    pointerdown: this.Bl,
    pointermove: this.Cl,
    pointerup: this.Dl,
    pointercancel: this.Al
  };
  Po(this)
}
u(Oo, qd);
function Po(a, b) {
  var c, d = a.D.length;
  for (c = 0; c < d; c++) {
    var e = a.D[c];
    var f = e.listener;
    "function" === typeof f && (b ? (e.target || a.f).removeEventListener(e.Sa, f) : (e.target || a.f).addEventListener(e.Sa, f))
  }
}
function Qo(a, b, c) {
  var d;
  if ("function" === typeof a.P[b]) {
    "pointermove" !== b && (a.u = !0);
    var e = 0;
    window._mapBoxEvent = b;
    for (d = a.b.length(); e < d; e++) {
      var f = a.b.a[e] || null;
      a.f.contains(c.composedPath()[0]) ? Ro(a, f, a.nj.bind(a, c, b, f)) : a.nj(c, b, f, null)
    }
  }
  a.b.clear()
}
p = Oo.prototype;
p.nj = function(a, b, c, d) {
  So(c.id, this.U);
  this.P[b].call(this, c, d, a)
}
;
function Ro(a, b, c) {
  if (a.c === b)
    c(b.target);
  else {
    var d = a.m;
    var e = b.viewportX;
    b = b.viewportY;
    if (0 > e || 0 > b || e >= d.width || b >= d.height)
      c(z);
    else {
      var f = a.map;
      f.Od(e, b, function(g) {
        c(g || f)
      })
    }
  }
}
p.Dl = function(a, b, c) {
  var d = a.id;
  a.target = b;
  To(this, a, c);
  Uo(this, b, "pointerup", c, a);
  "mouse" !== a.type && Uo(this, b, "pointerleave", c, a);
  b = this.l[a.id];
  var e = {
    x: a.viewportX,
    y: a.viewportY
  }
    , f = c.timeStamp
    , g = a.target
    , h = this.s;
  b && b.target === g && b.Se.Wa(e) < this.Zk && f - b.Hi < this.$k ? (Uo(this, g, "tap", c, a),
    h && h.target === g && f - h.Hi < this.Tg ? h.Se.Wa({
      x: a.viewportX,
      y: a.viewportY
    }) < this.gj && (Uo(this, g, "dbltap", c, a),
      this.s = null) : this.s = {
      target: g,
      Se: new J(a.viewportX,a.viewportY),
      Hi: c.timeStamp
    }) : this.s = null;
  this.l = {};
  So(d, this.g)
}
;
function To(a, b, c) {
  b === a.c && (Uo(a, b.dragTarget, "dragend", c, b),
    a.c = null,
    So(b.id, a.U));
  b.dragTarget = null
}
p.te = function(a, b) {
  var c = this;
  Uo(this, a.dragTarget, "drag", b, a);
  So(a.id, this.U);
  this.U[a.id] = setTimeout(function() {
    c.te(a, b)
  }, 150)
}
;
function So(a, b) {
  b[a] && (b[a].timeout ? clearTimeout(b[a].timeout) : clearTimeout(b[a]),
    delete b[a])
}
function Vo(a, b, c) {
  var d = b.target
    , e = new J(b.viewportX,b.viewportY)
    , f = b.id;
  So(f, a.g);
  var g = setTimeout(function() {
    d && d === b.target && e.Wa({
      x: b.viewportX,
      y: b.viewportY
    }) < a.Bh && (Uo(a, d, "longpress", c, b),
      delete a.l[b.id])
  }, a.Ch);
  a.g[f] = {
    timeout: g,
    Se: e
  }
}
p.Cl = function(a, b, c) {
  var d = a.dragTarget
    , e = a.id;
  var f = a.target;
  a.target = b;
  f !== b && (Uo(this, f, "pointerleave", c, a),
    Uo(this, b, "pointerenter", c, a));
  d ? this.c ? (this.te(a, c),
  this.g[e] && this.g[e].Se.Wa({
    x: a.viewportX,
    y: a.viewportY
  }) > this.Bh && So(e, this.g)) : this.u ? this.u = !1 : (this.c = a,
    Uo(this, d, "dragstart", c, a),
    this.te(a, c),
    delete this.l[e],
    this.u = !0) : (!this.c || this.c && this.c.dragTarget !== b && this.c.dragTarget !== this.map) && Uo(this, b, "pointermove", c, a)
}
;
p.Bl = function(a, b, c) {
  var d = !(/^(?:mouse|pen)$/.test(a.type) && 0 !== c.button);
  if (b) {
    a.target = b;
    this.l[a.id] = {
      Se: new J(a.viewportX,a.viewportY),
      target: a.target,
      Hi: c.timeStamp
    };
    "mouse" !== a.type && Uo(this, b, "pointerenter", c, a);
    var e = Uo(this, b, "pointerdown", c, a);
    !this.c && d && (b.draggable && !No(this.a, b) ? a.dragTarget = b : !this.map.draggable || e.defaultPrevented || No(this.a, this.map) || (a.dragTarget = this.map));
    Vo(this, a, c)
  }
}
;
p.Al = function(a, b, c) {
  var d = a.id;
  a.target = null;
  b ? (Uo(this, b, "pointerleave", c, a),
    Uo(this, b, "pointercancel", c, a)) : Uo(this, this.map, "pointercancel", c, a);
  To(this, a, c);
  this.l = {};
  So(d, this.g)
}
;
function Uo(a, b, c, d, e) {
  if (b && "function" === typeof b.dispatchEvent) {
    var f = Jo;
    var g = a.a.La()
      , h = a.b.La();
    a = a.a;
    var k, l = a.a.length, m = [];
    for (k = 0; k < l; k++)
      a.a[k].target === b && m.push(a.a[k]);
    f = new f(c,g,h,m,e,b,d);
    e.button = /^(?:longpress|(?:dbl)?tap|pointer(?:down|up))$/.test(c) ? e.a : Io.NONE;
    b.dispatchEvent(f)
  }
  return f
}
p.o = function() {
  Po(this, !0);
  this.a.clear();
  this.b.clear();
  var a = this.U, b;
  for (b in a)
    So(b, a);
  a = this.g;
  for (var c in a)
    So(c, a);
  delete this.f;
  delete this.D;
  delete this.a;
  delete this.b;
  delete this.map;
  this.s = null;
  delete this.l;
  this.c = null;
  qd.prototype.o.call(this)
}
;
function Wo(a) {
  this.i = A(this.i, this);
  Oo.call(this, a, [{
    Sa: "touchstart",
    listener: this.i
  }, {
    Sa: "touchmove",
    listener: this.i
  }, {
    Sa: "touchend",
    listener: this.i
  }, {
    Sa: "touchcancel",
    listener: this.i
  }]);
  this.L = {
    touchstart: "pointerdown",
    touchmove: "pointermove",
    touchend: "pointerup",
    touchcancel: "pointercancel"
  };
  this.J = (a = (a = a.s) ? a.N() : null) ? Array.prototype.slice.call(a.querySelectorAll("a"), 0) : []
}
u(Wo, Oo);
Wo.prototype.i = function(a) {
  var b = a.touches, c = this.a.length(), d;
  if ("touchstart" === a.type && c >= b.length) {
    c = this.a.clone();
    for (d = b.length; d--; )
      c.remove(b[d].identifier);
    for (d = c.length(); d--; )
      this.a.remove((c.a[d] || null).id);
    this.b = c;
    Qo(this, "pointercancel", a);
    this.b.clear()
  }
  if (this.L[a.type]) {
    b = Bo(this.m.element);
    c = a.type;
    d = a.changedTouches;
    var e = d.length, f;
    this.b.clear();
    for (f = 0; f < e; f++) {
      var g = d[f];
      var h = Lo(this.a, g.identifier);
      var k = g.pageX - b.x;
      var l = g.pageY - b.y;
      if (h)
        if ("touchmove" === c) {
          g = Math.abs(h.viewportX - k);
          var m = Math.abs(h.viewportY - l);
          if (1 < g || 1 < m || 1 === g && 1 === m)
            Eo(h, k, l),
              this.b.push(h)
        } else
          "touchend" === c && (this.a.remove(h.id),
            this.b.push(h),
            Ho(h, Io.LEFT));
      else
        h = new Do(k,l,g.identifier,"touch",Io.LEFT,1),
          this.a.push(h),
          this.b.push(h)
    }
    Qo(this, this.L[a.type], a);
    -1 === this.J.indexOf(a.target) && a.preventDefault()
  }
}
;
Wo.prototype.o = function() {
  this.J = null;
  Oo.prototype.o.call(this)
}
;
function Xo(a) {
  var b = Yo(this);
  (window.PointerEvent || window.MSPointerEvent) && b.push({
    Sa: "MSHoldVisual",
    listener: "prevent"
  });
  Oo.call(this, a, b)
}
u(Xo, Oo);
function Yo(a) {
  var b = !!window.PointerEvent, c, d, e = [];
  a.i = A(a.i, a);
  "MSPointerDown MSPointerMove MSPointerUp MSPointerCancel MSPointerOut MSPointerOver".split(" ").forEach(function(f) {
    c = f.toLowerCase().replace(/ms/g, "");
    d = b ? c : f;
    e.push({
      Sa: d,
      listener: a.i,
      target: /^pointer(up|move)$/.test(c) ? window : null
    })
  });
  return e
}
var Zo = {
  2: "touch",
  3: "pen",
  4: "mouse"
};
Xo.prototype.i = function(a) {
  var b = window.PointerEvent ? a.type : a.type.toLowerCase().replace(/ms/g, "")
    , c = Bo(this.f)
    , d = Lo(this.a, a.pointerId)
    , e = a.pageX - c.x;
  c = a.pageY - c.y;
  var f = Zo[a.pointerType] || a.pointerType;
  cd && "rtl" === x.getComputedStyle(this.m.element).direction && (e -= (x.devicePixelRatio - 1) * this.m.width);
  if (!(d || /^pointer(up|out|cancel)$/.test(b) || "touch" === f && "pointerdown" !== b)) {
    d = {
      x: e,
      y: c
    };
    var g = a.pointerType;
    "number" === typeof g && (g = Zo[g]);
    d = new Do(d.x,d.y,a.pointerId,g,a.button,a.buttons);
    this.a.push(d)
  }
  if (d)
    if (/^pointer(up|cancel)$/.test(b) ? ("touch" === f && this.a.remove(d.id),
      Ho(d, a.button)) : "pointerdown" === b && ("touch" === a.pointerType && (Mo(this.a, "mouse"),
      Mo(this.a, "pen")),
      Fo(d, a.button)),
      this.b.push(d),
    "pointermove" !== b)
      Eo(d, e, c),
        Qo(this, /^pointer(over|out)$/.test(b) ? "pointermove" : b, a);
    else if (d.viewportX !== e || d.viewportY !== c)
      Eo(d, e, c),
        Qo(this, b, a);
  this.b.clear()
}
;
function $o(a, b, c, d) {
  $o.j.constructor.call(this, "contextmenu");
  this.items = [];
  this.viewportX = a;
  this.viewportY = b;
  this.target = c;
  this.originalEvent = d
}
u($o, ud);
t("H.mapevents.ContextMenuEvent", $o);
function ap(a) {
  this.ph = A(this.ph, this);
  this.rh = A(this.rh, this);
  this.qh = A(this.qh, this);
  this.J = !1;
  this.i = -1;
  this.L = 0;
  ap.j.constructor.call(this, a, [{
    Sa: "contextmenu",
    listener: this.ph
  }, {
    target: a,
    Sa: "longpress",
    listener: this.rh
  }, {
    target: a,
    Sa: "dbltap",
    listener: this.qh
  }])
}
u(ap, Oo);
p = ap.prototype;
p.rh = function(a) {
  var b = a.currentPointer;
  "touch" === b.type && 1 === a.pointers.length && bp(this, b.viewportX, b.viewportY, a.originalEvent, a.target)
}
;
p.qh = function(a) {
  "touch" === a.currentPointer.type && (this.L = Date.now())
}
;
p.ph = function(a) {
  var b = this;
  -1 === this.i ? this.i = setTimeout(function() {
    var c = Bo(b.f)
      , d = a.pageX - c.x;
    c = a.pageY - c.y;
    b.i = -1;
    bp(b, d, c, a)
  }, this.Tg) : (clearInterval(this.i),
    this.i = -1);
  a.preventDefault()
}
;
function bp(a, b, c, d, e) {
  var f = a.map
    , g = Date.now() - a.L;
  e ? !a.J && g > a.Ch && (a.J = !0,
    e.dispatchEvent(new $o(b,c,e,d)),
    Fe(f.N(), cp, a.mj, !1, a)) : f.Od(b, c, a.Jn.bind(a, b, c, d))
}
p.Jn = function(a, b, c, d) {
  d = d && Da(d.dispatchEvent) ? d : this.map;
  bp(this, a, b, c, d)
}
;
p.mj = function() {
  this.J && (this.J = !1,
    this.map.dispatchEvent(new ud("contextmenuclose",this.map)))
}
;
p.o = function() {
  var a = this.map.N();
  clearInterval(this.i);
  a && Me(a, cp, this.mj, !1, this);
  Oo.prototype.o.call(this)
}
;
var cp = ["mousedown", "touchstart", "pointerdown", "wheel"];
function dp(a, b, c, d, e) {
  dp.j.constructor.call(this, "wheel");
  this.delta = a;
  this.viewportX = b;
  this.viewportY = c;
  this.target = d;
  this.originalEvent = e
}
u(dp, ud);
t("H.mapevents.WheelEvent", dp);
function ep(a) {
  var b = "onwheel"in document;
  this.T = b;
  this.L = (b ? "d" : "wheelD") + "elta";
  this.i = A(this.i, this);
  ep.j.constructor.call(this, a, [{
    Sa: (b ? "" : "mouse") + "wheel",
    listener: this.i
  }]);
  this.J = this.map.b
}
u(ep, Oo);
ep.prototype.i = function(a) {
  if (!a.kl) {
    var b = Bo(this.f);
    var c = a.pageX - b.x;
    b = a.pageY - b.y;
    var d = this.L, e = a[d + (d + "Y"in a ? "Y" : "")], f;
    cd && "rtl" === x.getComputedStyle(this.J.element).direction && (c -= (x.devicePixelRatio - 1) * this.J.width);
    if (e) {
      var g = Math.abs;
      var h = g(e);
      e = (!(f = a[d + "X"]) || 3 <= h / g(f)) && (!(f = a[d + "Z"]) || 3 <= h / g(f)) ? (+(0 < e) - +(0 > e)) * (this.T ? 1 : -1) : 0
    }
    c = new dp(e,c,b,null,a);
    c.delta && (a.stopImmediatePropagation(),
      a.preventDefault(),
      this.map.Od(c.viewportX, c.viewportY, this.R.bind(this, c)))
  }
}
;
ep.prototype.R = function(a, b) {
  var c = a.target = b || this.map, d, e;
  setTimeout(function() {
    c.dispatchEvent(a);
    a.c || (d = a.originalEvent,
      e = new x.WheelEvent("wheel",d),
      e.kl = !0,
      d.target.dispatchEvent(e))
  }, 0)
}
;
function fp(a) {
  this.i = A(this.i, this);
  Oo.call(this, a, [{
    Sa: "mousedown",
    listener: this.i
  }, {
    Sa: "mousemove",
    listener: this.i,
    target: window
  }, {
    Sa: "mouseup",
    listener: this.i,
    target: window
  }, {
    Sa: "mouseover",
    listener: this.i
  }, {
    Sa: "mouseout",
    listener: this.i
  }, {
    Sa: "dragstart",
    listener: this.J
  }])
}
u(fp, Oo);
fp.prototype.i = function(a) {
  var b = a.type
    , c = Bo(this.f);
  c = {
    x: a.pageX - c.x,
    y: a.pageY - c.y
  };
  var d;
  (d = this.a.a[0] || null) || (d = new Do(c.x,c.y,1,"mouse"),
    this.a.push(d));
  this.b.push(d);
  Eo(d, c.x, c.y);
  /^mouse(?:move|over|out)$/.test(b) ? Qo(this, "pointermove", a) : (/^mouse(down|up)$/.test(b) && (c = a.which - 1,
    "up" === Co.RegExp.$1 ? Ho(d, c) : Fo(d, c)),
    Qo(this, b.replace("mouse", "pointer"), a));
  this.b.clear()
}
;
fp.prototype.J = function(a) {
  a.preventDefault()
}
;
function gp(a) {
  var b = a.b.element.style;
  if (-1 !== hp.indexOf(a))
    throw Error("InvalidArgument: map is already in use");
  this.a = a;
  hp.push(a);
  b.msTouchAction = b.touchAction = "none";
  dd || !window.PointerEvent && !window.MSPointerEvent ? (this.c = new Wo(this.a),
    this.b = new fp(this.a)) : this.c = new Xo(this.a);
  this.g = new ep(this.a);
  this.f = new ap(this.a);
  this.a.tb(this.F, this);
  qd.call(this)
}
u(gp, qd);
t("H.mapevents.MapEvents", gp);
var hp = [];
Ic(hp);
gp.prototype.F = function() {
  delete this.a;
  this.c.F();
  this.g.F();
  this.f.F();
  this.b && this.b.F();
  hp.splice(hp.indexOf(this.a), 1);
  qd.prototype.F.call(this)
}
;
gp.prototype.dispose = gp.prototype.F;
gp.prototype.Nl = function() {
  return this.a
}
;
gp.prototype.getAttachedMap = gp.prototype.Nl;
function ip(a, b) {
  b = void 0 === b ? {} : b;
  var c;
  ip.j.constructor.call(this);
  if (-1 !== jp.indexOf(a))
    throw new D(ip,0,"events are already used");
  this.b = c = a.a;
  this.l = a;
  jp.push(a);
  c.draggable = !0;
  this.i = b.kinetics || {
    duration: 600,
    ease: em
  };
  this.s = b.modifierKey || "Alt";
  this.enable(b.enabled);
  this.c = c.b;
  this.f = this.c.element;
  this.g = 0;
  c.addEventListener("dragstart", this.Mh, !1, this);
  c.addEventListener("drag", this.bk, !1, this);
  c.addEventListener("dragend", this.Lh, !0, this);
  c.addEventListener("wheel", this.rk, !1, this);
  c.addEventListener("dbltap", this.lk, !1, this);
  c.addEventListener("pointermove", this.ck, !1, this);
  Ee(this.f, "contextmenu", this.ak, !1, this);
  a.tb(this.F, this)
}
u(ip, qd);
t("H.mapevents.Behavior", ip);
var jp = [];
Ic(jp);
ip.prototype.a = 0;
var kp = {
  PANNING: 1,
  PINCH_ZOOM: 2,
  WHEEL_ZOOM: 4,
  DBL_TAP_ZOOM: 8,
  FRACTIONAL_ZOOM: 16,
  TILT: 32,
  HEADING: 64
};
ip.Feature = kp;
var lp = kp.PANNING
  , mp = kp.PINCH_ZOOM
  , np = kp.WHEEL_ZOOM
  , op = kp.DBL_TAP_ZOOM
  , pp = kp.FRACTIONAL_ZOOM
  , qp = kp.TILT
  , rp = kp.HEADING
  , sp = lp | mp | np | op | pp | qp | rp;
ip.DRAGGING = lp;
ip.WHEELZOOM = np;
ip.DBLTAPZOOM = op;
ip.FRACTIONALZOOM = pp;
function tp(a, b) {
  if (a !== +a || a % 1 || 0 > a || 2147483647 < a)
    throw new D(b,0,"integer in range [0...0x7FFFFFFF] required");
}
ip.prototype.disable = function(a) {
  var b = this.a;
  a !== B ? (tp(a, this.disable),
    b ^= b & a) : b = 0;
  this.c.endInteraction(!0);
  this.a = b;
  this.b.draggable = 0 < (b & (lp | qp | rp | mp))
}
;
ip.prototype.disable = ip.prototype.disable;
ip.prototype.enable = function(a) {
  var b = this.a;
  a !== B ? (tp(a, this.enable),
    b |= a & sp) : b = sp;
  this.a = b;
  this.b.draggable = 0 < (b & (lp | qp | rp | mp))
}
;
ip.prototype.enable = ip.prototype.enable;
ip.prototype.isEnabled = function(a) {
  tp(a, this.isEnabled);
  return a === (this.a & a)
}
;
ip.prototype.isEnabled = ip.prototype.isEnabled;
function up(a, b, c) {
  var d = "touch" === b.currentPointer.type, e = 0, f;
  if (f = !d) {
    f = a.s;
    var g, h = b.originalEvent;
    h.getModifierState ? g = h.getModifierState(f) : g = !!h[f.replace(/^Control$/, "ctrl").toLowerCase() + "Key"];
    f = g
  }
  f ? e |= qp | rp : (e |= lp,
  d && (b = b.pointers,
  2 === b.length && (e |= mp | rp,
    c ? 55 > Dd(b[0].viewportY - b[1].viewportY) && (e |= qp) : a.uh & Nl.TILT && (e |= qp))));
  e &= a.a;
  return (e & qp ? Nl.TILT : 0) | (e & rp ? Nl.HEADING : 0) | (e & mp ? Nl.ZOOM : 0) | (e & lp ? Nl.COORD : 0)
}
function vp(a) {
  var b = a.pointers;
  a = b[0];
  b = b[1];
  a = [a.viewportX, a.viewportY];
  b && a.push(b.viewportX, b.viewportY);
  return a
}
p = ip.prototype;
p.uh = 0;
p.Mh = function(a) {
  var b = up(this, a, !0);
  if (this.uh = b) {
    var c = this.c;
    a = vp(a);
    c.startInteraction(b, this.i);
    c.interaction.apply(c, a);
    if (this.a & np && !(this.a & pp) && (b = a[0],
      c = a[1],
      this.g)) {
      a = this.b.pb();
      var d = (0 > this.g ? Cd : Bd)(a);
      a !== d && (this.g = 0,
        wp(this, a, d, b, c))
    }
  }
}
;
p.bk = function(a) {
  var b = up(this, a, !1);
  if (b !== this.uh)
    "pointerout" !== a.originalEvent.type && "pointerover" !== a.originalEvent.type && (this.Lh(a),
      this.Mh(a));
  else if (b) {
    b = this.c;
    var c = vp(a);
    b.interaction.apply(b, c);
    a.originalEvent.preventDefault()
  }
}
;
p.Lh = function(a) {
  up(this, a, !1) && this.c.endInteraction(!this.i)
}
;
function wp(a, b, c, d, e) {
  var f = +c - +b;
  a = a.b.c;
  if (isNaN(+b))
    throw Error("start zoom needs to be a number");
  if (isNaN(+c))
    throw Error("to zoom needs to be a number");
  0 !== f && (a.startControl(null, d, e),
    a.control(0, 0, 6, 0, 0, 0),
    a.endControl(!0, function(g) {
      g.zoom = c
    }))
}
p.rk = function(a) {
  if (!a.defaultPrevented && this.a & np) {
    var b = a.delta;
    var c = this.b.pb();
    var d = this.b;
    var e = d.f.type;
    d = this.a & pp ? c - b : (0 > -b ? Cd : Bd)(c) - b;
    if (e === kk.P2D || e === kk.WEBGL || e === kk.HARP)
      wp(this, c, d, a.viewportX, a.viewportY),
        this.g = b;
    a.preventDefault()
  }
}
;
p.ck = function() {}
;
p.lk = function(a) {
  var b = a.currentPointer
    , c = this.b.pb()
    , d = a.currentPointer.type
    , e = this.b.f.type;
  (e === kk.P2D || e === kk.WEBGL || e === kk.HARP) && this.a & op && (a = "mouse" === d ? 0 === a.originalEvent.button ? -1 : 1 : 0 < a.pointers.length ? 1 : -1,
    a = this.a & pp ? c - a : (0 > -a ? Cd : Bd)(c) - a,
    wp(this, c, a, b.viewportX, b.viewportY))
};
p.ak = function(a) {
  return this.a & op ? (a.preventDefault(),
    !1) : !0
}
;
p.F = function() {
  var a = this.b;
  a && (a.draggable = !1,
    a.removeEventListener("dragstart", this.Mh, !1, this),
    a.removeEventListener("drag", this.bk, !1, this),
    a.removeEventListener("dragend", this.Lh, !0, this),
    a.removeEventListener("wheel", this.rk, !1, this),
    a.removeEventListener("dbltap", this.lk, !1, this),
    a.removeEventListener("pointermove", this.ck, !1, this),
    delete this.b);
  this.f && (this.f.style.msTouchAction = "",
    Me(this.f, "contextmenu", this.ak, !1, this),
    delete this.f);
  delete this.c;
  delete this.i;
  jp.splice(jp.indexOf(this.l), 1);
  delete this.l;
  qd.prototype.F.call(this)
}
;
ip.prototype.dispose = ip.prototype.F;
t("H.mapevents.buildInfo", function() {
  return Hf("H-mapevents", "1.49.1", "74e5afb", {
    region: "row"
  })
});
`

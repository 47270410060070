<div class="c-toolbox-nav__wrapper" [@showList]="isHover" (mouseenter)="mouseOver(true)"
     (mouseleave)="mouseOver(false)">
    <ul class="c-toolbox-nav">
        <ng-container *ngFor="let link of links">
            <li class="c-toolbox-nav__element"
                *ngIf="hasPermission(link)"
                routerLink="{{link.link}}"
                routerLinkActive="active"
                (click)="routeClick($event, link.link)"
                [routerLinkActiveOptions]="link.link === '' ? {exact:true} : {}">
                <i ngClass="{{link.icon}}"></i><span>{{link.label|translate}}</span>
            </li>
        </ng-container>
    </ul>
</div>

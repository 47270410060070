import { NgModule } from '@angular/core';
import { NormsPopupComponent } from './norms-popup.component';
import { SharedModule } from '../../../shared/shared.module';
import { NormsPopupRoutingModule } from './norms-popup-routing.module';
import { NormTemplateComponent } from './norm-template/norm-template.component';

@NgModule({
  declarations: [NormsPopupComponent, NormTemplateComponent],
  exports: [
    NormTemplateComponent
  ],
  imports: [
    SharedModule,
    NormsPopupRoutingModule,
  ]
})
export class NormsPopupModule {
}

<div [ngClass]="[selectClass ? selectClass : '', isOpen ? 'is-open' : '']" *ngIf="!(isDisabled && multiple)">
    <app-form-group>
        <ng-content></ng-content>
        <input type="hidden" [ngModel]="this.selectedItems" app-form [disabled]="isDisabled">
        <ng-select
                [items]="items"
                [multiple]="multiple"
                [closeOnSelect]="!multiple"
                [searchable]="searchable"
                [disabled]="isDisabled"
                [clearable]="allowClear && !showTags"
                [bindLabel]="bindLabel"
                [placeholder]="placeholder"
                [ngModel]="this.selectedItems"
                [bindValue]="bindValue"
                (ngModelChange)="selectChange($event)"
                (open)="setOpenState(true)"
                (close)="setOpenState(false)"
        >
            <ng-template ng-multi-label-tmp let-items="items">
                {{selectItems(items)}}
            </ng-template>
          <ng-template ng-label-tmp let-item="item">
            {{(item[bindLabel] || item)|translate}}
          </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="form-checkbox">
                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                           [ngModelOptions]="{standalone: true}"/><span></span> {{(label(item) || item)|translate}}
                </div>
            </ng-template>
        </ng-select>
    </app-form-group>
</div>
<div *ngIf="showTags && multiple" class="c-select-input-tags">
    <app-selected-tags [items]="selectedTags"
                       [disabled]="isDisabled"
                       [removable]="!isDisabled && allowClear"
                       (removeItem)="removeItem($event)"
                       (removeAll)="removeAll()"
                       [bindLabel]="bindLabel"
    >
    </app-selected-tags>
</div>

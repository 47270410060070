<div class="d-flex flex-fill justify-content-between align-items-stretch flex-column h-100">
    <div class="p-xm border-primary border-bottom">
        <h2 translate>{{isNewView ? 'ADD_NEW_POI_GROUP' : poiGroup?.name}}</h2>
    </div>
    <div class="p-xm flex-grow-1 overflow-auto" [formGroup]="forms?.main">
        <h3 class="form-header mt-xl" translate>BASIC_DATA</h3>
        <div class="row">
            <div class="col-12 mb-xm">
                <app-form-group>
                    <label translate>NAME</label>
                    <input app-form formControlName="name" type="text">
                    <app-error-message [control]="forms?.main.get('name')"></app-error-message>
                </app-form-group>
            </div>

            <div class="col-6 ">
                <app-color-input formControlName="color">
                    <app-error-message [control]="forms?.main.get('color')"></app-error-message>
                </app-color-input>


            </div>
            <div class="col-6">
                <app-form-group>
                    <label translate>RADIUS</label>
                    <input app-form formControlName="radius" type="number">
                    <app-error-message [control]="forms?.main.get('radius')"></app-error-message>
                </app-form-group>
            </div>
        </div>
        <div class="row mt-m align-items-center">
            <div class="col-6">
                <app-form-group>
                    <label translate>
                        <input app-form formControlName="showOnMap" type="checkbox">
                        <span></span>
                        SHOW_ON_MAP</label>
                    <app-error-message [control]="forms?.main.get('showOnMap')"></app-error-message>
                </app-form-group>
            </div>
        </div>
    </div>
    <div class="p-xm d-flex justify-content-end align-items-center border-top">
        <button class="btn btn-primary btn-border px-xxl mr-m" (click)="cancel()" translate>
            {{(isEditMode ? 'CANCEL' : 'EXIT')|translate}}
        </button>
        <button class="btn btn-primary px-xxl" (click)="handlerSave()" [disabled]="pending" *ngIf="showButton">
            <app-spinner [showSpinner]="pending"></app-spinner>
            {{(isNewView || isEditMode ? 'SAVE' : 'EDIT')|translate}}
        </button>
    </div>
    <app-overflow-spinner [showSpinner]="pending"></app-overflow-spinner>
</div>

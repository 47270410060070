<div class="c-notification"
     [ngClass]="{'c-notification--is-new': !notification.isViewed, 'c-notification--is-not-read': !notification.isRead}"
>
    <div class="d-flex justify-content-between align-items-start" (click)="notificationClick()" >
        <p class="pr-m" [ngSwitch]="notification.type">
            <app-notification-norm *ngSwitchCase="notificationType.NORM"
                                   [notification]="notification.data.NORM"></app-notification-norm>
            <app-notification-norm-adhoc *ngSwitchCase="notificationType.NORM_ADHOC"
                                 [notification]="notification.data.NORM_ADHOC"></app-notification-norm-adhoc>
            <app-notification-reminder *ngSwitchCase="notificationType.REMINDER"
                                   [notification]="notification.data.REMINDER"></app-notification-reminder>
            <app-notification-zone *ngSwitchCase="notificationType.ZONE_CHANGE"
                                   [notification]="notification.data.ZONE_CHANGE"></app-notification-zone>
            <app-notification-norm-aggregate *ngSwitchCase="notificationType.NORM_AGGREGATE"
                                 [notification]="notification.data.NORM_AGGREGATE"></app-notification-norm-aggregate>
            <span *ngSwitchDefault>nie mam typu notyfikacji</span>
        </p>
        <p class="c-notification__eventTime">  {{notification.eventTime|date:'HH:mm dd.MM.yyyy'}}</p>
    </div>


</div>

import { BASE_CONF } from './env_config';

export const environment = {
  ...BASE_CONF,
  production: false,
  stage: true,
  themes: {
    mobiSystems: {
      host: 'stage.mobisystems.pl',
      apiUrl: 'https://stage-api.mobisystems.pl',
      cssName: 'localhost-theme',
      title: 'MobiSystems',
      favicon: 'mobi1_favicon',
    },
    data247: {
      host: 'stage.data247.pl',
      apiUrl: 'https://stage-api.data247.pl',
      cssName: 'data247-theme',
      title: 'Data247',
      favicon: 'data247_favicon',
    },
    connectedBoat: {
      host: 'stage.connected.eu',
      apiUrl: 'https://stage-api.connected.eu',
      cssName: 'connectedboat-theme',
      title: 'Connected Boat',
      favicon: 'cb1_favicon',
    },
    cbMarinaOjeju: {
      host: 'stage-marinaojeju.connected.eu',
      apiUrl: 'https://stage-api.connected.eu',
      cssName: 'connectedboat-theme;marinaojeju-theme',
      title: 'Marina Ojeju Monitoring',
      favicon: 'cb1_favicon',
    },
  },
};

import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { tap } from 'rxjs/operators';
import { PowerBiService } from '../../../core/services/power-bi.service';
import { Report } from 'report';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-power-bi',
  templateUrl: './power-bi.component.html',
  styleUrls: ['./power-bi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('showReport', [
      state('show', style({
        overflow: 'hidden',
        height: '*',
      })),
      state('hide', style({
        opacity: '0',
        overflow: 'hidden',
        height: '0px',
      })),
      transition('show <=> hide', animate('400ms ease-in-out'))
    ])
  ]
})
export class PowerBiComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('reportContainer', { static: true }) reportContainer: ElementRef;
  @Input() filters;
  @Input() userID;
  @Input() reportId: string;
  @Input() ratio: string;
  @Input() height: number;
  @Input() show = null;
  @Input() autoLoad = true;
  private setFilterOnInit = false;
  private embedReport: Report;
  public pendingValue = true;

  get pending() {
    return this.pendingValue;
  }

  set pending(value) {
    this.pendingValue = value;
    this.cd.detectChanges();
  }

  constructor(private service: PowerBiService, private cd: ChangeDetectorRef) {
  }

  get hasPadding() {
    return !!this.ratio;
  }

  get animationState() {
    return this.show === null ? null : this.show ? 'show' : 'hide';
  }

  get styles() {
    return this.hasPadding ? {'padding-top': `${this.ratio}%`} : {height: `${this.height}px`};
  }


  loadPowerBi() {
    this.service
      .generateTokenAndShowReport(this.reportContainer.nativeElement, this.reportId,
        this.filters ? this.filters : [])().pipe(
      tap(embeded => this.embedReport = embeded)
    ).subscribe(() => {
      this.embedReport.on('rendered', () => {
        this.pending = false;
      });
      this.embedReport.on('error', () => {
        this.pending = false;
      });
      if (this.setFilterOnInit) {
        this.setFilter();
      } else {
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.autoLoad) {
      this.loadPowerBi();
    }
  }

  initLoad() {
    if (!this.autoLoad && !this.embedReport) {
      this.loadPowerBi();
    }
  }

  showReportDone() {
    window.dispatchEvent(new Event('resize'));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filters && changes.filters.currentValue && !changes.filters.firstChange) {
      if (this.embedReport) {
        this.setFilter();
      } else {
        this.setFilterOnInit = true;
      }
    }
  }

  private setFilter() {
    this.pending = true;
    this.embedReport.setFilters(this.filters);
  }

  toggleShow() {
    this.show = !this.show;
    if (this.show) {
      this.initLoad();
    }
    this.cd.detectChanges();
  }

  markForChck() {
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.embedReport) {
      this.embedReport.off('rendered');
      this.embedReport.off('error');
      this.embedReport.iframe.remove();
    }
  }
}

import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

export enum PERMISSIONS {
  'DRIVER_ACCESS_ALL' = 'driver_access-all',
  'VEHICLE_GROUP_ACCESS-ALL' = 'vehicle-group_access-all',
  'POI_GROUP_ACCESS-ALL' = 'poi-group_access-all',
  'DRIVER_GROUP_VIEW' = 'driver-group_view',
  'DRIVER_VIEW' = 'driver_view',
  'POI_GROUP_VIEW' = 'poi-group_view',
  'DRIVER_GROUP_ACCESS-ALL' = 'driver-group_access-all',
  'BI_VIEW' = 'bi_view',
  'DRIVER_CREATE' = 'driver_create',
  'DRIVER_DELETE' = 'driver_delete',
  'DRIVER_ID' = 'driver_id',
  'DRIVER_GROUP_CREATE' = 'driver-group_create',
  'DRIVER_GROUP_DELETE' = 'driver-group_delete',
  'DRIVER_GROUP_UPDATE' = 'driver-group_update',
  'DRIVER_UPDATE' = 'driver_update',
  'SYSTEM_SUPERADMIN' = 'system_superadmin',
  'VEHICLE_VIEW' = 'vehicle_view',
  'POI_ACCESS_ALL' = 'poi_access-all',
  'POI_VIEW' = 'poi_view',
  'VEHICLE_GROUP_VIEW' = 'vehicle-group_view',
  'VEHICLE_ACCESS_ALL' = 'vehicle_access-all',
  'POI_CREATE' = 'poi_create',
  'POI_DELETE' = 'poi_delete',
  'POI_RECALC' = 'poi_recalc',
  'POI_GROUP_CREATE' = 'poi-group_create',
  'POI_GROUP_DELETE' = 'poi-group_delete',
  'POI_GROUP_UPDATE' = 'poi-group_update',
  'POI_SHOW_ON_MAP' = 'poi_show-map',
  'POI_UPDATE' = 'poi_update',
  'USER_CREATE' = 'user_create',
  'USER_DELETE' = 'user_delete',
  'USER_UPDATE' = 'user_update',
  'USER_UPDATE_ROLES' = 'user_update-roles',
  'USER_VIEW' = 'user_view',
  'VEHICLE_CREATE' = 'vehicle_create',
  'VEHICLE_DELETE' = 'vehicle_delete',
  'VEHICLE_GROUP_CREATE' = 'vehicle-group_create',
  'VEHICLE_GROUP_DELETE' = 'vehicle-group_delete',
  'VEHICLE_GROUP_UPDATE' = 'vehicle-group_update',
  'VEHICLE_UPDATE' = 'vehicle_update',
  'REMINDER_VIEW' = 'reminder_view',
  'REMINDER_UPDATE' = 'reminder_update',
  'WEB_ACCOUNT' = 'web_account',
  'WEB_DASHBOARD' = 'web_dashboard',
  'WEB_DRIVERS' = 'web_drivers',
  'WEB_DRIVING_STYLE' = 'web_driving-style',
  'WEB_ECO' = 'web_eco',
  'WEB_FUEL' = 'web_fuel',
  'WEB_MAP' = 'web_map',
  'WEB_NORMS' = 'web_norms',
  'WEB_NOTIFICATIONS' = 'web_notifications',
  'WEB_NOTIFICATIONSP' = 'web_notificationsp',
  'WEB_POI' = 'web_poi',
  'WEB_REPORTS' = 'web_reports',
  'WEB_SCHEDULE' = 'web_schedule',
  'WEB_VEHICLES' = 'web_vehicles',
  'WEB_WORK' = 'web_work',
  'WEB_WORK_ANALYSIS' = 'web_work-analysis',
  'WEB_ZONES' = 'web_zones',
  'WEB_BORDERS' = 'web_borders',
  'WEB_INPUTS' = 'web_inputs',
  'WEB_TEMPERATURE' = 'web_temperature',
  'WEB_ADMIN' = 'web_admin',
  'WEB_SPEECH' = 'web_speech',
  'ZONE_ACCESS_ALL' = 'zone_access-all',
  'ZONE_CREATE' = 'zone_create',
  'ZONE_DELETE' = 'zone_delete',
  'ZONE_GROUP_ACCESS-ALL' = 'zone-group_access-all',
  'ZONE_GROUP_CREATE' = 'zone-group_create',
  'ZONE_GROUP_DELETE' = 'zone-group_delete',
  'ZONE_GROUP_UPDATE' = 'zone-group_update',
  'ZONE_GROUP_VIEW' = 'zone-group_view',
  'ZONE_UPDATE' = 'zone_update',
  'ZONE_VIEW' = 'zone_view',
  'WEB_BALANCE' = 'web_balance',
  'WORK_PLAN_VERIFICATION' = 'work_plan-verification',
  'FUEL_DETAILS' = 'web_fuel-details',
  'VEHICLE_CHARTS' = 'web_veh-chart',
  'REPORT_PERIODIC' = 'report_periodic',
  'RIDES_NODATA' = 'rides_nodata',
  'STATUS_VIEW' = 'status_view',
  'STATUS_UPDATE' = 'status_update',
  'SCHEDULE_RECALC' = 'schedule_recalc',
  'NORM_VIEW' = 'norm_view',
  'NORM_UPDATE' = 'norm_update',
  'NORM_CREATE' = 'norm_create',
  'NORM_DELETE' = 'norm_delete',
  'MAP_COPY' = 'map_copy',
  'MAP_V_AVATAR' = 'map_v-avatar',
  'DEVICE_VIEW' = 'device_view',
  'DEVICE_CREATE' = 'device_create',
  'DEVICE_UPDATE' = 'device_update',
  'DEVICE_DELETE' = 'device_delete',
}

export type Permission = PERMISSIONS | PERMISSIONS[];

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private userPermission: PERMISSIONS[];
  private isSuperAdmin = false;
  public permissions = PERMISSIONS;

  constructor(private authService: AuthService) {
    this.authService.isLoggedIn$.subscribe((isLogged) => {
      this.isSuperAdmin = false;
      if (isLogged) {
        const tokenInfo = this.authService.tokenInfo;
        if (tokenInfo) {
          this.userPermission = tokenInfo.user.abilities;
          this.permissionEnum();
        }
      } else {
        this.userPermission = null;
      }
    });
  }

  private permissionEnum() {
    let data = '';
    this.userPermission.forEach(item => {
      data += `'${item.replace('-', '_').toUpperCase()}' = '${item}',
        `;
    });
  }

  hasPermission(permission: Permission): boolean {
    if (!permission || !this.userPermission) {
      return false;
    }
    if (this.isSuperAdmin) {
      return true;
    }
    if (typeof permission === 'string') {
      return this.permission.indexOf(permission) !== -1;
    } else if (Array.isArray(permission)) {
      return !!permission.find((perm) => this.permission.indexOf(perm) !== -1);
    }
    return false;
  }

  get permission() {
    return this.userPermission;
  }

  get permissionList() {
    return PERMISSIONS;
  }
}



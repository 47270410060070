import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit, Injectable } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { withZero } from '../../../../../utils/utils';
import { Info } from 'luxon';
import {NgbTimeStruct, NgbTimeAdapter} from '@ng-bootstrap/ng-bootstrap';


@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {

  fromModel(value: string): NgbTimeStruct {
    if (!value) {
      return null;
    }
    const split = value.split(':');
    return {
      hour: parseInt(split[0], 10),
      minute: parseInt(split[1], 10),
      second: 0
    };
  }

  toModel(time: NgbTimeStruct): string {
    if (!time) {
      return null;
    }
    return `${this.pad(time.hour)}:${this.pad(time.minute)}`;
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }
}

const TIMES_ARRAY = [];

for (let i = 0; i < 24; i++) {
  TIMES_ARRAY.push(`${withZero(i)}:00`);
}

@Component({
  selector: 'app-hour-range-input',
  templateUrl: './hour-range-input.component.html',
  styleUrls: ['./hour-range-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HourRangeInputComponent),
      multi: true
    },
    {provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter}
  ]
})
export class HourRangeInputComponent implements ControlValueAccessor, OnInit {
  public timeModel = {from: null, to: null};
  public time = null;
  public timesfrom;
  public disabled = false;
  public timesto;
  public dayLabel = '';
  public isSelect = false;
  public times = TIMES_ARRAY;
  @Input() dayNumber: number;

  getTimesBy(type = 'from') {
    const toKey = type === 'from' ? 'to' : 'from';
    this['times' + type] = this.timeModel && this.timeModel[toKey]
      ? this.times.filter((value) => type === 'from' ? value.localeCompare(this.timeModel[toKey]) < 0
        : this.timeModel[toKey].localeCompare(value) < 0) : this.times;
    this.cd.detectChanges();
  }


  constructor(private cd: ChangeDetectorRef) {

  }

  toggleSelect() {
    if (this.isSelect) {
      this.value = null;
    } else {
      this.value = {
        from: '08:00',
        to: '16:00',
      };
    }
    // this.isSelect = !this.isSelect;
  }

  timesChange() {
    this.getTimesBy();
    this.getTimesBy('to');
    this.cd.detectChanges();
  }

  set value(value) {
    this.setValue(value);
    this.onChange(value);
    this.onTouch(value);
  }

  timesCalculate() {

  }

  numberToString(value) {
    return value ? Object.keys(value).reduce((p, c) => ({...p, [c]: !isNaN(value[c]) ? `${withZero(value[c])}:00` : value[c]}), {}) : value;
  }

  setValue(value) {
    if (value && (value.from || value.to)) {
      this.isSelect = true;
      this.timeModel = Object.assign(this.timeModel || {}, value);
    } else {
      this.isSelect = false;
      this.timeModel = null;
    }
    this.timesChange();
  }

  modelChange(value, type) {
    if (value || value === 0) {
      this.value = Object.assign({}, this.timeModel, {[type]: value});
    }
  }

  onChange: any = () => {
  }

  onTouch: any = () => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cd.detectChanges();
  }

  writeValue(obj: any): void {
    this.setValue(this.numberToString(obj));
  }

  ngOnInit(): void {
    this.dayLabel = Info.weekdays()[this.dayNumber];
  }

}

<div class="d-flex justify-content-between align-items-stretch">
    <section>
        <div class="d-flex justify-content-start align-items-center">
            <div class="mr-m">
                <app-user-avatar size="54"
                                 [initials]="user|fullName|initials"
                                 [avatar]="user.avatarUrl">

                </app-user-avatar>
            </div>
            <div>
                <h2>{{user|fullName}}</h2>
            </div>

        </div>

    </section>
    <section>
        <p class="text-primary m-0 mt-s text-right"><strong>{{user.login}}</strong></p>
        <p *ngIf="user.email" class="m-0 mt-s text-right"> {{user.email}}</p>
    </section>
</div>

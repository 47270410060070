<div class="flex-row d-flex justify-content-start align-items-center c-sort-btn"
     (click)="sortClick()"
     [ngClass]="[sortDirection, isActive ? 'c-sort-btn--active' : '' ]">
  <p><span [innerHTML]="label"></span>
    <ng-content></ng-content>
  </p>
  <i class="icon-chevron-down c-sort-btn__carret">

  </i>
</div>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationT, NotificationType } from '../../../../../interfaces/notificationT';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent implements OnInit {
  @Input() notification: NotificationT;
  @Output() clicked = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  get notificationType() {
    return NotificationType;
  }

  notificationClick() {
    console.log(`notification clickek: ${this.notification.type}`);
    this.clicked.emit(this.notification);
  }

}

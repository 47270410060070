import { Injectable } from '@angular/core';
import { Driver } from '../../interfaces/driver';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiServiceWithSub } from '../../classes/api-service-with-sub';
import { AuthService } from './auth.service';
import { Device } from 'src/app/interfaces/device';
import { Command } from 'selenium-webdriver';

@Injectable({
  providedIn: 'root'
})
export class DevicesService extends ApiServiceWithSub<Device> {
  protected inludeList = ['vehicle'];
  protected partnerBaseUrl = '';

  constructor(config: ConfigService,
              authService: AuthService,
              http: HttpClient) {
    super('/devices', config, http, authService);
    this.partnerBaseUrl = this.baseUrl.replace('devices', 'partner/devices');
    this.initList();
  }

  getCommands(deviceId: string): Observable<Command[]> {
    return this.http.get<Command[]>(`${this.partnerBaseUrl}/${deviceId}/command`);
  }

  sendCommand(deviceId: string, data: string) {
    return this.http.post(`${this.partnerBaseUrl}/${deviceId}/command`, { data });
  }
}

<div class="v-authentication__view">
    <div class="mb-xxl">
        <app-logo width="100%"></app-logo>
    </div>
    <h1 class="headline  text-center">Reset hasła</h1>


    <form [formGroup]="form" (ngSubmit)="submit()" autocomplete="off">
        <div [ngSwitch]="isSend" class="my-l">
            <p *ngSwitchCase="true" class="mb-5">Zgłoszenie przyjęte. Wkrótce otrzymasz instrukcje pocztą elektroniczną.</p>
            <div *ngSwitchDefault>
                <app-form-group>
                    <label for="username">Login</label>
                    <input name="username"
                           id="username"
                           type="email"
                           formControlName="username"
                           app-form
                    >
                    <app-error-message [control]="form.controls['username']"
                                       [apiError]="formErrors?.username"></app-error-message>
                </app-form-group>
            </div>
        </div>
        <button class="btn btn-primary w-100" [disabled]="formPending || isSend">
            <app-spinner [showSpinner]="formPending" color="white"></app-spinner>
            <i class="i-check"></i> Wyślij
        </button>
        <app-error-message [control]="form" [apiError]="formErrors?.non_field_errors"></app-error-message>
        <div class="u--text-13 u--content-block mt-l  text-center">
            <a routerLink="/login" class="u--underline btn btn-link">Zaloguj</a>
        </div>

    </form>
</div>

import { NgModule } from '@angular/core';
import { VehicleGroupPopupComponent } from './vehicle-group-popup/vehicle-group-popup.component';
import { SharedModule } from '../../../shared/shared.module';
import { GroupsPopupRoutingModule } from './groups-popup-routing.module';
import { GroupsPopupComponent } from './groups-popup.component';
import { PoiGroupsPopupComponent } from './poi-groups-popup/poi-groups-popup.component';
import { DriverGroupPopupComponent } from './driver-group-popup/driver-group-popup.component';
import { VehicleGroupDetailsComponent } from './vehicle-group-popup/vehicle-group-details/vehicle-group-details.component';
import { VehicleGroupPoiComponent } from './vehicle-group-popup/vehicle-group-poi/vehicle-group-poi.component';
import { VehicleGroupNewComponent } from './vehicle-group-popup/vehicle-group-new/vehicle-group-new.component';
import { VehicleGroupResolve } from './vehicle-group-popup/vehicle-group.resolve';
import { DriverGroupDetailsComponent } from './driver-group-popup/driver-group-details/driver-group-details.component';
import { DriverGroupNewComponent } from './driver-group-popup/driver-group-new/driver-group-new.component';
import { DriverGroupPoiComponent } from './driver-group-popup/driver-group-poi/driver-group-poi.component';
import { DriverGroupResolve } from './driver-group-popup/driver-group.resolve';

@NgModule({
  declarations: [VehicleGroupPopupComponent, GroupsPopupComponent,
    PoiGroupsPopupComponent,
    DriverGroupPopupComponent,
    VehicleGroupDetailsComponent,
    VehicleGroupPoiComponent,
    VehicleGroupNewComponent,
    DriverGroupDetailsComponent,
    DriverGroupNewComponent,
    DriverGroupPoiComponent],
  providers: [VehicleGroupResolve, DriverGroupResolve],
  imports: [
    SharedModule,
    GroupsPopupRoutingModule,
  ]
})
export class GroupsPopupModule {
}

import { NgModule } from '@angular/core';

import { PoiPopupRoutingModule } from './poi-popup-routing.module';
import { PoiPopupComponent } from './poi-popup/poi-popup.component';
import { SharedModule } from '../../../shared/shared.module';
import { PoiPopupBasicComponent } from './poi-popup/poi-popup-basic/poi-popup-basic.component';
import { PoiPopupDashboardComponent } from './poi-popup/poi-popup-dashboard/poi-popup-dashboard.component';
import { PoiPopupNewComponent } from './poi-popup-new/poi-popup-new.component';

@NgModule({
  declarations: [PoiPopupComponent, PoiPopupBasicComponent, PoiPopupDashboardComponent, PoiPopupNewComponent],
  imports: [
    SharedModule,
    PoiPopupRoutingModule
  ]
})
export class PoiPopupModule {
}

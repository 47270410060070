import { Component, OnDestroy, OnInit } from '@angular/core';
import { PerspectiveMenuService } from '../../../shared/modules/perspective-menu/perspective-menu.service';

@Component({
  selector: 'app-groups-popup',
  templateUrl: './groups-popup.component.html',
  styleUrls: ['./groups-popup.component.scss']
})
export class GroupsPopupComponent implements OnInit, OnDestroy {

  constructor(  private perspectiveMenu: PerspectiveMenuService) { }

  ngOnInit() {
    this.perspectiveMenu.popupSizeType = 'normal';
  }

  ngOnDestroy(): void {
    this.perspectiveMenu.popupSizeType = 'normal';
  }

}

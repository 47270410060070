import { UntypedFormGroup } from '@angular/forms';
import { OnInit, Directive } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiValidatorService } from '../core/services/api-validator.service';


@Directive()
export abstract class FormController implements OnInit {
  formPending = false;
  formErrors: any;
  form: UntypedFormGroup;
  protected avatarState;
  protected rawValues = false;

  protected constructor(protected aV: ApiValidatorService) {
  }

  ngOnInit() {
    this.buildForm();
    this.runOnInit();
  }

  runOnInit() {
  }

  removeNullValues(formValues) {
    return Object.keys(formValues).reduce((previousValue, currentValue) => {
      if (formValues[currentValue]) {
        previousValue[currentValue] = formValues[currentValue];
      }
      return previousValue;
    }, {});
  }

  errorHandler(response: HttpErrorResponse) {
    this.formPending = false;
    this.formErrors = response.error;
  }

  submit() {
    if (!this.aV.formIsValid(this.form) || this.formPending) {
      return;
    }
    this.formPending = true;
    this.formErrors = null;
    this.processSubmit(this.rawValues ? this.form.getRawValue() : this.form.value);
  }

  abstract processSubmit(formValues);

  abstract buildForm();
}

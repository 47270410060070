import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-color-input',
  templateUrl: './color-input.component.html',
  styleUrls: ['./color-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorInputComponent),
      multi: true
    }
  ]
})
export class ColorInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  color: string;
  isDisabled = false;

  constructor() {
  }

  onChange: any = () => {
  }
  onTouch: any = () => {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  onColorChange($event) {
    this.color = $event;
    this.emmitEvent();
  }


  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  emmitEvent() {
    this.onChange(this.color);
    this.onTouch(this.color);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.color = obj;
  }
}

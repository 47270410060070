import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { ViewsRoutingModule } from './views-routing.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { ServerErrorComponent } from './server-error/server-error.component';
import { PopupsModule } from './popups/popups.module';


@NgModule({
  declarations: [ServerErrorComponent],
  imports: [
    SharedModule,
    DashboardModule,
    PopupsModule,
    AuthenticationModule,
    ViewsRoutingModule,
  ]
})
export class ViewsModule {
}

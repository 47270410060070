<div class="d-flex justify-content-between align-items-stretch">
    <section class="driver_header__driver">
        <div class="d-flex justify-content-start align-items-center">
            <!-- <div class="mr-m">
                <app-user-avatar size="54"
                                 [initials]="driver|fullName|initials"
                                 [avatar]="driver.avatarUrl">

                </app-user-avatar>
            </div> -->
            <div>
                <h2>{{device.imei}}</h2>
                <!-- <app-tags-group [tags]="driver.groups" size="lg"  iconClass="icon-account"></app-tags-group> -->
            </div>

        </div>
        <p *ngIf="device.type" class="text-primary m-0 mt-s">{{device.type || '-'}}</p>
        <!-- <p *ngIf="driver.email" class="m-0 mt-s"> {{driver.email}}</p> -->
    </section>
    <section class="driver_header__vehicle d-flex justify-content-between align-items-end flex-column flex-fill">
        <div class="d-flex justify-content-start align-items-center" *ngIf="device.vehicle">
            <div class="mr-m">
                <app-vehicle-avatar size="40"
                                    [avatar]="device.vehicle?.avatarUrl">

                </app-vehicle-avatar>
            </div>
            <app-label-with-tags
                    [label]="device.vehicle?.name"
                    (labelClick)="openPopup(device.vehicle.id, 'partner-vehicle', 'extensions')"

            >

            </app-label-with-tags>
        </div>
        <!-- <app-header-location-status [vehicle]="driver?.vehicle"></app-header-location-status> -->
    </section>
</div>

<div>
<div class="d-flex justify-content-end align-items-center" [formGroup]="chartForm">
  <div class="col-auto min-width">
    <app-select-input
            bindLabel="name"
            bindValue="propertyName"
            [max]="1"
            formControlName="propertyName"
            [items]="sensorProperties"
    ><label for="">Parametr</label></app-select-input>
  </div>
  <div class="col-auto min-width">
    <app-select-input
            [multiple]="false"
            [showTags]="false"
            [max]="1"
            formControlName="axisDimmension"
            [items]="dimmensions"
    ><label for="">Wykres wg</label></app-select-input>
  </div>
    <div class="date-range">
        <app-date-range-filter (changeRange)="dateFilterChange($event)" [date]="dateFilters"
                              [eventOnInit]="false"></app-date-range-filter>
    </div>
</div>
<div class="scroll-wrapper position-relative pt-0">
  <ngx-charts-line-chart
    [view]="view"
    [legend]="legend"
    [legendTitle]="legendTitle"
    legendPosition="below"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxis]="xAxis"
    [yAxis]="yAxis"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [timeline]="timeline"
    [results]="multi"
    [xAxisTickFormatting]='formatAxisLabel'
    (select)="chartClick($event)"
    >
    <ng-template #seriesTooltipTemplate let-model="model">
        <p>{{model[0].extras.eventDate| date : 'yyyy-MM-dd HH:mm'}}</p>
        <p *ngFor="let serie of model">{{ serie.extras.label }}: {{serie.value}}</p>
        <p>{{ 'DISTANCE' | translate }}: {{model[0].extras.totalDistance}} km</p>
    </ng-template>

    <ng-template #tooltipTemplate let-model="model">
      <p>{{model.extras.eventDate| date : 'yyyy-MM-dd HH:mm'}}</p>
      <p>{{ model.extras.label }}: {{model.value}}</p>
      <p>{{ 'DISTANCE' | translate }}: {{model.extras.totalDistance}} km</p>
  </ng-template>
  </ngx-charts-line-chart>

</div>
  <app-map height="300px" id="map_poi">
    <app-map-marker-poi *ngIf="mapLabel !== null" [coordinates]="coordinates" [name]="mapLabel" showLabel="true" radius="50" showCircle="true"></app-map-marker-poi>
  </app-map>
</div>
<app-overflow-spinner [showSpinner]="pending"></app-overflow-spinner>

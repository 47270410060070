import { Pipe, PipeTransform } from '@angular/core';
import { minutesFormat } from '../../utils/utils';
import { WarehouseResponseData } from '../../interfaces/warehouse';

@Pipe({
  name: 'warehouseUnit'
})
export class WarehouseUnitPipe implements PipeTransform {

  private withZero(value: number): string {
    return value > 9 ? value.toString() : '0' + value;
  }


  private secFormat(value): string {
    return minutesFormat(Math.round(value / 60));
  }

  transform(data: WarehouseResponseData, digits = 2, skipUnit = false): string {
    if (!data) {
      return null;
    }

    if (skipUnit) {
      return data.v !== null ? `${(+data.v.toFixed(digits)).toString().replace('.', ',')}`  : '-';
    }

    switch (data.unit) {
      case 'number':
        return data.v.toString().replace('.', ',');
      case 'minutes':
        return minutesFormat(data.v);
      case 'sec':
        return this.secFormat(data.v);
      default :
        return data.v !== null ? `${(+data.v.toFixed(digits)).toString().replace('.', ',')} ${data.unit || ''}` : '-';
    }
  }

}

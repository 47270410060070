import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-driver-group-new',
  templateUrl: './driver-group-new.component.html',
  styleUrls: ['./driver-group-new.component.scss']
})
export class DriverGroupNewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import ApiServiceClass from '../../../classes/api-service.class';
import { ConfigService } from '../../../core/services/config.service';
import { HttpClient } from '@angular/common/http';
import { Zone } from '../../../interfaces/zone';

export const ZONE_DEFAULT_COLOR = '#6E77FF';

@Injectable({
  providedIn: 'root'
})
export class ZonesService extends ApiServiceClass<Zone> {

  constructor(config: ConfigService,
              http: HttpClient) {
    super('/zones', config, http);
  }

}

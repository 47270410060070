import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '../../../../core/services/notifications.service';
import { map } from 'rxjs/operators';
import { NotificationT } from '../../../../interfaces/notificationT';
import { PERMISSIONS, PermissionService } from 'src/app/core/services/permission.service';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls: ['./notification-bell.component.scss']
})
export class NotificationBellComponent implements OnInit {
  public notifications$ = this.notiificationsService.collection({limit: 10, offset: 0}).pipe(map(res => res.collection));
  public notifications: NotificationT[] = [];
  public pending = false;
  readonly permissions = PERMISSIONS;

  private speechToken: { token: string, region: string } = null;

  constructor(public notiificationsService: NotificationsService,
    protected authService: AuthService,
    protected permissionService: PermissionService,
  ) {
  }

  ngOnInit() {
    if (this.permissionService.hasPermission(this.permissions.WEB_SPEECH)) {
      this.getSpeechToken();
    }
  }

  toggleOpen($event: {}) {
    if ($event) {
      this.pending = true;
      this.notifications$.subscribe(res => {
        this.notifications = res;
        this.pending = false;
        this.notiificationsService.viewed();
      }, () => {
        this.pending = false;
      });
    } else {
      this.notifications = [];
    }
  }

  getSpeechToken() {
    this.authService.getSpeechToken()
    .subscribe((res: any) => {
      this.speechToken = res;
    });
  }

  notificationClicked(n: NotificationT) {
    const label: string = this.notiificationsService.getLabel(n);
    console.log(`in bell clicked: ${label}`);
    if (this.speechToken !== null) {
      this.authService.speak(this.speechToken, label);
    }
  }

}

<div>
  <form [formGroup]="form" (ngSubmit)="save()" autocomplete="off">
    <div class="row">
      <div class="col-5">
        <h3 class="form-header">Dane</h3>
        <app-form-group>
          <label translate>NAME</label>
          <input app-form formControlName="name">
        </app-form-group>
        <div class="my-xm">
          <app-select-input
            [ngModel]="reminderDescExamplesValue"
            (ngModelChange)="setDesc($event)"
            [multiple]="false"
            [ngModelOptions]="{standalone: true}"
            [allowClear]="true"
            [items]="reminderDescExamples"
          ><label>Szablon</label></app-select-input>
        </div>
        <app-form-group>
          <label translate>DESCRIPTION</label>
          <textarea app-form formControlName="description"></textarea>
        </app-form-group>
        <p class="">
          <small *ngFor="let legendItem of reminderDescLegends"
                 class="d-inline-block mr-s"><strong>{{legendItem.value}}
            - </strong>{{legendItem.label|translate}}</small>
        </p>
      </div>
      <div class="col-7" formGroupName="criteria">
        <div class="row">
          <div class="col-4">
            <h3 class="form-header" translate>MILAGE</h3>
            <div class="row">
              <div class="col-12">
                <app-form-group>
                  <label>{{'VALUE'|translate}}(km)</label>
                  <input app-form formControlName="mileageThreshold" type="number"/>
                </app-form-group>
              </div>
              <div class="col-12 mt-xm">
                <app-form-group>
                  <label translate>{{'ITERATION'|translate}}(km)</label>
                  <input app-form formControlName="mileageIteration" type="number"/>
                </app-form-group>
              </div>
            </div>
          </div>
          <div class="col-8">
            <h3 class="form-header" translate>DATE</h3>
            <div class="row">
              <div class="col-12">
                <app-form-group (click)="d.open()">
                  <label translate>DATE</label>
                  <input formControlName="dateThreshold"
                         navigation="arrows"
                         outsideDays="collapsed"
                         #input
                         app-form
                         (focus)="stopEvents(input)"
                         autoClose="outside"
                         placement="bottom-right"
                         ngbDatepicker #d="ngbDatepicker"/>
                </app-form-group>
              </div>
              <div class="col-6 mt-xm">
                <app-form-group>
                  <label translate>ITERATION</label>
                  <input app-form formControlName="dateIteration">
                </app-form-group>

              </div>
              <div class="col-6 mt-xm">
                <app-select-input
                  [(ngModel)]="dateIterationValue"
                  bindLabel="label"
                  bindValue="value"
                  [multiple]="false"
                  [ngModelOptions]="{standalone: true}"
                  [allowClear]="false"
                  [items]="dateIterationType"
                ><label translate>TYPE</label></app-select-input>
              </div>
            </div>
          </div>
        </div>
        <section class="mt-xm">
          <h2 class="form-header" translate>NOTIFICATIONS</h2>
          <app-channels-input [formControl]="channelForm"
                              *ngIf="notificationGlobalSettings"
                              [showEmailInput]="true"
                              [channels]="notificationGlobalSettings?.availableChannels"></app-channels-input>
        </section>
      </div>
      <div class="py-m d-flex justify-content-between align-items-center">
        <button class="btn btn-primary btn-border w-100 mr-m" (click)="handlerCancel()" translate
                type="button">
          CANCEL
        </button>
        <button class="btn btn-primary w-100" [disabled]="pending" type="submit">
          <app-spinner [showSpinner]="pending"></app-spinner>
          {{(!reminder ? 'SAVE' : 'EDIT')|translate}}
        </button>
      </div>

    </div>


  </form>
</div>

<div class="d-flex justify-content-between align-items-stretch flex-column flex-fill h-100">
  <div class="overflow-auto flex-grow-1" [formGroup]="form">
    <div class="p-xm">
      <h3 class="form-header mt-xm">{{ 'ASSIGNED_ADDRESSES'|translate }}:</h3>
      <app-poi-select formControlName="poi" [max]="5" bindValue="id" placement="bottom-left">
      </app-poi-select>
    </div>
    <div class="p-xm">
      <h3 class="form-header mt-xl">{{'ASSIGNED_ADDRESS_GROUPS'|translate}}:</h3>
      <app-select-input
              [items]="poiGroup$|async"
              [multiple]="true"
              [max]="2"
              selectClass="w-100"
              [allowClear]="true"
              [showTags]="true"
              [searchable]="true"
              bindLabel="name"
              bindValue="id"
              formControlName="poi_group"
      >
        <label translate>POI_GROUPS</label>
      </app-select-input>
    </div>
  </div>
  <div class="p-xm d-flex justify-content-end align-items-center border-top">
    <button class="btn btn-primary btn-border px-xxl mr-m" (click)="cancel()">
      {{'EXIT'|translate}}
    </button>
    <button class="btn btn-primary px-xxl" (click)="sync()" [disabled]="pending">
      <app-spinner [showSpinner]="pending"></app-spinner>
      {{'SAVE'|translate}}
    </button>
  </div>
</div>


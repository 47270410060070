import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Vehicle } from '../../../../interfaces/vehicle';

@Component({
  selector: 'app-vehicle-poi',
  templateUrl: './vehicle-poi.component.html',
  styleUrls: ['./vehicle-poi.component.scss']
})
export class VehiclePoiComponent implements OnInit, OnDestroy {
  protected subscriptions = new Subscription();
  public vehicle: Vehicle;

  constructor(protected route: ActivatedRoute) {
  }


  ngOnInit() {
    this.routeSub();
  }

  protected routeSub() {
    this.subscriptions.add(this.route.parent.data.subscribe(({vehicle}) => {
      this.vehicle = vehicle;
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

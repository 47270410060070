import { Device } from './../../../../interfaces/device';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterHelperService } from '../../../../core/services/router-helper.service';
import { TelemetryService } from '../../../../core/services/telemetry.service';
import { Subscription } from 'rxjs';
import { DriversService } from '../../../../core/services/drivers.service';
import { CollectionUpdateEvent } from '../../../../classes/api-service.class';

@Component({
  selector: 'app-device-popup-header',
  templateUrl: './device-popup-header.component.html',
  styleUrls: ['./device-popup-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevicePopupHeaderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() device: Device;
  private sub: Subscription;

  constructor(private router: RouterHelperService,
              private service: DriversService,
              private telemetryService: TelemetryService,
              private cd: ChangeDetectorRef,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.sub = this.service.collectionUpdate$.subscribe((res: CollectionUpdateEvent<Device>) => {
      if (res.event === 'update' && (res.item as Device).id === this.device.id) {
        this.device = Object.assign({}, this.device, res.item);
      }
      this.cd.detectChanges();
    });

  }

  openPopup(id, type, segment) {
    this.router.navigatePopupToOpen(this.route, [type, id], segment);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (this.driver.vehicle) {
    //   const vehicleId = this.driver.vehicle.id;
    //   forkJoin(
    //     observableWithCatchError(this.telemetryService.vehiclesLastPosition([vehicleId])),
    //     observableWithCatchError(this.telemetryService.vehiclesLastRide({
    //       vehicleIds: [vehicleId],
    //     }))
    //   ).subscribe(res => {
    //     this.driver.vehicle = Object.assign({}, this.driver.vehicle, {
    //       telemetry: res[0][vehicleId],
    //       ride: res[1][vehicleId],
    //     });
    //     this.cd.detectChanges();
    //   });
    // }
  }

}

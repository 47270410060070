import { DriversService } from './../../../../core/services/drivers.service';
import { Driver } from './../../../../interfaces/driver';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Vehicle } from '../../../../interfaces/vehicle';
import { VehiclesService } from '../../../../core/services/vehicles.service';
import { CustomeInput } from '../custom-select.class';
import { PartnerVehiclesService } from 'src/app/core/services/partner-vehicles.service';

@Component({
  selector: 'app-vehicles-select',
  templateUrl: './vehicles-select.component.html',
  styleUrls: ['./vehicles-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VehiclesSelectComponent),
      multi: true
    }
  ]
})
export class VehiclesSelectComponent extends CustomeInput<Vehicle, VehiclesService> implements ControlValueAccessor {
  protected items$ = (this.service as any).list$();
  public dimension = 'VEHICLE';
  public clientsFilter = false;
  constructor(vehicleService: VehiclesService, cd: ChangeDetectorRef) {
    super(vehicleService, cd);
  }
}

@Component({
  selector: 'app-partner-vehicles-select',
  templateUrl: './vehicles-select.component.html',
  styleUrls: ['./vehicles-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PartnerVehiclesSelectComponent),
      multi: true
    }
  ]
})
export class PartnerVehiclesSelectComponent extends CustomeInput<Vehicle, PartnerVehiclesService> implements ControlValueAccessor {
  protected items$ = (this.service as any).list$();
  public dimension = 'VEHICLE';
  public clientsFilter = true;
  constructor(vehicleService: PartnerVehiclesService, cd: ChangeDetectorRef) {
    super(vehicleService, cd);
  }
}

@Component({
  selector: 'app-drivers-select',
  templateUrl: './vehicles-select.component.html',
  styleUrls: ['./vehicles-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DriversSelectComponent),
      multi: true
    }
  ]
})
export class DriversSelectComponent extends CustomeInput<Driver, DriversService> implements ControlValueAccessor {
  protected items$ = (this.service as any).list$();
  public dimension = 'DRIVER';
  public clientsFilter = false;
  constructor(vehicleService: DriversService, cd: ChangeDetectorRef) {
    super(vehicleService, cd);
  }
}

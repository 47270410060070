import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Reminder } from '../../../../../interfaces/reminder';
import { Vehicle } from '../../../../../classes/vehicle';
import { PERMISSIONS, PermissionService } from 'src/app/core/services/permission.service';
import { UserService } from 'src/app/core/services/user.service';
import { User } from 'src/app/interfaces/user';

@Component({
  selector: '[app-vehicle-sevice-reminder]',
  templateUrl: './vehicle-sevice-reminder.component.html',
  styleUrls: ['./vehicle-sevice-reminder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleSeviceReminderComponent implements OnInit {
  @Input() reminder: Reminder;
  @Input() vehicle: Vehicle;
  @Input() edited: boolean;
  @Output() updated = new EventEmitter();
  @Output() action = new EventEmitter();

  readonly permissions = PERMISSIONS;

  private currentUser: User = null;
  constructor(private permissionService: PermissionService, private userService: UserService) {
    this.userService.user$.subscribe((user) => this.currentUser = user);
  }

  get canUpdateOrDelete() {
    if (!this.reminder || this.reminder.userId !== this.currentUser.id) {
      return false;
    }
    return this.permissionService.hasPermission(this.permissions.REMINDER_UPDATE);
  }

  ngOnInit() {
  }

  edit() {
    this.emitAction('edit');
  }

  remove() {
    this.emitAction('delete');
  }

  emitAction(type) {
    this.action.emit({
      type,
      reminder: this.reminder
    });
  }
}

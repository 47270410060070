import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { ChoiceItem } from '../map-toolbox-switch/map-toolbox-switch.component';

@Component({
  selector: 'app-map-toolbox-radio',
  templateUrl: './map-toolbox-radio.component.html',
  styleUrls: ['./map-toolbox-radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapToolboxRadioComponent implements OnChanges {
  @Input() choices: ChoiceItem[];
  @Input() selected: string;
  @Input() isOpen: boolean;
  @Input() label = 'Ustawienia mapy:';
  @Output() selectChange = new EventEmitter();
  selectedItem: string;

  constructor(private cd: ChangeDetectorRef) {
  }

  changeState(choice: ChoiceItem) {
    this.selectedItem = choice.value;
    this.selectChange.emit(this.selectedItem);
    this.cd.detectChanges();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.selected) {
      this.selectedItem = this.selected;
    }
  }
}

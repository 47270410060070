import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type PopupSizeType = 'large' | 'normal' | 'small' | 'resize';

@Injectable({providedIn: 'root'})
export class PerspectiveMenuService {


  set popupSizeType(view: PopupSizeType) {
    this.popupSize$.next(view);
  }

  private popupSize$: BehaviorSubject<PopupSizeType> = new BehaviorSubject('normal' as PopupSizeType);
  private filtersTemplate$ = new BehaviorSubject<TemplateRef<any> | null>(null);
  private menuTemplate$ = new BehaviorSubject<TemplateRef<any> | null>(null);

  constructor() {
  }

  get popupSize() {
    return this.popupSize$.asObservable();
  }

  get template() {
    return this.filtersTemplate$.asObservable();
  }

  get menu() {
    return this.menuTemplate$.asObservable();
  }

  newTemplate(template: TemplateRef<any>) {
    this.filtersTemplate$.next(template);
  }

  removeTemplate() {
    this.filtersTemplate$.next(null);
  }

  newMenu(template: TemplateRef<any>) {
    this.menuTemplate$.next(template);
  }

  removeMenu() {
    this.menuTemplate$.next(null);
  }
}

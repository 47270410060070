import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { DateTime } from 'luxon';
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbDropdown,
  Placement
} from '@ng-bootstrap/ng-bootstrap';
import { DateRageValue, DateRangeItem, DateRangeService, DateRangeValueType } from './date-range.service';
import { FILTERS_VIEWS, StorageAndQueryService } from '../../../core/services/storage_and_query.service';


@Component({
  selector: 'app-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeFilterComponent implements OnInit, OnChanges {
  @Output() changeRange: EventEmitter<DateRageValue> = new EventEmitter();
  @Input() selectedDate: DateRangeValueType = DateRangeValueType.today;
  @Input() date: DateRageValue;
  @Input() eventOnInit = false;
  @Input() filterId: FILTERS_VIEWS;
  @Input() placement: Placement = 'bottom-right';
  @ViewChild(NgbDropdown, { static: true }) dropDown: NgbDropdown;
  isOpen = false;
  calendarIsOpen = false;

  private rangeDate = {
    fromDate: null,
    toDate: null,
  };
  private rangeDateTmp: {
    fromDate: NgbDate | NgbDateStruct | null,
    toDate: NgbDate | NgbDateStruct | null,
  } = {
    fromDate: null,
    toDate: null,
  };
  public dateType: DateRangeValueType = this.selectedDate;
  public dates: DateRangeItem[] = [
    {label: 'DATE_RANGE_PICKER.TODAY', value: DateRangeValueType.today},
    {label: 'DATE_RANGE_PICKER.YESTERDAY', value: DateRangeValueType.yesterday},
    {label: 'DATE_RANGE_PICKER.THIS_WEEK', value: DateRangeValueType.this_week},
    {label: 'DATE_RANGE_PICKER.LAST_WEEK', value: DateRangeValueType.last_week},
    {label: 'DATE_RANGE_PICKER.CURRENT_MONTH', value: DateRangeValueType.current_month},
    {label: 'DATE_RANGE_PICKER.PREVIOUS_MONTH', value: DateRangeValueType.previous_month},
    {label: 'DATE_RANGE_PICKER.SELECTED', value: DateRangeValueType.selected},
  ];

  maxDate = this.calendar.getToday();

  constructor(private cd: ChangeDetectorRef,
              private dateService: DateRangeService,
              private ngbDateParserFormatter: NgbDateParserFormatter,
              private storageService: StorageAndQueryService,
              private calendar: NgbCalendar) {
  }

  get showdatePicker(): boolean {
    return this.dateType && this.dateType === DateRangeValueType.selected;
  }

  ngOnInit() {
    this.setRangeFromInput();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setRangeFromInput();
  }

  setRangeFromInput() {
    if (this.date) {
      this.dateType = this.date.type;
      this.selectedDate = this.date.type;
      if (this.dateType === DateRangeValueType.selected) {
        this.rangeDateTmp.toDate = this.ngbDateParserFormatter.parse(DateTime.fromISO(this.date.end).toString());
        this.rangeDateTmp.fromDate = this.ngbDateParserFormatter.parse(DateTime.fromISO(this.date.start).toString());
        this.rangeDate.fromDate = DateTime.fromISO(this.date.start);
        this.rangeDate.toDate = DateTime.fromISO(this.date.end);
      }
    }
    if (this.eventOnInit) {
      this.changeRange.emit(this.returnDate());
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.rangeDateTmp.fromDate && !this.rangeDateTmp.toDate) {
      this.rangeDateTmp.fromDate = date;
    } else if (this.rangeDateTmp.fromDate
      && !this.rangeDateTmp.toDate && (date.after(this.rangeDateTmp.fromDate) || date.equals(this.rangeDateTmp.fromDate))) {
      this.rangeDateTmp.toDate = date;
    } else {
      this.rangeDateTmp.toDate = null;
      this.rangeDateTmp.fromDate = date;
    }
  }

  testParameter(someVar = 'default') {
  }


  dateToDateTimeObject(date: NgbDate | NgbDateStruct): DateTime {
    return DateTime.fromObject(date);
  }

  get calendarValue() {
    if (this.selectedDate) {
      switch (this.selectedDate) {
        case DateRangeValueType.selected:
          return this.rangeDate
            ? `${this.rangeDate.fromDate.toFormat('dd.LL.yyyy')} - ${this.rangeDate.toDate.toFormat('dd.LL.yyyy')}`
            : 'SELECT';
        default:
          return (this.dates.find(({value}) => value === this.selectedDate) as DateRangeItem).label;
      }

    } else {
      return '';
    }

  }

  close() {
    this.dropDown.close();
    this.calendarIsOpen = false;
  }

  returnDate(): DateRageValue {
    return this.dateService.dateRangeValueByType(this.selectedDate, this.rangeDate);
  }

  save() {
    if (this.dateType === DateRangeValueType.selected && !this.rangeDateTmp.fromDate) {
      return;
    } else if (this.dateType === DateRangeValueType.selected) {
      this.rangeDate = {
        fromDate: this.dateToDateTimeObject(this.rangeDateTmp.fromDate),
        toDate: this.dateToDateTimeObject(this.rangeDateTmp.toDate ? this.rangeDateTmp.toDate : this.rangeDateTmp.fromDate),
      };
    }
    this.selectedDate = this.dateType;
    const emitDate = this.returnDate();
    if (this.filterId) {
      this.dateService.saveDate(this.filterId, emitDate);
    }
    this.changeRange.emit(emitDate);
    this.close();
  }

  dateTypeChange($event) {
    this.dateType = $event;
    if (this.showdatePicker) {
      this.calendarIsOpen = true;
    } else {
      this.calendarIsOpen = false;
    }
  }

  calendarShown() {
    this.calendarIsOpen = true;
    this.cd.detectChanges();
  }

  toggleOpen() {
    if (this.dropDown.isOpen()) {
      this.dropDown.close();
      this.calendarIsOpen = false;
    } else {
      if (this.showdatePicker) {
        this.calendarIsOpen = true;
      }
      this.dropDown.open();
    }
  }
}

import { Injectable } from '@angular/core';
import { VehicleGroup } from '../../interfaces/group';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { ApiServiceWithSub } from '../../classes/api-service-with-sub';
import { Observable } from 'rxjs';
import { Vehicle } from '../../interfaces/vehicle';

@Injectable({
  providedIn: 'root'
})
export class VehiclesGroupsService extends ApiServiceWithSub<VehicleGroup> {

  protected inludeList = ['count-vehicles', 'example-vehicles'];

  constructor(config: ConfigService,
              authService: AuthService,
              http: HttpClient) {
    super('/vehicles-groups', config, http, authService);
    this.initList();
  }

  sync(id: string, vehicleIds: string[]) {
    return this.http.post(`${this.baseUrl}/${id}/vehicles`, {vehicleIds});
  }

  vehiclesInGroup(id: string): Observable<Vehicle[]> {
    return this.http.get<Vehicle[]>(`${this.baseUrl}/${id}/vehicles`);
  }
}


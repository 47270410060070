import { EventEmitter, Input, OnDestroy, OnInit, Output, Directive } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FILTERS_VIEWS, StorageAndQueryService } from '../core/services/storage_and_query.service';


@Directive()
export class FilterCollection implements OnDestroy, OnInit {

  @Output() filterChange = new EventEmitter();
  @Input() filters: {};
  @Input() initFilters: {};
  @Input() filterId: FILTERS_VIEWS;
  public selectedFilters;
  formSubscription$: Subscription;
  filterForm: UntypedFormGroup;
  protected constFilters = {};


  constructor(protected fB: UntypedFormBuilder, protected storageService?: StorageAndQueryService) {

  }

  clear() {
    this.filterForm.reset();
  }

  ngOnInit(): void {
    this.initForm();
    this.bindForm();
    this.checkStorage();
  }

  checkStorage() {
    if (this.hasStorage) {
      const filteres = this.storageService.getFilters(this.filterId);
      this.filterForm.patchValue(filteres);
    }
  }

  get hasStorage() {
    return this.filterId && this.storageService;
  }

  value(key) {
    return this.filterForm.get(key).value;
  }

  ngOnDestroy() {
    this.destroyFilter();
  }

  initForm(data = null) {
    this.filterForm = this.fB.group({
      search: [],
    });
  }

  destroyFilter() {
    if (this.formSubscription$) {
      this.formSubscription$.unsubscribe();
    }
  }

  bindForm() {
    if (this.filterForm) {
      this.formSubscription$ = this.filterForm.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged()
      ).subscribe(val => {
        if (this.filterForm.valid) {
          this.emitFilters(val);
        }
      });
    }
  }


  emitFilters(val = {}) {
    if (this.filterForm && !this.filterForm.valid) {
      return;
    }
    const filtersBeforeSend = {...val, ...this.filters};
    const filters = {...this.constFilters};
    Object.keys(filtersBeforeSend).forEach(key => {
      if ((filtersBeforeSend[key] !== null
        && filtersBeforeSend[key] !== ''
        && filtersBeforeSend[key] !== false
        && filtersBeforeSend[key] !== 'DEFAULT')) {
        filters[key] = filtersBeforeSend[key];
      }
    });
    this.selectedFilters = filters;

    const customMappedFilters = this.customMapFilters({...filters});

    this.filterChange.emit(customMappedFilters);
    if (this.hasStorage) {
      this.storageService.saveFilters(this.filterId, this.selectedFilters);
    }
  }


  customMapFilters(filters) {
    return filters;
  }
}

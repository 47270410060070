<div [formGroup]="filterForm" class="d-flex justify-content-start align-items-center">
    <div class="mr-s">
        <ng-content></ng-content>
    </div>

    <div class="min-width" class="mr-s">
        <app-form-group icon="icon-search" iconPosition="right" [clear]="true">
            <label translate>SEARCH_BY_PHRASE</label>
            <input app-form
                   name="search"
                   type="text"
                   [value]="value('search')"
                   formControlName="search"/>
        </app-form-group>
    </div>
    <div class="min-width flex-grow-1">
        <app-select-input
                [multiple]="true"
                [max]="2"
                formControlName="poiGroupIds"
                [items]="groups$|async"
                [value]="value('poiGroupIds')"
                bindLabel="name"
                bindValue="id"
        ><label translate>FILTER_BY_GROUP</label></app-select-input>
    </div>
</div>

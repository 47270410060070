import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UiUtilsService } from '../../core/services/ui-utils.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public portal = this.uiService.portal$;

  constructor(private cd: ChangeDetectorRef,
              private uiService: UiUtilsService) {
  }


  ngOnInit() {
  }


}

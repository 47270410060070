<div ngbDropdown class="c-notification-bell" (openChange)="toggleOpen($event)" placement="bottom-right">
    <div class="c-notification-bell__button" ngbDropdownToggle>
        <i class="icon-bell">
        </i>
        <app-badge [value]="(notiificationsService.count$|async)?.new"></app-badge>
    </div>

    <div ngbDropdownMenu class="c-notification-bell__dropdown">
        <div class="overflow-auto h-100">
            <app-notification *ngFor="let notification of notifications" (clicked)="notificationClicked($event)"
                              [notification]="notification"></app-notification>
        </div>
        <!--<app-overflow-spinner [showSpinner]="pending"></app-overflow-spinner>-->
    </div>
</div>


import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NormTemplate, NormTemplateConfiguration } from '../../../../interfaces/norms';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupViewService } from '../../../../shared/components/popup-view/popup-view.service';
import { NormsService } from '../../../../core/services/norms.service';
import { switchMap, tap } from 'rxjs/operators';
import { Zone } from 'src/app/interfaces/zone';

@Component({
  selector: 'app-zones-new-rules',
  templateUrl: './zones-new-rules.component.html',
  styleUrls: ['./zones-new-rules.component.scss']
})
export class ZonesNewRulesComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  public pending = true;
  public norm: NormTemplate;
  public zone: Zone;
  public configuration: NormTemplateConfiguration;

  constructor(private activeRoute: ActivatedRoute,
              private popupService: PopupViewService,
              private router: Router,
              private service: NormsService) {
  }

  ngOnInit() {
    this.subscriptions.add(this.activeRoute.parent.parent.data
      .pipe(
        tap(() => this.pending = true),
        tap(({zone}) => {
          this.zone = zone;
        }),
        switchMap(() => this.activeRoute.params.pipe(switchMap(({id}) => this.service.zoneTemplateWithConfiguration(id)))),
        tap(() => this.pending = false)
      )
      .subscribe(({configuration, template}) => {
        this.configuration = configuration;
        this.norm = template;
      }));


  }

  cancel() {
    this.router.navigate(['../'], {relativeTo: this.activeRoute});
    // this.popupService.close();
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}


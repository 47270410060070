import {Injectable} from '@angular/core';
import {ConfigService} from './config.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  WarehouseDefaultResponse,
  WarehouseField,
  WarehouseRequest,
  WarehouseResponse
} from '../../interfaces/warehouse';
import {TranslateService} from '@ngx-translate/core';
import {VehicleStatuses, VehicleStatusesColors} from './telemetry.service';


@Injectable({
  providedIn: 'root'
})
export class WarehouseService {
  private baseUrl: string;

  constructor(private config: ConfigService,
              private translate: TranslateService,
              private http: HttpClient) {
    this.baseUrl = this.config.fullApiUrl('/warehouse');
    this.availableFieldsLog();
  }

  availableOutputFields() {
    return this.http.get(`${this.baseUrl}/dimensions/output-fields`);
  }

  headerLabel<T>(field: WarehouseField<T>) {
    return this.translate.instant(`WAREHOUSE_LABEL.${field.name}`);
  }

  getData<T, P>(requestData: WarehouseRequest<T>): Observable<WarehouseResponse<P>> {
    return this.http.post<WarehouseResponse<P>>(`${this.baseUrl}/dimensions`, requestData);
  }

  rideStatus(work: WarehouseDefaultResponse): VehicleStatuses {
    return work.SUM_WORK_RIDE_COUNT && work.SUM_WORK_RIDE_COUNT[0].v
      ? VehicleStatuses.BUSINESS_RIDE : VehicleStatuses.PRIVATE_RIDE;
  }

  colorByState(state: VehicleStatuses) {
    return VehicleStatusesColors[state];
  }

  parkStatus(work: WarehouseDefaultResponse): VehicleStatuses {
    return work.SUM_CUSTOMER_WORK_PARK_COUNT && work.SUM_CUSTOMER_WORK_PARK_COUNT[0].v
      ? VehicleStatuses.CUSTOMER_PARK : work.SUM_OTHER_WORK_PARK_COUNT && work.SUM_OTHER_WORK_PARK_COUNT[0].v
        ? VehicleStatuses.OTHER_WORK_PARK : work.SUM_BRANCH_WORK_PARK_COUNT && work.SUM_BRANCH_WORK_PARK_COUNT[0].v
          ? VehicleStatuses.BRANCH_WORK_PARK : VehicleStatuses.NONWORK_PARK;
  }

  availableFieldsLog() {
    this.availableOutputFields().subscribe((res: any) => {
      let data = '';
      res.data.forEach(item => {
        data += `${item} = '${item}',
        `;
      });
      console.log(data);
    });
  }

  filterObjectToWarehuse(filter: Object) {
    return Object.keys(filter).reduce((p, c) => ({...p, ['$' + c + 'e']: filter[c]}), {});
  }

  sortStringToObject(value: string) {
    const direction = value && value[0] === '-' ? -1 : 1;
    return value ? {[value.replace('-', '')]: direction} : {};
  }

  warehouseFilters(filtersObj) {
    const filters = filtersObj ? Object.keys(filtersObj).reduce((p, c) => {
      let item;
      switch (c) {
        case 'status':
          item = {'$or': filtersObj[c].reduce((pr, cr) => ([...pr, {[cr]: {'$eq': 1}}]), [])};
          break;
        case 'PARK_REVGEO':
          item = {
            [c]: {
              '$regex': filtersObj[c]
                          // replace comas with spaces and reducre double spaces
                          .replace(',', ' ').replace('  ', ' ')
                          .split(' ')
                          // concat regex (skip empty)
                          .reduce((prev, cur) => prev += cur !== ' ' ? `(?=.*${cur})` : '' , ''),
              '$options': 'i'
                }
              };
          break;
        default :
          item = {
            [c]: Array.isArray(filtersObj[c])
              ? {'$in': filtersObj[c]} :
              typeof filtersObj[c] === 'object' ? this.filterObjectToWarehuse(filtersObj[c])
                : {'$eq': filtersObj[c]}
          };
      }
      p.push(item);
      return p;
    }, []) : [];

    return filters;
  }
}

function getValueByPropertyName(entity, propertyName: string): string {
  if (!entity) {
    return '';
  }

  const propertyChain: string[] = propertyName.split('.');
  if (propertyChain.length === 1) {
    return entity.hasOwnProperty(propertyName) && entity[propertyName] ? entity[propertyName] : '-';
  }

  // reccurence, pass propertName without first property chain
  return getValueByPropertyName(entity[propertyChain[0]], propertyName.replace(`${propertyChain[0]}.`, ''));
}

export function getCustomLabel(entity, customLabelProperties: string, defaultProperty: string = 'name') {
  if (!customLabelProperties) {
    return entity[defaultProperty];
  }
  try {
    const propertiesList = customLabelProperties.split(';');
    let labelString = '';
    for (const prop of propertiesList) {
      // if not first property add delimiter
      if (labelString !== '') {
        labelString += ' - ';
      }
      const [path, format] = prop.split('|');

      labelString += format ? format.replace('@0', getValueByPropertyName(entity, path)) : getValueByPropertyName(entity, path);
    }
    return labelString;
  } catch {
    console.log(`error parsing vehicle label: ${customLabelProperties} vId: ${entity.id} vName: ${entity.name}`);
    return entity ? entity[defaultProperty] : '';
  }
}

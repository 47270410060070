import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NewEditClass } from 'src/app/classes/new-edit.class';
import { ApiValidatorService } from 'src/app/core/services/api-validator.service';
import { PERMISSIONS, PermissionService } from 'src/app/core/services/permission.service';
import { Device } from 'src/app/interfaces/device';
import { PopupViewService } from 'src/app/shared/components/popup-view/popup-view.service';

@Component({
  selector: 'app-device-extensions',
  templateUrl: './device-extensions.component.html',
  styleUrls: ['./device-extensions.component.scss']
})
export class DeviceExtensionsComponent extends NewEditClass {
  protected editPermission: PERMISSIONS = PERMISSIONS.DEVICE_UPDATE;
  protected newPermission: PERMISSIONS = PERMISSIONS.DEVICE_CREATE;

  public device: Device;
  protected subscriptions = new Subscription();
  public forms: { [key: string]: AbstractControl } = {};

  constructor(protected route: ActivatedRoute,
              public permissionService: PermissionService,
              popupService: PopupViewService,
              apiValidator: ApiValidatorService,
              protected fb: UntypedFormBuilder) {
    super(popupService, apiValidator, fb, permissionService);
  }

  addRouteSub() {
    this.subscriptions.add(this.route.parent.data.subscribe(({device}) => {
      this.device = device;
      this.resetForm();
    }));
  }
  update() {
    const external = this.forms.EXTERNAL.value;
    console.log(JSON.stringify(external));

    this.editSuccess();
  }
  save() {
    throw new Error('Method not implemented.');
  }

  buildForm() {
    this.forms = {
      EXTERNAL: this.fb.group({
        id: [null],
        enabled: [null],
        stopFurtherProcessing: [null],
      }),
    };
  }

  resetForm() {
    this.forms.EXTERNAL.patchValue({
      id: 'test',
      enabled: true,
      stopFurtherProcessing: true,
    });
    if (this.device.extensions && this.device.extensions.EXTERNAL) {
      this.forms.EXTERNAL.patchValue(this.device.extensions.EXTERNAL);
    }
    // this.forms.group.setValue(this.driver.groups.map(group => group.id));
  }
}

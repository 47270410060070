export interface WorkSchedule {
  name: string;
  entityType: WorkScheduleEntityType;
  entityId: string;
  dateFrom: string;
  dateTo: string;
  timetable: WorkScheduleTimetable[];
  timeType: WorkScheduleTimeType;
  priority: number;
  id: string;
  createdAt: string;
  updatedAt: string;
  clientId: string;
}

export enum WorkScheduleTimeType {
  WORK = 'work',
  NONWORK = 'nonwork'
}

export enum WorkScheduleEntityType {
  CLIENT = 'client',
  DRIVER = 'driver',
  DRIVER_GROUP = 'driver_group',
  VEHICLE = 'vehicle',
  VEHICLE_GROUP = 'vehicle_group',
}

export interface WorkScheduleTimetable {
  from: string;
  to: string;
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DateRangeService } from '../../../../../shared/modules/date-range-filter/date-range.service';
import { PowerBiService } from '../../../../../core/services/power-bi.service';
import { Subscription } from 'rxjs';
import { Poi } from '../../../../../interfaces/poi';

@Component({
  selector: 'app-poi-popup-dashboard',
  templateUrl: './poi-popup-dashboard.component.html',
  styleUrls: ['./poi-popup-dashboard.component.scss']
})
export class PoiPopupDashboardComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  public powerBiFilters;
  private filters;
  private poi: Poi;

  constructor(private route: ActivatedRoute,
              private dateFilterService: DateRangeService,
              private powerBiService: PowerBiService) {
  }


  ngOnInit(): void {
    this.subscription = this.route.parent.data.subscribe(({poi}) => {
      this.poi = poi;
      this.setPowerBiFilters();
    });
  }

  setPowerBiFilters() {
    if (this.poi) {
      setTimeout(() => {
        this.powerBiFilters = this.powerBiService.poiFilters(this.poi.id);
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}

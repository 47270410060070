<div class="d-flex justify-content-between align-items-stretch flex-column flex-fill h-100">
  <div class="overflow-auto flex-grow-1" *ngIf="form">
    <div [formGroup]="form" class="p-xm">
      <h3 class="form-header mt-xl" translate>COMMAND</h3>
      <div class="row">
        <div class="col-12 mt-m">
          <app-form-group>
            <label translate>ENTER_COMMAND</label>
            <input type="text" [formControl]="form.get('commandText')" app-form>
            <app-error-message [control]="form.get('commandText')"></app-error-message>
          </app-form-group>
          <button class="btn btn-secondary btn-sm btn-border px-l" (click)="sendCommand()"
                                translate>SEND
                        </button>
        </div>
      </div>
    </div>
    <div class="p-xm">
      <h3 class="form-header mt-xl" translate>LIST</h3>
      <button class="btn btn-secondary btn-sm btn-border px-l" (click)="refreshCommands()"
        translate>REFRESH
              </button>
      <p *ngFor="let cmd of commands$|async">{{getCommandLabel(cmd)}}</p>
    </div>
  </div>
</div>

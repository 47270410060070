<div class="v-authentication__view">
    <div class="mb-xxl">
        <app-logo width="100%"></app-logo>
    </div>
    <form [formGroup]="form" (ngSubmit)="submit()" autocomplete="off">
        <h1 class="headline  text-center">Zaloguj się</h1>
        <div class="my-l">
            <app-form-group>
                <label for="username">Login</label>
                <input name="username"
                       id="username"
                       type="text"
                       formControlName="username"
                       app-form
                       placeholder="Twój login"
                >
                <app-error-message [control]="form.controls['username']"
                                   [apiError]="formErrors?.username"></app-error-message>
            </app-form-group>
        </div>
        <app-form-group>
            <label for="password" class="u--text-uppercase">Hasło</label>
            <input name="password"
                   type="password"
                   app-form
                   id="password"
                   formControlName="password"
                   placeholder="Twoje hasło"
            >
            <app-error-message [control]="form.controls['password']"
                               [apiError]="formErrors?.password"></app-error-message>
        </app-form-group>
        <button class="btn btn-primary w-100 mt-xl" [disabled]="formPending">
            <app-spinner [showSpinner]="formPending"></app-spinner>
            Zaloguj
        </button>
        <app-error-message [control]="form" [apiError]="formErrors"></app-error-message>
        <div class="u--text-13 u--content-block mt-l  text-center">
            <a routerLink="/password-reset" class="u--underline btn btn-link">Zapomniałem hasła</a>
        </div>


    </form>
</div>

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NotificationNORM } from '../../../../../../interfaces/notificationT';

@Component({
  selector: 'app-notification-norm-adhoc',
  templateUrl: './notification-norm-adhoc.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationNormAdhocComponent implements OnInit {
  @Input() notification: NotificationNORM;

  constructor() {
  }

  ngOnInit() {
  }

}

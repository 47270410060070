import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ngIfEnterWidthTrigger } from '../../../../../core/animations';

@Component({
  selector: 'app-map-toolbox-wrapper',
  templateUrl: './map-toolbox-wrapper.component.html',
  styleUrls: ['./map-toolbox-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ngIfEnterWidthTrigger]
})
export class MapToolboxWrapperComponent {
  @Input() isOpen;
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {

  transform(value: any = '', args?: any): any {
    return value ? value.toString()
      .split(' ')
      .map(string => string.charAt(0))
      .join('')
      .toUpperCase() : '--';
  }

}

import { Injectable } from '@angular/core';


export enum FILTERS_VIEWS {
  VEHICLE = 'vehicle',
  MAP_VEHICLE = 'map_vehicle',
  WORK_VEHICLE = 'work_vehicle',
  VISITS_CLIENTS = 'visits_clients',
  ZONES_MAP = 'zones_map',
  VEHICLE_HISTORY = 'vehicle_history',
  POI = 'poi',
  POI_MAP = 'poi_map',
  WORK_SCHEDULE = 'WORK_SCHEDULE',
  DRIVERS_TABLE = 'DRIVERS_TABLE',
  DEVICES_TABLE = 'DEVICES_TABLE',
  PARTNER_VEHICLE_SERVICE = 'PARTNER_VEHICLE_SERVICE',
}

@Injectable({
  providedIn: 'root'
})
export class StorageAndQueryService {
  private FILTER_KEY = '_filtersFC';

  constructor() {
  }

  setItem(key: string, data) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  getItem(key: string) {
    return JSON.parse(localStorage.getItem(key)) || null;
  }

  saveFilters(id: FILTERS_VIEWS, data) {
    this.setItem(`${id}${this.FILTER_KEY}`, data);
    localStorage.setItem(`${id}${this.FILTER_KEY}`, JSON.stringify(data));
  }

  getFilters(id: FILTERS_VIEWS) {
    return this.getItem(`${id}${this.FILTER_KEY}`) || {};
  }
}

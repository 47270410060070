import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {MapService} from '../map.service';
import {VehicleWithTelemetryAndRide} from '../../../../interfaces/telemetry';
import {MarkerClass} from '../marker.class';
import {TelemetryService, VehicleStatus, VehicleStatusesColors} from '../../../../core/services/telemetry.service';
import {DateTime} from 'luxon';
import {getCustomLabel} from 'src/app/utils/custom-label';

export const MAX_MARKER_CIRCLE_TIME = 120;

@Component({
  selector: 'app-map-marker-vehicle',
  templateUrl: './map-marker-vehicle.component.html',
  styleUrls: ['./map-marker-vehicle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MapMarkerVehicleComponent extends MarkerClass implements OnDestroy, OnChanges {

  @Input() vehicle: VehicleWithTelemetryAndRide;
  @HostBinding('class.is-hover')
  @Input() isHover = false;
  @HostBinding('class.is-active')
  @Input() isActive = false;
  @HostBinding('class.show-label')
  @Input() showLabel = false;
  public circleSize = 0;
  @Input()
  public customLabelProperties = null;
  public avatarPosition;
  vehicleStatus: VehicleStatus;
  markerGroupName: string = 'MapMarkerVehicleComponent';
  constructor(mapService: MapService,
      el: ElementRef,
      private telemetry: TelemetryService) {
    super(mapService, el);
  }

  get bearing() {
    return this.vehicle.telemetry ? this.vehicle.telemetry.bearing : 0;
  }

  get showAvatar() {
    return this.vehicle && this.vehicle.avatarUrl;
  }

  get color() {
    return this.vehicleStatus ? VehicleStatusesColors[this.vehicleStatus.type] : null;
  }

  get label() {
    return getCustomLabel(this.vehicle, this.customLabelProperties);
  }

  getCoords() {
    return this.vehicle.telemetry.position.coordinates;
  }

  markerClickEvent() {
    this.markerClick.emit(this.vehicle);
  }

  markerHover() {
    this.hover.emit(this.vehicle);
  }

  markerHoverLeave() {
    this.leaveHover.emit(this.vehicle);
  }
  domIconOptions() {
    return {
      onAttach: (clonedElement, domIcon, domMarker) => {
        clonedElement.addEventListener('mouseenter', this.markerHover.bind(this));
        clonedElement.addEventListener('click', this.markerClickEvent.bind(this));
        clonedElement.addEventListener('mouseleave', this.markerHoverLeave.bind(this));
      },
      onDetach: (clonedElement, domIcon, domMarker) => {
        clonedElement.removeEventListener('mouseenter', this.markerHover.bind(this));
        clonedElement.removeEventListener('click', this.markerClickEvent.bind(this));
        clonedElement.removeEventListener('mouseleave', this.markerHoverLeave.bind(this));
      }
    };
  }
  setCircleSize() {
    if (this.vehicleStatus) {
      const duration = DateTime.fromISO(this.vehicleStatus.time).diffNow('minutes').minutes * -1;
      if (duration >= MAX_MARKER_CIRCLE_TIME) {
        this.circleSize = 200;
      } else if (duration > 10) {
        this.circleSize = duration / MAX_MARKER_CIRCLE_TIME * 100 + 100;
      } else {
        this.circleSize = 0;
      }
    }
  }

  toDegress(angel) {
    return angel * (Math.PI / 180);
  }

  calculatePosition() {
    const R = 30;
    // this.boat.data.state.bearing = 45;
    let angle = this.bearing - 90;
    if (angle < 0) {
      angle += 360;
    }
    const angleDegress = this.toDegress(angle);
    const x = R * Math.cos(angleDegress);
    const y = R * Math.sin(angleDegress);
    this.avatarPosition = {
      top: `${y + 8}px`,
      left: `${x + 8}px`,
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.vehicle && changes.vehicle.currentValue && changes.vehicle.currentValue.ride) {
      this.vehicleStatus = this.vehicle.ride.status;
      this.setCircleSize();
    }
    if (this.marker) {
     // this.marker.setZIndex(this.isActive ? 3 : this.isHover ? 2 : 1);
      this.doUpdate = true;
      if (changes.isActive && changes.isActive.currentValue && !changes.isActive.previousValue) {
        const point = this.marker.getGeometry() as H.geo.Point;
        if (!this.mapService.isInMapView(point)) {
          this.mapService.mapInstance.setCenter(point, true);
        }
      }
    }
    this.calculatePosition();
  }

}

import { DevicesService } from './../../../../core/services/devices.service';
import { VehiclesService } from './../../../../core/services/vehicles.service';
import { User } from '../../../../interfaces/user';
import { UserService } from '../../../../core/services/user.service';
import { Component } from '@angular/core';
import { NewEditClass } from '../../../../classes/new-edit.class';
import { ActivatedRoute } from '@angular/router';
import { PopupViewService } from '../../../../shared/components/popup-view/popup-view.service';
import { ApiValidatorService } from '../../../../core/services/api-validator.service';
import { RouterHelperService } from '../../../../core/services/router-helper.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { switchMap, tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { DriversService } from '../../../../core/services/drivers.service';
import { DriversGroupsService } from '../../../../core/services/drivers-groups.service';
import { Driver } from '../../../../interfaces/driver';
import { PERMISSIONS, PermissionService } from '../../../../core/services/permission.service';
import { Device } from 'src/app/interfaces/device';

@Component({
  selector: 'app-device-details-edit',
  templateUrl: './device-details.component.html',
  styleUrls: ['./device-details.component.scss']
})
export class DeviceDetailsComponent extends NewEditClass {
  readonly permissions = PERMISSIONS;

  protected editPermission = PERMISSIONS.DEVICE_UPDATE;
  protected newPermission = PERMISSIONS.DEVICE_CREATE;
  public device: Device;
  public groups$ = this.driversGroupsService.list();
  public vehicles$ = this.vehicleService.list();

  constructor(private route: ActivatedRoute,
              private devicesService: DevicesService,
              popupService: PopupViewService,
              apiValidator: ApiValidatorService,
              private routerHelper: RouterHelperService,
              public permissionService: PermissionService,
              private driversGroupsService: DriversGroupsService,
              private vehicleService: VehiclesService,
              fb: UntypedFormBuilder) {
    super(popupService, apiValidator, fb, permissionService);
  }


  buildForm() {
    this.forms = {
      main: this.fb.group({
        imei: [null, Validators.required],
        type: [null, Validators.required],
        ipAddress: [null],
        vehicleId: [null],
        simMsisdn: [null],
        simSerialNumber: [null],
      }),
    };
  }

  addRouteSub() {
    this.subscriptions.add(this.route.parent.data.subscribe(({device}) => {
      this.device = device;
      this.resetForm();
    }));
  }

  resetForm() {
    this.forms.main.patchValue(this.device);
    // this.forms.group.setValue(this.driver.groups.map(group => group.id));
  }

  save() {
    let device;
    this.devicesService.add(this.getDeviceFromFB()).pipe(
      tap(res => device = res),
      // switchMap(res => this.driverService.updateGroup(res.id, this.forms.group.value)),
    ).subscribe((res) => {
      this.pending = false;
      this.devicesService.updateListSub().subscribe();
      // this.routerHelper.navigatePopup(['driver', driver.id, 'details']);
      this.popupService.close();
    }, this.errorHandler.bind(this));
  }

  update() {
    forkJoin(
      this.devicesService.partialUpdate(this.device.id, this.getDeviceFromFB()),
    ).subscribe(res => {
      Object.assign(this.device, res[0]);
      this.devicesService.updateListSub().subscribe();
      this.editSuccess();
    }, err => {
      console.log(err);
      console.log(err.error.data.msg);
      this.errorHandler(err);
    });
  }

  getDeviceFromFB(): any {
    const entity = {};

    const  formData = this.forms.main.value;
    for (const i in formData) {
        if (i === 'vehicleId' && formData[i]) {
          //array of ids in update, vehicleid in insert (dont know why :))
          entity[i] = Array.isArray(formData[i]) ? formData[i][0] : formData[i];
        } else {
          entity[i] = formData[i];
        }
    }
    if (!entity.hasOwnProperty('firmware')) {
      entity['firmware'] = '1.0';
    }
    if (!entity.hasOwnProperty('ipAddress') || !entity['ipAddress']) {
      entity['ipAddress'] = '123.123.123.123';
    }
    return entity;
  }


  getUserLabel = (item) => {
    return 'asdfasdf';
  }
}


import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeSelectorService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document, private config: ConfigService) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  initTheme() {
    if (this.config.themeCss) {
      this.config.themeCss.split(';').forEach(cssClass => this.renderer.addClass(this.document.body, cssClass));
    }
    if (this.config.favicon) {
      this.document.getElementById('appFavicon').setAttribute('href', `${this.config.favicon}.ico`);
    }
  }
}

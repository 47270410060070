import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostBinding,
  HostListener, Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true
    }
  ]
})
export class SwitchComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @HostBinding('class.is-active')
  state = false;
  isDisabled = false;
  @Input() label: string;

  @HostListener('click')
  toggleState() {
    this.state = !this.state;
    this.cd.detectChanges();
    this.emmitEvent();
  }

  constructor(private cd: ChangeDetectorRef) {
  }

  onChange: any = () => {
  }
  onTouch: any = () => {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  emmitEvent() {
    this.onChange(this.state);
    this.onTouch(this.state);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.cd.detectChanges();
  }

  writeValue(obj: any): void {
    this.state = obj;
    this.cd.detectChanges();
  }

}

import {ChangeDetectorRef, Component, NgZone, OnInit, ViewEncapsulation} from '@angular/core';
import {PerspectiveMenuService} from './shared/modules/perspective-menu/perspective-menu.service';
import {routerTransition} from './core/animations';
import {UserSettingsService} from './core/services/user-settings.service';
import {ThemeSelectorService} from './core/services/theme-selector.service';
import {hereMapEvents} from "./shared/modules/map/heremap-events";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerTransition],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  public popupClass;
  private showChild;
  private showChildInside;

  constructor(private cd: ChangeDetectorRef,
              public perspectiveService: PerspectiveMenuService,
              private ngZone: NgZone,
              private themeService: ThemeSelectorService,
              private userSettings: UserSettingsService) {
    this.themeService.initTheme();
  }

  ngOnInit(): void {
    this.perspectiveService.popupSize.subscribe(res => {
      this.popupClass = res;
      this.cd.detectChanges();
    });
    this.ngZone.runOutsideAngular(args => {
      // @ts-ignore
      H.util.eval(hereMapEvents)
    })
  }


  get getState() {
    return this.showChild;
  }

  get getStateInside() {
    return this.showChildInside;
  }

  onActivate($event, type = null) {
    type ? this.showChildInside = $event : this.showChild = $event;
    this.cd.detectChanges();
  }

  onDeactive($event, type = null) {
    type ? this.showChildInside = false : this.showChild = false;
    this.cd.detectChanges();
  }
}

<div class="d-flex justify-content-between align-items-stretch">
    <section class="driver_header__driver">
        <div class="d-flex justify-content-start align-items-center">
            <div class="mr-m">
                <app-user-avatar size="54"
                                 [initials]="driver|fullName|initials"
                                 [avatar]="driver.avatarUrl">

                </app-user-avatar>
            </div>
            <div>
                <h2>{{driver|fullName}}</h2>
                <app-tags-group [tags]="driver.groups" size="lg"  iconClass="icon-account"></app-tags-group>
            </div>

        </div>
        <p *ngIf="driver.phoneNumber" class="text-primary m-0 mt-s"><span translate>tel.</span> {{driver.phoneNumber || '-'}}</p>
        <p *ngIf="driver.email" class="m-0 mt-s"> {{driver.email}}</p>
    </section>
    <section class="driver_header__vehicle d-flex justify-content-between align-items-end flex-column flex-fill">
        <div class="d-flex justify-content-start align-items-center" *ngIf="driver.vehicle">
            <div class="mr-m">
                <app-vehicle-avatar size="40"
                                    [ignition]="driver.vehicle?.telemetry?.ignition"
                                    [avatar]="driver.vehicle.avatarUrl">

                </app-vehicle-avatar>
            </div>
            <app-label-with-tags
                    [label]="driver.vehicle.name || driver.vehicle.id"
                    [tags]="driver.vehicle.groups"
                    (labelClick)="openPopup(driver.vehicle.id, 'vehicle')"

            >

            </app-label-with-tags>
        </div>
        <app-header-location-status [vehicle]="driver?.vehicle"></app-header-location-status>
    </section>
</div>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef, Input, OnInit
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomeInput } from '../custom-select.class';
import { PoiService } from '../../../../views/dashboard/poi/poi.service';
import { Poi } from '../../../../interfaces/poi';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-poi-select',
  templateUrl: './poi-select.component.html',
  styleUrls: ['./poi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PoiSelectComponent),
      multi: true
    }
  ]
})


export class PoiSelectComponent extends CustomeInput<Poi, PoiService> implements ControlValueAccessor, OnInit {
  protected items$ = (this.service as any).list();
  public items = [];
  private isFetchPoi = false;

  constructor(service: PoiService, cd: ChangeDetectorRef) {
    super(service, cd);
  }

  filterChange(filters) {
    if (Object.keys(filters).length) {
      this.service.list(filters).pipe().subscribe(res => {
        this.items = res;
        this.cd.detectChanges();
      });
    } else {
      this.items = [];
    }
  }

  @Input()
  set value(val) {
    console.log({val});
    if (this.bindValue && this.items && this.items.length) {
      const keysToFetch = [];
      this.selected = this.items.filter(value1 => {
        const keyValue = value1[this.bindValue];
        if (!keyValue) {
          return false;

        }
        if (Array.isArray(val)) {
          const isInArray = (val as Array<string>).indexOf(keyValue) !== -1;
          if (!isInArray) {
            keysToFetch.push(isInArray);
          }
          return isInArray;
        } else {
          return val === keyValue;
        }
      });
      this.fetchSelected(keysToFetch);
    } else if (this.bindValue && val) {
      this.fetchSelected(Array.isArray(val) ? val : [val]);
    } else {
      this.selected = val;
    }
    // this.emitChanges();
  }

  get allItems() {
    return [...this.selectedArray, ...this.items.filter(value1 => !this.selectedArray.find(value2 => value2.id === value1.id))];
  }

  fetchSelected(keys: string[] = []) {
    const keysToFetch = this.selected && this.selected.length
      ? keys.filter(key => !this.selected.find(value1 => value1.id === key))
      : keys;
    if (keys && keys.length && !this.isFetchPoi) {
      this.isFetchPoi = true;
      forkJoin(keysToFetch.map(id => this.service.get(id))).subscribe(res => {
        this.selected = res;
        this.cd.detectChanges();
      });
    }

  }

  ngOnInit(): void {
  }
}

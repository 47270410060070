import { Component, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ApiValidatorService } from '../../../core/services/api-validator.service';
import { showErrorTrigger } from '../../../core/animations';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  animations: [showErrorTrigger],
})
export class ErrorMessageComponent implements OnChanges, OnInit, OnDestroy {
  @Input() control: UntypedFormGroup | UntypedFormControl;
  @Input() apiError?: any;
  @Input() required: string;
  @Input() pattern: string;
  @Input() name?: string;
  @Input() context?: string;
  private controlSubscribe: Subscription;

  constructor(private translateService: TranslateService) {
  }

  get showError() {
    return this.control.dirty;
  }

  get errorMessage() {
    if (this.control) {
      for (const key in this.control.errors) {
        if (this.control.errors[key] && this.showError && key !== 'apiError') {
          return this[key] || ApiValidatorService.getValidationMessage(key, this.control.errors[key]);
        }
      }
    }
    if (this.apiError && this.apiError.name) {
      if (!this.name || !this.context) {
        return this.translateService.instant(`API_ERRORS.${this.apiError.name}`);
      }

      if (this.apiError.name === this.name && this.apiError.data &&
        this.apiError.data.context && this.apiError.data.context.hasOwnProperty(this.context)) {
          return this.translateService.instant(`API_ERRORS.${this.apiError.name}_${this.context}`);
        }
    }
    return null;
  }

  ngOnDestroy() {
    if (this.controlSubscribe) {
      this.controlSubscribe.unsubscribe();
    }
  }

  ngOnInit() {
    if (this.control) {
      this.controlSubscribe = this.control.valueChanges.subscribe((data) => {
        if (this.apiError) {
          delete this.apiError;
        }
      });
    }
  }

  ngOnChanges(changes: any) {
    if (changes.apiError && changes.apiError.currentValue && this.control) {
      if (!this.name || !this.context) {
        this.control.setErrors({
          'apiError': true,
        });
        this.control.markAsDirty();
        return;
      }
      if (changes.apiError.name === this.name && changes.apiError.data &&
        changes.apiError.data.context && changes.apiError.data.context.hasOwnProperty(this.context)) {
        this.control.setErrors({
          'apiError': true,
        });
        this.control.markAsDirty();
        return;
      }
    }
  }
}

<div class="c-toolbox">
    <div class="text-right">
        <i class="{{icon.icon}}"
           [ngClass]="{'active': active[icon.value]}"
           (click)="openToggle(icon.value)"
           *ngFor="let icon of icons"></i>
    </div>
    <div class="d-flex justify-content-end align-items-start position-relative c-toolbox_wrapper">
        <ng-content></ng-content>
    </div>

</div>

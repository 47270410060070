import { User } from '../../../../interfaces/user';
import { UserService } from '../../../../core/services/user.service';
import { Component } from '@angular/core';
import { NewEditClass } from '../../../../classes/new-edit.class';
import { ActivatedRoute } from '@angular/router';
import { PopupViewService } from '../../../../shared/components/popup-view/popup-view.service';
import { ApiValidatorService } from '../../../../core/services/api-validator.service';
import { RouterHelperService } from '../../../../core/services/router-helper.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { switchMap, tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { DriversService } from '../../../../core/services/drivers.service';
import { DriversGroupsService } from '../../../../core/services/drivers-groups.service';
import { Driver } from '../../../../interfaces/driver';
import { PERMISSIONS, PermissionService } from '../../../../core/services/permission.service';

@Component({
  selector: 'app-driver-details-edit',
  templateUrl: './driver-details.component.html',
  styleUrls: ['./driver-details.component.scss']
})
export class DriverDetailsComponent extends NewEditClass {
  readonly permissions = PERMISSIONS;

  protected editPermission = PERMISSIONS.DRIVER_UPDATE;
  protected newPermission = PERMISSIONS.DRIVER_CREATE;
  public driver: Driver;
  public groups$ = this.driversGroupsService.list();
  public users$ = this.usersService.list();

  constructor(private route: ActivatedRoute,
              private driverService: DriversService,
              popupService: PopupViewService,
              apiValidator: ApiValidatorService,
              private routerHelper: RouterHelperService,
              public permissionService: PermissionService,
              private driversGroupsService: DriversGroupsService,
              private usersService: UserService,
              fb: UntypedFormBuilder) {
    super(popupService, apiValidator, fb, permissionService);
  }


  buildForm() {
    this.forms = {
      main: this.fb.group({
        firstName: [null, Validators.required],
        lastName: [null, Validators.required],
        avatarUrl: [null],
        email: [null, this.apiValidator.validateEmail],
        phoneNumber: [],
        userId: [null],
        cardId: [null],
      }),
      group: this.fb.control([]),
    };
  }

  addRouteSub() {
    this.subscriptions.add(this.route.parent.data.subscribe(({driver}) => {
      this.driver = driver;
      this.resetForm();
    }));
  }

  resetForm() {
    this.forms.main.patchValue(this.driver);
    this.forms.group.setValue(this.driver.groups.map(group => group.id));
  }

  save() {
    let driver;
    this.driverService.add(this.getDriverFromFB()).pipe(
      tap(res => driver = res),
      // switchMap(res => this.driverService.updateGroup(res.id, this.forms.group.value)),
    ).subscribe((res) => {
      this.pending = false;
      this.driverService.updateListSub().subscribe();
      // this.routerHelper.navigatePopup(['driver', driver.id, 'details']);
      this.popupService.close();
    }, this.errorHandler.bind(this));
  }

  update() {
    forkJoin(
      this.driverService.partialUpdate(this.driver.id, this.getDriverFromFB()),
      this.driverService.updateGroup(this.driver.id, this.forms.group.value || []),
    ).subscribe(res => {
      Object.assign(this.driver, res[0]);
      this.driverService.updateListSub().subscribe();
      this.editSuccess();
    }, this.errorHandler.bind(this));
  }

  getDriverFromFB(): any {
    const entity = {};

    const  formData = this.forms.main.value;
    for (const i in formData) {
        if ((i === 'userId' || i === 'cardId') && !formData[i]) {
          continue;
        } else {
          entity[i] = formData[i];
        }
    }

    return entity;
  }


  getUserLabel = (item) => {
    return 'asdfasdf';
  }
}


import { Address, Contact } from './address';
import { PoiGroup } from './group';
import { VehicleStatusesColors } from '../core/services/telemetry.service';

export interface Area {
  type: string;
  coordinates: any[];
}


export enum PoiType {
  CUSTOMER = 'customer',
  BRANCH = 'branch',
  OTHER = 'other',
}

export const PoiTypeColor = {
  'customer': VehicleStatusesColors.CUSTOMER_PARK,
  'branch': VehicleStatusesColors.BRANCH_WORK_PARK,
  'other': VehicleStatusesColors.OTHER_WORK_PARK,
};

export interface Poi {
  id: string;
  name: string;
  area: Area;
  point?: Area;
  radius: number;
  type: PoiType;
  color?: string;
  externalId?: any;
  showOnMap: boolean;
  clientId: string;
  createdAt: Date;
  updatedAt: Date;
  groups?: PoiGroup[];
  address: Address;
  contact: Contact;
  poiGroupIds: string[];
}



import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Driver } from '../../../../interfaces/driver';
import { ActivatedRoute } from '@angular/router';
import { RouterHelperService } from '../../../../core/services/router-helper.service';
import { TelemetryService } from '../../../../core/services/telemetry.service';
import { forkJoin, Subscription } from 'rxjs';
import { observableWithCatchError } from '../../../../utils/utils';
import { DriversService } from '../../../../core/services/drivers.service';
import { CollectionUpdateEvent } from '../../../../classes/api-service.class';

@Component({
  selector: 'app-driver-popup-header',
  templateUrl: './driver-popup-header.component.html',
  styleUrls: ['./driver-popup-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DriverPopupHeaderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() driver: Driver;
  private sub: Subscription;

  constructor(private router: RouterHelperService,
              private service: DriversService,
              private telemetryService: TelemetryService,
              private cd: ChangeDetectorRef,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.sub = this.service.collectionUpdate$.subscribe((res: CollectionUpdateEvent<Driver>) => {
      if (res.event === 'update' && (res.item as Driver).id === this.driver.id) {
        this.driver = Object.assign({}, this.driver, res.item);
      }
      this.cd.detectChanges();
    });

  }

  openPopup(id, type) {
    this.router.navigatePopupToOpen(this.route, [type, id]);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.driver.vehicle) {
      const vehicleId = this.driver.vehicle.id;
      forkJoin(
        observableWithCatchError(this.telemetryService.vehiclesLastPosition([vehicleId])),
        observableWithCatchError(this.telemetryService.vehiclesLastRide({
          vehicleIds: [vehicleId],
        }))
      ).subscribe(res => {
        this.driver.vehicle = Object.assign({}, this.driver.vehicle, {
          telemetry: res[0][vehicleId],
          ride: res[1][vehicleId],
        });
        this.cd.detectChanges();
      });
    }
  }

}

import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
  selector: 'app-favorite-input',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FavoriteInputComponent),
      multi: true
    }
  ]
})
export class FavoriteInputComponent implements ControlValueAccessor {
  public isFavorite: boolean;
  private isDisabled = false;

  @Input() color: string;

  constructor() {
  }

  onChange: any = () => {
  }
  onTouch: any = () => {
  }

  @Input()
  set valueNoEvent(val) {
    this.isFavorite = val;
  }

  @Input()
  set value(val) {
    this.isFavorite = val;
    this.onChange(val);
    this.onTouch(val);
  }

  toggleFavorite() {
    if (!this.isDisabled) {
      this.value = !this.isFavorite;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

}

import { Injectable } from '@angular/core';
import * as pbi from 'powerbi-client';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { IEmbedConfiguration } from 'powerbi-client';
import { map } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { Report } from 'report';
import { UserSettingsService } from './user-settings.service';

export interface PowerBiAccessTokenResponse {
  token: string;
  embedUrl: string;
  reportId: string;
}

@Injectable({
  providedIn: 'root'
})
export class PowerBiService {
  private baseUrl: string;

  constructor(private config: ConfigService,
              private userSettings: UserSettingsService,
              private http: HttpClient) {
    this.baseUrl = this.config.fullApiUrl('/powerbi');
  }

  public generateTokenAndShowReport(element: HTMLElement, reportId, filters = []): (userId?: string) => Observable<Report> {
    return (userId = 'me') => this.generateAccesToken(reportId, userId).pipe(
      map(res => this.showReport(element, res, filters))
    );
  }

  public generateAccesToken(reportId, userId = 'me') {
    return this.http.post<PowerBiAccessTokenResponse>(`${this.baseUrl}/${userId}/token`, {reportId});
  }


  public vehivleFilters(id) {
    return [
      this.tableFilter([id]),
      //  this.dateFilter(date),
    ];
  }

  public poiFilters(id) {
    return [
      this.tableFilter([id], 'Rides', 'ParkStartPOIId'),
      //  this.dateFilter(date),
    ];
  }

  public tableFilter(id = [], table = 'vehicle', column = 'id') {
    return {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table,
        column,
      },
      operator: 'In',
      values: id,
      filterType: pbi.models.FilterType.Basic,
    };
  }

  public dateFilter(date: { start: string, end: string }): any {
    const start = DateTime.fromISO(date.start).toFormat('yyyyLLdd');
    const end = DateTime.fromISO(date.end).toFormat('yyyyLLdd');
    return {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: 'Period',
        column: 'DateId'
      },
      logicalOperator: 'And',
      conditions: [
        {
          operator: 'GreaterThanOrEqual',
          value: start,
        },
        {
          operator: 'LessThanOrEqual',
          value: end,
        }
      ],
      filterType: pbi.models.FilterType.Advanced
    };
  }

  public showReport(element: HTMLElement, accessToken: PowerBiAccessTokenResponse, filters = []) {

    const config: any = {
      type: 'report',
      tokenType: pbi.models.TokenType.Embed,
      accessToken: accessToken.token,
      embedUrl: accessToken.embedUrl,
      pageView: 'fitToWidth',
      id: accessToken.reportId,
      permissions: pbi.models.Permissions.All,
      filters,
      settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        background: pbi.models.BackgroundType.Transparent,
        localeSettings: {
          language: this.userSettings.language,
        }
      },
    };

    // for further use :)
    // config.slicers = [
    //   {
    //     selector: {
    //       $schema: 'http://powerbi.com/product/schema#slicerTargetSelector',
    //       target: {
    //         table: 'Period',
    //         column: 'Period'
    //       } ,
    //     },
    //     state: {
    //       filters: [{
    //         $schema: 'http://powerbi.com/product/schema#basic',
    //         target: {
    //           table: 'Period',
    //           column: 'Period'
    //         },
    //         operator: 'In',
    //         values: ['Wczoraj'],
    //         filterType: pbi.models.FilterType.Basic
    //       }]
    //     }
    //   }
    // ];

    return new pbi.service.Service(pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory, pbi.factories.routerFactory)
      .embed(element, config) as Report;
  }

}

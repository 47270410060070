import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouteHistoryComponent } from './route-history.component';
import { RouteStatsMapboxComponent } from './route-stats-mapbox/route-stats-mapbox.component';
import { TableHeaderComponent } from './route-history-table-row/table-header/table-header.component';
import { RouteHistoryTableRowRideComponent } from './route-history-table-row/route-history-table-row-ride.component';
import { RouteHistoryTableRowComponent } from './route-history-table-row/route-history-table-row.component';
import { RouteHistoryTableRowParkComponent } from './route-history-table-row/route-history-table-row-park.component';
import { RouteHistoryTableComponent } from './route-history-table/route-history-table.component';
import { ComponentsModule } from '../../components/components.module';
import { DateRangeFilterModule } from '../date-range-filter/date-range-filter.module';
import { MapModule } from '../map/map.module';
import { OverflowSpinnerModule } from '../overflow-spinner/overflow-spinner.module';
import { PipesModule } from '../../pipes/pipes.module';
import { WarehouseUtilsModule } from '../warehouse-utils/warehouse-utils.module';
import { DirectivesModule } from '../../directives/directives.module';
import { RouteHistoryMapComponent } from './route-history-map/route-history-map.component';
import { CustomInputsModule } from '../custom-inputs/custom-inputs.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TranslateModule } from '@ngx-translate/core';
import { RouteFiltersComponent } from './route-filters/route-filters.component';
import { FormGroupModule } from '../form-group/form-group.module';
import { RouteAvatarSwitchComponent } from './route-avatar-switch/route-avatar-switch.component';
import {  NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { RouteHistoryFuelStatsComponent } from './route-history-fuel-stats/route-history-fuel-stats.component';

@NgModule({
  declarations: [RouteHistoryComponent,
    RouteStatsMapboxComponent,
    TableHeaderComponent,
    RouteHistoryTableRowComponent,
    RouteHistoryTableRowParkComponent,
    RouteHistoryTableComponent,
    RouteHistoryTableRowRideComponent,
    RouteHistoryMapComponent,
    RouteFiltersComponent,
    RouteAvatarSwitchComponent,
    RouteHistoryFuelStatsComponent],
  exports: [RouteHistoryComponent],
    imports: [
        CommonModule,
        DirectivesModule,
        NgbTooltipModule,
        TranslateModule,
        ReactiveFormsModule,
        CustomInputsModule,
        FormGroupModule,
        ComponentsModule,
        FormsModule,
        MapModule,
        WarehouseUtilsModule,
        PipesModule,
        PerfectScrollbarModule,
        OverflowSpinnerModule,
        DateRangeFilterModule,
        CustomInputsModule,
    ]
})
export class RouteHistoryModule {
}

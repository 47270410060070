import { NotificationT, NotificationType } from './../../../../../interfaces/notificationT';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { ApiValidatorService } from '../../../../../core/services/api-validator.service';
import { MapService } from '../../../../../shared/modules/map/map.service';
import { PoiService } from '../../../../dashboard/poi/poi.service';
import { PopupViewService } from '../../../../../shared/components/popup-view/popup-view.service';
import { PoiGroupsService } from '../../../../dashboard/poi/poi-groups.service';
import { PermissionService } from '../../../../../core/services/permission.service';
import { RouterHelperService } from '../../../../../core/services/router-helper.service';

@Component({
  selector: 'app-notification-popup-basic',
  templateUrl: './notification-popup-basic.component.html',
  styleUrls: ['./notification-popup-basic.component.scss'],
  providers: [MapService],
})
export class NotifcationPopupBasicComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public notification: NotificationT;

  constructor(private route: ActivatedRoute,
              private routerHelper: RouterHelperService,
              private mapService: MapService,
              private poiGroupsService: PoiGroupsService,
              popupService: PopupViewService,
              private poiService: PoiService,
              apiValidator: ApiValidatorService,
              permission: PermissionService,
              fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    // this.subscription = this.route.params.subscribe(res => console.log(res));
    this.addRouteSub();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  addRouteSub() {
    this.subscription.add(this.route.parent.data.subscribe(({notification}) => {
      console.log(notification);
      this.notification = notification;
    }));
    this.subscription.add(this.mapService.mapIsLoad.subscribe(() => {
      if (this.notificationCoordinates !== null) {
        this.mapService.mapInstance.setCenter(this.notificationCoordinates, true);
        this.mapService.mapInstance.setZoom(18, true);
      }
    }));
  }

  get notificationType() {
    return NotificationType;
  }

  get vehiclesFulfilled() {
    return this.notification.data[NotificationType.NORM_AGGREGATE].vehiclesFulfilled;
  }

  get vehiclesNotFulfilled() {
    return this.notification.data[NotificationType.NORM_AGGREGATE].vehiclesNotFulfilled;
  }

  get notificationCoordinates() {
    if (!this.notification || !this.notification.data[this.notification.type]['lng'] ||
    !this.notification.data[this.notification.type]['lat']) {
      return null;
    }
    return {
      lat: this.notification.data[this.notification.type]['lat'],
      lng: this.notification.data[this.notification.type]['lng'],
    };
  }
}

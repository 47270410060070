<app-map height="100%" width="100%" id="map-history">
  <app-map-toolbox [icons]="toolboxIcons" (toggleIcon)="toggleToolbox($event)">
    <app-map-toolbox-search [isOpen]="activeIcon['search']"
                              [enableRevIso]="false"
                              (suggestionSelected)="suggestionSelected($event)"
                              (reverseIsolineLoaded)="reverseIsolineLoaded($event)"></app-map-toolbox-search>
    <app-map-toolbox-radio [choices]="mapLayers"
                           [selected]="selectedLayers"
                           label="{{'SETTINGS'|translate}}"
                           [isOpen]="activeIcon['layers']"
                           (selectChange)="switchChange($event)">
      <div *ngIf="selectedLayers === selectedLayersType.MARKERS">
        <app-switch label="{{'SHOW_POIS'|translate}}"  [ngModel]="showPoi" *hasPermission="PERMISSIONS.POI_SHOW_ON_MAP"
                    (ngModelChange)="poiSwitchChange($event)"></app-switch>
        <app-switch label="{{'SHOW_STOPS'|translate}}" [(ngModel)]="showPark" *ngIf="rideDataAvailable"></app-switch>
        <app-switch label="{{'SHOW_PARK_ORDER'|translate}}" [ngModel]="showParkOrder"  (ngModelChange)="showParkOrderChange($event)"></app-switch>
        <app-switch label="{{'SHOW_LABELS'|translate}}" [ngModel]="showLabel"  (ngModelChange)="showLabelChange($event)"></app-switch>
        <app-switch label="{{'SHOW_ZONES'|translate}}" [ngModel]="showZones"  (ngModelChange)="showZonesChange($event)"></app-switch>
      </div>
    </app-map-toolbox-radio>
    <app-map-toolbox-custom
      label="{{'SUMMARY'|translate}}"
      [isOpen]="activeIcon['stats']"

    >
      <app-route-stats-mapbox [fuelLimits]="fuelLimits" [rideData]="selectedRideData"
                              [layer]="selectedLayers"></app-route-stats-mapbox>
    </app-map-toolbox-custom>

  </app-map-toolbox>
  <ng-container *ngIf="showZones">
    <app-map-polygon *ngFor="let zone of zones$|async" [area]="zone.area"
                        [zoneStyle]="true"
                      [boundToPoly]="false"
                      [color]="zone.color || '#777FE6'"></app-map-polygon>
  </ng-container>
  <ng-container *ngIf="showSearchResults">
    <app-map-marker-poi *ngIf="geocodedSuggestion" [coordinates]="geocodedSuggestion.coordinates"
                          color="#233445" [showLabel]="showLabel"
                          [name]="geocodedSuggestion.label"></app-map-marker-poi>
    <app-map-polygon  *ngIf="reverseIsoline" [area]="reverseIsoline"
        [zoneStyle]="true"
        [boundToPoly]="false"
        [color]="'#777FE6'"></app-map-polygon>
  </ng-container>
  <div *ngIf="rideDataAvailable">
    <app-map-polyline [telemetry]="telemetry.rideTelemetry" [color]="telemetry.color"
                    [shape]="telemetry.shape"
                    (polyLoaded)="polyLoaded.emit($event)"
                    *ngFor="let telemetry of telemetryArray"></app-map-polyline>
  </div>
  <ng-container [ngSwitch]="selectedLayers">
    <ng-container *ngSwitchCase="selectedLayersType.SPEED">
      <app-map-marker-speed *ngFor="let marker of speedMarkers" [speedMarker]="marker">
      </app-map-marker-speed>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngIf="showPoi">
        <ng-container *ngFor="let poi of poiCollection">
          <app-map-marker-poi [coordinates]="poi.point.coordinates"
                              [color]="poi.color"
                              [name]="poi.name"
                              [radius]="poi.radius"
                              [autoZoom]="false"
                              (markerClick)="poiClick(poi)"
                              [showCircle]="showCircle"
                              *ngIf="poi.point.coordinates.length"
                              [showLabel]="showLabel"></app-map-marker-poi>
        </ng-container>
      </ng-container>


      <ng-container *ngFor="let marker of polylineMarkers">
        <app-map-marker-ride-icon
          *ngIf="showMarker(marker.data.zoomIndex)"
          [data]="marker.data"
          [type]="marker.type"
          [status]="marker.status"
        ></app-map-marker-ride-icon>
      </ng-container>
      <ng-container *ngIf="showPark">
        <app-map-marker-park *ngFor="let marker of markers; index as i"
                             [data]="marker"
                             [index]="i"
                             [showParkOrder]="showParkOrder"
                             (markerClick)="markerClick(marker)"
                             [showLabel]="showLabel"
                             [showDate]="areParksManyDays"
        >
        </app-map-marker-park>
      </ng-container>
    </ng-container>
  </ng-container>
  <app-map-contextmenu [items]="mapService.defaultContexMenuItems" (itemClick)="mapService.contexMenuClick($event)"></app-map-contextmenu>
</app-map>
<app-close-btn position="inside" (toggle)="closeMap.emit()"></app-close-btn>



import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import { UserSettingsService } from '../../../../core/services/user-settings.service';

export interface TableHeaders<T> {
  name: T;
  sort?: string;
  width: number;
  label?: string;
}


@Component({
  selector: 'app-data-grid-header',
  templateUrl: './data-grid-header.component.html',
  styleUrls: ['./data-grid-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataGridHeaderComponent implements OnInit {
  @Input() headers: TableHeaders<any>[];
  @Input() activeSort: string;
  @Output() sortChange = new EventEmitter();
  @Output() headersChange = new EventEmitter();
  @Input() actionButtons = true;
  @Input() tableId: string;
  @Input() template: string;
  startDrag = false;
  startWidth = 0;
  startX = 0;
  startIndex = 0;

  constructor(private el: ElementRef, private cd: ChangeDetectorRef, private userSettings: UserSettingsService) {
  }

  ngOnInit() {
    this.getFromStorage();
  }

  get width() {
    return this.el.nativeElement.offsetWidth;
  }

  getFromStorage() {
    this.userSettings.settings$.subscribe((settings) => {
      const data = settings && settings[`tableHeaders_${this.tableId}`] || null;
      this.headers = data && data.length === this.headers.length ? data : this.headers;
      this.headersChange.emit(this.headers);
    });
  }

  drop($event: CdkDragDrop<any>) {
    this.headers.splice($event.currentIndex, 0, this.headers.splice($event.previousIndex, 1)[0]);
    this.saveHeaders();
    this.headersChange.emit(this.headers);
  }

  saveHeaders() {
    this.userSettings.updateSettings({[`tableHeaders_${this.tableId}`]: this.headers }).subscribe();
  }


  dragElement($event: MouseEvent) {
    $event.stopPropagation();
    if (this.startDrag && this.startIndex >= 0) {
      this.headers[this.startIndex].width = this.startWidth + $event.pageX - this.startX;
      this.headersChange.emit(this.headers);
    }
  }

  dragStart($event: MouseEvent, i: number) {
    $event.stopPropagation();
    this.startDrag = true;
    this.startIndex = i;
    this.startWidth = this.headers[this.startIndex].width;
    this.startX = $event.pageX;
  }

  dragEnd() {
    this.startDrag = false;
    this.startWidth = 0;
    this.startX = 0;
    this.startIndex = -1;
    this.saveHeaders();
  }

  getType(header: TableHeaders<any>) {
    return this.template ? 'template' : header.sort ? 'sort' : 'normal';
  }

  getLabel(header: TableHeaders<any>) {
    return header.label || header.label === '' ? header.label : header.name;
  }
}

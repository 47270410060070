import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-vehicle-avatar',
  templateUrl: './vehicle-avatar.component.html',
  styleUrls: ['./vehicle-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleAvatarComponent implements OnInit {
  @Input() avatar: string = null;
  @Input() size = 40;
  @Input() ignition: boolean;
  @Input() etoll : any = null;

  constructor() {
  }

  ngOnInit() {
  }

  get etollTooltip() {
    return this.etoll && this.etoll.businessNumber ? this.etoll.businessNumber : '(brak)';
  }

  copyToClipboard() {
    if (this.etoll && this.etoll.businessNumber) {
      this.copyMessage(this.etoll.businessNumber);
    }
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-table-sort-btn',
  templateUrl: './table-sort-btn.component.html',
  styleUrls: ['./table-sort-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSortBtnComponent {
  @Input() activeSort: string;
  @Input() label: string;
  @Input() value: string;
  @Input() emitNumber: boolean;
  @Output() sortChange = new EventEmitter();


  get descValue() {
    return `-${this.value}`;
  }

  get isASC() {
    return this.activeSort === this.value;
  }

  get sortDirection(): string {
    return this.isActive ? this.isASC ? 'c-sort-btn--asc' : 'c-sort-btn--desc' : '';
  }

  get isActive(): boolean {
    return this.activeSort && (this.activeSort === this.value || this.activeSort === this.descValue);
  }

  constructor() {
  }

  sortClick() {
    switch (this.activeSort) {
      case this.value :
        this.sortChange.emit(this.descValue);
        break;
      case this.descValue :
        this.sortChange.emit(null);
        break;
      default:
        this.sortChange.emit(this.value);
    }
  }
}

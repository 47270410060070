import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReminderService } from '../../../../core/services/reminder.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Vehicle } from '../../../../interfaces/vehicle';
import { ActivatedRoute } from '@angular/router';
import { filter, switchMap } from 'rxjs/operators';
import { Reminder } from '../../../../interfaces/reminder';
import { DeleteModalComponent } from '../../../../shared/components/delete-modal/delete-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PERMISSIONS, PermissionService } from 'src/app/core/services/permission.service';

@Component({
  selector: 'app-vehicle-service',
  templateUrl: './vehicle-service.component.html',
  styleUrls: ['./vehicle-service.component.scss']
})
export class VehicleServiceComponent implements OnInit, OnDestroy {
  public addNew = false;
  public editId: string;
  private remindersSub = new BehaviorSubject(null);
  public reminders$ = this.remindersSub.asObservable().
    pipe(filter(res => !!res), switchMap((id) => this.remindersService.list({vehicleIds: id})));
  protected subscriptions = new Subscription();
  public vehicle: Vehicle;
  readonly permissions = PERMISSIONS;

  constructor(private remindersService: ReminderService,
              protected permissionService: PermissionService,
              protected modal: NgbModal,
              protected route: ActivatedRoute) {
  }

  ngOnInit() {
    this.routeSub();
  }

  get canEdit() {
    return this.permissionService.hasPermission(this.permissions.REMINDER_UPDATE);
  }

  protected routeSub() {
    this.subscriptions.add(this.route.parent.data.subscribe(({vehicle}) => {
      this.vehicle = vehicle;
      this.remindersSub.next(this.vehicle.id);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handlerAdd() {
    this.addNew = true;
  }

  addUpdate($event) {
    if ($event) {
      this.remindersSub.next(this.vehicle.id);
    }
    this.addNew = false;
  }

  reminderHasUpdate($event, reminder: Reminder) {
    if ($event) {
      Object.assign(reminder, $event);
    }
    this.editId = null;
  }

  deleteRemider(reminder: Reminder) {
    const modal = this.modal.open(DeleteModalComponent);
    modal.componentInstance.text = {
      header: 'DELETE',
      body: `Usunąć przypomnienie <strong>${reminder.name}</strong>`,
      submit: 'DELETE'
    };
    modal.componentInstance.deleteFunc = () => {
      return this.remindersService.delete(reminder.id);
    };
    modal.result.then(res => {
      if (res) {
        this.remindersSub.next(this.vehicle.id);
      }
    }, () => {
    });
  }

  handlerAction({type, reminder}) {
    switch (type) {
      case 'delete':
        this.deleteRemider(reminder);
        break;
      default:
        this.editId = reminder.id;
    }
  }
}

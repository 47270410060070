import { VehicleExtensions } from './../../../../interfaces/vehicle';
import { TelemetryVehicle } from './../../../../interfaces/telemetry';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Vehicle } from '../../../../interfaces/vehicle';
import { DateRangeService, DateRageValue, DateRangeValueType } from '../../../../shared/modules/date-range-filter/date-range.service';
import { TelemetryService } from '../../../../core/services/telemetry.service';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { PerspectiveMenuService } from '../../../../shared/modules/perspective-menu/perspective-menu.service';
import { arrayValueFilter, arrayValueFilterByRange } from 'src/app/utils/array-value-filter';
import { DatePipe } from '@angular/common';
import { getAnalogFuelPercentage } from 'src/app/utils/fuel-analog';
import { VehiclesService } from 'src/app/core/services/vehicles.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-vehicle-fuel',
  templateUrl: './vehicle-fuel.component.html',
  styleUrls: ['./vehicle-fuel.component.scss'],
  providers: [DatePipe]
})
export class VehicleFuelComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  private vehicle: Vehicle;

  public pending = true;
  public dateFilters: DateRageValue = this.dateFilterService.queryToDateRange(this.route.snapshot.queryParams, true)
    || this.dateFilterService.queryToDateRange(this.route.snapshot.queryParams)
    || this.dateFilterService.dateRangeValueByType(DateRangeValueType.today);
  public calibrationString = '';
  public showVoltageValue = true;
  public showCalibration = false;

  multi: any[];
  view: any[] = [1160, 700];
  private frames: TelemetryVehicle[];

  // options
  legend = false;
  showLabels = true;
  animations = true;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  xAxisLabel = this.translate.instant('TOTAL_DISTANCE');
  yAxisLabel = this.translate.instant('FUEL_LEVEL');
  timeline = true;

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  constructor(private route: ActivatedRoute,
              private dateFilterService: DateRangeService,
              private perspectiveMenu: PerspectiveMenuService,
              private datePipe: DatePipe,
              private translate: TranslateService,
              private vehicleService: VehiclesService,
              private telemetryService: TelemetryService) {
  }

  ngOnInit(): void {
    // tslint:disable-next-line: max-line-length
    this.showCalibration = this.route.snapshot && this.route.snapshot.queryParams && this.route.snapshot.queryParams.calibration ? true : false;
    this.perspectiveMenu.popupSizeType = 'large';
    this.subscription = this.route.parent.data.subscribe(({vehicle}) => {
      this.vehicle = vehicle;

      this.loadFuelChartData();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  loadFuelChartData(reload: boolean = true): void {
    this.pending = true;
    this.multi = [];
    if (reload) {
      this.vehicleService.getExtensions(this.vehicle.id).subscribe(ext => {
        const extensions: VehicleExtensions = ext;
        if (extensions && extensions.ANALOG_FUEL && extensions.ANALOG_FUEL.calibration) {
          this.calibrationString = extensions.ANALOG_FUEL.calibration.join(',');
        }

        this.getTelemeteryFrames(this.vehicle.id).subscribe(res => {
          console.log(`fuel chart data length: ${res.length}`);

          // const filteredRes = this.filterFrames(res);
          this.frames = res;
          this.buildChartData();
          this.pending = false;
        });
      });
    } else {
      this.buildChartData();
      this.pending = false;
    }
  }

  buildChartData() {
    const showRawFrames = this.route.snapshot && this.route.snapshot.queryParams && this.route.snapshot.queryParams.raw ? true : false;

    const isAnalogFuel = this.frames.length ?
      // calibration exists &  frames have volageAnalog3
      // tslint:disable-next-line: max-line-length
      this.calibrationString && this.calibrationString.length > 0 && this.frames[0].hasOwnProperty('voltageAnalog3') && this.frames[0].voltageAnalog3 > 0 :
      false;
    // tslint:disable-next-line: max-line-length
    console.log(`[fuel] ShowRawFrames: ${showRawFrames} isAnalogFuel: ${isAnalogFuel}`);
    if (isAnalogFuel) {
      const calibration: number[] = this.calibrationString.split(',').map(m => parseFloat(m));
      this.frames.forEach(f => {
        f.fuelLevelPercents = getAnalogFuelPercentage(f.voltageAnalog3, calibration);
        f.fuelLevelLitres = null;
      });
    }

    let tempFrames: TelemetryVehicle[] = showRawFrames ? this.frames : this.filterFrames(this.frames);

    if (!showRawFrames && tempFrames) {
      tempFrames = tempFrames
      // tslint:disable-next-line: max-line-length
        .filter((item, index) => index > 0 && Math.round(tempFrames[index].totalDistance) !== Math.round(tempFrames[index - 1].totalDistance));
    }

    const tempMulti: any[] = [
      {
        'name': 'fuel',
        'series': tempFrames
          .map(item => ({
            // name: new Date(item.eventDate),
            name: item.totalDistance,
            value: item.fuelLevelLitres ? item.fuelLevelLitres : Math.round(item.fuelLevelPercents / 100  * this.vehicle.fuelTankSize),
            extras: {
              eventDate: item.eventDate,
              speed: item.speed
            },
          })),
      },
    ];

    if (this.showCalibration && this.showVoltageValue) {
      tempMulti.push({
        'name': 'voltage',
        'series': tempFrames
          .map(item => ({
            // name: new Date(item.eventDate),
            name: item.totalDistance,
            value: item.voltageAnalog3 * 10,
            extras: {
              eventDate: item.eventDate,
              speed: item.speed
            },
          })),
      })
    }

    this.multi = tempMulti;
  }

  getChartItemValue(item: TelemetryVehicle): number {
    if (this.showVoltageValue) {
      return item.voltageAnalog3;
    } else {
      return item.fuelLevelLitres ? item.fuelLevelLitres : Math.round(item.fuelLevelPercents / 100  * this.vehicle.fuelTankSize);
    }
  }

  recalibrateChart() {
    console.log(`Calibration: ${this.calibrationString} Show voltage: ${this.showVoltageValue}`);
    this.loadFuelChartData(false);
  }

  changeShowVoltage() {
    this.showVoltageValue = !this.showVoltageValue;
    this.recalibrateChart();
  }

  getTelemeteryFrames(vehicleId: string): Observable<TelemetryVehicle[]> {
    const showRawFrames = this.route.snapshot && this.route.snapshot.queryParams && this.route.snapshot.queryParams.raw ? true : false;
    // tslint:disable-next-line: max-line-length
    const includeRawData = this.route.snapshot && this.route.snapshot.queryParams && this.route.snapshot.queryParams.includeRaw ? true : false;
    let zoomIndex = 0;
    const {start, end} = this.dateFilters;
    const telemetry = this.telemetryService.vehiclesFuelPositions(vehicleId, {
      from: start,
      to: end,
    }, includeRawData, showRawFrames ? 'true' : null,).pipe(
      tap(() => this.pending = false),
      // tslint:disable-next-line: max-line-length
      map(res => res.filter((item, index) =>
        item.totalDistance > 0 &&
        !!item.ignition  &&
        // filter incorect frames when distance between is grater than threshol
        (index > 0 && Math.abs(res[index].totalDistance - res[index - 1].totalDistance) < 1000))),
      tap(res => res.forEach(item => {
        item.zoomIndex = zoomIndex++;
        if (zoomIndex > 11) {
          zoomIndex = 0;
        }
        if (includeRawData) {
          this.setVoltageAnalog3FromRaw(item);
        }
      }))
    );
    return telemetry;
  }

  setVoltageAnalog3FromRaw(frame: TelemetryVehicle): void {
    const raw: string[] = frame.raw.split(',');
    frame.voltageAnalog3 = parseFloat(raw[15]);
  }

  filterFrames(frames: TelemetryVehicle[]): TelemetryVehicle[] {
    let filteredFrames: TelemetryVehicle[] = [];

    // find refuels so filter can be applied to frames between refuels
    const refuelIndexes: number[] = this.getRefuelIndexes(frames, 15, 5);
    console.log(`Refuel indexes: ${refuelIndexes}`);
    let rangeStart = 0;
    let framesBetweenRefuels: TelemetryVehicle[] = null;
    let  filteredFramesBetweenRefules: TelemetryVehicle[] = null;

    refuelIndexes.forEach( rIndex => {
      framesBetweenRefuels = frames.slice(rangeStart, rIndex + 1);
      filteredFramesBetweenRefules = this.applyFilter(framesBetweenRefuels);
      filteredFrames = filteredFrames.concat(filteredFramesBetweenRefules);

      rangeStart = rIndex + 1;
    });

    framesBetweenRefuels = frames.slice(rangeStart, frames.length);
    filteredFramesBetweenRefules = this.applyFilter(framesBetweenRefuels);
    filteredFrames = filteredFrames.concat(filteredFramesBetweenRefules);


    return filteredFrames;
  }

  applyFilter(frames: TelemetryVehicle[], distanceDelta: number = 10): TelemetryVehicle[] {
    const filteredFrames: TelemetryVehicle[] = [];

    frames.forEach(f => {
      const newFrame: TelemetryVehicle = {
        ...f
      };

      const hasLitres = !!f.fuelLevelLitres;
      const startIndex = frames.indexOf(frames.find(fnd => fnd.totalDistance >= (f.totalDistance - distanceDelta)));
      const endIndex = frames.indexOf(frames.find(fnd => fnd.totalDistance >= (f.totalDistance + distanceDelta)));

      const filteredValue: number = Math.round(arrayValueFilterByRange(f, frames, startIndex, endIndex, (item: TelemetryVehicle) => {
        return hasLitres ? item.fuelLevelLitres : item.fuelLevelPercents;
      }));

      if (hasLitres) {
        newFrame.fuelLevelLitres = filteredValue;
      } else {
        newFrame.fuelLevelPercents = filteredValue;
      }

      filteredFrames.push(newFrame);

    });

    return filteredFrames;
  }

  getRefuelIndexes(frames: TelemetryVehicle[], fuelPercentsDelta: number, distanceDelta: number): number[] {
    if (!this.vehicle.fuelTankSize) {
      return [];
    }

    const refuelIndexes: number[] = [];
    const tankSize: number = this.vehicle.fuelTankSize;

    frames.forEach (f => {
      const hasPercents = !!f.fuelLevelPercents;
      const currentFuelPercents = hasPercents ? f.fuelLevelPercents : (f.fuelLevelLitres / tankSize);

      const currentIndex = frames.indexOf(f);
      const currentDistance = f.totalDistance;

      const isRefuel: boolean = this.areNextFramesGreater(
        frames, currentIndex, currentFuelPercents, currentDistance,
        fuelPercentsDelta, distanceDelta, tankSize);

      if (isRefuel) {
        refuelIndexes.push(currentIndex);
      }
    });

    return refuelIndexes;
  }

  areNextFramesGreater(frames: TelemetryVehicle[], currentIndex: number,  currentLevel: number,
    currentDistance: number, fuelPercentsDelta: number, distanceDelta: number, tankSize: number): boolean {

    for (let i = currentIndex + 1; i < frames.length; i++) {
      const frame = frames[i];

      const hasPercents = !!frame.fuelLevelPercents;
      const frameFuelPercents = hasPercents ? frame.fuelLevelPercents : (frame.fuelLevelLitres / tankSize);

      if (frameFuelPercents <= (currentLevel + (fuelPercentsDelta / 100))) {
        return false;
      }

      if (frame.totalDistance - currentDistance >= distanceDelta ) {
        return true;
      }
    }


    return false;
  }

  dateFilterChange($event) {
     this.dateFilters = $event;
     this.dateFilterService.dateRangeToQuery($event, this.route, true);
    this.loadFuelChartData();
  }

  formatAxisLabel = (value) => {
    const axisLabel = `${value.toLocaleString('pl')}`;

    // const sufix = this.multi[0].series.find(f => f.name === value);
    // if (sufix && sufix.extras && sufix.extras.eventDate) {
    //   axisLabel += ` (${this.datePipe.transform(sufix.extras.eventDate, 'yy-MM-dd HH:mm')})`;
    // }

    return axisLabel;
  }

}

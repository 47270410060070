import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NormsConfigComponent } from './norms-config.component';
import { TemplateConfigurationComponent } from './template-configuration.component';
import { ReportConfigComponent } from './report-config.component';
import { ComponentsModule } from '../../components/components.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    TranslateModule,
  ],
  declarations: [NormsConfigComponent, TemplateConfigurationComponent, ReportConfigComponent],
  exports: [TemplateConfigurationComponent],
})
export class TemplateConfigurationsModule {
}

<div class="d-flex justify-content-start align-self-center">
  <div class="mr-m" *ngIf="allButton">
    <app-form-group>
      <label>
        <input app-form [ngModel]="allSelected" type="checkbox" (ngModelChange)="allToggle()">
        <span></span>{{'ALL'|translate}}</label>
    </app-form-group>
  </div>
  <div class="mr-m" *ngFor="let channel of formArray.controls; let i=index">
    <app-form-group *ngIf="showChannel(channels[i])">
      <label>
        <input app-form [formControl]="channel" type="checkbox">
        <span></span>{{channels[i]|translate}}</label>
    </app-form-group>
  </div>
</div>
<div *ngIf="showEmailInput && hasEmail" class="mt-xm">
  <app-form-group>
    <label>{{ 'EMAILS'|translate }}</label>
    <input app-form [(ngModel)]="emailsModel" type="text" (blur)="emailChanges()">
  </app-form-group>
</div>

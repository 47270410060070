import { Component, HostBinding } from '@angular/core';
import { routeFadeStateTrigger } from '../authentication.animations';
import { AuthService } from '../../../core/services/auth.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ApiValidatorService } from '../../../core/services/api-validator.service';
import { FormController } from '../../../classes/form-controller.class';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
  animations: [routeFadeStateTrigger],
})
export class PasswordResetComponent extends FormController {
  @HostBinding('@routeFadeState') true;
  isSend = false;

  constructor(private authService: AuthService,
              private fB: UntypedFormBuilder,
              aV: ApiValidatorService) {
    super(aV);
  }


  buildForm() {
    this.form = this.fB.group({
      username: [null, [
        Validators.required,
      ]],
    });
  }

  processSubmit(formValues: any) {
    this.authService.resetPassword(this.form.value)
      .subscribe(
        (response: Response) => {
          this.formPending = false;
          this.isSend = true;
        },
        this.errorHandler.bind(this)
      );
  }
}

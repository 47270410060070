import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalizationComponent } from './localization/localization.component';
import { WorkTypeComponent } from './work-type/work-type.component';

@NgModule({
  declarations: [LocalizationComponent, WorkTypeComponent],
  imports: [
    CommonModule
  ],
  exports: [
    LocalizationComponent,
    WorkTypeComponent
  ]
})
export class WarehouseUtilsModule {
}

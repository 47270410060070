<div class="d-flex" [ngClass]="inline ? 'flex-row align-items-center justify-content-start' : 'flex-column'" >


    <app-selected-tags [items]="selectedArray"
                       bindLabel="name"
                       [removable]="!isDisabled"
                       (removeItem)="removeItem($event)"
                       (removeAll)="removeAll()"
                       [max]="max"
    >
    </app-selected-tags>
    <div ngbDropdown [placement]="placement" [autoClose]="'outside'">
        <div ngbDropdownAnchor class="c-vehicle-select__drop-anchor">
            <button class="btn btn-primary btn-border" translate (click)="toggleOpen()" [disabled]="isDisabled">
                {{label || 'SELECT_' + dimension}}
            </button>
        </div>
        <app-vehicle-select-dropdown ngbDropdownMenu
                                     [selected]="selected"
                                     (close)="close()"
                                     [items]="items"
                                     [multi]="multi"
                                     (selectChange)="setValue($event)"
                                     [dimension]="dimension"
                                     [clientsFilter]="clientsFilter"
                                     class="dropdown-menu dropdown-menu-left"></app-vehicle-select-dropdown>
    </div>
</div>

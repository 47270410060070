import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {TelemetryRide, TelemetryVehicle} from '../../../interfaces/telemetry';
import {TelemetryService, VehicleStatus, VehicleStatusesColors} from '../../../core/services/telemetry.service';
import {interval, Subscription} from 'rxjs';
import {DateTime} from 'luxon';
import {minutesFormat} from '../../../utils/utils';

@Component({
  selector: 'app-vehicle-status',
  templateUrl: './vehicle-status.component.html',
  styleUrls: ['./vehicle-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleStatusComponent implements OnInit, OnChanges, OnDestroy {
  @Input() rideTelemetry: TelemetryRide;
  @Input() lastPosition: TelemetryVehicle;
  public status: VehicleStatus;
  public timeLabel: string;
  private subscription: Subscription;

  constructor(private telemetryService: TelemetryService, private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.subscription = interval(60000)
      .subscribe(res => {
        this.checkStatus();
        this.cd.detectChanges();
      });
  }

  get color() {
    return this.status ? VehicleStatusesColors[this.status.type] : null;
  }

  checkStatus() {
    if (this.rideTelemetry) {
      this.status = this.rideTelemetry.status;
      const duration = DateTime.fromISO(this.status.time).diffNow('minutes').minutes;
      // const hours = duration.hours * -1;
      // const minuts = withZero(Math.floor(duration.minutes * -1));
      this.timeLabel = minutesFormat(Math.round(duration * -1));
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rideTelemetry && changes.rideTelemetry.currentValue) {
      this.checkStatus();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}

<div placement="left"
     [ngbTooltip]="isDriver ? data.VEHICLE[0].v.name : data.DRIVER[0].v.name">
    <div [ngSwitch]="isDriver">
        <app-vehicle-avatar *ngSwitchCase="true"
                            [avatar]="data.VEHICLE[0].v.avatarUrl" size="30"
        ></app-vehicle-avatar>
        <app-user-avatar *ngSwitchDefault initials="{{data.DRIVER[0].v.name|initials}}" [round]="true"
                         [avatar]="data.DRIVER[0].v.avatarUrl" size="30"></app-user-avatar>
    </div>
</div>

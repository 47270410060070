import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, HostBinding, OnDestroy, Renderer2 } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[heightFromTop]'
})
export class HeightFromTopDirective implements AfterViewInit, OnDestroy {
  @HostBinding('style.height.px')
  private height = 0;
  private subsription: Subscription;

  constructor(private el: ElementRef,
              private renderer: Renderer2,
              private cd: ChangeDetectorRef) {
  }

  calculateHeight() {
    this.renderer.addClass(document.body, 'overflow-hidden');
    const coords = (this.el.nativeElement as HTMLElement).getBoundingClientRect();
    this.height = Math.floor(window.innerHeight - coords.top - 1);
    setTimeout(() => {
      this.renderer.removeClass(document.body, 'overflow-hidden');
      this.cd.detectChanges();
    }, 1);
  }


  windowResize() {
    this.subsription = fromEvent(window, 'resize').pipe(
      debounceTime(300)
    ).subscribe((event) => {
      this.calculateHeight();
    });
  }

  ngAfterViewInit(): void {
    this.windowResize();
    setTimeout(() => {
      this.calculateHeight();
    }, 3);
    // this.calculateHeight();
  }

  ngOnDestroy(): void {
    this.subsription.unsubscribe();
  }
}

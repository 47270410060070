import { Injectable } from '@angular/core';
import ApiServiceClass from '../../classes/api-service.class';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Reminder } from '../../interfaces/reminder';

@Injectable({
  providedIn: 'root'
})
export class ReminderService extends ApiServiceClass<Reminder> {


  public descValues = [
    {value: '%d', label: 'DESC_VALUES.DATE'},
    {value: '%km', label: 'DESC_VALUES.MILAGE'},
    {value: '%v', label: 'DESC_VALUES.VEHICLE_NAME'},
    {value: '%r', label: 'DESC_VALUES.PLATE'},
  ];
  public dateIterationType = [
    {value: 'D', label: 'DAY'},
    {value: 'M', label: 'MONTH'},
    {value: 'Y', label: 'YEAR'},
  ];
  public reminderDescExamples = [
    'Pojazd %v (%r) - przegląd przy %km km',
    'Pojazd %v (%r) - data przeglądu %d'
  ];

  constructor(config: ConfigService,
              http: HttpClient) {
    super('/reminders', config, http);
  }

}

import { Injectable } from '@angular/core';
import { ResolveClass } from '../../../classes/resolver.class';
import { Driver } from '../../../interfaces/driver';
import { DriversService } from '../../../core/services/drivers.service';

@Injectable()
export class DriverResolve extends ResolveClass<Driver> {
  protected serviceGetter(id) {
    return (this.service as any).getWithGroups(id);
  }

  constructor(service: DriversService) {
    super(service);
  }
}

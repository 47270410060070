<td class="dates-cell" colspan="2">
    <div class="d-flex justify-content-start align-items-center">
        <div class="status-label--wrapper">
            <app-vehicle-status-label size="sm"
                                      [status]="rideStatus"></app-vehicle-status-label>
        </div>
        <app-from-to showType="hours" [from]="data.RIDE_STARTDATE[0].v"
                     [small]="true"

                     [to]="data.RIDE_ENDDATE[0].v">

        </app-from-to>
    </div>
</td>
<td class="localization-row">
    <div>
        <app-localization type="start" [data]="data" [ellipsis]="true" class="d-block mb-s"></app-localization>
        <app-localization type="end" [data]="data" [ellipsis]="true"></app-localization>
    </div>
</td>
<td class="work-type">
    <app-status-bar [max]="120*60" [value]="data.SUM_RIDE_DURATION[0].v">
        {{data.SUM_RIDE_DURATION[0]|warehouseUnit}}
    </app-status-bar>
</td>
<td class="work-type work-type--time">
    <app-status-bar [max]="100" [value]="data.SUM_RIDE_DISTANCE[0].v">
        {{data.SUM_RIDE_DISTANCE[0]|warehouseUnit}}
    </app-status-bar>
</td>

<td>
    <!-- <div class="urban-over-50"></div> -->
    <span class="nowrap">{{data|avgSpeed}}</span><br>
    <span class="nowrap hide-by-theme">{{data|maxSpeed}}</span>
</td>
<td>
    <app-status-bar [max]="10" [value]="fuelDimmension === 'DURATION' ? data.AVG_FUEL_CONSUMPTION_H[0].v : data.AVG_FUEL_CONSUMPTION[0].v">
      {{(fuelDimmension === 'DURATION' ? data.AVG_FUEL_CONSUMPTION_H[0] : data.AVG_FUEL_CONSUMPTION[0])|warehouseUnit}}
    </app-status-bar>
</td>
<td class="hide-by-theme">
    <app-status-bar [max]="10" [value]="data.AVG_ECO_SCORE[0].v">
        {{data.AVG_ECO_SCORE[0]|warehouseUnit}}
    </app-status-bar>
</td>
<td>

</td>
<td class="drivers-row">

<app-route-avatar-switch [isDriver]="isDriver" [data]="data"></app-route-avatar-switch>
</td>

<td class="table-action" (click)="stopPropagation($event)">
    <app-action-menu [tableRow]="trElementRef" *ngIf="trElementRef" >
      <button  translate class="dropdown-item" (click)="changeRideStatus($event)">
        {{getNewIsWorkStatusLabel()}}
      </button>
    </app-action-menu>
</td>

import { Component, Input } from '@angular/core';
import { WorkVehicleWarehouse } from '../../../../core/services/vehicles.service';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-route-avatar-switch',
  templateUrl: './route-avatar-switch.component.html',
  styleUrls: ['./route-avatar-switch.component.scss'],
})
export class RouteAvatarSwitchComponent {
  @Input() data: WorkVehicleWarehouse;
  @Input() isDriver = false;

  constructor(private tooltipService: NgbTooltipConfig) {
    this.tooltipService.tooltipClass = 'tooltip';
  }
}

<div class="d-flex flex-fill justify-content-between align-items-stretch flex-column overflow-hidden max-height">
    <div class="p-xm flex-grow-1 overflow-auto" *ngIf="fieldsForm">
        <section [formGroup]="normForm">
            <h2 class="form-header" translate>NORM</h2>
            <div class="w-50">
                <app-form-group>
                    <label translate>NAME</label>
                    <input type="text" app-form formControlName="name">
                    <app-error-message [control]="normForm.controls['name']"
                                       [apiError]="formErrors?.name"></app-error-message>
                </app-form-group>
                <app-error-message [control]="fieldsForm.controls['isPeriodic']"
                                   [apiError]="formErrors?.isPeriodic"></app-error-message>
            </div>
        </section>
        <div [formGroup]="fieldsForm">
            <ng-container *ngFor="let section of norm.sections">
                <section *ngIf="!(zone && section.fields && section.fields[0] && section.fields[0].type ==='zones')">
                    <h2 class="form-header mt-l" translate>{{section.label}}</h2>
                    <div class="row">
                        <div *ngFor="let field of section.fields" class="mt-xm"
                             [ngClass]="fieldClass(field, section.fields.length)">
                            <ng-container [ngSwitch]="field.type">
                                <app-vehicles-select
                                        [max]="4"
                                        bindValue="id"
                                        *ngSwitchCase="normType.VEHICLES"
                                        formControlName="{{field.name}}"
                                >
                                </app-vehicles-select>

                                <app-select-input
                                        *ngSwitchCase="normType.VEHICLE_GROUPS"
                                        bindLabel="name"
                                        bindValue="id"
                                        selectClass="w-50"
                                        [max]="3"
                                        [showTags]="true"
                                        [searchable]="true"
                                        formControlName="{{field.name}}"
                                        [items]="vehiclesGroups$|async"
                                >
                                    <label translate>REPORT_FORM_LABEL.{{field.name}}</label>
                                </app-select-input>
                                <app-select-input
                                        *ngSwitchCase="normType.SELECT"
                                        bindLabel="name"
                                        bindValue="value"
                                        [max]="3"
                                        [allowClear]="false"
                                        [multiple]="field.multiselect"
                                        formControlName="{{field.name}}"
                                        [items]="field.options"
                                >
                                    <label translate>{{field.label || field.name}}</label>
                                </app-select-input>
                                <ng-container *ngSwitchCase="normType.PERIOD_SELECTOR" formArrayName="{{field.name}}">
                                    <div class="mb-xm"
                                         *ngFor="let hour of fieldsForm.get(field.name).controls; let i = index;">
                                        <app-hour-range-input [formControlName]="i"
                                                              [dayNumber]="i"
                                        ></app-hour-range-input>
                                        <app-error-message [control]="hour"></app-error-message>
                                    </div>
                                </ng-container>
                                <div *ngSwitchDefault [ngClass]="{'d-none': field.type==='hidden'}">
                                    <app-form-group>
                                        <label translate>{{field.label || field.name}}</label>
                                        <input [type]="field.type" formControlName="{{field.name}}" app-form>
                                    </app-form-group>
                                </div>

                            </ng-container>
                            <app-error-message [control]="fieldsForm.controls[field.name]"
                                               [apiError]="formErrors ? formErrors[field.name] : null"></app-error-message>

                        </div>
                    </div>
                </section>
            </ng-container>

            <section>
                <h2 class="form-header" translate>NOTIFICATIONS</h2>
                <app-channels-input [formControl]="channelForm"
                                    *ngIf="notificationGlobalSettings"
                                    [channels]="notificationGlobalSettings?.availableChannels"></app-channels-input>
            </section>
        </div>
        <div [formGroup]="normForm" class="w-50">
            <app-form-group>
                <label>
                    <input type="checkbox" app-form formControlName="isAggregated">
                    <span></span>{{ 'GROUP_NOTIFICATIONS' | translate}}</label>
            </app-form-group>
        </div>
    </div>
    <div class="p-xm d-flex flex-row justify-content-end align-items-center border-top">
        <button class="btn btn-primary  btn-border mr-m px-xxl" translate (click)="handlerCancel()" [disabled]="pending">
            CANCEL
        </button>
        <button class="btn btn-secondary mr-m px-xxl" translate (click)="save()" [disabled]="pending" *ngIf="canEditOrUpdate">
            <app-spinner [showSpinner]="saving"></app-spinner>
            {{configuration ? 'UPDATE' : 'SAVE'}}
        </button>
    </div>
</div>

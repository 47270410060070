import { Injectable } from '@angular/core';
import { VehicleGroup } from '../../../../interfaces/group';
import { VehiclesGroupsService } from '../../../../core/services/vehicles-groups.service';
import { ResolveClass } from '../../../../classes/resolver.class';

@Injectable()
export class VehicleGroupResolve extends ResolveClass<VehicleGroup> {
  constructor(service: VehiclesGroupsService) {
    super(service);
  }
}

<app-map-toolbox-wrapper [isOpen]="isOpen">
    <h4 class="c-toolbox-header">{{label}}</h4>
    <div class="col-auto min-width">
      <app-form-group icon="icon-search" iconPosition="right" [clear]="true">
          <label>{{'SEARCH_BY_PHRASE'|translate}}</label>
          <input app-form id="typeahead-http" type="text" class="form-control mx-sm-3" [class.is-invalid]="searchFailed"
            [resultTemplate]="rt" [inputFormatter]="formatter" [ngbTooltip]="model?.address?.label"
            [(ngModel)]="model" [ngbTypeahead]="search" placeholder="{{'SEARCH_BY_PHRASE'|translate}}" (selectItem)="itemSelected($event.item)" />
      </app-form-group>
    </div>
    <div class="col-12  mb-m"*ngIf="enableRevIso">
      <app-switch [label]="'TRAVEL_BOUNDARY'|translate" [ngModel]="showReverseIsoline"
        (ngModelChange)="changeState($event)"></app-switch>
    </div>
    <div *ngIf="enableRevIso && showReverseIsoline" [formGroup]="reverseIsolineForm" >
      <div class="col-12  mb-m">
        <app-select-input
                [multiple]="false"
                [allowClear]="false"
                bindLabel="label"
                bindValue="value"
                formControlName="rangeType"
                [value]="value('rangeType')"
                [items]="rangeTypeItems">
            <label for="" translate>RANGE_TYPE</label>
        </app-select-input>
      </div>
      <div class="col-12  mb-m">
        <app-form-group>
          <label>{{ reverseIsolineForm.controls['rangeType'].value === 'time' ? 'min' : 'km'}} </label>
          <input app-form type="number" formControlName="rangeValue">
        </app-form-group>
      </div>
      <div class="col-12  mb-m">
        <app-switch [label]="'INCLUDE_TRAFFIC'|translate" formControlName="traffic" *ngIf="reverseIsolineForm.controls['rangeType'].value === 'time'"></app-switch>
      </div>
    </div>
</app-map-toolbox-wrapper>

<ng-template #rt let-r="result" let-t="term">
  {{ r.address.label}}
</ng-template>

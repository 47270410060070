import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { PermissionService } from '../services/permission.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
  constructor(private permissions: PermissionService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    const permissions = route.data['permissions'];
    const hasPermissions = permissions && this.permissions.hasPermission(permissions);
    return hasPermissions ? true : route.pathFromRoot.find(value => value.outlet === 'popup') ? false : this.router.parseUrl('/no-access');
  }
}

<div class="modal-header">
    <h4 class="modal-title" translate>{{text.header}}</h4>
</div>
<div class="modal-body u--text-center">
    <span [innerHTML]="text.body"></span>
    <app-error-message [apiError]="formErrors?.non_field_errors || formErrors"></app-error-message>
</div>
<div class="modal-footer mt-3">
    <button class="btn btn-primary px-xl btn-border" (click)="activeModal.dismiss()" translate>CANCEL</button>
    <button class="btn btn-danger px-xl" (click)="onDeleteClick()" [disabled]="pending" translate>
        <app-spinner [showSpinner]="pending"></app-spinner>
        {{text.submit}}
    </button>
</div>

import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { VehicleStatuses, VehicleStatusesColors } from '../../../../core/services/telemetry.service';

@Component({
  selector: 'app-vehicle-status-label',
  templateUrl: './vehicle-status-label.component.html',
  styleUrls: ['./vehicle-status-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleStatusLabelComponent implements OnInit {
  @Input() status: VehicleStatuses;
  @Input() showIcon = true;
  @HostBinding('class')
  @Input() size: 'lg' | 'sm' = 'lg';
  @Input() shortLabel = false;
  @Input() ignition = false;

  constructor() {
  }

  ngOnInit() {
  }

  get iconClass() {
    return this.status === VehicleStatuses.PRIVATE_RIDE || this.status === VehicleStatuses.BUSINESS_RIDE
      ? 'icon-marker-arrow-right' : 'icon-parking';
  }

  @HostBinding('style.background')
  get color() {
    return this.status ? VehicleStatusesColors[this.status] : null;
  }

}

import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Poi } from '../../../../interfaces/poi';
import { PopupNavigationItem } from '../../../../shared/components/popup-view/popup-navigation/popup-navigation.component';

@Component({
  selector: 'app-poi-popup',
  templateUrl: './poi-popup.component.html',
  styleUrls: ['./poi-popup.component.scss']
})
export class PoiPopupComponent implements OnInit {

  public navItems: PopupNavigationItem[] = [
    {label: 'DASHBOARD', route: 'dashboard'},
    {label: 'EDIT', route: './', exact: true},
  ];
  public poi$: Observable<Poi> = this.route.data
    .pipe(
      map(({poi}: { poi: Poi }) => poi)
    );

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
  }

}

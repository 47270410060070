import { Injectable } from '@angular/core';
import { DriverGroup } from '../../../../interfaces/group';
import { ResolveClass } from '../../../../classes/resolver.class';
import { DriversGroupsService } from '../../../../core/services/drivers-groups.service';

@Injectable()
export class DriverGroupResolve extends ResolveClass<DriverGroup> {
  constructor(service: DriversGroupsService) {
    super(service);
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PoiPopupComponent } from './poi-popup/poi-popup.component';
import { AuthGuardService } from '../../../core/guards/auth-guard.service';
import { PoiResolve } from './poi.resolve';
import { PoiPopupBasicComponent } from './poi-popup/poi-popup-basic/poi-popup-basic.component';
import { PoiPopupDashboardComponent } from './poi-popup/poi-popup-dashboard/poi-popup-dashboard.component';
import { PoiPopupNewComponent } from './poi-popup-new/poi-popup-new.component';
import { PermissionsGuard } from '../../../core/guards/permissions.guard';
import { PERMISSIONS } from '../../../core/services/permission.service';

const children: Routes =  [
  {
    path: '',
    pathMatch: 'full',
    component: PoiPopupNewComponent,
    canActivate: [PermissionsGuard],
    data: {
      permissions: [PERMISSIONS.POI_CREATE],
    }
  },
  {
    path: ':id',
    component: PoiPopupComponent,
    resolve: {
      poi: PoiResolve,
    },
    children: [{
      path: '',
      pathMatch: 'full',
      component: PoiPopupBasicComponent,
    },
      {
        path: 'dashboard',
        component: PoiPopupDashboardComponent
      },
      {
        path: '**',
        redirectTo: '',
      }
    ]
  }
];

const routes: Routes = [
  {
    path: 'poi',
    outlet: 'popup',
    canActivate: [AuthGuardService],
    children,
  },
  {
    path: 'poi',
    outlet: 'inside',
    canActivate: [AuthGuardService],
    children,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [PoiResolve],
})
export class PoiPopupRoutingModule {
}

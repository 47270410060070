import { IThemeConfig } from './../../../environments/env_config';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Title } from '@angular/platform-browser';

export const SENTRY_KEY = 'https://b8f579d9bebf48249c4eb8482e770f60@bugs.futuremind.com/265';
export const CB_CSS_NAME = 'connectedboat-theme';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  // private API_URL: string = environment.apiUrl;
  private API_VERSION = '1';
  public tokenName = 'FC_T';
  private currentTheme: IThemeConfig = {
    apiUrl: environment.apiUrl,
    host: 'default',
    title: 'FleetConnectWeb',
    favicon: null,
  };

  constructor(private titleService: Title) {
    const themeKey = Object.keys(environment.themes).find(value => window.location.href.includes(environment.themes[value].host));
    if (themeKey && environment.themes[themeKey]) {
      this.currentTheme = environment.themes[themeKey];
    }
    titleService.setTitle(this.currentTheme.title);
  }

  public get host() {
    return this.currentTheme.host;
  }

  get version() {
    return this.API_VERSION;
  }

  public get mapApiKey() {
    return environment.maps.apiKey;
  }

  public get mapReverseUrl() {
    return environment.maps.geoUrl;
  }

  public get themeCss() {
    return this.currentTheme.cssName;
  }

  public get favicon() {
    return this.currentTheme.favicon;
  }

  public get framesFromTableDate(): string {
    return environment.framesFromTableDate;
  }
  get maps() {
    return environment.maps;
  }
  get apiUrl(): string {
    return this.currentTheme.apiUrl;
  }

  get autoCompleteUrl(): string {
    return environment.maps.autocompleteUrl;
  }

  get reverseIsolineUrl(): string {
    return environment.maps.reverseIsolineUrl;
  }

  public fullApiUrl(url: string): string {
    return this.currentTheme.apiUrl + url;
  }

  public isCbTheme(): boolean {
    return this.currentTheme.cssName && this.currentTheme.cssName.indexOf(CB_CSS_NAME) > -1;
  }
}

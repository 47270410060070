import { NotificationResolve } from './notification.resolve';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../../../core/guards/auth-guard.service';
import { PermissionsGuard } from '../../../core/guards/permissions.guard';
import { PERMISSIONS } from '../../../core/services/permission.service';
import { NotifcationPopupBasicComponent } from './notification-popup/notification-popup-basic/notification-popup-basic.component';
import { NotificationPopupComponent } from './notification-popup/notification-popup.component';

const children: Routes =  [
  {
    path: ':id',
    component: NotificationPopupComponent,
    resolve: {
      notification: NotificationResolve,
    },
    children: [{
      path: '',
      pathMatch: 'full',
      component: NotifcationPopupBasicComponent,
    },
    {
      path: '**',
      redirectTo: '',
    }]
  }
];

const routes: Routes = [
  {
    path: 'notification',
    outlet: 'popup',
    canActivate: [AuthGuardService],
    children,
  },
  {
    path: 'notification',
    outlet: 'inside',
    canActivate: [AuthGuardService],
    children,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [NotificationResolve],
})
export class NotificationPopupRoutingModule {
}

<div class="d-flex justify-content-between align-items-start">
    <h5 translate>MILAGE</h5>
    <div class="text-right">
        <p class="m-0"></p>{{criteria.mileageThreshold || '-'}} km
        <p class="m-0 c-milage__iteration"><span translate>ITERATION</span>: {{criteria.mileageIteration || '-'}} km</p>
    </div>
</div>
<div class="d-flex justify-content-between align-items-start">
    <h5 translate>DATE</h5>
    <div class="text-right">
        <p class="m-0"></p>{{criteria.dateThreshold|date:'dd.MM.yyyy' || '-'}}
        <p class="m-0 c-milage__iteration"><span translate>ITERATION</span>: {{ parseDateIteration(criteria.dateIteration) || '-' }}</p>
    </div>
</div>


import { VehicleChartComponent } from './vehicle-chart/vehicle-chart.component';
import { EntityStatusComponent } from './entity-status/entity-status.component';
import { NgModule } from '@angular/core';

import { VehiclePopupRoutingModule } from './vehicle-popup-routing.module';
import { VehicleDetailsComponent } from './vehicle-details/vehicle-details.component';
import { VehicleHistoryComponent } from './vehicle-history/vehicle-history.component';
import { VehiclePopupComponent, PartnerVehiclePopupComponent } from './vehicle-popup.component';
import { VehicleHeaderComponent } from './vehicle-header/vehicle-header.component';
import { VehicleStatisticComponent } from './vehicle-statistic/vehicle-statistic.component';
import { VehicleFuelComponent } from './vehicle-fuel/vehicle-fuel.component';
import { VehicleSettingsComponent } from './vehicle-settings/vehicle-settings.component';
import { VehicleNewComponent } from './vehicle-new/vehicle-new.component';
import { SharedModule } from '../../../shared/shared.module';
import { RouteHistoryModule } from '../../../shared/modules/route-history/route-history.module';
import { VehiclePoiComponent } from './vehicle-poi/vehicle-poi.component';
import { VehicleServiceComponent } from './vehicle-service/vehicle-service.component';
import { VehicleServiceAddComponent } from './vehicle-service/vehicle-service-add/vehicle-service-add.component';
import { VehicleSeviceReminderComponent } from './vehicle-service/vehicle-sevice-reminder/vehicle-sevice-reminder.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
// tslint:disable-next-line: max-line-length
import { VehicleServiceReminderCriteriaComponent } from './vehicle-service/vehicle-sevice-reminder/vehicle-service-reminder-criteria/vehicle-service-reminder-criteria.component';
import {RidePopupModule} from '../ride-popup/ride-popup.module';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import { VehicleExtensionsComponent } from './vehicle-extensions/vehicle-extensions.component';

@NgModule({
  declarations: [
    VehicleDetailsComponent, VehicleExtensionsComponent,
    VehicleHistoryComponent, VehiclePopupComponent, VehicleHeaderComponent, PartnerVehiclePopupComponent,
    VehicleStatisticComponent, VehicleSettingsComponent, VehicleNewComponent,
    VehiclePoiComponent, VehicleServiceComponent, VehicleServiceAddComponent,
    VehicleSeviceReminderComponent, VehicleServiceReminderCriteriaComponent,
    VehicleFuelComponent, EntityStatusComponent, VehicleChartComponent
  ],
  imports: [
    SharedModule,
    NgxChartsModule,
    RouteHistoryModule,
    NgbDatepickerModule,
    VehiclePopupRoutingModule,
    RidePopupModule
  ],
  exports: [],
})
export class VehiclePopupModule {
}

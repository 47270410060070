import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent } from './map.component';
import { MapMarkerVehicleComponent } from './map-markers/map-marker-vehicle.component';
import { MapClusterMarkerComponent } from './map-cluster/map-cluster-marker.component';
import { MapPolylineComponent } from './map-polyline/map-polyline.component';
import { MapMarkerRideIconComponent } from './map-markers/map-marker-ride-icon.component';
import { ComponentsModule } from '../../components/components.module';
import { MapPolygonComponent } from './map-polygon/map-polygon.component';
import { MapMarkerPoiComponent } from './map-markers/map-marker-poi/map-marker-poi.component';
import { MapCircleComponent } from './map-circle/map-circle.component';
import { MapContextmenuComponent } from './map-contextmenu/map-contextmenu.component';
import { MapMarkerParkComponent } from './map-markers/map-marker-park/map-marker-park.component';
import { MapToolboxComponent } from './map-toolbox/map-toolbox.component';
import { MapToolboxSwitchComponent } from './map-toolbox/map-toolbox-switch/map-toolbox-switch.component';
import { MapToolboxCustomComponent } from './map-toolbox/map-toolbox-custom/map-toolbox-custom.component';
import { MapToolboxVehiclesStatusComponent } from './map-toolbox/map-toolbox-vehicles-status/map-toolbox-vehicles-status.component';
import { MapToolboxWrapperComponent } from './map-toolbox/map-toolbox-wrapper/map-toolbox-wrapper.component';
import { CustomInputsModule } from '../custom-inputs/custom-inputs.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MapToolboxRadioComponent } from './map-toolbox/map-toolbox-radio/map-toolbox-radio.component';
import { FormGroupModule } from '../form-group/form-group.module';
import { MapMarkerSpeedComponent } from './map-markers/map-marker-speed/map-marker-speed.component';
import { MapPolygonEditedComponent } from './map-polygon/map-polygon-edited/map-polygon-edited.component';
import { TranslateModule } from '@ngx-translate/core';
import { MapToolboxSearchComponent } from './map-toolbox/map-toolbox-search/map-toolbox-search.component';
import { NgbTypeaheadModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [MapComponent, MapMarkerVehicleComponent, MapClusterMarkerComponent,
    MapPolylineComponent,
    MapMarkerRideIconComponent,
    MapPolygonComponent,
    MapMarkerPoiComponent, MapCircleComponent,
    MapContextmenuComponent,
    MapMarkerParkComponent,
    MapToolboxComponent,
    MapToolboxSwitchComponent,
    MapToolboxWrapperComponent,
    MapToolboxCustomComponent,
    MapToolboxVehiclesStatusComponent,
    MapToolboxRadioComponent,
    MapMarkerSpeedComponent,
    MapToolboxSearchComponent,
    MapPolygonEditedComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    CustomInputsModule,
    FormsModule,
    FormGroupModule,
    TranslateModule,
    NgbTypeaheadModule,
    ReactiveFormsModule,
    NgbTooltipModule,
  ],
  exports: [MapPolygonComponent, MapComponent, MapMarkerVehicleComponent,
    MapCircleComponent,
    MapContextmenuComponent,
    MapPolygonEditedComponent,
    MapToolboxCustomComponent,
    MapToolboxVehiclesStatusComponent,
    MapToolboxComponent,
    MapToolboxRadioComponent,
    MapMarkerSpeedComponent,
    MapToolboxSwitchComponent,
    MapToolboxSearchComponent,
    MapClusterMarkerComponent, MapPolylineComponent, MapMarkerRideIconComponent, MapMarkerPoiComponent, MapMarkerParkComponent],
})
export class MapModule {
}

import { DeviceCommandsComponent } from './device-commands/device-commands.component';
import { DeviceExtensionsComponent } from './device-extensions/device-extensions.component';
import { NgModule } from '@angular/core';

import { DevicePopupRoutingModule } from './device-popup-routing.module';
import { DevicePopupComponent } from './device-popup.component';
import { DeviceNewComponent } from './device-new/device-new.component';
import { DeviceResolve } from './device.resolve';
import { SharedModule } from '../../../shared/shared.module';
import { DevicePopupHeaderComponent } from './device-popup-header/device-popup-header.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetailsComponent } from './device-details/device-details.component';
import { RouteHistoryModule } from 'src/app/shared/modules/route-history/route-history.module';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [DevicePopupComponent, DeviceNewComponent, DeviceDetailsComponent,
    DeviceExtensionsComponent, DeviceCommandsComponent,
    DevicePopupHeaderComponent],
  providers: [DeviceResolve],
  imports: [
    SharedModule,
    NgbDatepickerModule,
    RouteHistoryModule,
    DevicePopupRoutingModule
  ]
})
export class DevicePopupModule {
}

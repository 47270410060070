import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MapService } from '../../../../shared/modules/map/map.service';
import { RouterHelperService } from '../../../../core/services/router-helper.service';
import { ZonesService } from '../../../dashboard/zones/zones.service';
import { Subscription } from 'rxjs';
import { Zone } from 'src/app/interfaces/zone';
import { switchMap, tap } from 'rxjs/operators';
import { NormsService } from '../../../../core/services/norms.service';
import { NormTemplateConfiguration } from '../../../../interfaces/norms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from 'src/app/shared/components/delete-modal/delete-modal.component';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-zone-rules',
  templateUrl: './zone-rules.component.html',
  styleUrls: ['./zone-rules.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZoneRulesComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();
  public zone: Zone;
  public norms: NormTemplateConfiguration[];

  constructor(private route: ActivatedRoute,
              private mapService: MapService,
              private cd: ChangeDetectorRef,
              private routerHelper: RouterHelperService,
              private normService: NormsService,
              protected modal: NgbModal,
              private translate: TranslateService,
              private service: ZonesService) {

  }

  ngOnInit() {
    this.subscriptions.add(this.route.parent.parent.data.pipe(
      tap(({zone}) => this.zone = zone),
      switchMap(res => this.normService.templatesConfigurations({zone: this.zone.id}))
    ).subscribe((res) => {
      this.norms = res;
      this.cd.detectChanges();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  remove(norm: NormTemplateConfiguration): void {
    this.deleteElement(norm.id, {
      header: 'REPORT',
      body: this.translate.instant('DELETE_PROMPT', { name: norm.name})
    }, (id) => {
      return this.normService.deleteTemplateConfiguration(norm.templateId, norm.id);
    }).then(res => {
      if (res) {
        this.norms = this.norms.filter(f => f.id !== norm.id);
        this.cd.markForCheck();
      }
    }, () => {
    });
  }

  deleteElement(id: any, message: { header: string, body: string }, deleteFunction?) {
    const modal = this.modal.open(DeleteModalComponent);
    modal.componentInstance.text = message;
    modal.componentInstance.deleteFunc = () => {
      return deleteFunction(id);
    };
    return modal.result;
  }

}

import {
  AfterContentInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input, OnDestroy,
  Optional,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';


@Directive({
  // tslint:disable-next-line
  selector: '[form-inline]',
})
export class FormControlInlineDirective {
  @HostBinding('class.form-group--inline') addClass = true;
}

@Directive({
  // tslint:disable-next-line
  selector: '[form-select-tags]',
})
export class FormSelectTagsDirective {
  @HostBinding('class.form-group--select-tags') addClass = true;
}

@Directive({
  // tslint:disable-next-line
  selector: '[form-lg]',
})
export class FormControlLgDirective {
  @HostBinding('class.form-group--lg') addClass = true;
}

@Directive({
  // tslint:disable-next-line
  selector: '[form-white]',
})
export class FormControlWhiteDirective {
  @HostBinding('class.form-white') addClass = true;
}

@Directive({
  // tslint:disable-next-line
  selector: `[app-form]`,
})
export class FormControlDirective {
  private _placeholder = '';
  private _type = 'text';
  private _disabled = false;
  private _required = false;
  private _id: string;
  private _name: string;
  private _neverEmptyArray = [
    'date',
    'datetime',
    'datetime-local',
    'month',
    'time',
    'week'
  ];
  focused = false;
  @HostBinding('attr.aria-describedby') 'ariaDescribedby';

  @HostBinding()
  @Input()
  get disabled() {
    return this.ngControl ? this.ngControl.disabled : this._disabled;
  }

  set disabled(value: any) {
    this._disabled = !!value;
  }

  @HostBinding()
  @Input()
  get id() {
    return this._id || this._name;
  }

  set id(value: string) {
    this._id = value;
  }

  @HostBinding()
  @Input()
  get name() {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  @HostBinding()
  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
  }

  @Input()
  get type() {
    return this._type;
  }

  set type(value: string) {
    this._type = value || 'text';
    if (this.elementRef.nativeElement && this.elementRef.nativeElement.nodeName.toLowerCase() === 'input') {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'type', this._type);
    }
  }

  @HostBinding()
  @Input()
  get required() {
    return this._required = this.ngControl && this.ngControl.errors && this.ngControl.errors.required || false;
  }

  set required(value: any) {
    this._required = !!value;
  }

  get value() {
    return this.elementRef.nativeElement.value;
  }

  set value(value: string) {
    this.elementRef.nativeElement.value = value;
  }

  get empty() {
    return !(this._neverEmptyArray.indexOf(this._type) !== -1) && (this.value == null || this.value === '')
      && !(this.elementRef.nativeElement as HTMLInputElement).validity.badInput;
  }

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2,
              @Optional() public ngControl: NgControl) {


  }

  focus() {
    this.elementRef.nativeElement.focus();
  }

  blur() {
    this.elementRef.nativeElement.blur();
  }

  @HostListener('focus')
  onFocus() {
    this.focused = true;
  }

  @HostListener('blur')
  onBlur() {
    this.focused = false;
  }

  @HostListener('input')
  onInput() {
  }

}

@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormGroupComponent implements AfterContentInit, OnDestroy {
  private subscription: Subscription;

  @Input() iconPosition: 'left' | 'right';
  @Input() clear: boolean;
  @Input() noFocus = false;
  @ContentChild(FormControlDirective, { static: false }) inputChild?: FormControlDirective;

  @HostBinding('class') typeClass?: string;
  @HostBinding('class.form-group') className = true;
  @HostBinding('class.ng-focused')
  get hasFocused():boolean {
    return this.inputChild.focused;
  };
  @HostBinding('class.ng-disabled')
  get hasDisabled():boolean {
    return this.inputChild.disabled;
  };
  @HostBinding('class.ng-untouched')
  get hasUntouched():boolean {
    return this.checkClass("untouched");
  };
  @HostBinding('class.ng-hasValue')  get hasValueCheck():boolean {
    return this.checkClass("value");
  };
  @HostBinding('class.ng-overflowSpinnerState')
  get hasPending():boolean {
    return this.checkClass("pending");
  };
  @HostBinding('class.ng-hasPlaceholder') get hasPlaceholder():boolean {
    return this.checkFormDirective("placeholder");
  };
  @HostBinding('class.ng-required')
  get required() {
    return this.checkFormDirective('required');
  }
  @HostBinding('class.ng-invalid')
  get hasInvalid() {
    return this.checkClass('invalid');
  }
  @HostBinding('class.ng-touched')
  get hasTouched() {
    return this.checkClass('touched');
  }
  @HostBinding('class.ng-pristine')
  get hasPristine() {
    return this.checkClass('pristine');
  }
  @HostBinding('class.ng-dirty')
  get hasDirty() {
    return this.checkClass('dirty');
  }
  @HostBinding('class.ng-valid')
  get hasValid() {
    return this.checkClass('valid');
  }
  @HostBinding('class.form-group--has-icon')
  @Input() icon?: string;

  @HostListener('click')
  focusInput() {
    if (!this.noFocus) {
      this.inputChild.focus();
    }

  }


  constructor(private cd: ChangeDetectorRef) {
  }

  checkClass(prop: string): boolean {
    const control = this.inputChild ? this.inputChild.ngControl : null;
    if (control && prop === 'value' && Array.isArray((control as any)[prop]) && (control as any)[prop].length === 0) {
      return false;
    }

    return (
      control &&
      ((control as any)[prop] || (prop === 'value' && ((control as any)[prop] === 0 || (control as any)[prop] === 0)))
    );
  }

  checkFormDirective(prop: string) {
    const control = this.inputChild ? this.inputChild : null;
    return control && (control as any)[prop];
  }

  get hasValue() {
    return this.inputChild && this.inputChild.ngControl && (
      this.inputChild.ngControl.value !== undefined
      && this.inputChild.ngControl.value !== ''
      && this.inputChild.ngControl.value !== null);
  }



  ngAfterContentInit() {
    let className = '';
    if (this.inputChild) {
      className += `ng-type-${this.inputChild.type}`;
      if (this.inputChild && this.inputChild.ngControl) {
        this.subscription = this.inputChild.ngControl.valueChanges.subscribe(() => {
          this.cd.detectChanges();
        });
        this.cd.detectChanges();
      }
    }
    if (this.icon && this.iconPosition === 'right') {
      className += ' form-group--icon-right';
    }
    this.typeClass = className;
  }

  onClear($event) {
    const control = this.inputChild ? this.inputChild.ngControl : null;
    if (control && this.clear) {
      $event.stopPropagation();
      $event.preventDefault();
      control.valueAccessor.writeValue(null);
      control.reset(null);
      this.inputChild.blur();
      this.cd.detectChanges();
    }
  }

  get iconClass() {
    return this.hasValue && this.clear ? 'icon-x' : this.icon;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

  }

}

import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MapService } from '../map.service';
import { TelemetryVehicle } from '../../../../interfaces/telemetry';
import { Subscription } from 'rxjs';
import { VehicleStatusesColors } from '../../../../core/services/telemetry.service';

@Component({
  selector: 'app-map-polyline',
  templateUrl: './map-polyline.component.html',
  styleUrls: ['./map-polyline.component.scss']
})
export class MapPolylineComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  private subsciption$: Subscription = new Subscription();
  private lineString: H.geo.LineString;
  private polyline: H.map.Polyline;
  @Input() telemetry: TelemetryVehicle[];
  @Input() color: VehicleStatusesColors = VehicleStatusesColors.BUSINESS_RIDE;
  @Input() shape: any[];
  @Output() polyLoaded = new EventEmitter();

  constructor(private mapService: MapService) {
  }

  ngOnInit() {
    this.subsciption$.add(this.mapService.mapIsLoad.subscribe(() => {
      this.initPolyline();
    }));
  }

  initPolyline() {
    if (this.polyline) {
      this.removePolyline();
    }
    this.lineString = new H.geo.LineString();
    if (this.shape) {
      this.shape.forEach(item => {
        this.lineString.pushPoint({lng: item[1], lat: item[0]});
      });
    } else {
      this.telemetry.forEach(tele => {
        this.lineString.pushPoint({lng: tele.position.coordinates[0], lat: tele.position.coordinates[1]});
        if (this.telemetry.length === 1) {
          this.lineString.pushPoint({lng: tele.position.coordinates[0], lat: tele.position.coordinates[1]});
        }
      });
    }
    this.polyline = new H.map.Polyline(this.lineString, {
      style: {
        lineWidth: 7,
        lineJoin: 'round', fillColor: this.color || VehicleStatusesColors.BUSINESS_RIDE, strokeColor: this.color || VehicleStatusesColors.BUSINESS_RIDE},
     // arrows: {fillColor: '#4A78FF', frequency: 5, width: 5, length: 4},
    });
    this.polyline.setArrows({});
    this.polyline.addEventListener('pointerenter', () => {
      const style = this.polyline.getStyle();
      const newStyle = style.getCopy({lineWidth: 10});
      this.polyline.setStyle(newStyle);

    });
    this.polyline.addEventListener('pointerleave', () => {
      const style = this.polyline.getStyle();
      const newStyle = style.getCopy({lineWidth: 7});
      this.polyline.setStyle(newStyle);

    });
    this.mapService.mapInstance.addObject(this.polyline);
    this.polyLoaded.emit(this.polyline.getGeometry());
    // this.mapService.mapInstance.setViewBounds(this.polyline.getBounds());

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.telemetry && !changes.telemetry.firstChange || changes.shape && !changes.shape.firstChange) {
      this.initPolyline();
    }
  }

  ngAfterViewInit(): void {
  }


  removePolyline() {
    if (this.polyline) {
      this.mapService.mapInstance.removeObject(this.polyline);
      this.polyline.dispose();
    }
    // if (this.mapService.mapInstance) {
    //   this.mapService.mapInstance.removeObject(this.polyline);
    // }
  }

  ngOnDestroy(): void {
    this.removePolyline();
    this.subsciption$.unsubscribe();
  }

}

import { NgModule } from '@angular/core';
import { ZonePopupRoutingModule } from './zone-popup-routing.module';
import { ZoneBasicComponent } from './zone-details/zone-basic.component';
import { ZoneHeaderComponent } from './zone-header/zone-header.component';
import { ZoneRulesComponent } from './zone-rules/zone-rules.component';
import { SharedModule } from '../../../shared/shared.module';
import { ZonePopupNewComponent } from './zone-popup-new/zone-popup-new.component';
import { ZonePopupDetailsComponent } from './zone-popup-details/zone-popup-details.component';
import { ZonesNewRulesComponent } from './zones-new-rules/zones-new-rules.component';
import { ZoneRulesEditComponent } from './zone-rules-edit/zone-rules-edit.component';
import { NormsPopupModule } from '../norms-popup/norms-popup.module';

@NgModule({
  declarations: [ZoneBasicComponent,
    ZoneHeaderComponent, ZoneRulesComponent,
    ZonePopupNewComponent,
    ZonePopupDetailsComponent,
    ZonesNewRulesComponent,
    ZoneRulesEditComponent],
  imports: [
    SharedModule,
    NormsPopupModule,
    ZonePopupRoutingModule
  ]
})
export class ZonePopupModule {
}

import { ClientsService } from './../../../../../../core/services/clients.service';
import { DriversGroupsWithSubService } from './../../../../../../core/services/drivers-groups.service';
import { Component, Input } from '@angular/core';
import { FilterCollection } from '../../../../../../classes/filters-collection.class';
import { UntypedFormBuilder } from '@angular/forms';
import { VehiclesGroupsService } from '../../../../../../core/services/vehicles-groups.service';

@Component({
  selector: 'app-vehicle-seelct-dropdown-filters',
  templateUrl: './vehicle-seelct-dropdown-filters.component.html',
  styleUrls: ['./vehicle-seelct-dropdown-filters.component.scss']
})
export class VehicleSeelctDropdownFiltersComponent extends FilterCollection {
  @Input() dimension = 'VEHICLE';
  @Input() clientsFilter = false;

  public groups$;

  constructor(
    formBuilder: UntypedFormBuilder,
    public vehicleGroupsService: VehiclesGroupsService,
    public clientsService: ClientsService,
    public driverGroupsService: DriversGroupsWithSubService) {
    super(formBuilder);
  }

  selectChange($event) {
  }

  initForm(data = null) {
    this.groups$ =  this.clientsFilter ? this.clientsService.list$() :
      (this.dimension === 'VEHICLE' ? this.vehicleGroupsService.list$() : this.driverGroupsService.list$());
    this.filterForm = this.fB.group({
      search: [],
      group: [],
      favorite: [],
    });
  }

}

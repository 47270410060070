import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {filter, map, tap} from "rxjs/operators";
import {BehaviorSubject, forkJoin, Observable, Observer} from "rxjs";
import {ConfigService} from "../../../core/services/config.service";
import {VehicleWithTelemetryAndRide} from "../../../interfaces/telemetry";
import {
  GeoResponse,
  GeoResponseAddress,
  GeoResponseItem,
  MultiReverseGeoItem,
  MultiReverseResponse
} from "../../../interfaces/map-geo";
import {chunkArray} from "../../../utils/array-value-filter";

@Injectable({
  providedIn: 'root'
})
export class MapGeoService {
  public multiReverseSubject = new BehaviorSubject(null);

  constructor(private http: HttpClient, private config: ConfigService,) {
  }

  reverseGeoById() {
    return this.multiReverseSubject.asObservable().pipe(filter(res => !!res));
  }

  geocode(searchText: string | Record<string, string>) {
    console.log(searchText);
    let url = `${this.config.apiUrl}/${this.config.maps.geoUrl}?limit=1&apiKey=${this.config.mapApiKey}&`;
    if (typeof searchText === 'string') {
      url += `q=${searchText}`
    } else {
      url += `qq=${Object.keys(searchText).map(key => `${key}=${searchText[key]}`).join(';')}`
    }
    return this.http.get<GeoResponse>(url).pipe(map((res) => {
      const location = res.items[0].position;
      return {
        lat: location.lat,
        lng: location.lng
      }
    }))
  }

  reverseGeo(coordinates: number[], returnString = true):
      Observable<any> {
    const url = `${this.config.apiUrl}/${this.config.maps.reverseGeoUrl}?types=address&at=${[...coordinates].reverse().join(',')}&limit=10&apiKey=${this.config.mapApiKey}`
    return this.http.get<GeoResponse>(url).pipe(map(res => {
      const address = this.findMostAccurateRevgeoResult(res.items);
      if (returnString) {
        return this.reversToLabel(address);
      }
      return address
    }));
  }

  reversToLabel(address: GeoResponseAddress) {

    const street = address.street && address.street.indexOf('ulica ') === 0 ? address.street.replace('ulica ', '') : address.street;
    let returnLabel = street || '';
    if (street && street !== '') {
      returnLabel += address.houseNumber && address.houseNumber !== '' ? ` ${address.houseNumber}, ` : ', ';
    }
    returnLabel += this.cityMapper(address.label, address.city, address.district);
    if (address.countryCode !== 'POL') {
      returnLabel += ` (${address.countryName})`;
    }
    return returnLabel;
  }

  reversToLabelOld({AdditionalData, City, Country, Street, HouseNumber, Label, District}: {
    AdditionalData: any[],
    HouseNumber: string,
    City: string,
    Country: string,
    Street: string,
    Label: string,
    District: string
  }) {
    const street = Street && Street.indexOf('ulica ') === 0 ? Street.replace('ulica ', '') : Street;
    let returnLabel = street || '';
    if (street && street !== '') {
      returnLabel += HouseNumber && HouseNumber !== '' ? ` ${HouseNumber}, ` : ', ';
    }
    returnLabel += this.cityMapper(Label, City, District);
    if (Country !== 'POL') {
      returnLabel += ` (${AdditionalData.find((item) => item.key === 'CountryName').value})`;
    }
    return returnLabel;
  }

  // if city is not in label means that real city name is in 'district' field
  cityMapper(label: string, city: string, district: string): string {
    if (district && label.toLowerCase().indexOf(city.toLowerCase()) === -1) {
      return `${district} (${city})`;
    }
    return city;
  }

  findMostAccurateRevgeoResultOld(results: any): any {
    if (results.length) {
      const firstHouseNumberMatch: any = results.find((val) => val.MatchQuality.HouseNumber === 1);
      const firstStreetMatch: any = results.find((val) =>
          val.MatchQuality.Street && (val.MatchQuality.Street[0] === 1));

      if (firstHouseNumberMatch && !firstStreetMatch) {
        return firstHouseNumberMatch.Location.Address;
      }

      if (!firstHouseNumberMatch && firstStreetMatch) {
        return firstStreetMatch.Location.Address;
      }

      if (firstHouseNumberMatch && firstStreetMatch) {
        const houseMatchIndex: number = results.indexOf(firstHouseNumberMatch);
        const streetMatchIndex: number = results.indexOf(firstStreetMatch);
        const streetsEqual: boolean = firstStreetMatch.Location.Address.Street === firstHouseNumberMatch.Location.Address.Street;

        // if same streeets return houseNumber match
        if (streetsEqual) {
          return firstHouseNumberMatch.Location.Address;
        } else {
          // otherwise (different streetts) if house match is closer return it,
          // if house match is further -> return steet match
          return houseMatchIndex < streetMatchIndex ?
              firstHouseNumberMatch.Location.Address :
              firstStreetMatch.Location.Address;
        }
      }

      return results[0].Location.Address;
    }

    return null;
  }

  findMostAccurateRevgeoResult(results: MultiReverseGeoItem[]): null | GeoResponseAddress {
    if (results.length) {
      const firstHouseNumberMatch = results.find((val) => val.resultType === "houseNumber");
      const firstStreetMatch = results.find((val) => val.resultType === 'street');

      if (firstHouseNumberMatch && !firstStreetMatch) {
        return firstHouseNumberMatch.address;
      }

      if (!firstHouseNumberMatch && firstStreetMatch) {
        return firstStreetMatch.address;
      }

      if (firstHouseNumberMatch && firstStreetMatch) {
        const houseMatchIndex: number = results.indexOf(firstHouseNumberMatch);
        const streetMatchIndex: number = results.indexOf(firstStreetMatch);
        const streetsEqual: boolean = firstStreetMatch.address.street === firstHouseNumberMatch.address.street;

        if (streetsEqual) {
          return firstHouseNumberMatch.address;
        } else {
          return houseMatchIndex < streetMatchIndex ?
              firstHouseNumberMatch.address :
              firstStreetMatch.address;
        }
      }

      return results[0].address;
    }

    return null;
  }

  multiReverseGoeVehicles(vehicles: VehicleWithTelemetryAndRide[]) {
    const body = [];
    const idMap = {};
    vehicles.filter(f => f.telemetry.ignition || !f.ride).forEach((vehicle, index) => {
      body.push(`id=${index}&at=${[...vehicle.telemetry.position.coordinates].reverse().join(',')},100`);
      idMap[index] = vehicle.id;
    });
    return this.multiReverseGoe(body).pipe(map((res) => {
      if (res.results) {
        return (res.results).reduce((prev, cur) => {
          const vehicleId = idMap[cur.id];
          let addressLabel: string = null;
          try {
            const address = this.findMostAccurateRevgeoResult(cur.items);
            addressLabel = this.reversToLabel(address);
          } catch {
            addressLabel = null;
            console.log(`error revgeo vId: ${vehicleId}`, cur.items);
          }
          return {...prev, [vehicleId]: addressLabel};
        }, {});
      }
      return res;
    }), tap(res => this.multiReverseSubject.next(res)));
  }

  multiReverseGoe(body: string[]):Observable<MultiReverseResponse> {
    const url  = `${this.config.apiUrl}${this.config.maps.multiReverseGeoUrl}?types=address&limit=10&apiKey=${this.config.mapApiKey}`
    return forkJoin(chunkArray(body, 100).map(value => this.http.post<MultiReverseResponse>(url, value.join('\n'), {
      headers: {
        'Content-Type': 'text/json',
      },
    }))).pipe(map( (res) => {
      return res.reduce((p, c, currentIndex) => {
        if(currentIndex) {
          p.results.push(...c.results)
        }
        return p;
      }, res[0]);
    }));
  }
}

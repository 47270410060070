<app-popup-view>
    <div class="d-flex flex-column justify-content-between align-items-stretch" style="height:100%">
        <div class="flex-grow-0 border-primary border-bottom">
            <div class="p-l pt-0">
                <h3 translate="" class="m-0">ADD_NEW_DRIVER</h3>
            </div>
        </div>
        <div class="flex-grow-1 u-route-popup-wrapper">
            <app-driver-details-edit [isNewView]="true"></app-driver-details-edit>
        </div>
    </div>
</app-popup-view>

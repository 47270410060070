import { Client } from './client';
import { Driver } from './driver';
import { Group } from './group';

export enum FuelType {
  petrol = 'petrol',
  diesel = 'diesel',
  petrol_gas = 'petrol_gas',
  diesel_gas = 'diesel_gas',
  hybrid_petrol = 'hybrid_petrol',
  hybrid_diesel = 'hybrid_diesel',
  electric = 'electric',
}

export interface VehicleAvatar {
  label: string;
  value: string;
}

export interface Vehicle {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  driver?: Driver;
  client: Client;
  name?: string;
  mark: string;
  groups?: Group[];
  model: string;
  numberPlate: string;
  avatarUrl: string;
  vin?: string;
  description?: string;
  totalMileage?: number;
  productionDate?: Date;
  utilizationStartDate?: Date;
  utilizationStartMileage?: number;
  fuelType?: FuelType;
  fuelTankSize?: number;
  engineCapacity?: number;
  weight?: number;
  length?: number;
  width?: number;
  height?: number;
  frontAxlePressure?: number;
  rearAxlePressure?: number;
  extensions?: any;
  noCacheExtensions?: any;
}

export interface VehicleExtensions {
  PHYSICAL_WORK_BTN: PhysicalWorkButtonExtension;
  VIRTUAL_WORK_BTN: VirtualWorkButtonExtension;
  ENGINE_ON_PARKS: EngineOnParksExtension;
  ANALOG_FUEL: AnalogFuelExtension;
};

export enum WorkButtonValue {
  WORK = 'work',
  NONWORK = 'nonwork',
  SCHEDULE = 'schedule',
}

export interface EngineOnParksExtension {
  maxSpeed: number;
  minSeconds: number;
  maxDistance: number;
};

export interface AnalogFuelExtension {
  calibration: number[];
};

export interface PhysicalWorkButtonExtension {
  high: WorkButtonValue;
  low: WorkButtonValue;
  input: String;
};

export interface VirtualWorkButtonExtension {
  values: WorkButtonValue[];
};

export enum EEtollSource {
  DEFAULT = 'default',
  ETOLL = 'etoll',
}

import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { DataGridRowComponent } from '../../modules/data-grid/data-grid-row/data-grid-row.component';

@Component({
  selector: 'app-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ActionMenuComponent implements OnInit, AfterContentInit, OnDestroy {
  @Output() openChange = new EventEmitter();

  @HostBinding('class.c-action-menu--table')
  @Input() tableRow: HTMLElement | DataGridRowComponent;

  get tableScroo() {
    return this.tableRow && this.tableRow instanceof DataGridRowComponent;
  }

  @HostBinding('style.height.px') height = null;
  @HostBinding('style.top.px') top = null;
  @HostBinding('style.right.px') right = null;

  public showButton = false;
  private subscriptions = new Subscription();


  constructor(private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  ngAfterContentInit(): void {
    this.cd.detectChanges();

    if (this.tableRow) {
      const htmlElemnt = this.tableRow instanceof DataGridRowComponent ? this.tableRow.el.nativeElement as HTMLElement : this.tableRow;
      this.subscriptions.add(fromEvent(htmlElemnt, 'mouseenter').subscribe(() => {
        this.height = htmlElemnt.offsetHeight;
        if (this.tableScroo) {
          this.top = 0;
          const parent = htmlElemnt.parentElement.parentElement;
          const right = parent.scrollWidth
            - parent.offsetWidth
            - parent.scrollLeft;
          this.right = right < 0 ? 0 : right;
        } else {
          this.top = htmlElemnt.offsetTop;
        }
        this.showButton = true;
        this.cd.detectChanges();
      }));
      this.subscriptions.add(fromEvent(htmlElemnt, 'mouseleave').subscribe(() => {
        this.showButton = false;
        this.cd.detectChanges();
      }));
    }

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}

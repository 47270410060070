<app-map-toolbox-wrapper [isOpen]="isOpen">
    <h4 class="c-toolbox-header">{{label}}</h4>
    <div *ngFor="let choice of choices" class="mb-xs">
        <app-form-group>
            <label>
                <input type="radio" name="value"
                       [value]="choice.value"
                       [ngModel]="selectedItem"
                       (ngModelChange)="changeState(choice)"
                       app-form>
                <span></span>
                <small>{{choice.label|translate}}</small>
            </label>
        </app-form-group>
    </div>
    <ng-content></ng-content>
</app-map-toolbox-wrapper>

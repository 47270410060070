import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface RangeInputValue {
  gt?: number;
  lt?: number;
}

@Component({
  selector: 'app-range-input',
  templateUrl: './range-input.component.html',
  styleUrls: ['./range-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangeInputComponent),
      multi: true
    }
  ]
})
export class RangeInputComponent implements ControlValueAccessor {
  @Input() only: 'gt' | 'lt';
  public inputValue: RangeInputValue = {
    lt: null,
    gt: null,
  };
  public isDisabled = false;

  constructor() {
  }

  onChange: any = () => {
  }

  onTouch: any = () => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: any): void {
    if (!obj) {
      this.resetValue();
    } else {
      this.inputValue = Object.assign(this.inputValue, obj);
    }
  }
  @Input()
  set value(val) {
    const value = Object.keys(val).length ? val : null;
    this.onTouch(value);
    this.onChange(value);
  }

  resetValue() {
    this.inputValue.gt = null;
    this.inputValue.lt = null;
  }

  clear() {
    this.resetValue();
    this.value = {};
  }

  get showClear() {
    return this.inputValue.lt !== null || this.inputValue.gt !== null;
  }

  modelChange(value, type) {
    if (this.inputValue[type] === value) {
      return;
    }
    this.inputValue[type] = value === 0 || value ? value : null;
    this.value = Object.keys(this.inputValue)
      .filter(key => this.inputValue[key] !== null)
      .reduce((previousValue, currentValue) => ({...previousValue, [currentValue]: this.inputValue[currentValue]}), {});
  }
}

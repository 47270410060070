import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorMessageComponent } from './error-messages/error-message.component';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { PopupViewComponent } from './popup-view/popup-view.component';
import { RouterModule } from '@angular/router';
import { TagsGroupComponent } from './tags-group/tags-group.component';
import { TableActionBtnsComponent } from './table-action-btns/table-action-btns.component';
import { PopupNavigationComponent } from './popup-view/popup-navigation/popup-navigation.component';
import { FavoriteVehicleComponent } from './favorite-vehicle/favorite-vehicle.component';
import { TableSortBtnComponent } from './table-sort-btn/table-sort-btn.component';
import { PowerBiComponent } from './power-bi/power-bi.component';
import { BadgeComponent } from './badge/badge.component';
import { TagGroupComponent } from './tags-group/tag-group/tag-group.component';
import { OverflowSpinnerModule } from '../modules/overflow-spinner/overflow-spinner.module';
import { RolloverTableRowComponent } from './rollover-table-row/rollover-table-row.component';
import { PoiInfoComponent } from './poi-info/poi-info.component';
import { RideIconComponent } from './ride-icon/ride-icon.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { TableCollectionComponent } from './table-collection/table-collection.component';
import { VehicleStatusComponent } from './vehicle-status/vehicle-status.component';
import { PipesModule } from '../pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { IconVehicleComponent } from './icon-vehicle/icon-vehicle.component';
import { PowerBiToggleComponent } from './power-bi/power-bi-toggle/power-bi-toggle.component';
import { AvailablSoonComponent } from './availabl-soon/availabl-soon.component';
import { ActionMenuComponent } from './action-menu/action-menu.component';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LabelWithTagsComponent } from './label-with-tags/label-with-tags.component';
import { FromToComponent } from './from-to/from-to.component';
import { StatusBarComponent } from './status-bar/status-bar.component';
import { EventDateAlertComponent } from './event-date-alert/event-date-alert.component';
import { VehicleLocationComponent } from './vehicle-location/vehicle-location.component';
import { VehicleStatusLabelComponent } from './vehicle-status/vehicle-status-label/vehicle-status-label.component';
import { FiltersPanelComponent } from './filters-panel/filters-panel.component';
import { PortalModule } from '@angular/cdk/portal';
import { VehicleAvatarComponent } from './vehicle-avatar/vehicle-avatar.component';
import { CloseBtnComponent } from './close-btn/close-btn.component';
import { PlateComponent } from './plate/plate.component';
import { DropdownCardComponent } from './dropdown-card/dropdown-card.component';
import { SelectedTagsComponent } from './selected-tags/selected-tags.component';
import { WarehouseUtilsModule } from '../modules/warehouse-utils/warehouse-utils.module';
import { HeaderLocationStatusComponent } from './header-location-status/header-location-status.component';
import { MapWrapperComponent } from './map-wrapper/map-wrapper.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DirectivesModule } from '../directives/directives.module';
import { MapCardComponent } from './map-wrapper/map-card.component';
import { IconBgComponent } from './icon-bg/icon-bg.component';
import { StickyComponent } from './sticky/sticky.component';
import { ZoneIconComponent } from './zone-icon/zone-icon.component';
import { PaginationComponent } from './pagination/pagination.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DriverDetailsComponent } from './driver-details/driver-details.component';
import { TimetableComponent } from './timetable/timetable.component';
import {ScrollingModule} from '@angular/cdk/scrolling';

@NgModule({
    declarations: [
        ErrorMessageComponent,
        UserAvatarComponent,
        PopupViewComponent,
        TagsGroupComponent,
        TableActionBtnsComponent,
        PopupNavigationComponent,
        FavoriteVehicleComponent,
        TableSortBtnComponent,
        PowerBiComponent,
        BadgeComponent,
        TagGroupComponent,
        RolloverTableRowComponent,
        PoiInfoComponent,
        RideIconComponent,
        DeleteModalComponent,
        TableCollectionComponent,
        VehicleStatusComponent,
        IconVehicleComponent,
        PowerBiToggleComponent,
        AvailablSoonComponent,
        ActionMenuComponent,
        LabelWithTagsComponent,
        FromToComponent,
        StatusBarComponent,
        EventDateAlertComponent,
        VehicleLocationComponent,
        VehicleStatusLabelComponent,
        FiltersPanelComponent,
        VehicleAvatarComponent,
        CloseBtnComponent,
        PlateComponent,
        DropdownCardComponent,
        SelectedTagsComponent,
        HeaderLocationStatusComponent,
        MapWrapperComponent,
        MapCardComponent,
        IconBgComponent,
        StickyComponent,
        ZoneIconComponent,
        PaginationComponent,
        DriverDetailsComponent,
        TimetableComponent,
    ],
    exports: [ErrorMessageComponent,
        HeaderLocationStatusComponent,
        AvailablSoonComponent,
        DriverDetailsComponent,
        UserAvatarComponent,
        MapCardComponent,
        TagsGroupComponent,
        SelectedTagsComponent,
        IconVehicleComponent,
        TableActionBtnsComponent,
        PopupNavigationComponent,
        FavoriteVehicleComponent,
        TableSortBtnComponent,
        PowerBiComponent,
        BadgeComponent,
        PaginationComponent,
        TagGroupComponent,
        MapWrapperComponent,
        RolloverTableRowComponent,
        RideIconComponent,
        PoiInfoComponent,
        DeleteModalComponent,
        StatusBarComponent,
        StickyComponent,
        TableCollectionComponent,
        VehicleStatusComponent,
        PowerBiToggleComponent,
        ActionMenuComponent,
        LabelWithTagsComponent,
        EventDateAlertComponent,
        FromToComponent,
        VehicleLocationComponent,
        VehicleStatusLabelComponent,
        FiltersPanelComponent,
        VehicleAvatarComponent,
        CloseBtnComponent,
        PlateComponent,
        DropdownCardComponent,
        IconBgComponent,
        ZoneIconComponent,
        TimetableComponent,
        PopupViewComponent],
    imports: [
        CommonModule,
        RouterModule,
        PortalModule,
        PerfectScrollbarModule,
        OverflowSpinnerModule,
        PipesModule,
        DirectivesModule,
        WarehouseUtilsModule,
        NgbDropdownModule,
        TranslateModule,
        InfiniteScrollModule,
        ScrollingModule,
        NgbTooltipModule,
    ]
})
export class ComponentsModule {
}

<ng-container [ngSwitch]="showParkOrder">
  <strong *ngSwitchCase="true">{{index + 1}}</strong>
  <i class="icon-parking" *ngSwitchCase="false">
  </i>
</ng-container>


<div class="c-marker__bg" [style.background]="color" [style.width.%]="circleSize" [style.height.%]="circleSize"></div>
<div class="c-marker__label-wrapper">
  <div class="c-marker__label" [style.background]="color">
    <strong>{{time}}</strong> {{address}} {{temp1}}
  </div>
</div>

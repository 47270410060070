import { Injectable } from '@angular/core';
import { DriverGroup } from '../../interfaces/group';
import ApiServiceClass from '../../classes/api-service.class';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Vehicle } from '../../interfaces/vehicle';
import { AuthService } from './auth.service';
import { ApiServiceWithSub } from 'src/app/classes/api-service-with-sub';

@Injectable({
  providedIn: 'root'
})
export class DriversGroupsService extends ApiServiceClass<DriverGroup> {

  constructor(config: ConfigService,
              http: HttpClient) {
    super('/drivers-groups', config, http);
  }

  sync(id: string, driverIds: string[]) {
    return this.http.post(`${this.baseUrl}/${id}/drivers`, {driverIds});
  }

  driversInGroup(id: string): Observable<Vehicle[]> {
    return this.http.get<Vehicle[]>(`${this.baseUrl}/${id}/drivers`);
  }
}

@Injectable({
  providedIn: 'root'
})
export class DriversGroupsWithSubService extends ApiServiceWithSub<DriverGroup> {

  constructor(config: ConfigService,
              authService: AuthService,
              http: HttpClient) {
    super('/drivers-groups', config, http, authService);
    this.initList();
  }
}

import { Injectable } from '@angular/core';
import ApiServiceClass from '../../classes/api-service.class';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import {
  OutputFile,
  Report,
  ReportConfiguration,
  ReportTemplate,
  ReportTemplateEntity,
  ReportTemplateWithConfig,
  ReportTemplateWithFields
} from '../../interfaces/reports';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Validators } from '@angular/forms';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class ReportsService extends ApiServiceClass<Report> {

  private reportsUpdate = new BehaviorSubject(null);
  public reportWasUpdate$ = this.reportsUpdate.asObservable();

  constructor(config: ConfigService,
              http: HttpClient) {
    super('/reports', config, http);
  }

  reportFileUrl(report: Report, file: OutputFile) {
    return `${this.baseUrl}/${report.id}/download/${file.type}`;
  }

  templates(): Observable<ReportTemplate[]> {
    return this.http.get<ReportTemplate[]>(`${this.baseUrl}/templates`);
  }

  template(id: string): Observable<ReportTemplateWithFields> {
    return this.http.get<ReportTemplateWithFields>(`${this.baseUrl}/templates/${id}`);
  }

  templatesConfigurations(): Observable<ReportConfiguration[]> {
    return this.http.get<ReportConfiguration[]>(`${this.baseUrl}/templates/configurations`);
  }

  templateConfiguration(templateId: string, configurationId: string): Observable<ReportConfiguration> {
    return this.http.get<ReportConfiguration>(`${this.baseUrl}/templates/${templateId}/configurations/${configurationId}`);
  }

  saveTemplateConfiguration(templateId: string, template: ReportConfiguration): Observable<ReportConfiguration> {
    return this.http.post<ReportConfiguration>(`${this.baseUrl}/templates/${templateId}/configurations`, template)
      .pipe(tap(res => this.reportsUpdate.next(res)));
  }

  updateTemplateConfiguration(templateId: string, configurationId: string, template: ReportConfiguration): Observable<ReportConfiguration> {
    return this.http.patch<ReportConfiguration>(`${this.baseUrl}/templates/${templateId}/configurations/${configurationId}`, template)
      .pipe(tap(res => this.reportsUpdate.next(res)));
  }

  deleteTemplateConfiguration(templateId: string, configurationId: string): Observable<any> {
    return this.http.delete<ReportConfiguration>(`${this.baseUrl}/templates/${templateId}/configurations/${configurationId}`);
  }

  templateWithConfiguration(templateId: string, configurationId?: string):
    Observable<{ template: ReportTemplateWithFields, configuration?: ReportConfiguration }> {
    if (configurationId) {
      return forkJoin(this.template(templateId), this.templateConfiguration(templateId, configurationId))
        .pipe(map(([template, configuration]) => ({template, configuration})));
    } else {
      return this.template(templateId).pipe(map(res => ({template: res})));
    }
  }

  templateWithConfigurations(): Observable<ReportTemplateWithConfig[]> {
    return this.templates().pipe(
      switchMap(templates => this.templatesConfigurations().pipe(
        map(configurations => configurations.reduce((a, c) => ({
          ...a,
          [c.reportTemplateId]: a[c.reportTemplateId] ? [...a[c.reportTemplateId], c] : [c]
        }), {})),
        map(configurations => templates.map(template => ({...template, configurations: configurations[template.id] || []}))))));
  }

  convertEntityToformController(entity: ReportTemplateEntity) {
    const fieldValidators = [];
    if (entity.required) {
      fieldValidators.push(Validators.required);
    }
    if (entity.validator && entity.validator.min >= 0) {
      fieldValidators.push(Validators.min(entity.validator.min));
    }
    if (entity.validator && entity.validator.max) {
      fieldValidators.push(Validators.max(entity.validator.max));
    }
    return [entity.default, fieldValidators];
  }

  reportDays(report: Report | ReportConfiguration): number | null {
    if (report.fields.dateRange && report.fields.dateRange.startDate && report.fields.dateRange.endDate) {
      return DateTime.fromISO(report.fields.dateRange.endDate).startOf('days')
        .diff(DateTime.fromISO(report.fields.dateRange.startDate).startOf('days'), 'days').toObject().days + 1;
    }
    return null;
  }

}

<div class="d-flex justify-content-between align-items-stretch flex-column flex-fill h-100">
    <div class="overflow-auto flex-grow-1">
        <div [formGroup]="forms?.main" class="p-xm">
            <h3 class="form-header mt-xl" translate>BASIC_DATA</h3>
            <div class="row">
                <div class="col-4">
                    <app-form-group>
                        <label translate>IMEI</label>
                        <input app-form formControlName="imei" type="text">
                        <app-error-message [control]="forms?.main.get('imei')"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4">
                    <app-form-group>
                        <label translate>TYPE</label>
                        <input app-form formControlName="type" type="text">
                        <app-error-message [control]="forms?.main.get('type')"></app-error-message>
                    </app-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <app-form-group>
                      <label translate>MSISDN</label>
                      <input app-form formControlName="simMsisdn" type="text">
                      <app-error-message [control]="forms?.main.get('simMsisdn')"></app-error-message>
                  </app-form-group>
                </div>
                <div class="col-4">
                  <app-form-group>
                      <label translate>SERIAL_NUMBER</label>
                      <input app-form formControlName="simSerialNumber" type="text">
                      <app-error-message [control]="forms?.main.get('simSerialNumber')"></app-error-message>
                  </app-form-group>
              </div>
            </div>
        </div>
        <div class="p-xm" *ngIf="!isNewView">
          <h3 class="form-header mt-xl" translate>VEHICLE</h3>
          <app-partner-vehicles-select
                        [max]="1"
                        [multi]="false"
                        [inline]="true"
                        bindValue="id"
                        [formControl]="forms?.main.get('vehicleId')"
                >
                </app-partner-vehicles-select>
          <app-error-message [apiError]="formErrors" name="ENTITY_ERROR" context="vehicleId" ></app-error-message>
    </div>
    <div class="p-xm d-flex justify-content-end align-items-center border-top">
        <button class="btn btn-primary btn-border px-xxl mr-m" (click)="cancel()" translate>
            {{(isEditMode ? 'CANCEL' : 'EXIT')|translate}}
        </button>
        <button class="btn btn-primary px-xxl" (click)="handlerSave()" [disabled]="pending" *ngIf="showButton">
            <app-spinner [showSpinner]="pending"></app-spinner>
            {{(isNewView || isEditMode ? 'SAVE' : 'EDIT')|translate}}
        </button>
    </div>
</div>


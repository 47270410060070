import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DataGridHeaderComponent} from './data-grid-header/data-grid-header.component';
import {ComponentsModule} from '../../components/components.module';
import {TranslateModule} from '@ngx-translate/core';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DataGridTableComponent} from './data-grid-table/data-grid-table.component';
import {OverflowSpinnerModule} from '../overflow-spinner/overflow-spinner.module';
import {DataGridRowComponent} from './data-grid-row/data-grid-row.component';
import {DataGridCellComponent} from './data-grid-cell/data-grid-cell.component';
import {DirectivesModule} from '../../directives/directives.module';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';


export type TableCellType = 'header' | 'body';

@NgModule({
  declarations: [DataGridHeaderComponent, DataGridTableComponent, DataGridRowComponent, DataGridCellComponent],
  exports: [DataGridHeaderComponent, DataGridTableComponent, DataGridRowComponent, DataGridCellComponent],
  imports: [
    DragDropModule,
    InfiniteScrollModule,
    ComponentsModule,
    OverflowSpinnerModule,
    TranslateModule,
    CommonModule,
    DirectivesModule
  ]
})
export class DataGridModule {
}

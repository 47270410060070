import { Component, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Poi, PoiType } from '../../../../../interfaces/poi';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiValidatorService } from '../../../../../core/services/api-validator.service';
import { MapService } from '../../../../../shared/modules/map/map.service';
import { ContextMenuItem, ContextMenuItemEvent } from '../../../../../shared/modules/map/map-contextmenu/map-contextmenu.component';
import { PoiService } from '../../../../dashboard/poi/poi.service';
import { MapCircleComponent } from '../../../../../shared/modules/map/map-circle/map-circle.component';
import { tap } from 'rxjs/operators';
import { PopupViewService } from '../../../../../shared/components/popup-view/popup-view.service';
import { PoiGroupsService } from '../../../../dashboard/poi/poi-groups.service';
import { PERMISSIONS, PermissionService } from '../../../../../core/services/permission.service';
import { NewEditClass } from '../../../../../classes/new-edit.class';
import { TelemetryRide } from '../../../../../interfaces/telemetry';
import { RouterHelperService } from '../../../../../core/services/router-helper.service';
import {MapGeoService} from "../../../../../shared/modules/map/map-geo.service";

@Component({
  selector: 'app-poi-popup-basic',
  templateUrl: './poi-popup-basic.component.html',
  styleUrls: ['./poi-popup-basic.component.scss'],
  providers: [MapService],
})
export class PoiPopupBasicComponent extends NewEditClass {
  protected editPermission = PERMISSIONS.POI_UPDATE;
  protected newPermission = PERMISSIONS.POI_CREATE;


  @ViewChild('circleMarker') circleMarker: MapCircleComponent;


  public poi: Poi;
  public showPolygon = true;
  public poiMarkerCords;
  public geoPending = false;
  public addressPending = false;
  public addressFormCol = {
    street: 'col-6',
    houseNumber: 'col-2',
    city: 'col-4',
    postalCode: 'col-4',
    country: 'col-4',
  };
  public customeMenuItems: ContextMenuItem[] = [
    {label: 'UPDATE_COORDS', type: 'geo'},
    {label: 'UPDATE_COORDS_AND_ADDRESS', type: 'geo_address'},
  ];
  public poiTypes = Object.values(PoiType);
  public poiGroups$ = this.poiGroupsService.list$();
  private ride: TelemetryRide;
  private point: H.geo.Point;

  get poiContact() {
    return (this.forms.poiForm.get('contact') as UntypedFormGroup).controls;
  }

  constructor(private route: ActivatedRoute,
              private routerHelper: RouterHelperService,
              private mapService: MapService,
              private mapGeoService: MapGeoService,
              private poiGroupsService: PoiGroupsService,
              popupService: PopupViewService,
              private poiService: PoiService,
              apiValidator: ApiValidatorService,
              permission: PermissionService,
              fb: UntypedFormBuilder) {
    super(popupService, apiValidator, fb, permission);
    this.ride = this.routerHelper.getState('ride');
    this.point = this.routerHelper.getState('point');
  }

  get circleFormControls() {
    return (this.forms.circleForm as UntypedFormGroup).controls;
  }

  get addessFormControls() {
    return Object.keys(this.addressFormCol);
  }

  findOnMap() {
    if (this.geoPending || !this.apiValidator.formIsValid(this.forms.addessForm as UntypedFormGroup)) {
      return;
    }
    this.geoPending = true;
    console.log({here: this.forms.addessForm.value});
    this.mapGeoService.geocode(this.forms.addessForm.value).subscribe(res => {
      this.geoPending = false;
      this.forms.circleForm.patchValue(res);
    }, () => {
      this.geoPending = false;
    });
  }


  addRouteSub() {
    this.subscriptions.add(this.route.parent.data.subscribe(({poi}) => {
      this.poi = poi;
      this.resetForm();
      this.toggleDisableForm();
    }));
  }

  buildForm() {
    this.forms = {
      poiForm: this.fb.group({
        name: ['', Validators.required],
        type: ['', Validators.required],
        externalId: [null],
        contact: this.fb.group({
          name: [null],
          email: [null, this.apiValidator.validateEmail],
          phone: [null],
        })
      }),
      groupsForm: this.fb.control([]),
      circleForm: this.fb.group({
        lat: ['', Validators.required],
        lng: ['', Validators.required],
        radius: [100, [Validators.required, Validators.min(1)]],
      }),
      addessForm: this.fb.group({
        street: [null],
        houseNumber: [null],
        city: [null],
        postalCode: [null],
        country: [null]
      })
    };
    if (this.isNewView && this.ride) {
      this.forms.addessForm.patchValue(this.ride.parkData.revgeo || this.ride.rideData.startRevgeo);
      this.forms.circleForm.patchValue(this.ride.parkStartFrame && this.ride.parkStartFrame.fields
        ? this.ride.parkStartFrame.fields : this.ride.rideStartFrame.fields);
    } else if (this.isNewView && this.point) {
      this.handlerContexMenu({
        point: this.point,
        type: 'geo_address'
      });
    }
  }

  resetForm() {
    this.forms.circleForm.patchValue({
      radius: this.poi.radius,
      lat: this.poi.point.coordinates[1],
      lng: this.poi.point.coordinates[0],
    });
    this.forms.addessForm.patchValue(this.poi.address);
    this.forms.poiForm.patchValue(this.poi);
    this.forms.groupsForm.setValue(this.poi.groups.map(({id}) => id));
  }

  updateAddress() {
    if (this.addressPending || !this.apiValidator.formIsValid(this.forms.circleForm as UntypedFormGroup)) {
      return;
    }
    this.addressPending = true;
    this.mapGeoService.reverseGeo([this.forms.circleForm.get('lng').value, this.forms.circleForm.get('lat').value], false)
      .subscribe(res => {
        this.addressPending = false;
        this.forms.addessForm.reset(Object.keys(res).reduce((p, c) => ({...p, [c[0].toLowerCase() + c.slice(1)]: res[c]}), {}));
        // this.addessForm.patchValue();
      }, (error) => {
        this.addressPending = false;
      });

  }

  get showButtons() {
    return this.isEditMode || this.isNewView;
  }

  get isUpdateAddressDisabled() {
    return !this.forms.circleForm.dirty || this.addressPending;
  }

  circleGeoChanged() {
    this.mapGeoService.reverseGeo(Object.values(this.forms.circleForm.value)).subscribe();
  }


  handlerContexMenu($event: ContextMenuItemEvent) {
    this.showPolygon = false;
    this.forms.circleForm.patchValue($event.point);
    this.forms.circleForm.markAsDirty({onlySelf: false});
    if ($event.type === 'geo_address') {
      this.updateAddress();
    }
  }


  getData() {
    const area = this.mapService.geoToGeoJson((this.circleMarker.circle as any).getGeometry());
    if (area.coordinates) {
      const first = [...area.coordinates[0][0]];
      area.coordinates[0].push(first);
      area.coordinates[0] = area.coordinates[0].reverse();
    }
    const poiForm = this.forms.poiForm.value;
    poiForm.contact.email = poiForm.contact.email || null;
    poiForm.externalId = poiForm.externalId || null;

    const circleFormValues = this.forms.circleForm.value;
    const point = this.mapService.geoToGeoJson(new H.geo.Point(circleFormValues.lat, circleFormValues.lng) as any);

    const address = this.forms.addessForm.value;
    Object.keys(address).forEach(key => address[key] = address[key] || null);

    const data = {
      address: address,
      ...poiForm,
      radius: circleFormValues.radius,
      area,
      point,
      poiGroupIds: this.forms.groupsForm.value
    };
    return data;
  }


  save() {
    const data = this.getData();
    let poi;
    this.poiService.add(data).pipe(
      tap(res => poi = res),
    ).subscribe(() => {
      this.pending = false;
      if (this.ride) {
        this.routerHelper.navigatePopup(['ride', this.ride._id], {
          state: {
            poi,
          }
        });
      } else {
        this.popupService.close();
      }
    }, () => {
      this.pending = false;
    });
  }

  update() {
    const data = this.getData();
    delete data['poiGroupIds'];

    forkJoin(
      this.poiService.partialUpdate(this.poi.id, data),
      this.poiService.updatePoiGroup(this.poi.id, this.forms.groupsForm.value),
    ).subscribe(res => {
      this.pending = false;
      this.editSuccess();
    }, () => {
      this.pending = false;
    });
  }
}

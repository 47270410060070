import { Component, Input, OnInit } from '@angular/core';
import { Zone } from 'src/app/interfaces/zone';

@Component({
  selector: 'app-zone-header',
  templateUrl: './zone-header.component.html',
  styleUrls: ['./zone-header.component.scss'],
 // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZoneHeaderComponent implements OnInit {
  @Input() zone: Zone;

  constructor() {
  }

  ngOnInit() {
  }

}

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NotFoundComponent } from "./not-found/not-found.component";
import { ServerErrorComponent } from "./server-error/server-error.component";
import { AuthGuardService } from "../core/guards/auth-guard.service";

const routes: Routes = [
  {
    path: "mobile-notifications",
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import("./mobile-notification/mobile-notification.module").then(
        (m) => m.MobileNotificationModule,
      ),
  },
  { path: "404", component: NotFoundComponent },
  { path: "500", component: ServerErrorComponent },
  { path: "**", redirectTo: "/404" },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  declarations: [NotFoundComponent],
  exports: [RouterModule],
})
export class ViewsRoutingModule {}

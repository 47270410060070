import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RouterHelperService} from '../../../core/services/router-helper.service';
import {PopupViewService} from './popup-view.service';
import {Subscription} from 'rxjs';
import {PerspectiveMenuService, PopupSizeType} from '../../modules/perspective-menu/perspective-menu.service';


@Component({
  selector: 'app-popup-view',
  templateUrl: './popup-view.component.html',
  styleUrls: ['./popup-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupViewComponent implements OnInit, OnDestroy {
  private sub: Subscription = new Subscription();
  private outletName = 'popup';
  private popupQueryParams = ['ride', 'park', 'dateTypePopup', 'fromDatePopup', 'toDatePopup'];
  public popupClass: PopupSizeType;

  constructor(private activatedRoute: ActivatedRoute,
              private popupService: PopupViewService,
              private routeHelper: RouterHelperService,
              private cd: ChangeDetectorRef,
              private perspectiveService: PerspectiveMenuService,
              private router: Router) {
  }

  ngOnInit() {
    this.outletName = this.activatedRoute.snapshot.outlet;
    this.sub.add(this.popupService.close$.subscribe(() => {
      this.closeModal();
    }));
    this.sub.add(this.perspectiveService.popupSize.subscribe(res => {
      this.popupClass = res;
      this.cd.detectChanges();
    }));
  }


  closeModal() {
    const outlet = this.routeHelper.findOutletName(this.activatedRoute) || 'popup';
    if (outlet === 'popup') {
      this.router.navigate([{outlets: {[outlet]: null, inside: null}}], {
        queryParams: this.popupQueryParams.reduce((p, c) => ({...p, [c]: null}), {}),
        queryParamsHandling: 'merge'
      });
    } else {
      this.router.navigate([{outlets: {[outlet]: null}}], {
        queryParamsHandling: 'merge'
      });
    }

    // this.routeHelper.navugateByUrl(this.routeHelper.routeUrlFromParent(this.activatedRoute));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}

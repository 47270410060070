import { ClientsService } from './../../../../core/services/clients.service';
import { PartnerVehiclesService } from 'src/app/core/services/partner-vehicles.service';
import { EEtollSource } from './../../../../interfaces/vehicle';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { NewEditClass } from 'src/app/classes/new-edit.class';
import { ApiValidatorService } from 'src/app/core/services/api-validator.service';
import { PERMISSIONS, PermissionService } from 'src/app/core/services/permission.service';
import { Device } from 'src/app/interfaces/device';
import { Vehicle } from 'src/app/interfaces/vehicle';
import { PopupViewService } from 'src/app/shared/components/popup-view/popup-view.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-vehicle-extensions',
  templateUrl: './vehicle-extensions.component.html',
  styleUrls: ['./vehicle-extensions.component.scss']
})
export class VehicleExtensionsComponent extends NewEditClass {
  protected editPermission: PERMISSIONS = PERMISSIONS.DEVICE_UPDATE;
  protected newPermission: PERMISSIONS = PERMISSIONS.DEVICE_CREATE;

  public etollSources = Object.values(EEtollSource);
  public vehicle: Vehicle;
  protected subscriptions = new Subscription();
  public forms: { [key: string]: AbstractControl } = {};

  public etollConfigIds$: Observable<string[]> = this.clientsService.me().pipe(
    map((client) => client.extensions && client.extensions.ETOLL ? client.extensions.ETOLL.configIds : [])
  );

  constructor(protected route: ActivatedRoute,
              public permissionService: PermissionService,
              popupService: PopupViewService,
              private partnerVehicleService: PartnerVehiclesService,
              apiValidator: ApiValidatorService,
              private clientsService: ClientsService,
              protected fb: UntypedFormBuilder) {
    super(popupService, apiValidator, fb, permissionService);
  }

  addRouteSub() {
    this.subscriptions.add(this.route.parent.data.subscribe(({vehicle}) => {
      this.vehicle = vehicle;
      this.resetForm();
    }));
  }
  update() {
    const payload = this.getPayloadFromForm();
    this.partnerVehicleService.updateVehicleExtensions(this.vehicle.id, payload).subscribe(res => {
      this.editSuccess();
      console.log(`update success res: ${res}`);
    });
  }
  save() {
    throw new Error('Method not implemented.');
  }

  buildForm() {
    this.forms = {
      ETOLL: this.fb.group({
        configId: [null],
        enabled: [null],
        serialNumber: [null],
        source: [null],
        businessNumber: [null],
      }),
    };
  }

  getPayloadFromForm() {
    return Object.keys(this.forms).reduce((p, c) => { p[c] = this.forms[c].value; return p; }, {});
  }

  resetForm() {
    Object.keys(this.forms).forEach(f => {
      const value: any = this.vehicle.extensions && this.vehicle.extensions.hasOwnProperty(f) ?
        this.vehicle.extensions[f] : {};
        this.forms[f].patchValue(value);
    });
    // if (this.vehicle.extensions && this.vehicle.extensions.ETOLL) {
    //   this.forms.ETOLL.patchValue(this.vehicle.extensions.ETOLL);
    // }
  }
}

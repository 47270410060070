<app-dropdown-card (close)="close.emit()" label="{{'SEARCH_ADDRESSES'|translate}}:">
    <ng-container dropdown-card-header>
        <app-poi-seelct-dropdown-filters (filterChange)="filtersChange($event)">
            <div class="form-checkbox form-white" *ngIf="multi">
                <label for="select_all_poi">
                    <input type="checkbox" id="select_all_poi" [(ngModel)]="isChecked" (change)="checkValue(isChecked)">
                    <span></span>
                </label>
            </div>
        </app-poi-seelct-dropdown-filters>
    </ng-container>
    <div class="vehicles-list">
        <div class="form-checkbox" *ngFor='let poi of items' (click)="toggleSelect(poi)">
            <input id="item-{{poi.id}}" type="checkbox" [ngModel]="isSelect(poi.id)"
                   [ngModelOptions]="{standalone: true}"/><span></span> {{poi.name}}
        </div>
    </div>
</app-dropdown-card>

<div class="d-flex justify-content-between align-items-start">
  <div class="d-flex justify-content-start align-items-start">
    <div class="d-flex justify-content-start align-items-stretch flex-row">
      <div class="mr-m c-vehicle-header__avatar">
        <app-vehicle-avatar size="60"
                            [ignition]="vehicle.telemetry?.ignition"
                            [avatar]="vehicle.avatarUrl"
                            [etoll]="vehicle.extensions && vehicle.extensions.ETOLL ?  vehicle.extensions.ETOLL : null">

        </app-vehicle-avatar>
      </div>

      <section>
        <div class="d-flex justify-content-start align-items-center mb-s">
          <h2 class="c-vehicle-header__name">{{vehicle.name || vehicle.id}}</h2>
          <div class="mx-s">
            <app-favorite-vehicle [id]="vehicle.id" size="25"></app-favorite-vehicle>
          </div>
        </div>
        <app-tags-group [tags]="vehicle.groups" size="lg"></app-tags-group>

      </section>
    </div>
    <app-entity-status [entityId]="vehicle.id" entityType="VEHICLE" *hasPermission="PERMISSIONS.STATUS_VIEW"></app-entity-status>
  </div>

  <div class="text-right d-flex flex-column justify-content-between align-items-end">
    <app-driver-details
      [driver]="vehicle.ride?.driver && vehicle.ride?.driver.id !== null ? vehicle.ride.driver : vehicle.driver"
      *ngIf="!!vehicle.driver || vehicle.ride?.driver.id !== null"
      class="pointer"
      [isDriverOverriden]="!vehicle.ride?.driver ? false : (!vehicle.driver ? true : (vehicle.ride.driver.id !== vehicle.driver.id))"
      (click)="driver()"></app-driver-details>
  </div>
</div>
<div class="d-flex justify-content-between align-items-center mt-s">
  <div class="d-flex justify-content-start align-items-center">
    <app-plate [numberPlate]="vehicle.numberPlate" [fuelType]="vehicle.fuelType"></app-plate>
    <p class="c-vehicle-header__mark">{{vehicle.mark}} {{vehicle.model}}</p>
  </div>
  <app-header-location-status [vehicle]="vehicle"></app-header-location-status>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutModule } from './modules/layout/layout.module';
import { FormGroupModule } from './modules/form-group/form-group.module';
import { OverflowSpinnerModule } from './modules/overflow-spinner/overflow-spinner.module';
import { ComponentsModule } from './components/components.module';
import { PipesModule } from './pipes/pipes.module';
import { DateRangeFilterModule } from './modules/date-range-filter/date-range-filter.module';
import { PerspectiveMenuModule } from './modules/perspective-menu/perspective-menu.module';
import { MapModule } from './modules/map/map.module';
import { VehicleStatusCardModule } from './modules/vehicle-status-card/vehicle-status-card.module';
import { DirectivesModule } from './directives/directives.module';
import { NgbModalModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { WarehouseUtilsModule } from './modules/warehouse-utils/warehouse-utils.module';
import { PortalModule } from '@angular/cdk/portal';
import { CustomInputsModule } from './modules/custom-inputs/custom-inputs.module';
import { TemplateConfigurationsModule } from './modules/template-configurations/template-configurations.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DataGridModule } from './modules/data-grid/data-grid.module';

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NgbPaginationModule,
    DirectivesModule,
    DragDropModule,
    LayoutModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    TranslateModule,
    NgbModalModule,
    PipesModule,
    RouterModule,
    FormGroupModule,
    PerfectScrollbarModule,
    OverflowSpinnerModule,
    ComponentsModule,
    DateRangeFilterModule,
    PerspectiveMenuModule,
    MapModule,
    VehicleStatusCardModule,
    PerfectScrollbarModule,
    WarehouseUtilsModule,
    PortalModule,
    CustomInputsModule,
    DataGridModule,
    TemplateConfigurationsModule,
  ],
  declarations: []
})
export class SharedModule {
}

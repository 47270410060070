import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Area } from '../../../../../interfaces/poi';
import { MapService } from '../../map.service';
import { fromEvent, Subscription } from 'rxjs';
import { hexToRgba } from '../../../../../utils/utils';
import { ZONE_DEFAULT_COLOR } from '../../../../../views/dashboard/zones/zones.service';
import {filter, map, tap} from 'rxjs/operators';

@Component({
  selector: 'app-map-polygon-edited',
  templateUrl: './map-polygon-edited.component.html',
  styleUrls: ['./map-polygon-edited.component.scss']
})
export class MapPolygonEditedComponent implements OnInit, OnDestroy, OnChanges {
  @Input() area: Area;
  @Input() color: string;
  @Input() editMode: boolean;
  @Output() areaChange = new EventEmitter();
  private subscription = new Subscription();
  private isMapInit = false;
  private drawShape: H.map.Polyline | H.map.Polygon;
  private lineString: H.geo.LineString;
  private mapInstance: H.Map;
  public isDrawnEnd = false;
  private polygonHandles: H.map.Group;

  constructor(private mapService: MapService) {
  }


  emmitChange() {
    if (this.drawShape) {
      const area = this.mapService.geoToGeoJson((this.drawShape as any).getGeometry());
      if (area.coordinates) {
        const first = [...area.coordinates[0][0]];
        area.coordinates[0].push(first);
        area.coordinates[0] = area.coordinates[0].reverse();
      }
      this.areaChange.emit(area);
    }
  }

  ngOnInit() {
    this.subscription.add(this.mapService.mapIsLoad.subscribe((mapInstance) => {
      this.mapInstance = mapInstance;
      this.mapService.behavior.disable(H.mapevents.Behavior.DBLTAPZOOM);
      this.addMapEvents();
      this.setArea();
    }));
  }

  setArea() {
    if (this.mapInstance && this.area) {
      this.clearPoly();
      const area = {...this.area, coordinates: [(this.area.coordinates[0] as Array<number>).slice(1)]};
      this.drawShape = this.mapService.readGeoJSON(area, this.shapeOptions.style)[0] as H.map.Polyline | H.map.Polygon;
      this.mapInstance.addObject(this.drawShape);
      this.mapInstance.getViewModel().setLookAtData({
        bounds: this.drawShape.getBoundingBox()
      });
      this.makeDraggable();
      if (this.editMode) {
        this.makeHandles();
      }
      this.isDrawnEnd = true;
    }
  }

  addMapEvents() {
    this.subscription.add(fromEvent(this.mapInstance, 'tap')
      .pipe(
        filter((e: any) => e.originalEvent.which === 1 && !this.isDrawnEnd && this.editMode),
        map(this.pointFromEvent)
      )
      .subscribe(this.mapClickEvent));
    this.subscription.add(fromEvent(this.mapInstance, 'dbltap')
      .pipe(filter(() => !this.isDrawnEnd))
      .subscribe(this.closePolygon));
    this.subscription.add(fromEvent<any>(this.mapInstance, 'pointermove').pipe(
      filter(() => !!this.lineString && !this.isDrawnEnd && this.editMode),
      tap(x => console.log('pointer move')),
      map(this.pointFromEvent)
    ).subscribe(this.refreshNonFinalizedPolygon));

  }

  removeEvents() {
     this.mapInstance.removeEventListener('tap', this.mapClickEvent);
     this.mapInstance.removeEventListener('dbltap', this.closePolygon);
     this.mapInstance.removeEventListener('pointermove', this.refreshNonFinalizedPolygon);
  }

  closePolygon = ($event) => {
    $event.originalEvent.preventDefault();
    $event.originalEvent.stopPropagation();
    if (this.lineString && this.lineString.getPointCount() > 2) {
      this.isDrawnEnd = true;
      const length = this.lineString.getPointCount();
      this.lineString.removePoint(length - 1);
      this.setPolygoneGeometry(this.lineString);
      this.makeHandles();
      this.makeDraggable();
      this.emmitChange();
    }
  }

  get shapeOptions(): any {
    return {
      style: {
        lineWidth: 3,
        lineJoin: 'miter',
        lineCap: 'square',
        lineDash: [10],
        fillColor: hexToRgba(this.styleColor, 0.3),
        strokeColor: this.styleColor,
      }
    };
  }

  get styleColor() {
    return this.color || ZONE_DEFAULT_COLOR;
  }

  refreshNonFinalizedPolygon = (point) => {
    const strip = new H.geo.LineString([...this.lineString.getLatLngAltArray(), point.lat, point.lng, point.alt]);
    const pointsCout = this.lineString.getPointCount();
    if (pointsCout === 1) {
      if (!this.drawShape) {
        this.drawShape = new H.map.Polyline(strip, this.shapeOptions);
        this.mapInstance.addObject(this.drawShape);
      } else {
        (this.drawShape as H.map.Polyline).setGeometry(strip);
      }
    } else if (pointsCout === 2) {
      if (this.drawShape instanceof H.map.Polyline) {
        this.mapInstance.removeObject(this.drawShape);
        this.drawShape = new H.map.Polygon(strip, this.shapeOptions);
        this.mapInstance.addObject(this.drawShape);
      } else {
        this.setPolygoneGeometry(strip);
      }
    } else if (pointsCout > 2) {
      this.setPolygoneGeometry(strip);
    }
  }

  pointFromEvent = ($event: H.mapevents.Event): H.geo.Point => {
    const {viewportX, viewportY} = $event.currentPointer as any;
    return this.mapInstance.screenToGeo(viewportX, viewportY);
  }

  mapClickEvent = (point) => {
    if (!this.lineString) {
      this.lineString = new H.geo.LineString();
    }
    this.lineString.pushPoint(point);
  }


  makeHandles() {
    if (this.polygonHandles) {
      this.polygonHandles.removeAll();
    }
    this.polygonHandles = new H.map.Group();
    this.lineString = (this.drawShape as any).getGeometry().getExterior();
    const icon = this.getDomIcon();
    for (let k = 0; k < this.lineString.getPointCount(); k++) {
      const handleCenter = this.lineString.extractPoint(k);
      const handleMarker = new H.map.DomMarker(handleCenter, {
        icon,
      });
      handleMarker.draggable = true;
      this.fromEventEdit(handleMarker, 'dragstart').subscribe(() => {
        this.mapService.behavior.disable();
      });
      this.fromEventEdit(handleMarker, 'dragend').subscribe(() => {
        this.mapService.behavior.enable();
        this.mapService.behavior.disable(H.mapevents.Behavior.DBLTAPZOOM);
        this.emmitChange();
      });
      this.bindMarkerDrag(k, handleMarker);
      this.bindMarkerDbtap(k, handleMarker);

      this.polygonHandles.addObject(handleMarker);
      this.mapInstance.addObject(this.polygonHandles);
    }
  }

  getDomIcon() {
    return new H.map.DomIcon(`<div class="c-map-marker-drag" style="background: ${this.color}"></div>`);
  }

  setPolygoneGeometry(lineStrip) {
    (this.drawShape as any).setGeometry(new (H.geo as any).Polygon(lineStrip));
  }

  bindMarkerDrag = (i, marker: H.map.Marker) => {
    this.fromEventEdit(marker, 'drag').subscribe((ev: any) => {
      const target = ev.target;
      const point = this.pointFromEvent(ev);
      console.log(target);
      target.setGeometry(point);
      const newStrip = new H.geo.LineString();
      this.lineString.eachLatLngAlt((lat, lng, alt, idx) => {
        if (idx !== i) {
          newStrip.pushLatLngAlt(lat, lng, 0);
        } else {
          newStrip.pushLatLngAlt(point.lat, point.lng, 0);
        }
      });
      this.lineString = newStrip;
      this.setPolygoneGeometry(newStrip);
    });
  }

  bindMarkerDbtap = (i, marker: H.map.Marker) => {
    this.fromEventEdit(marker, 'dbltap').subscribe(() => {
      if (this.lineString.getPointCount() > 3) {
        this.lineString.removePoint(i);
        this.setPolygoneGeometry(this.lineString);
        this.polygonHandles.removeAll();
        this.makeHandles();
        this.emmitChange();
      }
    });
  }

  fromEventEdit(target, event) {
    return fromEvent(target, event).pipe(filter(() => this.editMode));
  }

  makeDraggable() {
    const polygon = this.drawShape as any;
    polygon['draggable'] = true;
    let startCoord;
    this.fromEventEdit(polygon, 'dragstart').subscribe((evt: any) => {
      document.body.style.cursor = 'pointer';
      startCoord = this.pointFromEvent(evt);
      this.mapService.behavior.disable();
    });
    this.fromEventEdit(polygon, 'dragend').subscribe(() => {
      document.body.style.cursor = 'auto';
      this.mapService.behavior.enable();
      this.mapService.behavior.disable(H.mapevents.Behavior.DBLTAPZOOM);
      this.emmitChange();
    });
    this.fromEventEdit(polygon, 'drag').subscribe((evt: any) => {
      const newCoord = this.pointFromEvent(evt);
      if (newCoord.lat !== startCoord.lat || newCoord.lng !== startCoord.lng) {
        if (polygon.getGeometry !== null) {
          const strip = polygon.getGeometry().getExterior();
          const newStrip = new H.geo.LineString();
          strip.eachLatLngAlt(function (lat, lng, alt, idx) {
            const diffLat = (lat - startCoord.lat);
            const diffLng = (lng - startCoord.lng);
            newStrip.pushLatLngAlt(newCoord.lat + diffLat, newCoord.lng + diffLng, 0);
          });
          this.lineString = newStrip;
          this.setPolygoneGeometry(this.lineString);
          this.makeHandles();
        } else {
          polygon.setGeometry(newCoord);
        }
        if (!this.mapInstance.getViewModel().getLookAtData().bounds.getBoundingBox().containsPoint(newCoord)) {
          this.mapInstance.setCenter(newCoord, true);
        }
        startCoord = newCoord;
      }
    });
  }

  handlerClearPoly() {
    this.clearPoly();
    this.areaChange.emit(null);
  }

  clearPoly() {
    this.isDrawnEnd = false;
    this.lineString = null;
    if (this.polygonHandles) {
      this.polygonHandles.removeAll();
    }
    if (this.drawShape) {
      this.mapInstance.removeObject(this.drawShape);
      this.drawShape.dispose();
      this.drawShape = null;
    }
  }

  ngOnDestroy(): void {
    this.removeEvents();
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.color && this.drawShape) {
      this.colorWasChange();
    }
    if (changes.area && !changes.area.firstChange) {
      this.setArea();
    }
    if (changes.editMode && !changes.editMode.firstChange) {
      if (this.editMode && this.isDrawnEnd) {
        this.makeHandles();
      } else if (this.polygonHandles) {
        this.polygonHandles.removeAll();
      }
    }
  }

  colorWasChange() {
    this.drawShape.setStyle(this.shapeOptions.style);
    if (this.polygonHandles) {
      const icon = this.getDomIcon();
      this.polygonHandles.forEach((marker: H.map.DomMarker) => {
        marker.setIcon(icon);
      });
    }
  }

}

import { Injectable } from '@angular/core';
import { ResolveClass } from '../../../classes/resolver.class';
import { UserService } from '../../../core/services/user.service';
import { User } from '../../../interfaces/user';

@Injectable()
export class UserResolve extends ResolveClass<User> {
  constructor(service: UserService) {
    super(service);
  }
}

import { NotificationT } from './../../../interfaces/notificationT';
import { NotificationsService } from './../../../core/services/notifications.service';
import { Injectable } from '@angular/core';
import { ResolveClass } from '../../../classes/resolver.class';

@Injectable()
export class NotificationResolve extends ResolveClass<NotificationT> {
  protected params = {};

  constructor(service: NotificationsService) {
    super(service);
  }
}

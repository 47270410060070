import {Component} from '@angular/core';
import {TelemetryRide} from '../../../interfaces/telemetry';
import {ActivatedRoute} from '@angular/router';
import {RouterHelperService} from '../../../core/services/router-helper.service';
import {RideRequest, TelemetryService, VehicleStatuses} from '../../../core/services/telemetry.service';
import {Poi, PoiType} from '../../../interfaces/poi';
import {NewEditClass} from '../../../classes/new-edit.class';
import {PERMISSIONS, PermissionService} from '../../../core/services/permission.service';
import {PopupViewService} from '../../../shared/components/popup-view/popup-view.service';
import {ApiValidatorService} from '../../../core/services/api-validator.service';
import {UntypedFormBuilder} from '@angular/forms';

@Component({
  selector: 'app-ride-popup',
  templateUrl: './ride-popup.component.html',
  styleUrls: ['./ride-popup.component.scss']
})
export class RidePopupComponent extends NewEditClass {
  protected editPermission: PERMISSIONS = PERMISSIONS.POI_UPDATE;
  protected newPermission: PERMISSIONS;
  public ride: TelemetryRide;
  public poi: Poi;
  statuses = [
    {label: VehicleStatuses.OTHER_WORK_PARK, value: PoiType.OTHER},
    {label: VehicleStatuses.NONWORK_PARK, value: 'nonwork'},
    {label: VehicleStatuses.CUSTOMER_PARK, value: PoiType.CUSTOMER},
    {label: VehicleStatuses.BRANCH_WORK_PARK, value: PoiType.BRANCH}
  ];

  constructor(
    popupService: PopupViewService,
    apiValidator: ApiValidatorService,
    fb: UntypedFormBuilder,
    permission: PermissionService,
    private route: ActivatedRoute,
    private routeHelper: RouterHelperService,
    private telemetryService: TelemetryService) {
    super(popupService, apiValidator, fb, permission);
    const poi = this.routeHelper.getState('poi');
    if (poi) {
      this.poi = poi;
      this.isEditMode = true;
    }

  }


  addPoi() {
    this.routeHelper.navigatePopup(['poi'], {
      state: {
        ride: this.ride,
      }
    });
  }

  resetForm() {
    const poi = !this.poi && this.ride.parkData && this.ride.parkData.poi.userPoi ? this.ride.parkData.poi.userPoi : this.poi;
    this.forms.ride.patchValue({
      poi: poi ? [poi] : null,
      status: this.statuses.find((val) => this.ride.status.type === val.label).value || null,
    });
  }

  addRouteSub() {
    this.subscriptions.add(this.route.data.subscribe(({ride}) => {
      this.ride = ride;
      this.resetForm();
    }));
  }

  buildForm() {
    this.forms.ride = this.fb.group({
      poi: [],
      status: [],
    });
  }

  updateRide() {
    this.telemetryService.getRideById(this.ride._id).subscribe(res => {
      this.ride = res;
    });
  }

  save() {
  }

  formData() {
    const formData: RideRequest = {
      park: {
        isWork: true,
      },
    };
    const data = this.forms.ride.value;
    if (data.status === 'nonwork') {
      formData.park.isWork = false;
      formData.park.poi = {type: PoiType.OTHER};
    } else {
      formData.park.poi = {type: data.status};
    }
    if (data.poi && data.poi.length && data.poi[0].id && this.ride.parkData
      && (!this.ride.parkData.poi.userPoi || this.ride.parkData.poi.userPoi
        && this.ride.parkData.poi.userPoi.id !== data.poi[0].id)) {
      formData.park.poi.id = data.poi[0].id;
    } else if (data.poi && data.poi.length === 0) {
      formData.park.poi.id = null;
    }

    return formData;
  }

  update() {
    this.telemetryService.updateRide(this.ride._id, this.formData()).subscribe(res => {
      this.pending = false;
      this.updateRide();
    }, this.errorHandler.bind(this));
  }
}

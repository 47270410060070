import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { PermissionService } from '../services/permission.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionsRedirectGuard implements CanActivate {
  constructor(private permissions: PermissionService, private router: Router, private route: ActivatedRoute) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    const permissions = route.data['permissions'];
    const hasPermissions = permissions && this.permissions.hasPermission(permissions);
    if (hasPermissions) {
      return true;
    } else {
      const prentChidren = route.parent.routeConfig.children;
      const childrenWithAccess = prentChidren
        .find(value => !value.redirectTo && (!value.canActivate
          || value.data && value.data['permissions'] && this.permissions.hasPermission(value.data['permissions'])));
      if (childrenWithAccess) {
        let isPopup = false;
        const path = [...route.parent.pathFromRoot
          .filter(value => {
            if (value.outlet === 'popup') {
              isPopup = true;
            }
            return value.url.length;
          })
          .map(value => value.url[0].path), childrenWithAccess.path];
        const urlTree = this.router
          .createUrlTree(isPopup ? [{outlets: {popup: path}}] : path);
        return urlTree;
        // return this.router.parseUrl( route.parent.pathFromRoot);
      }

    }
    return this.router.parseUrl('/no-access');
  }
}

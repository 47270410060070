<div class="c-marker" [style.background]="color">
  <div *ngIf="name" >{{name}}</div>
  <app-map-circle
    [radius]="radius"
    [color]="color"
    [autoZoom]="autoZoom"
    [coordinates]="coordinates"
    *ngIf="showCircle"
  ></app-map-circle>
</div>


<div class="row justify-content-start align-items-start c-selected-tag__list">
    <div class="col-auto" *ngFor="let item of itemsArray">
        <div class="c-selected-tag__tag c-selected-tag__tag--vehicle" (click)="handleClickItem(item)" [ngClass]="{
        'pointer': clickItem.observers.length
        }">
            {{label(item)}}
            <i class="icon-x" (click)="handlerRemoveItem(item)" *ngIf="removable"></i>
        </div>
    </div>
    <div class="col-auto" *ngIf="showMoreLength">
        <div class="c-selected-tag__tag c-selected-tag__tag--more-length">+{{showMoreLengthLabel}}</div>
    </div>
    <div class="col-auto" *ngIf="showMore">
        <div class="c-selected-tag__tag c-selected-tag__tag--more"
             (click)="showMoreToggle()">{{showMoreLabel}}</div>
    </div>

    <div class="col-auto" *ngIf="items?.length && removable">
        <div class="c-selected-tag__tag c-selected-tag__tag--remove" translate (click)="handlerRemoveAll()">REMOVE_ALL
        </div>
    </div>
</div>

import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard.component';
import { SharedModule } from '../../shared/shared.module';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardViewComponent } from './dashboard-view/dashboard-view.component';
import { PortalModule } from '@angular/cdk/portal';
import { NoAccessComponent } from './no-access/no-access.component';

@NgModule({
  declarations: [DashboardComponent, DashboardViewComponent, NoAccessComponent],
  imports: [
    DashboardRoutingModule,
    SharedModule,
    PortalModule,
  ],
  exports: [DashboardRoutingModule]
})
export class DashboardModule { }

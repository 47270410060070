import { FiltersConfig } from './../../../../../classes/filters';
import { Component, Input } from '@angular/core';
import { Filters } from 'src/app/classes/filters';
import { Vehicle } from '../../../../../classes/vehicle';
import { CustomSelectDropdown } from '../../custom-select-dropdown';

@Component({
  selector: 'app-vehicle-select-dropdown',
  templateUrl: './vehicle-select-dropdown.component.html',
  styleUrls: ['./vehicle-select-dropdown.component.scss']
})
export class VehicleSelectDropdownComponent extends CustomSelectDropdown<Vehicle> {
  @Input() dimension = 'VEHICLE';
  @Input() clientsFilter = false;

  constructor() {
    super();
  }

  initFilters() {
    const filterConfig: FiltersConfig = {
      search: {
        type: 'TEXT',
        fields: ['name', 'model', 'mark']
      },
    };

    if (this.clientsFilter) {
      filterConfig.group = { type: 'SELECT', fields: 'clientId'};
    } else {
      filterConfig.group = { type: 'GROUPS', fields: 'groups'};
      filterConfig.favorite = (vehicle: Vehicle, value) => vehicle.isFavorite();
    }
    this.filters = new Filters(filterConfig);
  }

  getLabel(vehicle: Vehicle) {
    let label = vehicle.name;
    if (this.clientsFilter) {
      label += `(${vehicle.clientId})`;
      label += vehicle.device ? ` - urzadzenie: ${vehicle.device.imei}` : ' - dostępne';
    }
    return label;
  }
}

<div class="d-flex justify-content-between align-items-stretch flex-column flex-fill h-100">
  <div class="overflow-auto flex-grow-1">
      <div [formGroup]="forms?.EXTERNAL" class="p-xm">
          <h3 class="form-header mt-xl" translate>BASIC_DATA</h3>
          <div class="row">
              <div class="col-4">
                  <app-form-group>
                      <label translate>Id kolejki</label>
                      <input app-form formControlName="id" type="text">
                      <app-error-message [control]="forms?.EXTERNAL.get('id')"></app-error-message>
                  </app-form-group>
              </div>
              <div class="col-4">
                  <!-- <app-form-group> -->
                      <label translate>Aktywane</label>
                      <input app-form formControlName="enabled" type="checkbox">
                      <app-error-message [control]="forms?.EXTERNAL.get('enabled')"></app-error-message>
                  <!-- </app-form-group> -->
              </div>
              <div class="col-4">
                <!-- <app-form-group> -->
                    <label translate>Wyłącz dalsze przetwarzanie</label>
                    <input app-form formControlName="stopFurtherProcessing" type="checkbox">
                    <app-error-message [control]="forms?.EXTERNAL.get('stopFurtherProcessing')"></app-error-message>
                <!-- </app-form-group> -->
            </div>
          </div>
      </div>
  </div>
  <div class="p-xm d-flex justify-content-end align-items-center border-top">
      <button class="btn btn-primary btn-border px-xxl mr-m" (click)="cancel()" translate>
          {{(isEditMode ? 'CANCEL' : 'EXIT')|translate}}
      </button>
      <button class="btn btn-primary px-xxl" (click)="handlerSave()" [disabled]="pending" *ngIf="showButton">
          <app-spinner [showSpinner]="pending"></app-spinner>
          {{(isNewView || isEditMode ? 'SAVE' : 'EDIT')|translate}}
      </button>
  </div>
</div>

import { FuelType } from './../../../../../interfaces/vehicle';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[app-table-header]',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableHeaderComponent implements OnInit {
  @Input() isDriver = false;
  @Input() vehicleFuelType: FuelType;
  @Input() fuelDimmension = 'DISTANCE';

  constructor() {
  }

  ngOnInit() {
  }

  get fuelLabel() {
    return this.fuelDimmension === 'DURATION' ? 'AVG_FUEL_CONSUMPTION_H' :
      (this.vehicleFuelType === 'electric' ? 'AVG_KWH_CONSUMPTION' : 'AVG_FUEL_CONSUMPTION');
  }

}

import {Injectable} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, AbstractControl, UntypedFormArray} from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class ApiValidatorService {

  static MESSAGES = {
    'required': 'To pole jest wymagane',
    'oneRequired': 'FORM_VALIDATOR.ONE_REQUIRED',
    'min': 'FORM_VALIDATOR.MIN_NUMBER',
    'max': 'FORM_VALIDATOR.MAX_NUMBER',
    'minlength': 'Minimalna dugość to {requiredLength}',
    'startsWith': 'FORM_VALIDATOR.STARTS_WITH',
    'digits': 'FORM_VALIDATOR.DIGITS',
    'validateEmail': 'To nie jest poprawny adres email',
    'mismatch': 'Wpisane hasła różnią się',
    'tooHighSpecial': 'FORM_VALIDATOR.SPECIAL',
    'periodError': 'FORM_VALIDATOR.PERIOD_ERROR',
    'specialOfferContent': 'FORM_VALIDATOR.SPECIAL_OFFER_CONTENT',
    'noDefaultLang': 'FORM_VALIDATOR.NO_STORE_DEFAULT_LANG',
    'invalidFinishAt': 'Data końca musi być większa niż startu',
    'requredVehicleGroup': 'Wybierz samochód albo grupę'
  };

  static getValidationMessage(validator: string, validatorValue?: any) {
    let message = ApiValidatorService.MESSAGES[validator];
    if (message) {
      if (validatorValue) {
        Object.keys(validatorValue).forEach(key => {
          message = message.replace(`{${key}}`, validatorValue[key]);
        });
      }
      return message;
    }
    return validatorValue;
  }

  validateEmail(c: UntypedFormControl) {
    // tslint:disable-next-line:max-line-length
    const EXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
    let email = c.value;
    if (!email) {
      return null;
    }
    email = email.email || email;
    return EXP.test(email) ? null : {
      validateEmail: {
        valid: false
      }
    };
  }


  validatePasswords(c: AbstractControl) {
    return c.get('password').value === c.get('password2').value ? null : {
      mismatch: {
        valid: false
      }
    };
  }

  periodValidator(fc: UntypedFormControl) {
    const values = fc.value;
    return !values || values && values.to && (values.from || values.from === 0) ? null : {
      periodError: {
        valid: false
      }
    };
  }

  validateDatesRange(c: AbstractControl) {
    const startAt = c.get('start_at').value;
    const finishAt = c.get('finish_at').value;

    if (finishAt !== null) {
      return new Date(startAt).getTime() < new Date(finishAt).getTime() ? null : {
        invalidFinishAt: {
          valid: false,
        }
      };
    }

    return null;
  }

  constructor() {
  }

  showToastrError(error) {
    const errorTmp = error.non_field_errors || error;
    if (errorTmp && errorTmp.length > 0) {
      // this.toastr.error(errorTmp[0].message);
    } else if (errorTmp && errorTmp.message) {
      // this.toastr.error(errorTmp.message);
    }
  }

  setDateFields(dates: string[], form: UntypedFormGroup, object: any) {
    dates.forEach(value => {
      if (object[value]) {
        form.get(value).patchValue(new Date(object[value]));
      }
    });
  }

  checkValid(form: UntypedFormGroup | UntypedFormArray) {
    for (const key of Object.keys(form.controls)) {
      if (!form.controls[key].valid) {
        form.controls[key].markAsDirty();
        if (form.controls[key] instanceof UntypedFormGroup || form.controls[key] instanceof UntypedFormArray) {
          this.checkValid((form.controls[key] as UntypedFormGroup));
        }
      }
    }
  }

  formIsValid(form: UntypedFormGroup | UntypedFormArray): boolean {
    if (!form.valid) {
      this.checkValid(form);
      form.markAsDirty();
    }
    return form.valid;
  }

  checkFormValidatorsFromApi(form: UntypedFormGroup | UntypedFormArray, errors: any) {
    for (const key of Object.keys(errors)) {
      if (form.controls[key]) {
        form.controls[key].setErrors({
          apiError: true,
        });
        form.controls[key].markAsTouched();
      }
    }
  }
}

import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'app-authentication',
  templateUrl: 'authentication.component.html',
  styleUrls: ['authentication.component.scss'],
})
export class AuthenticationComponent implements OnInit {
  @HostBinding('class.authentication') addClass = true;
  constructor() { }

  ngOnInit() {
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../../../core/guards/auth-guard.service';
import { WorkSchedulesPopupComponent } from './work-schedules-popup.component';

const routes: Routes = [
  {
    path: 'work-schedule',
    outlet: 'popup',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: WorkSchedulesPopupComponent,
      },
      {
        path: ':id',
        component: WorkSchedulesPopupComponent,
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkSchedulesPopupRoutingModule {
}

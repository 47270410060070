import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectInputComponent),
      multi: true
    }
  ]
})
export class SelectInputComponent implements ControlValueAccessor {
  @Input() items;
  @Input() multiple = true;
  @Input() searchable = false;
  @Input() allowClear = true;
  @Input() bindLabel;
  @Input() bindIcon;
  @Input() bindValue;
  @Input() selectClass: string;
  @Input() showTags = false;
  @Input() max = 4;
  @Input()
  placeholder: string;
  public isDisabled = false;
  public selectedItems;
  public selectedObject;
  public isOpen = false;

  constructor(private translate: TranslateService, private cd: ChangeDetectorRef) {
  }

  onChange: any = () => {
  };

  onTouch: any = () => {
  };


  @Input()
  set value(val) {
    this.selectedItems = val;
    this.onChange(val);
    this.onTouch(val);
    this.cd.detectChanges();
  }

  removeAll() {
    this.value = null;
  }

  get selectedTags() {
    if (this.bindValue && this.items) {
      return this.selectedItems ? this.items.filter(item => this.selectedItems.indexOf(item[this.bindValue]) !== -1) : [];
    } else {
      return this.selectedItems;
    }
  }

  removeItem(item) {
    if (this.bindValue) {
      this.value = Array.isArray(this.selectedItems) ? this.selectedItems.filter(id => id !== item[this.bindValue]) : null;
    } else {
      this.value = Array.isArray(this.selectedItems) ? this.selectedItems.filter(itemObj => itemObj !== item) : null;
    }
  }

  selectItems(items: any[], key = this.bindLabel) {
    if (items.length > this.max) {
      const length = items.length - this.max;
      items = items.slice(0, this.max);
      items.push('+' + length);
    }
    this.selectedObjectChange(items);
    return items.map((item) => {
      const transKey = item[key] || item;
      return typeof transKey === 'string' && transKey !== '' ? this.translate.instant(transKey) : '';
    }).join(', ');
  }

  selectedObjectChange($event) {
    this.selectedObject = $event;
  }

  selectChange(val) {
    this.value = Array.isArray(val) && val.length === 0 ? null : val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.detectChanges();
  }

  detectChanges() {
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }

  writeValue(obj: any): void {
    this.selectedItems = obj;
    this.detectChanges();
  }

  label(item) {
    return typeof this.bindLabel === 'function' ? this.bindLabel(item) : item[this.bindLabel];
  }

  setOpenState(state) {
    if (this.searchable) {
      this.isOpen = state;
      this.cd.detectChanges();
    }
  }

  closeSelect() {

  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { WarehouseDefaultResponse } from '../../interfaces/warehouse';

@Pipe({
  name: 'maxSpeed'
})
export class MaxSpeedPipe implements PipeTransform {

  transform(value: WarehouseDefaultResponse, args?: any): string {
    const maxSpeed = value.TOTAL_MAX_SPEED[0].v;
    const avgSpeed = value.SUM_RIDE_DISTANCE && value.SUM_RIDE_DURATION
      ? (value.SUM_RIDE_DISTANCE[0].v / (value.SUM_RIDE_DURATION[0].v / 60 / 60)).toFixed(0)
      : 0;

    return (maxSpeed <= avgSpeed ? '-' : maxSpeed) + ' km/h';
  }

}

<div class="c-filters d-flex align-items-center justify-content-between">
    <div class="flex-fill max-width">
        <ng-content></ng-content>
    </div>
    <div class="row align-items-center justify-content-end">
        <ng-content select="[filters-right]"></ng-content>
        <div class="col c-filters__toggle" (click)="panelToggle()" *ngIf="showPanel">
            <app-badge [value]="selectedLength" color="maya"></app-badge>
            <i class="icon-filter pointer"></i>
        </div>
    </div>

</div>
<ng-template cdk-portal #panelTemplate>
    <div class="c-filters__panel" [@ngIfEnter] *ngIf="isOpen">
        <app-close-btn (toggle)="panelToggle()"></app-close-btn>
        <ng-content select="[filters-sidebar]">
        </ng-content>
    </div>
</ng-template>
<ng-container *ngIf="popupView">
    <ng-container *ngTemplateOutlet="panelTemplate">
    </ng-container>
</ng-container>


import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportsService } from '../../../../core/services/reports.service';
import { Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  ReportConfiguration,
  ReportTemplateWithFields
} from '../../../../interfaces/reports';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PopupViewService } from '../../../../shared/components/popup-view/popup-view.service';
import { ApiValidatorService } from '../../../../core/services/api-validator.service';
import { DateRageValue } from '../../../../shared/modules/date-range-filter/date-range.service';
import {RouterHelperService} from '../../../../core/services/router-helper.service';
import { PERMISSIONS } from 'src/app/core/services/permission.service';

export enum PeriodReportCycle {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

@Component({
  selector: 'app-new-report-popup',
  templateUrl: './new-report-popup.component.html',
  styleUrls: ['./new-report-popup.component.scss']
})
export class NewReportPopupComponent implements OnInit, OnDestroy {
  readonly permissions = PERMISSIONS;
  private subscription: Subscription;
  public pending = true;
  public periodReportCycleItems = [PeriodReportCycle.DAY, PeriodReportCycle.WEEK, PeriodReportCycle.MONTH];
  public fieldsForm: UntypedFormGroup;
  public formErrors: any;
  public customeFieldsForm: UntypedFormGroup;
  public saving = false;
  private dateRange: DateRageValue;
  public report: ReportTemplateWithFields;
  public configuration: ReportConfiguration;
  public types = [
    {label: 'DISPOSABLE', value: false},
    {label: 'PERIODIC', value: true},
  ];
  private configFromStorage: any;
  public date: DateRageValue ;

  constructor(private activeRoute: ActivatedRoute,
              private fb: UntypedFormBuilder,
              private router: Router,
              private validator: ApiValidatorService,
              private popupService: PopupViewService,
              private routeHelper: RouterHelperService,
              private apiValidator: ApiValidatorService,
              private service: ReportsService) {
  }

  ngOnInit() {
    this.subscription = this.activeRoute.params.pipe(
      tap(() => this.pending = true),
      switchMap(({id, configId}) => {
        if (configId === 'storage') {
          this.configFromStorage = this.routeHelper.getState('report');
        }
        return this.service.templateWithConfiguration(id, this.configFromStorage ? null : configId); }),
    ).subscribe(res => {
      this.pending = false;
      const {
        template,
        configuration
      } = res;
      this.report = template;
      this.configuration = configuration;
      this.buildForms();
    }, () => {
      this.pending = false;
    });
  }

  get periodReportCycle() {
    return PeriodReportCycle;
  }

  periodArrray() {
    const periods: any[] = this.configuration && this.configuration.periodicOptions ?
      this.periodReplacMidnight(this.configuration.periodicOptions.cycleRange) : this.getEmptyPeriodArray();
    return this.fb.array(periods
      .map((value, index) => this.fb.control(value, this.apiValidator.periodValidator)));
  }

  getEmptyPeriodArray() {
    return [
      { from: '0:00', to: '23:59' },
      { from: '0:00', to: '23:59' },
      { from: '0:00', to: '23:59' },
      { from: '0:00', to: '23:59' },
      { from: '0:00', to: '23:59' },
      { from: '0:00', to: '23:59' },
      { from: '0:00', to: '23:59' },
    ];
  }

  get cycleRange(): UntypedFormArray {
    return this.fieldsForm.get('cycleRange') as UntypedFormArray;
  }

  periodReplacMidnight(cycleRange: { from: string, to: string}[]) {
    return cycleRange.map(c => {
      if (c === null) {
        return c;
      }
      c.to = c.to === '24:00' ? '0:00' : c.to;
      return c;
    });
  }

  buildForms() {

    this.fieldsForm = this.buildForm(this.report.fields.entities, {
      name: [this.configuration ? this.configuration.name : this.report.name],
      isPeriodic: [this.configuration ? this.configuration.isPeriodic : false],
      outputFormat: [this.configuration && this.configuration.outputFormats.length > 0 ?
        this.configuration.outputFormats[0] : this.report.availableOutputFormats[0]],
      cycle: [this.configuration && this.configuration.periodicOptions ?
        this.configuration.periodicOptions.cycle : 'DAY'],
      cycleRange: this.periodArrray(),
      recipients: [this.configuration && this.configuration.isPeriodic ?
        this.configuration.periodicOptions.recipients.emails.join(';') : '' ],
      dimension: this.getDimension(),
    });
    this.customeFieldsForm = this.buildForm(this.report.customFields);
    if (this.configuration) {
      this.fieldsForm.patchValue(this.patchValueToForm(this.configuration.fields.entities));
      this.customeFieldsForm.patchValue(this.patchValueToForm(this.configuration.customFields));
    }
    if (this.configFromStorage) {
      this.fieldsForm.patchValue(this.patchValueToForm(this.configFromStorage.fields.entities));
      this.date = this.configFromStorage.dateFilters;
    }
  }

  getDimension() {
    let dimension = this.report.fields.entities[0].name;
    if (this.configuration && this.configuration.fields && this.configuration.fields.entities) {
      this.configuration.fields.entities.forEach(f => {
        if (f.value && f.value.length > 0) {
          dimension = f.name;
        }
      });
    }
    return dimension;
  }


  patchValueToForm(entites) {
    return entites.reduce((p, c) => ({...p, [c.name]: c.value}), {});
  }

  formIsValid() {
    return this.validator.formIsValid(this.fieldsForm) && this.validator.formIsValid(this.customeFieldsForm);
  }

  buildForm(entites, fields = {}) {
    const formFields = fields;
    entites.forEach(item => {
      formFields[item.name] = this.service.convertEntityToformController(item);
    });
    return this.fb.group(formFields);
  }

  filterChange($event) {
    this.dateRange = $event;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  cancel() {
    this.popupService.close();
  }

  save() {
    this.fieldsForm.get('name').setValidators([Validators.required]);
    this.fieldsForm.get('name').updateValueAndValidity();
    if (!this.formIsValid() || this.pending) {
      return;
    }
    this.pending = true;
    this.saving = true;
    if (this.configuration) {
      this.service.updateTemplateConfiguration(this.report.id, this.configuration.id, this.buildReportConfigurationObject())
        .subscribe(
          (res) => {
            this.configuration = res;
          },
          () => {
            this.completeSave();
          },
          () => {
            this.completeSave();
          },
        );
    } else {
      this.service.saveTemplateConfiguration(this.report.id, this.buildReportConfigurationObject())
        .subscribe(
          (res) => {
            this.configuration = res;
          },
          (err) => {
            this.completeSave();
            this.formErrors = err;
          },
          () => {
            this.completeSave();
          },
        );
    }
  }

  completeSave(closePopup: boolean = true) {
    this.pending = false;
    this.saving = false;
    if (closePopup) {
      this.popupService.close();
    }
  }

  generate() {
    this.fieldsForm.get('name').clearValidators();
    this.fieldsForm.get('name').updateValueAndValidity();
    if (!this.formIsValid() || this.pending) {
      return;
    }
    this.pending = true;
    this.saving = true;
    const params = this.buildReportConfigurationObject();
    this.service.add({reportTemplateId: this.report.id, ...params})
      .subscribe(
        (res) => {
          this.router.navigate([{outlets: {popup: null, primary: ['reports', 'my-reports']}}]);
        },
        (err) => {
          this.completeSave(false);
          this.formErrors = err;
        },
        () => {
          this.completeSave(false);
        },
      );
  }

  buildReportConfigurationObject(): any {
    const formValues = this.fieldsForm.getRawValue();
    const customeFields = this.customeFieldsForm.getRawValue();
    const dimension = formValues.dimension;
    const payload: any =  {
      isPeriodic: formValues.isPeriodic,
      name: formValues.name,
      outputFormats: [formValues.outputFormat],
      fields: {
        entities: Object.keys(formValues).filter(key =>
          key !== 'name' &&
          key !== 'isPeriodic' &&
          key !== 'outputFormat' &&
          key !== 'cycleRange' &&
          key !== 'recipients' &&
          key !== 'dimension' &&
          key !== 'cycle')
          .filter(item => !!formValues[item]).map(item => ({
            // if entity is the selected dimension => send empty list
            value: item === dimension ? formValues[item] : [],
            name: item,
          }))
      },
      customFields: Object.keys(customeFields).map(item => ({
        value: customeFields[item],
        name: item,
      }))
    };

    if (formValues.isPeriodic) {
      payload.periodicOptions = {
        cycleValue: 1,
        recipients: {
          emails: formValues.recipients.replace(' ', '').split(';'),
          users: [],
        },
        cycle: formValues.cycle,
        cycleRange: formValues.cycle !== 'DAY' ? this.getFullCycleRange() : formValues.cycleRange.map(m => {
          if (m) {
            return {from: m.from, to: m.to === '0:00' ? '24:00' : m.to};
          } else {
            return null;
          }
        }),
      };
    } else {
      payload.fields.dateRange = {
        startDate: this.dateRange.start,
        endDate: this.dateRange.end,
      };
    }

    return payload;
  }

  getFullCycleRange() {
    return Array(7).fill({ from: '0:00', to: '24:00'});
  }
}

<app-form-group>
    <label translate>COLOR</label>
    <div class="d-flex justify-content-start align-items-center">
        <div [style.background]="color" class="bg-square"></div>
        <input app-form [(colorPicker)]="color"
               cpAlphaChannel="disabled"
               [cpDisableInput]="true"
               id="color_picker"
               name="color_picker"
               [disabled]="isDisabled"
               [ngModel]="color"
               (colorPickerChange)="onColorChange($event)"
               cpPosition="bottom"/>
    </div>
</app-form-group>
<ng-content></ng-content>

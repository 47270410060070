import { Role } from './../../interfaces/role';
import { Injectable } from '@angular/core';
import ApiServiceClass from '../../classes/api-service.class';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RoleService extends ApiServiceClass<Role> {

  constructor(config: ConfigService,
              http: HttpClient) {
    super('/roles', config, http);
  }
}

import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-bg',
  templateUrl: './icon-bg.component.html',
  styleUrls: ['./icon-bg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconBgComponent implements OnInit {
  @Input() iconClass: string;
  @Input() label: string;
  @Input() size = 24;
  @HostBinding('class')
  @Input() style = 'primary';


  get styles() {
    return {
      width: this.size + 'px',
      height: this.size + 'px',
      'line-height': this.size + 'px',
    };
  }

  constructor() {
  }

  ngOnInit() {
  }

}

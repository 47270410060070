import { Component, OnInit } from '@angular/core';
import { PopupNavigationItem } from '../../../shared/components/popup-view/popup-navigation/popup-navigation.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Driver } from '../../../interfaces/driver';
import { PERMISSIONS } from '../../../core/services/permission.service';

@Component({
  selector: 'app-driver-popup',
  templateUrl: './driver-popup.component.html',
  styleUrls: ['./driver-popup.component.scss']
})
export class DriverPopupComponent implements OnInit {
  public navItems: PopupNavigationItem[] = [
    {label: 'DRIVER_DETAILS', route: 'details'},
    {label: 'ROUTES', route: 'history'},
    {label: 'POIS', route: 'poi', permission: PERMISSIONS.DRIVER_UPDATE},
    {label: 'ID', route: 'identification', permission: PERMISSIONS.DRIVER_ID},
  ];
  public driver$: Observable<Driver> = this.route.data
    .pipe(
      map(({driver}: { driver: Driver }) => driver)
    );

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
  }
}

import { PartnerVehiclesService } from './../../../../core/services/partner-vehicles.service';
import { Component } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { VehicleWithTelemetryAndRide } from '../../../../interfaces/telemetry';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { VehiclesService } from '../../../../core/services/vehicles.service';
import { VehiclesGroupsService } from '../../../../core/services/vehicles-groups.service';
import { NewEditClass } from '../../../../classes/new-edit.class';
import { PopupViewService } from '../../../../shared/components/popup-view/popup-view.service';
import { ApiValidatorService } from '../../../../core/services/api-validator.service';
import { shareReplay, switchMap, tap } from 'rxjs/operators';
import { RouterHelperService } from '../../../../core/services/router-helper.service';
import { DriversService } from '../../../../core/services/drivers.service';
import { FullNamePipe } from '../../../../shared/pipes/full-name.pipe';
import { PERMISSIONS, PermissionService } from '../../../../core/services/permission.service';
import { FuelType } from '../../../../interfaces/vehicle';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { EVehicleStatus } from 'src/app/classes/vehicle';

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss'],
  providers: [FullNamePipe],
})
export class VehicleDetailsComponent extends NewEditClass {
  public vehicle: VehicleWithTelemetryAndRide;
  public groups;
  private dateFieldKeys = ['productionDate', 'utilizationStartDate'];
  public maxDate = this.ngbCalendar.getToday();
  public fuelTypes = Object.values(FuelType);
  public vehicleStatuses = Object.keys(EVehicleStatus)
    // have to filter since .keys return all keys and numbers (in enum like string:number)
    .filter((v) => isNaN(Number(v)))
    .map(m => ({ label: m, value: EVehicleStatus[m]}));
  protected editPermission = PERMISSIONS.VEHICLE_UPDATE;
  protected newPermission = PERMISSIONS.VEHICLE_CREATE;
  public drivers$ = this.driverService.list$()
    .pipe(shareReplay());

  public avatars = null;
  public partnerView = false;
  private vehicleService: VehiclesService | PartnerVehiclesService;

  constructor(private route: ActivatedRoute,
              private generalVehicleService: VehiclesService,
              private partnerVehicleService: PartnerVehiclesService,
              private driverService: DriversService,
              private fullName: FullNamePipe,
              private ngbCalendar: NgbCalendar,
              popupService: PopupViewService,
              apiValidator: ApiValidatorService,
              private routerHelper: RouterHelperService,
              permission: PermissionService,
              private vehicleGroupService: VehiclesGroupsService,
              fb: UntypedFormBuilder) {
    super(popupService, apiValidator, fb, permission);
    this.checkPartnerView();
    this.vehicleService = this.partnerView ? this.partnerVehicleService : this.generalVehicleService;
    this.avatars = this.vehicleService.getVehicleAvatarList();
  }

  checkPartnerView() {
    if (this.route && this.route.parent && this.route.parent.parent &&
      this.route.parent.parent.routeConfig && this.route.parent.parent.routeConfig.path &&
      this.route.parent.parent.routeConfig.path.indexOf('partner') !== -1 ) {
        this.partnerView = true;
      }
  }

  buildForm() {
    this.subscriptions.add(this.vehicleGroupService.list$().subscribe(res => {
      this.groups = res;
    }));
    this.forms = {
      main: this.fb.group({
        mark: [null, Validators.required],
        model: [null, Validators.required],
        name: [null, Validators.required],
        numberPlate: [null, Validators.required],
        vin: [null],
        weight: [null],
        length: [null],
        width: [null],
        height: [null],
        frontAxlePressure: [null],
        rearAxlePressure: [null],
        avatarUrl: [null],
        description: [null],
        totalMileage: [null],
        productionDate: [null],
        utilizationStartDate: [null],
        utilizationStartMileage: [null],
        fuelType: [null],
        fuelTankSize: [null],
        engineCapacity: [null],
        status: [null],
      }),
      group: this.fb.control([]),
      driver: this.fb.control(null),
    };
  }

  stopEvents(input: HTMLInputElement) {
    input.blur();
  }

  addRouteSub() {
    console.log(this.route.parent.parent.snapshot.routeConfig.path);
    this.subscriptions.add(this.route.parent.data.subscribe(({vehicle}) => {
      this.vehicle = vehicle;
      this.resetForm();
      this.toggleDisableForm();
    }));
  }

  resetForm() {
    this.forms.main.reset();
    this.forms.main.patchValue(this.vehicle);
    this.forms.group.setValue(this.vehicle.groups.map(group => group.id));
    if (this.vehicle.driver) {
      this.forms.driver.setValue(this.vehicle.driver.id);
    }
    if (this.vehicle) {
      this.apiValidator.setDateFields(this.dateFieldKeys, this.forms.main as UntypedFormGroup, this.vehicle);
    }
  }

  save() {
    let vehicle;
    this.vehicleService.add(this.forms.main.value).pipe(
      tap(res => vehicle = res),
      switchMap(res => this.updateGroupAndDriver())
    ).subscribe((res) => {
      this.pending = false;
      this.vehicleService.updateListSub().subscribe();
      this.routerHelper.navigatePopup(['vehicle', vehicle.id, 'details']);
    }, this.errorHandler.bind(this));
  }

  updateGroupAndDriver() {
    const request = [this.vehicleService.updateGroup(this.vehicle.id, this.forms.group.value || [])
      .pipe(tap(res => this.vehicleGroupService.updateList()))];
    if (this.forms.driver.value) {
      // @ts-ignore
      request.push(this.vehicleService.setDriver(this.vehicle.id, this.forms.driver.value));
    } else {
      // @ts-ignore
      request.push(this.vehicleService.clearDriver(this.vehicle.id));
    }
    return forkJoin(request);
  }

  updateItem(vehicle) {
    this.drivers$.subscribe((res) => {
      const groups = this.forms.group.value;
      Object.assign(this.vehicle, vehicle, {
        driver: this.forms.driver.value ? res.find(({id}) => id === this.forms.driver.value) : null,
        groups: this.groups.filter(({id}) => groups.indexOf(id) !== -1),
      });
      this.vehicleService.sendUpdateEvent(this.vehicle);
    });
  }

  update() {
    this.updateGroupAndDriver().pipe(
      switchMap(() => this.vehicleService.partialUpdate(this.vehicle.id, this.forms.main.value)
        .pipe(tap(res => this.updateItem(res))))
    ).subscribe(res => {
      this.vehicleService.updateListSub().subscribe();
      this.pending = false;
      this.isEditMode = false;
      this.toggleDisableForm();
    }, this.errorHandler.bind(this));
  }

}

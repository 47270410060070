import {
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';

import { Injectable, Injector } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Observable, throwError } from 'rxjs';
import { ConfigService } from './services/config.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  authService: AuthService;

  constructor(private inj: Injector, private config: ConfigService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.authService = this.inj.get(AuthService);
    // TODO: Remove mock
    const requestClone = request.clone({
      setHeaders: [].find(url => request.url.indexOf(url) !== -1) ? {
        'Accept-Language': 'pl',
        'x-mock': '1',
        'Accept': `application/json; version=${this.config.version}`,
      } : {
        'Accept-Language': 'pl',
        'Accept': `application/json; version=${this.config.version}`,
      }
    });

    return next
      .handle(requestClone)
      .pipe(
        catchError((response: any) => {
          if (response instanceof HttpErrorResponse) {
            if (response.status === 401) {
              this.authService.logout();
            } else if (response.status >= 500) {
              console.log('%cBACKEND - 500', 'background: red; color: yellow; font-size: x-large');
            }
          }
          return throwError(response);
        })
      );
  }
}

import { Injectable } from '@angular/core';
import ApiServiceClass from '../../classes/api-service.class';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { CardTransfer } from 'src/app/interfaces/card-transfers';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CardTransferService extends ApiServiceClass<CardTransfer> {
  constructor(config: ConfigService,
              http: HttpClient) {
    super('/card-transfers', config, http);
  }

  listByDriverId(driverId: string): Observable<CardTransfer[]> {
    return this.http.get<CardTransfer[]>(`${this.baseUrl}/driver/${driverId}`);
  }
}

import { Injectable } from '@angular/core';
import ApiServiceClass from '../../classes/api-service.class';
import { WorkSchedule } from '../../interfaces/work-schedule';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WorkScheduleService extends ApiServiceClass<WorkSchedule> {

  constructor(config: ConfigService,
              http: HttpClient) {
    super('/work-schedules', config, http);
  }

  recalculate(parameters) {
    return this.http.post(`${this.baseUrl}/recalculate`, parameters);
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VehiclesGroupsService } from '../../../../core/services/vehicles-groups.service';
import { VehicleGroup } from '../../../../interfaces/group';
import { Subscription } from 'rxjs';
import { PopupNavigationItem } from '../../../../shared/components/popup-view/popup-navigation/popup-navigation.component';
import { PERMISSIONS } from '../../../../core/services/permission.service';

@Component({
  selector: 'app-vehicle-group-popup',
  templateUrl: './vehicle-group-popup.component.html',
  styleUrls: ['./vehicle-group-popup.component.scss']
})
export class VehicleGroupPopupComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  vehicleGroup: VehicleGroup;
  public navItems: PopupNavigationItem[] = [
    {label: 'DETAILS', route: 'details'},
    {label: 'POIS', route: 'poi', permission: PERMISSIONS.VEHICLE_GROUP_UPDATE},

  ];

  constructor(private route: ActivatedRoute,
              private vehicleGroupService: VehiclesGroupsService) {

  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.data
        .subscribe(res => {
          this.vehicleGroup = res.vehicleGroup;
        }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

import { Component, OnInit } from '@angular/core';
import { Languages, UserSettingsService } from '../../../../core/services/user-settings.service';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent implements OnInit {

  public languages = Object.keys(Languages);

  get selectedLanguage() {
    return this.settings.language;
  }

  constructor(private settings: UserSettingsService) {
  }

  ngOnInit() {
  }

  setLanguage(language: string) {
    this.settings.changeLanguage(Languages[language]);
  }
}

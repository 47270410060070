import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TelemetryRide } from '../../../../interfaces/telemetry';

@Component({
  selector: 'app-ride-popup-header',
  templateUrl: './ride-popup-header.component.html',
  styleUrls: ['./ride-popup-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RidePopupHeaderComponent implements OnInit {
  @Input()
  public ride: TelemetryRide;

  constructor() {
  }

  ngOnInit() {
  }

  get vehicle() {
    return {
      ride: this.ride,
    };
  }

  openPoiPopup($event) {

  }

  get location() {
    if (this.ride
      && this.ride.parkData && this.ride.parkData.poi.userPoi) {
      return this.ride.parkData.poi.userPoi.name;
    } else if (this.ride
      && this.ride.parkData && this.ride.parkData.revgeo) {
      return this.ride.parkData.revgeo.label;
    }
  }

  get temperature1() {
    if (!this.ride || !this.ride.parkStartFrame || !this.ride.parkStartFrame.fields.temp1) {
      return null;
    }
    return this.ride.parkStartFrame.fields.temp1;
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { VehicleStatusesColors } from '../../../core/services/telemetry.service';

@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusBarComponent implements OnInit {

  @Input() value = 0;
  @Input() max = 120 * 60;
  @Input() status;
  public color;

  constructor() {
  }


  get bgWidth() {
    const width = this.value / this.max * 100;
    return width > 100 ? 100 : width;
  }

  ngOnInit() {
    this.color = VehicleStatusesColors[this.status] || undefined;
  }

}

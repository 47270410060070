import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NewReportPopupComponent } from './new-report-popup/new-report-popup.component';
import { AuthGuardService } from '../../../core/guards/auth-guard.service';

const routes: Routes = [
  {
    path: 'report/:id',
    component: NewReportPopupComponent,
    outlet: 'popup',
    canActivate: [AuthGuardService],
  },
  {
    path: 'report/:id/:configId',
    component: NewReportPopupComponent,
    outlet: 'popup',
    canActivate: [AuthGuardService],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsPopupRoutingModule {
}

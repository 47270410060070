import { EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, Directive } from '@angular/core';
import { Subscription } from 'rxjs';
import { Filters } from '../../../classes/filters';
import { Vehicle } from '../../../classes/vehicle';

@Directive()
export abstract class CustomSelectDropdown<T> implements OnInit, OnChanges {

  private subscription: Subscription;
  protected filters: Filters;
  public isChecked = false;
  @Input() selected: ({ id: string } & T)[] = [];
  @Input() items: ({ id: string } & T)[];
  @Input() multi = true;
  @Output() close = new EventEmitter();
  @Output() selectChange: EventEmitter<({ id: string } & T)[]> = new EventEmitter();
  public filtered: ({ id: string } & T)[] = [];
  protected selectedFilters;

  constructor() {
  }

  checkValue(event: any) {
    if (event) {
      this.selectChange.emit([...this.filtered]);
    } else {
      this.selectChange.emit(this.selected && this.selected.length > 0 ? this.selected.filter(({id}) => this.filtered
        .findIndex(value => value.id === id) === -1) : null);
    }
  }

  protected initFilters() {
    this.filters = new Filters({
      search: {
        type: 'TEXT',
        fields: ['name', 'model', 'mark']
      },
      group: {
        type: 'GROUPS',
        fields: 'groups'
      },
      favorite: (vehicle: Vehicle, value) => vehicle.isFavorite(),
    });
  }

  ngOnInit() {
    this.initFilters();
    this.filterCollection();
  }

  isSelect(vehicleId: string) {
    return this.selected && this.selected.findIndex(value => value.id === vehicleId) !== -1;
  }

  filterCollection(filters = {}) {
    if (this.items && this.filters) {
      this.filtered = this.items.filter((value) => this.filters.filter(value, filters));
    }

  }

  filtersChange(filters) {
    this.selectedFilters = filters;
    this.filterCollection(filters);
  }

  toggleSelect(vehicle: ({ id: string } & T)) {
    const selected = this.isSelect(vehicle.id)
      ? this.selected.filter(({id}) => id !== vehicle.id) : this.multi ? [...(this.selected || []), vehicle] : [vehicle];
    this.selectChange.emit(selected);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filterCollection(this.selectedFilters);
  }

}

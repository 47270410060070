import { Device } from './../../../interfaces/device';
import { Component, OnInit } from '@angular/core';
import { PopupNavigationItem } from '../../../shared/components/popup-view/popup-navigation/popup-navigation.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Driver } from '../../../interfaces/driver';
import { PERMISSIONS } from '../../../core/services/permission.service';

@Component({
  selector: 'app-device-popup',
  templateUrl: './device-popup.component.html',
  styleUrls: ['./device-popup.component.scss']
})
export class DevicePopupComponent implements OnInit {
  public navItems: PopupNavigationItem[] = [
    {label: 'DETAILS', route: 'details'},
    // {label: 'EXTENSIONS', route: 'extensions'},
    {label: 'COMMANDS', route: 'commands', permission: PERMISSIONS.DEVICE_UPDATE},
  ];
  public device$: Observable<Device> = this.route.data
    .pipe(
      map(({device}: { device: Device }) => device)
    );

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
  }
}

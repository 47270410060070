import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { spinnerShowTrigger } from '../spinners.animation';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [spinnerShowTrigger],
})
export class SpinnerComponent implements OnInit {
  @Input() showSpinner: boolean;
  @Input() size = 'sm';
  @Input() color: 'white' | 'primary' = 'white';

  get sizeClass() {
    return `spinner--${this.size}`;
  }

  constructor() {
  }

  ngOnInit() {
  }

}

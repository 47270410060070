<div class="route-panel-outlet" [@routerTransition]="getState"
     [ngClass]="popupClass">
    <router-outlet #o="outlet" (activate)='onActivate($event)' (deactivate)="onDeactive($event)"
                   name="popup"></router-outlet>
</div>
<div class="route-panel-outlet" [@routerTransition]="getStateInside"
     [ngClass]="popupClass">
  <router-outlet #o="outlet" (activate)='onActivate($event, "inside")' (deactivate)="onDeactive($event,'inside')"
                 name="inside"></router-outlet>
</div>

<router-outlet></router-outlet>

import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationExtras, QueryParamsHandling, Router} from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class RouterHelperService {
  private previousUrl: string;
  private currentUrl: string;
  private lastModules = {};
  private activeModule = null;

  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }

    });
  }

  navigatePopup(url, extras: NavigationExtras = {queryParamsHandling: 'merge'}) {
    return this.router.navigate([{outlets: {popup: url}}], extras);
  }

  navigatePopupExtra(url, extras: NavigationExtras = {queryParamsHandling: 'merge'}) {
    return this.router.navigate([{outlets: {inside: url}}], extras);
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  checkIfVisitedModule(link) {
    this.activeModule = link;
    return this.lastModules[link];
  }

  saveModuleVisit(module) {
    if (this.activeModule) {
      return this.lastModules[this.activeModule] = module;
    }

  }

  getState(key: string): any {
    const curNav = this.router.getCurrentNavigation();
    if (curNav && curNav.extras && curNav.extras.state && curNav.extras.state[key]) {
      return curNav.extras.state[key];
    }
    return null;
  }

  findOutletName(activatedRoute: ActivatedRoute) {
    const outletName = activatedRoute.outlet;
    return outletName !== 'primary' ? outletName : activatedRoute.parent ? this.findOutletName(activatedRoute.parent) : null;
  }

  navigatePopupToOpen(activatedRoute: ActivatedRoute, url: string[], defaultPage?: string) {
    const urlSegment = this.hasPopopChildOpened(activatedRoute) || defaultPage;
    const urltoNav = urlSegment ? [...url, urlSegment] : url;
    this.navigatePopup(urltoNav, {
      queryParamsHandling: 'merge',
    });
  }

  findPopupOutlet(route: ActivatedRoute) {
    return route.children.find(({outlet}) => outlet === 'popup');
  }

  queryParams(route, queryParams, queryParamsHandling: QueryParamsHandling = 'merge') {
    this.router.navigate([], {
      queryParams,
      queryParamsHandling,
      relativeTo: route,
    });
  }

  hasPopopChildOpened(activatedRoute: ActivatedRoute) {
    const popup = this.findPopupOutlet(activatedRoute.root);
    if (popup) {
      const lastChildren = this.findLastRoute(popup);
      return lastChildren && lastChildren.snapshot.url.length ? lastChildren.snapshot.url[0].path : null;
    }
    return null;
  }

  findLastRoute(route: ActivatedRoute) {
    return route.children.length ? this.findLastRoute(route.children[0]) : route;
  }

  navigateOutlet(url: string, outletName: string, outletUrl: string | string[]) {
    return this.router.navigate([{outlets: {[outletName]: outletUrl}}]);
  }

  public navugateByUrl(url: string) {
    return this.router.navigateByUrl(url);
  }

  public routeUrlFromParent(activatedRoute: ActivatedRoute) {
    const parent = activatedRoute.parent;
    return (parent.firstChild && parent.firstChild.outlet === 'primary'
      ? parent.firstChild.pathFromRoot : parent.pathFromRoot)
      .filter(({snapshot}) => snapshot.url.length)
      .map(({snapshot}) => snapshot.url)
      .join('/');

  }
}

import { Area } from './../../../../../interfaces/poi';
import { MapService, IGeocodedSuggestion } from './../../map.service';
import { ChangeDetectionStrategy, Component, EventEmitter, Injectable, Input, OnInit, Output, OnChanges } from '@angular/core';
import { Observable, of, OperatorFunction, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { WikipediaService } from 'src/app/core/services/wikipedia.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import {MapGeoService} from "../../map-geo.service";


export enum EChangeSource {
  SHOW_REV_ISO,
  SUGGESTION_SELECTED
}

@Component({
  selector: 'app-map-toolbox-search',
  templateUrl: './map-toolbox-search.component.html',
  styleUrls: ['./map-toolbox-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapToolboxSearchComponent implements OnInit {
  @Input() icon: string;
  @Input() label: string;
  @Input() isOpen: boolean;
  @Input() enableRevIso = true;

  @Output() suggestionSelected = new EventEmitter<IGeocodedSuggestion>();
  @Output() reverseIsolineLoaded = new EventEmitter<Area>();

  model: any;
  searching = false;
  searchFailed = false;
  showReverseIsoline = false;
  rangeTypeItems = [{ label: 'DISTANCE', value: 'distance'}, { label: 'TRAVEL_TIME', value: 'time'}];
  geocodedSuggestion: IGeocodedSuggestion = null;
  public reverseIsolineForm: UntypedFormGroup = null;
  formSubscription$: Subscription;

  constructor(
    private _service: WikipediaService,
    private mapGeoService: MapGeoService,
    private fB: UntypedFormBuilder,
    private mapService: MapService) {
  }

  ngOnInit() {
    this.initForm();
    this.bindForm();
  }

  initForm() {
    this.reverseIsolineForm  = this.fB.group({
      rangeType: 'time',
      rangeValue: 10,
      traffic: true,
    });
  }

  bindForm() {
    if (this.reverseIsolineForm) {
      this.formSubscription$ = this.reverseIsolineForm.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged()
      ).subscribe(val => {
        if (this.reverseIsolineForm.valid) {
          this.revIsoParamsChanged(val);
        }
      });
    }
  }

  value(key) {
    return this.reverseIsolineForm.get(key).value;
  }

  changeState(value) {
    this.showReverseIsoline = value;
    this.reverseIsolineLoaded.emit(null);
    if (this.showReverseIsoline && this.geocodedSuggestion) {
      this.revIsoParamsChanged(this.reverseIsolineParams);
    }
  }

  search: OperatorFunction<string, string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.mapService.autocompleteSearch(term).pipe(
          tap(() => this.searchFailed = false),
          map((res) => res.items),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )

  itemSelected($event) {
    // alert(JSON.stringify($event));
    this.mapGeoService.geocode( $event.address.label).subscribe(res => {
      this.geocodedSuggestion = {
        ...$event.address,
        coordinates: res,
      };
      this.suggestionSelected.emit(this.geocodedSuggestion);
      this.reverseIsolineLoaded.emit(null);
      if (this.showReverseIsoline) {
        this.revIsoParamsChanged(this.reverseIsolineParams);
      }
    }, () => {
    });
  }

  formatter = (x: {address: { label: string }}) => x.address.label;

  get reverseIsolineParams() {
    return this.reverseIsolineForm ? this.reverseIsolineForm.getRawValue() : null;
  }


  revIsoParamsChanged(value) {
    if (!this.geocodedSuggestion) {
      return;
    }
    this.mapService.reverseIsoline(
      this.geocodedSuggestion.coordinates,
      value.rangeType,
      value.rangeValue,
      value.traffic)
    .subscribe(isoRes => {
      this.reverseIsolineLoaded.emit(isoRes);
      console.log(JSON.stringify(isoRes));
    });
  }

}

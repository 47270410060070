<app-dropdown-card (close)="close.emit()" label="{{'SEARCH_' + dimension|translate}}">
    <ng-container dropdown-card-header>
        <app-vehicle-seelct-dropdown-filters (filterChange)="filtersChange($event)" [dimension]="dimension" [clientsFilter]="clientsFilter">
            <div class="form-checkbox form-white" *ngIf="!clientsFilter">
                <label for="select_all_vehicles">
                    <input type="checkbox" id="select_all_vehicles" [(ngModel)]="isChecked" (change)="checkValue(isChecked)">
                    <span></span>
                </label>
            </div>
        </app-vehicle-seelct-dropdown-filters>
    </ng-container>
    <div class="vehicles-list">
        <div class="form-checkbox" *ngFor='let vehicle of filtered' (click)="toggleSelect(vehicle)">
            <input id="item-{{vehicle.id}}" type="checkbox" [ngModel]="isSelect(vehicle.id)"
                   [ngModelOptions]="{standalone: true}"/><span></span>
            {{getLabel(vehicle)}}
            <i class="c-favorite-icon icon-star-full" *ngIf="!clientsFilter && vehicle.isFavorite && vehicle.isFavorite()"></i>
        </div>
    </div>
</app-dropdown-card>

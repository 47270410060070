<div class="c-map-marker">
    <div class="c-marker__bg" [style.background]="color" [style.width.%]="circleSize"
         [style.height.%]="circleSize"></div>
    <app-icon-vehicle [ignition]="vehicle.telemetry.ignition" [color]="color" size="24" [avatarUrl]="vehicle.avatarUrl"></app-icon-vehicle>
    <i class="icon-marker-arrow" [ngStyle]="avatarPosition" [style.color]="color"
       *ngIf="vehicle.telemetry.ignition"
       [style.transform]="'rotate(' + bearing + 'deg)'"></i>
    <div class="c-map-marker__label">

    </div>
</div>
<div class="c-marker__label-wrapper">
    <div class="c-marker__label" [style.background]="color">
        {{label}}
    </div>
</div>



import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnDestroy } from '@angular/core';
import { MarkerClass } from '../marker.class';
import { MapService } from '../map.service';
import { RideType } from '../../../../interfaces/ride';
import { VehicleStatuses, VehicleStatusesColors } from '../../../../core/services/telemetry.service';
import { TelemetryVehicle } from '../../../../interfaces/telemetry';
import { DateTime } from 'luxon';
import {MapGeoService} from "../map-geo.service";

@Component({
  selector: 'app-map-marker-ride-icon',
  templateUrl: './map-marker-ride-icon.component.html',
  styleUrls: ['./map-marker-ride-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapMarkerRideIconComponent extends MarkerClass implements OnDestroy {


  @Input() status: VehicleStatuses;
  @HostBinding('class')
  @Input() type: RideType;
  @Input() data: TelemetryVehicle;
  addEvents = true;
  markerGroupName: string = 'MapMarkerRideIconComponent';
  get color() {
    return this.type === RideType.START ? 'black' : VehicleStatusesColors[this.status];
  }
  private _location: string = null;

  constructor(
    mapService: MapService,
    protected cd: ChangeDetectorRef,
    private mapGeoService: MapGeoService,
    el: ElementRef) {
    super(mapService, el);
  }

  getTemperature() {
    return this.data.temp1 ? ` (${this.data.temp1} °C)` : '';
  }

  time() {
    if (!this.data.eventDate) {
      return '';
    }
    const format = DateTime.TIME_24_SIMPLE;
    const time = DateTime.fromISO(this.data.eventDate).toLocaleString(format);
    return `${time} ${this._location ?  this._location : ''}, ${this.data.speed} km/h${this.getTemperature()}`;
  }
  domIconOptions() {
    return {
      onAttach: (clonedElement, domIcon, domMarker) => {
        clonedElement.addEventListener('mouseenter', this.markerHover.bind(this));
      },
      onDetach: (clonedElement, domIcon, domMarker) => {
        clonedElement.removeEventListener('mouseenter', this.markerHover.bind(this));
      }
    };
  }
  markerHover() {
    if (this._location) {
      return;
    }

    this.mapGeoService
      .reverseGeo(this.data.position.coordinates)
      .subscribe(res => {
        if (!this.cd['destroyed']) {
          this._location = res;
          this.cd.detectChanges();
          this.updateMarker();
        }
      });
  }

  getCoords() {
    if (this.type === RideType.PARK) {
      return null;
    }
    return this.data.position.coordinates ? this.data.position.coordinates : null;
  }

}

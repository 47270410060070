import { FuelType } from './../../../../interfaces/vehicle';
import { PERMISSIONS, PermissionService } from 'src/app/core/services/permission.service';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { WorkVehicleWarehouse } from '../../../../core/services/vehicles.service';
import { slideInOutTrigger } from '../../../../core/animations';
import { VehicleHistoryCollection } from '../route-history.component';

@Component({
  selector: 'app-route-history-table',
  templateUrl: './route-history-table.component.html',
  styleUrls: ['./route-history-table.component.scss'],
  animations: [slideInOutTrigger],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteHistoryTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() collection: VehicleHistoryCollection[];
  @Input() aggregationSum: WorkVehicleWarehouse;
  @Input() selected: string;
  @Input() isDriver = false;
  @Input() vehicleFuelType: FuelType;
  @Input() noCacheExtensions: any = null;
  @Input() isParkSelected;
  @Output() showAggregationSum = new EventEmitter();
  @Output() showDaily = new EventEmitter();
  @Output() refreshView = new EventEmitter();
  public isOpen = true;
  @Input()
  public initActiveRow: string;
  public activeRowId = null;
  public configPS = {
    suppressScrollY: false,
  };

  constructor(private cd: ChangeDetectorRef, private permissionService: PermissionService) {
  }

  handlerShowDailyRide(item) {
    this.showDaily.emit({
      item,
      type: 'dailySum'
    });
  }

  handlerShowOneRide(item, park = false) {

    // ride was clicked and no ride trace is available skip it
    if (!park && this.permissionService.hasPermission(PERMISSIONS.RIDES_NODATA)) {
      return;
    }
    this.showDaily.emit({
      item,
      type: 'oneRide',
      park,
    });
  }

  handlerShowAllRide() {
    this.showAggregationSum.emit(this.aggregationSum);
  }

  rolloverToggle(id: string) {
    if (this.activeRowId === id) {
      this.activeRowId = null;
    } else {
      this.activeRowId = id;
    }
    this.cd.detectChanges();
  }

  toggleOpenState() {
    this.isOpen = !this.isOpen;
    this.activeRowId = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initActiveRow) {
      this.activeRowId = this.initActiveRow;
    }
    this.cd.detectChanges();
  }

  ngAfterViewInit(): void {
    this.activeRowId = this.initActiveRow;
   // this.cd.detach();
  }

  ngOnInit(): void {
  }

  get fuelDimmension() {
    return this.noCacheExtensions && this.noCacheExtensions.FUEL &&
      this.noCacheExtensions.FUEL.dimmension === 'DURATION' ? 'DURATION' : 'DISTANCE';
  }
}

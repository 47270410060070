<section class="d-flex justify-content-between align-items-stretch p-xm">
    <div class="d-flex flex-column justify-content-between">
        <div class="d-flex justify-content-start align-items-center mb-xm">
            <app-vehicle-status-label
                    [status]="ride.status.type"></app-vehicle-status-label>
            <div class="ml-xm">
                <app-from-to [from]="ride.parkStartFrame.eventDate"
                             [to]="ride.parkEndFrame.eventDate"
                             showType="one_day"
                ></app-from-to>
            </div>

        </div>
        <app-localization (poiClick)="openPoiPopup($event)" type="telemetry" [data]="ride"
                          [ellipsis]="true" [break]="true"></app-localization>
        <div *ngIf="temperature1">
          <span translate>TEMPERATURE</span> 1: {{temperature1}} °C
        </div>

    </div>
    <div class="d-flex flex-column justify-content-between align-items-end">
        <div class="mb-xm d-flex justify-content-end align-items-center">
            <app-label-with-tags
                    [label]="ride.vehicle.name"
                    [tags]="ride.vehicleGroups"
            >

            </app-label-with-tags>
            <div class="ml-xm">
                <app-vehicle-avatar [avatar]="ride.vehicle.avatarUrl"></app-vehicle-avatar>
            </div>
        </div>

        <app-driver-details [driver]="ride.driver"></app-driver-details>
    </div>

</section>

import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent implements OnInit {
  @Input() avatar: string = null;
  @Input() size = 40;
  @Input() initials: string;
  @Input() icon;
  @HostBinding('class.round')
  @Input() round = false;

  constructor(private sanitization: DomSanitizer) {
  }

  get avatarUrl() {
    if (this.avatar) {
      return this.sanitization.bypassSecurityTrustStyle(`url(${this.avatar})`);
    }
    return '';
  }

  ngOnInit() {
  }

}

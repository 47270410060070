import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ReminderCriteria } from '../../../../../../interfaces/reminder';

@Component({
  selector: 'app-vehicle-service-reminder-criteria',
  templateUrl: './vehicle-service-reminder-criteria.component.html',
  styleUrls: ['./vehicle-service-reminder-criteria.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleServiceReminderCriteriaComponent implements OnInit {
  @Input() criteria: ReminderCriteria;

  constructor() {
  }

  ngOnInit() {
  }


  parseDateIteration(dateIterationString: string): string {
    if (!dateIterationString) {
      return '-';
    }

    const yIndex = dateIterationString.indexOf('Y');
    const mIndex = dateIterationString.indexOf('M');
    const dIndex = dateIterationString.indexOf('D');

    const yValue = dateIterationString.substring(0, yIndex);
    if (yValue !== '0') {
      return yValue + 'Y';
    }

    const mValue = dateIterationString.substring(yIndex + 1, mIndex);
    if (mValue !== '0') {
      return mValue + 'M';
    }

    const dValue = dateIterationString.substring(mIndex + 1, dIndex);
    if (dValue !== '0') {
      return dValue + 'D';
    }

    return '-';
  }
}

import { VehicleExtensionsComponent } from './vehicle-extensions/vehicle-extensions.component';
import { VehicleChartComponent } from './vehicle-chart/vehicle-chart.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {PartnerVehiclePopupComponent, VehiclePopupComponent} from './vehicle-popup.component';
import { VehicleResolve, PartnerVehicleResolve } from './vehicle.resolve';
import {VehicleHistoryComponent} from './vehicle-history/vehicle-history.component';
import {VehicleStatisticComponent} from './vehicle-statistic/vehicle-statistic.component';
import {VehicleFuelComponent} from './vehicle-fuel/vehicle-fuel.component';
import {VehicleDetailsComponent} from './vehicle-details/vehicle-details.component';
import {AuthGuardService} from '../../../core/guards/auth-guard.service';
import {VehicleNewComponent} from './vehicle-new/vehicle-new.component';
import {VehiclePoiComponent} from './vehicle-poi/vehicle-poi.component';
import {VehicleServiceComponent} from './vehicle-service/vehicle-service.component';
import {PermissionsRedirectGuard} from '../../../core/guards/permissions_redirect.guard';
import {PERMISSIONS} from '../../../core/services/permission.service';
import {PermissionsGuard} from '../../../core/guards/permissions.guard';

const routes: Routes = [
  {
    path: 'vehicle',
    outlet: 'popup',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: VehicleNewComponent,
        canActivate: [PermissionsGuard],
        data: {
          permissions: [PERMISSIONS.VEHICLE_CREATE],
        }
      },
      {
        path: ':id',
        component: VehiclePopupComponent,
        resolve: {
          vehicle: VehicleResolve
        },
        children: [
          {
            path: 'history',
            component: VehicleHistoryComponent
          },
          {
            path: 'service',
            component: VehicleServiceComponent,
          },
          {
            path: 'statistics',
            component: VehicleStatisticComponent,
            canActivate: [PermissionsRedirectGuard],
            data: {
              permissions: [PERMISSIONS.BI_VIEW]
            }
          },
          {
            path: 'fuel',
            component: VehicleFuelComponent,
          },
          {
            path: 'chart',
            component: VehicleChartComponent,
          },
          {
            path: 'poi',
            component: VehiclePoiComponent,
          },
          {
            path: 'details',
            component: VehicleDetailsComponent
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'statistics'
          },
        ]
      },
    ]
  },
  {
    path: 'partner-vehicle',
    outlet: 'popup',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: VehicleNewComponent,
        canActivate: [PermissionsGuard],
        data: {
          permissions: [PERMISSIONS.VEHICLE_CREATE],
        }
      },
      {
        path: ':id',
        component: PartnerVehiclePopupComponent,
        resolve: {
          vehicle: PartnerVehicleResolve
        },
        children: [
          // {
          //   path: 'details',
          //   component: VehicleDetailsComponent
          // },
          {
            path: 'extensions',
            component: VehicleExtensionsComponent
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'extensions'
          },
          {
            path: '**',
            pathMatch: 'full',
            redirectTo: 'extensions'
          },
        ]
      },
    ]
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [VehicleResolve, PartnerVehicleResolve],
})
export class VehiclePopupRoutingModule {
}

import { Component, HostBinding, OnInit } from '@angular/core';
import { enterFromTopTrigger } from '../../../../core/animations';
import { PerspectiveMenuService } from '../../perspective-menu/perspective-menu.service';

@Component({
  selector: 'app-top-panel-menu',
  templateUrl: './top-panel-menu.component.html',
  styleUrls: ['./top-panel-menu.component.scss'],
  animations: [enterFromTopTrigger],
})
export class TopPanelMenuComponent implements OnInit {
  @HostBinding('@enterFromTop') true;

  constructor(private perspectiveMenuService: PerspectiveMenuService) {
  }

  get menu() {
    return this.perspectiveMenuService.menu;
  }

  ngOnInit() {
  }

}

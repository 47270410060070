<ul class="c-popup-nav flex-row d-flex justify-content-start align-items-center">
    <ng-container *ngFor="let route of items">
        <li *ngIf="hasPermission(route)">
            <a [routerLink]="route.route" queryParamsHandling="merge"
               [routerLinkActiveOptions]="{ exact: !!route.exact, __change_detection_hack__: route.route }"
               [routerLinkActive]="'c-popup-nav--active'">
                <span translate>{{route.label}}</span>
                <app-badge [value]="3" color="red" *ngIf="route.badge"></app-badge>
            </a></li>
    </ng-container>
</ul>

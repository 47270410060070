<div class="d-flex align-items-center justify-content-start">
    <p *ngIf="showType==='one_day'" class="mr-xs">{{dateLabel('from')}}</p>
    <div class="c-from-to__border"></div>
    <div [ngSwitch]="showType" class="c-from-to__dates">
        <ng-container *ngSwitchCase="'days'">
            <ng-container *ngTemplateOutlet="dateTime; context: getContext('from')"></ng-container>
            <ng-container *ngTemplateOutlet="dateTime; context: getContext('to')"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'range'">
            <ng-container *ngTemplateOutlet="dateTime; context: getContextRange('from')"></ng-container>
            <ng-container *ngTemplateOutlet="dateTime; context: getContextRange('to')"></ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <p>{{getTime('from')}}</p>
            <p>{{getTime('to')}}</p>
        </ng-container>
    </div>
</div>


<ng-template #dateTime let-value>
    <p class="d-flex justify-content-between align-items-center">
        <span>{{value?.first}}</span>
        <span class="c-from-to__secondary" [innerHTML]="value?.second"></span>
    </p>
</ng-template>

<div>
  <form [formGroup]="form" (ngSubmit)="save()" autocomplete="off">
    <div class="row">
      <div class="col-5">
        <app-form-group>
          <label translate>CODE</label>
          <input app-form formControlName="cardNumber">
        </app-form-group>
      </div>
      <ng-container [ngSwitch]="!!cardTransfer" >
        <div class="col-5" *ngSwitchCase="true">
          <app-form-group (click)="d.open()">
            <label translate>RETURN_DATE</label>
            <input formControlName="returnDate"
                   navigation="arrows"
                   outsideDays="collapsed"
                   #input
                   app-form
                   (focus)="stopEvents(input)"
                   autoClose="outside"
                   placement="bottom-right"
                   ngbDatepicker #d="ngbDatepicker"/>
          </app-form-group>
        </div>
        <div class="col-5" *ngSwitchDefault>
          <app-form-group (click)="d.open()">
            <label translate>TRANSFER_DATE</label>
            <input formControlName="transferDate"
                   navigation="arrows"
                   outsideDays="collapsed"
                   #input
                   app-form
                   (focus)="stopEvents(input)"
                   autoClose="outside"
                   placement="bottom-right"
                   ngbDatepicker #d="ngbDatepicker"/>
          </app-form-group>
        </div>
      </ng-container>

      <div class="py-m d-flex justify-content-between align-items-center">
        <button class="btn btn-primary btn-border w-100 mr-m" (click)="handlerCancel()" translate
                type="button">
          CANCEL
        </button>
        <button class="btn btn-primary w-100" [disabled]="pending" type="submit">
          <app-spinner [showSpinner]="pending"></app-spinner>
          {{(!cardTransfer ? 'SAVE' : 'EDIT')|translate}}
        </button>
      </div>

    </div>


  </form>
</div>

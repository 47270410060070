import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {ConfigService} from '../../../core/services/config.service';
import {MapService} from './map.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('map', { static: true })
  public mapElement: ElementRef;

  @Input()
  public lat: any = 52.221861;

  @Input()
  public lng: any = 19.3772523;

  @Input()
  public width: any;

  @Input()
  public height: any;

  @Input()
  public id = 'map_default';

  @Input()
  public boundOnInit = true;
  @Input()
  public padding: H.map.ViewPort.Padding;
  private map: H.Map;
  private mapUi: H.ui.UI;
  private isDown = false;
  private zoom: number | undefined;
  private offset: { x: number, y: number };
  private startPosition: H.math.Point;

  constructor(private config: ConfigService, private mapService: MapService, private el: ElementRef) {
  }

  ngOnInit() {
  }

  public ngAfterViewInit() {
    this.map = this.mapService.newMap(this.mapElement.nativeElement, {
      padding: this.padding,
      center: {lat: this.lat, lng: this.lng},
      zoom: 10
    }, this.id);
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.dispose();
      this.mapService.destroyMap();
    }
  }


  onMouseMove($event: MouseEvent) {

    if (this.isDown) {
      const offsetX = this.offset.x - $event.clientX;
      const offsetY = this.offset.y - $event.clientY;
      this.offset.x = $event.clientX;
      this.offset.y= $event.clientY;
      this.mapService.mapInstance.setCenter(this.mapService.mapInstance.screenToGeo(this.startPosition.x + offsetX, this.startPosition.y + offsetY), false)
    }
  }

  onWheel($event: WheelEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    let zoom = this.mapService.mapInstance.getZoom();
    zoom += $event.deltaY * -0.01;

    // Restrict scale
    zoom = Math.min(Math.max(5, zoom), 20);
    this.mapService.mapInstance.setZoom(zoom);
    // const viewMOdel =  this.mapService.mapInstance.getViewModel();
    // viewMOdel.startControl(null,$event.clientX, $event.clientY )
    // viewMOdel.control(0, 0, 6, 0,0,0 );
    // viewMOdel.endControl(!0, (data)  =>data.zoom = zoom )

  }

  onMouseDown($event: MouseEvent) {
    console.log('mousedown');
    this.isDown = true;
    this.offset = {
      x: $event.clientX,
      y: $event.clientY,
    }
    console.log($event);
    this.startPosition = this.mapService.mapInstance.geoToScreen(this.mapService.mapInstance.getCenter())
    console.log(this.startPosition);
  }

  onMouseLeave($event: MouseEvent) {
    this.isDown = false;
  }

  onContexMenu($event: MouseEvent) {
    console.log('onContexMenu');
    $event.preventDefault();
    this.isDown = false;
    this.mapService.mapInstance.dispatchEvent(new H.util.Event('contextmenu', $event));
  }
}

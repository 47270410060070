import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { TableCellType } from '../data-grid.module';

@Component({
  selector: 'app-data-grid-row',
  templateUrl: './data-grid-row.component.html',
  styleUrls: ['./data-grid-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataGridRowComponent implements OnInit {
  @Input() type: TableCellType = 'body';
  constructor(public el: ElementRef) { }

  ngOnInit() {
  }

}

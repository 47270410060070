import { NgModule } from '@angular/core';

import { WorkSchedulesPopupRoutingModule } from './work-schedules-popup-routing.module';
import { WorkSchedulesPopupComponent } from './work-schedules-popup.component';
import { SharedModule } from '../../../shared/shared.module';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [WorkSchedulesPopupComponent],
  imports: [
    SharedModule,
    NgbDatepickerModule,
    WorkSchedulesPopupRoutingModule
  ]
})
export class WorkSchedulesPopupModule {
}

import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { MapService } from '../map.service';
import { Area } from '../../../../interfaces/poi';
import { hexToRgba } from '../../../../utils/utils';
import { ZONE_DEFAULT_COLOR } from '../../../../views/dashboard/zones/zones.service';

@Component({
  selector: 'app-map-polygon',
  templateUrl: './map-polygon.component.html',
  styleUrls: ['./map-polygon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapPolygonComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  private subsciption$: Subscription = new Subscription();
  private polygon: H.map.Polygon;
  @Input() area: Area;
  @Input() color: string;
  @Input() zoneStyle = false;
  @Input() boundToPoly = true;

  constructor(private mapService: MapService) {
  }

  ngOnInit() {
    this.subsciption$.add(this.mapService.mapIsLoad.subscribe(() => {
      this.initPolygon();
    }));
  }

  get styleColor() {
    return this.color || ZONE_DEFAULT_COLOR;
  }

  initPolygon() {
    if (this.polygon) {
      this.removePolygon();
    }
    if (this.area.type === 'Point') {
      return;
    }
    this.polygon = this.mapService.readGeoJSON(this.area, this.polyStyle())[0] as H.map.Polygon;
    this.mapService.mapInstance.addObject(this.polygon);
    if (this.boundToPoly) {
      this.mapService.setViewBounds(this.polygon.getBoundingBox());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.area && !changes.area.firstChange) {
      this.initPolygon();
    }
  }

  ngAfterViewInit(): void {
  }

  defaultStyle() {
    return {
      lineWidth: '0',
      lineJoin: 'round',
      fillColor: 'rgb(240, 125, 125, .4)',
      strokeColor: '#F07D7D',
    };
  }

  polyStyle(): any {
    if (this.zoneStyle) {
      return {
        lineWidth: 3,
        lineJoin: 'miter',
        lineCap: 'square',
        lineDash: [10],
        fillColor: hexToRgba(this.styleColor, 0.1),
        strokeColor: this.styleColor,
      };
    }
    return this.defaultStyle();
  }

  removePolygon() {
    if (this.polygon) {
      this.polygon.dispose();
    }

    // if (this.mapService.mapInstance) {
    //   this.mapService.mapInstance.removeObject(this.polygon);
    // }
  }

  ngOnDestroy(): void {
    this.removePolygon();
    this.subsciption$.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { ResolveClass } from '../../../classes/resolver.class';
import { ZonesService } from '../../dashboard/zones/zones.service';
import { Zone } from '../../../interfaces/zone';

@Injectable()
export class ZoneResolve extends ResolveClass<Zone> {
  constructor(service: ZonesService) {
    super(service);
  }
}

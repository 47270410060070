import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {filter, finalize, map, switchMap} from 'rxjs/operators';
import { Reminder } from '../../../../interfaces/reminder';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CardTransferService } from 'src/app/core/services/card-transfers.service';
import { Driver } from 'src/app/interfaces/driver';
import {CardTransfer} from '../../../../interfaces/card-transfers';

@Component({
  selector: 'app-driver-code-tracking',
  templateUrl: './driver-code-tracking.component.html',
  styleUrls: ['./driver-code-tracking.component.scss']
})
export class DriverCodeTrackingComponent implements OnInit, OnDestroy {
  public addNew = false;
  public lastCardReturned = false;
  public editId: string;
  private remindersSub = new BehaviorSubject(null);
  // tslint:disable-next-line: max-line-length
  public reminders$ = this.remindersSub.asObservable().pipe(
    filter(res => !!res),
    switchMap((id) => this.codeAssignmentService.listByDriverId(this.driver.id).pipe(
      map(data => {
        data.sort((a, b) => a.transferDate < b.transferDate ? 1 : -1);
        if (!data || data.length === 0 || data[0].returnDate) {
          this.lastCardReturned = true;
        }
        return data;
      }),
      finalize(() => {
        this.pending = false;
      }))));
  protected subscriptions = new Subscription();
  public driver: Driver;
  public pending = true;
  constructor(private codeAssignmentService: CardTransferService,
              protected modal: NgbModal,
              protected route: ActivatedRoute) {
  }

  ngOnInit() {
    this.routeSub();
  }

  protected routeSub() {
    this.subscriptions.add(this.route.parent.data.subscribe(({driver}) => {
      this.driver = driver;
      this.remindersSub.next('SiEmAnKo');
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handlerAdd() {
    this.addNew = true;
  }

  addUpdate($event) {
    if ($event) {
      this.remindersSub.next(this.driver.id);
      this.lastCardReturned = false;
    }
    this.addNew = false;
  }

  reminderHasUpdate($event, card: CardTransfer) {
    if ($event) {
      Object.assign(card, $event);
      this.lastCardReturned = true;
    }
    this.editId = null;
  }

}

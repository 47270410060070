import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown-card',
  templateUrl: './dropdown-card.component.html',
  styleUrls: ['./dropdown-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownCardComponent implements OnInit {
  @Input() label: string;
  @Output() close = new EventEmitter();
  constructor() {
  }

  ngOnInit() {
  }

}

import {
  AfterViewInit,
  Directive,
  ElementRef, HostListener,
  OnChanges, OnDestroy, Renderer2,
  SimpleChanges,
} from '@angular/core';
import {fromEvent, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';

@Directive({
  selector: '[resizer]'
})
export class ResizerDirective implements OnChanges, OnDestroy, AfterViewInit {
  private parentElement: HTMLElement;
  private isMove = false;
  private startWidth: number = null;
  private startEditWidth: number;
  private startX: number;
  private subscriptions = new Subscription();

  @HostListener('mousedown', ['$event']) onMouseDown(e: MouseEvent) {
    if (this.parentElement) {
      if (this.startWidth === null) {
        this.startWidth = this.parentElement.offsetWidth;
      }
      this.startEditWidth = this.parentElement.offsetWidth;
      this.isMove = true;
      this.startX = e.pageX;
      this.renderer.addClass(this.parentElement, 'resize');
    }
  }


  constructor(
    private element: ElementRef<HTMLElement>,
    private renderer: Renderer2,
  ) {
  }

  onMouseMove = (pointX: number) => {
    if (this.parentElement && this.isMove) {
      const startWidth = this.startEditWidth;
      const offset = this.startX - pointX;
      const newWidth = startWidth + offset;
      if (newWidth > this.startWidth && newWidth < window.innerWidth - 10) {
        this.renderer.setStyle(this.parentElement, 'width', newWidth + 'px');
      }
    }

  };
  onMouseUp = () => {
    this.isMove = false;
    this.renderer.removeClass(this.parentElement, 'resize');
  };

  private updateView() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateView();
  }

  ngAfterViewInit(): void {
    this.parentElement = this.element.nativeElement.closest('ng-component') as HTMLElement;
    if (this.parentElement) {
      this.subscriptions.add(fromEvent(document, 'mousemove').pipe(map((e: MouseEvent) => e.pageX)).subscribe(this.onMouseMove));
      this.subscriptions.add(fromEvent(document, 'mouseup').subscribe(this.onMouseUp));
    }
  }

  ngOnDestroy(): void {
    this.clear();
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  clear() {
    if (this.parentElement) {
      this.renderer.removeClass(this.parentElement, 'resize');
      this.renderer.removeStyle(this.parentElement, 'width');
    }
  }
}

import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true
    }
  ]
})
export class NumberInputComponent implements ControlValueAccessor {
  public isDisabled = false;
  public numberValue = null;
  @Input() minValue;
  @Input() label;

  constructor() {
  }

  set value(val) {
    this.numberValue = val;
    this.onTouch();
    this.onChange(this.numberValue);
  }

  get value() {
    return this.numberValue;
  }

  onChange: any = () => {
  }
  onTouch: any = () => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.numberValue = obj;
  }

  increment() {
    if (this.isDisabled) {
      return;
    }
    if (!this.numberValue && this.numberValue !== 0) {
      this.value = 1;
    } else {
      ++this.value;
    }
  }

  decrement() {
    if (this.isDisabled) {
      return;
    }
    if (this.numberValue > this.minValue || (!this.minValue && this.minValue !== 0)) {
      --this.value;
    }
  }

  stopEvent(input: HTMLInputElement) {
    input.blur();
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Vehicle} from '../../../../interfaces/vehicle';
import {Subscription} from 'rxjs';
import {RouterHelperService} from '../../../../core/services/router-helper.service';

@Component({
  selector: 'app-vehicle-history',
  templateUrl: './vehicle-history.component.html',
  styleUrls: ['./vehicle-history.component.scss'],
})
export class VehicleHistoryComponent implements OnInit, OnDestroy {
  public vehicle: Vehicle;
  private subscriptions: Subscription = new Subscription();

  constructor(private route: ActivatedRoute, private routeHelper: RouterHelperService) {
  }

  ngOnInit() {
    this.subscriptions.add(this.route.parent.data.subscribe(({vehicle}) => {
      this.vehicle = vehicle;
    }));
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output
} from '@angular/core';
import { FuelType } from 'src/app/interfaces/vehicle';
import { WorkVehicleWarehouse } from '../../../../core/services/vehicles.service';


@Component({
  selector: '[app-route-history-table-row]',
  templateUrl: './route-history-table-row.component.html',
  styleUrls: ['./route-history-table-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteHistoryTableRowComponent {
  @Input() isOpen = false;
  @Input() data: WorkVehicleWarehouse;

  @HostBinding('class.sum-row')
  @Input() aggregationRow = false;
  @Output() rollover = new EventEmitter();
  @HostBinding('class.is-selected')
  @Input() isSelected = false;
  @Input() isDriver = false;
  @Input() fuelDimmension = 'DISTANCE';
  @Input() vehicleFuelType: FuelType;

  get startLocalization() {
    return this.data.RIDE_START_POI
      ? this.data.RIDE_START_POI[0].v.name
      : this.data.RIDE_START_REVGEO
        ? this.data.RIDE_START_REVGEO[0].v : '-';
  }

  get endLocalization() {
    return this.data.PARK_POI
      ? this.data.PARK_POI[0].v.name
      : this.data.PARK_REVGEO
        ? this.data.PARK_REVGEO[0].v : '-';
  }

  handlerRolloverToggle($event) {
    $event.stopPropagation();
    if (!this.aggregationRow) {
      this.rollover.emit();
    }
  }

}

import {Injectable} from '@angular/core';
import ApiServiceClass from '../../classes/api-service.class';
import {ConfigService} from './config.service';
import {HttpClient} from '@angular/common/http';
import {TelemetryRide, TelemetryVehicle, TelemetryVehicles} from '../../interfaces/telemetry';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DateTime} from 'luxon';
import {PoiType} from '../../interfaces/poi';

export enum VehicleStatuses {
  CUSTOMER_PARK = 'CUSTOMER_PARK',
  BRANCH_WORK_PARK = 'BRANCH_WORK_PARK',
  OTHER_WORK_PARK = 'OTHER_WORK_PARK',
  NONWORK_PARK = 'NONWORK_PARK',
  BUSINESS_RIDE = 'BUSINESS_RIDE',
  PRIVATE_RIDE = 'PRIVATE_RIDE',
}

export enum VehicleStatusesColors {
  PRIVATE_RIDE = '#B3B3B3',
  CUSTOMER_PARK = '#5EB9FF',
  BRANCH_WORK_PARK = '#B45EFF',
  OTHER_WORK_PARK = '#F07D7D',
  NONWORK_PARK = '#B3B3B3',
  BUSINESS_RIDE = '#6FD69A',
}

export const FUEL_CONSUMPTION = [
  {
    limit: 0,
    color: '#84CA50',
  },
  {
    limit: 10,
    color: '#ffbe0a',
  },
  {
    limit: 20,
    color: '#F07D02',
  },

  {
    limit: 30,
    color: '#ff0000',
  },
  {
    limit: 40,
    color: '#9E1313',
  },
  {
    limit: 50,
    color: '#661010',
  },
  {
    limit: 60,
    color: '#0E0E0E',
  }

];

export interface VehicleStatus {
  type: VehicleStatuses;
  time: string;
}

export interface RideRequest {
  ride?: {
    isWork: boolean;
    startPoi?: {
      id: string;
    }
  };
  park?: {
    isWork?: boolean;
    poi?: {
      id?: string;
      type?: PoiType
    }
  };
}

@Injectable({
  providedIn: 'root'
})

// TODO any to telelmtry
export class TelemetryService extends ApiServiceClass<any> {

  constructor(config: ConfigService,
              http: HttpClient) {
    super('/telemetry', config, http);
  }

  vehiclesLastPosition(vehicleIds: string[], urlParams = {}): Observable<TelemetryVehicles> {
    const params = this.httpParams(urlParams);
    return this.http.post<TelemetryVehicles>(`${this.baseUrl}/vehicles/lastposition`, {vehicleIds}, {params});
  }

  getRideById(rideId: string): Observable<TelemetryRide> {
    return this.http.get<TelemetryRide>(`${this.baseUrl}/rides/${rideId}`)
      .pipe(map(res => ({...res, status: this.vehicleStatus(res)})));
  }

  updateRide(rideId: string, rideBody: RideRequest): Observable<VehicleStatus> {
    return this.http.patch<any>(`${this.baseUrl}/rides/${rideId}`, rideBody);
  }


  vehiclesLastRide(data: { vehicleIds: string[], from?: Date, to?: Date }): Observable<{ [key: string]: TelemetryRide }> {
    if (!data.from) {
      data.from = DateTime.local().startOf('day').plus({days: -7}).toJSDate();
    }
    if (!data.to) {
      data.to = new Date();
    }
    return this.http.post<{ [key: string]: TelemetryRide }>(`${this.baseUrl}/vehicles/lastride`, data).pipe(
      map(res => {
        Object.keys(res).forEach(key => res[key].status = this.vehicleStatus(res[key]));
        return res;
      })
    );
  }

  vehiclesPositions(vehicleId: string, range: { from: string, to: string }, includeRaw: boolean = false): Observable<TelemetryVehicle[]> {
    const params = this.httpParams(range);
    const include = includeRaw ? '/includeraw' : '';
    let querySuffix = '';
    if (!this.config.framesFromTableDate || range.to < this.config.framesFromTableDate) {
      querySuffix = include;
    } else {
      querySuffix = '/az';
    }
    return this.http.get<TelemetryVehicle[]>(`${this.baseUrl}/vehicles/${vehicleId}/positions${querySuffix}`, {
      params
    });
  }

  // tslint:disable-next-line: max-line-length
  vehiclesFuelPositions(vehicleId: string, range: { from: string, to: string }, includeRaw: boolean = false, noFiltering: string = null): Observable<TelemetryVehicle[]> {
    const sourceParams: Object = {
      ...range,
      type: 'IGNITION,LOC',
    };
    if (noFiltering) {
      sourceParams['noFiltering'] = noFiltering;
    }
    const params = this.httpParams(sourceParams);
    const include = includeRaw ? '/includeraw' : '';

    let querySuffix = '';
    if (!this.config.framesFromTableDate || range.to < this.config.framesFromTableDate) {
      querySuffix = `/fuel${include}`;
    } else {
      querySuffix = '/positions/az';
    }

    return this.http.get<TelemetryVehicle[]>(`${this.baseUrl}/vehicles/${vehicleId}${querySuffix}`, {
      params
    });
  }

  vehiclesTemperaturePositions(vehicleId: string, range: { from: string, to: string }): Observable<TelemetryVehicle[]> {
    const sourceParams: Object = {
      ...range,
      type: 'IGNITION,LOC',
    };
    const params = this.httpParams(sourceParams);
    return this.http.get<TelemetryVehicle[]>(`${this.baseUrl}/vehicles/${vehicleId}/positions/az`, {
      params
    });
  }


  driversPositions(driverId: string, range: { from: string, to: string }): Observable<TelemetryVehicle[]> {
    const params = this.httpParams(range);
    return this.http.get<TelemetryVehicle[]>(`${this.baseUrl}/drivers/${driverId}/positions`, {
      params
    });
  }


  vehicleStatus(lastRide: TelemetryRide): VehicleStatus {
    try {
      if (lastRide.parkStartFrame && lastRide.parkData) {
        return {
          type: lastRide.parkData.work.isWorkCustomer
            ? VehicleStatuses.CUSTOMER_PARK : lastRide.parkData.work.isWorkBranch
              ? VehicleStatuses.BRANCH_WORK_PARK : lastRide.parkData.work.isWorkOther
                ? VehicleStatuses.OTHER_WORK_PARK : VehicleStatuses.NONWORK_PARK,
          time: lastRide.parkStartFrame.eventDate,
        };
      } else {
        return {
          type: lastRide.rideData.work.isWork ? VehicleStatuses.BUSINESS_RIDE : VehicleStatuses.PRIVATE_RIDE,
          time: lastRide.rideStartFrame.eventDate,
        };
      }

    } catch (e) {
      return {
        type: null,
        time: lastRide.rideStartFrame.eventDate,
      };
    }

  }
}

import { Permission, PermissionService } from '../core/services/permission.service';

interface PermissonMenuItem {
  permission?: Permission;
}

export class PermissonMenu<T extends PermissonMenuItem> {
  constructor(protected permissionService: PermissionService) {

  }

  public hasPermission(item: T) {
    return item.permission ? this.permissionService.hasPermission(item.permission) : true;
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { animate, keyframes, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        animate('.35s cubic-bezier(.25, .8, .25, 1)', keyframes([
          style({transform: 'scale(.6)', opacity: 0, offset: 0}),
          style({transform: 'scale(1.2)', opacity: 1, offset: 0.8}),
          style({transform: 'scale(.9)', opacity: 1, offset: 0.9}),
          style({transform: 'scale(1)', opacity: 1, offset: 1})
        ]))
      ]),
      transition(':leave', [
        animate('.35s cubic-bezier(.25, .8, .25, 1)', keyframes([
          style({transform: 'scale(1)', opacity: 1, offset: 0}),
          style({transform: 'scale(1.2)', opacity: 1, offset: 0.2}),
          style({transform: 'scale(.6)', opacity: 0, offset: 1}),
        ]))
      ]),
      transition('* => *', [
        animate('.55s cubic-bezier(.25, .8, .25, 1)', keyframes([
          style({transform: 'translateY(0)', offset: 0}),
          style({transform: 'translateY(-10px)', offset: 0.3}),
          style({transform: 'translateY(0px)', offset: 0.6}),
          style({transform: 'translateY(-3px)', offset: 0.8}),
          style({transform: 'translateY(0)', offset: 1}),
        ]))
      ])
    ])
  ]
})
export class BadgeComponent implements OnInit {
  @Input() value;
  @Input() color: 'black' | 'primary' | 'maya' | 'red' = null;
  @Input() size: 'lg' | null;
  @Input() showIfZero = false;

  constructor() {
  }


  get valueNumber() {
    return parseInt(this.value, 10);
  }

  get colorClass() {
    if (this.color) {
      return 'badge--' + this.color;
    }
    return null;
  }

  ngOnInit() {
  }

}

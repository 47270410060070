import { Component, OnDestroy, OnInit } from '@angular/core';
import { DriverGroup } from '../../../../interfaces/group';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { PopupNavigationItem } from '../../../../shared/components/popup-view/popup-navigation/popup-navigation.component';
import { PERMISSIONS } from '../../../../core/services/permission.service';

@Component({
  selector: 'app-driver-group-popup',
  templateUrl: './driver-group-popup.component.html',
  styleUrls: ['./driver-group-popup.component.scss']
})
export class DriverGroupPopupComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  driverGroup: DriverGroup;
  public navItems: PopupNavigationItem[] = [
    {label: 'DETAILS', route: 'details'},
    {label: 'POIS', route: 'poi', permission: PERMISSIONS.DRIVER_GROUP_UPDATE},
  ];

  constructor(private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.data
        .subscribe(res => {
          this.driverGroup = res.driverGroup;
        }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

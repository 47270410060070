import { NgModule } from '@angular/core';
import { UserPopupRoutingModule } from './user-popup-routing.module';
import { UserPopupComponent } from './user-popup.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UserNewComponent } from './user-new/user-new.component';
import { UserResolve } from './user.resolve';
import { SharedModule } from '../../../shared/shared.module';
import { UserHeaderComponent } from './user-header/user-header.component';

@NgModule({
  declarations: [UserPopupComponent, UserDetailsComponent, UserNewComponent, UserHeaderComponent],
  providers: [UserResolve],
  imports: [
    SharedModule,
    UserPopupRoutingModule
  ]
})
export class UserPopupModule { }

export enum NotificationType {
  NORM = 'NORM',
  NORM_ADHOC = 'NORM_ADHOC',
  REMINDER = 'REMINDER',
  ZONE_CHANGE = 'ZONE_CHANGE',
  NORM_AGGREGATE = 'NORM_AGGREGATE',
}

export enum NotificationTemplateType {
  NORM = 'norm_template_configuration',
  REMINDER = 'reminder_template_configuration',
  ZONE_CHANGE = 'reminder_template_configuration',
}

export type NotificationChannel = 'WEB' | 'EMAIL' | 'PUSH';

export interface NotificationRelated {
  id: string;
  type: NotificationTemplateType;
}


export interface NotificationDataBase {
  normConfigurationId: string;
  normInstanceId: string;
  normInstanceResultId: string;
  normConfigurationName: string;
}

export interface NotificationNORM extends NotificationDataBase {
  vehicleName: string;
}

export interface NotificationREMINDER extends NotificationDataBase {
  description: string;
}

export interface NotificationNORM_AGGREGATE extends NotificationDataBase {
  entitiesCount: number;
  vehiclesFulfilled: any;
  vehiclesNotFulfilled: any;
}

export interface NotificationZONE_CHANGE extends NotificationDataBase {
  vehicleName: string;
  state: string;
  zoneId: string;
  zoneName: string;
}

export interface NotificationData {
  [NotificationType.NORM]: NotificationNORM;
  [NotificationType.NORM_ADHOC]: NotificationNORM;
  [NotificationType.NORM_AGGREGATE]: NotificationNORM_AGGREGATE;
  [NotificationType.REMINDER]: NotificationREMINDER;
  [NotificationType.ZONE_CHANGE]: NotificationZONE_CHANGE;
  [key: string]: any;
}

export interface NotificationT {
  id: string;
  userId: string;
  type: NotificationType;
  isViewed: boolean;
  isRead: boolean;
  eventTime: Date;
  related: NotificationRelated;
  data: NotificationData;
}

export interface NotificationCount {
  new: number;
  all: number;
}

export interface NotificationRequest {
  channels: NotificationChannel[];
  type: NotificationType;
  related?: NotificationRelated;
}

export interface NotificationSetting extends NotificationRequest {
  availableChannels: NotificationChannel[];
}

import { RoleService } from './../../../../core/services/role.service';
import { Component } from '@angular/core';
import { NewEditClass } from '../../../../classes/new-edit.class';
import { ActivatedRoute } from '@angular/router';
import { PopupViewService } from '../../../../shared/components/popup-view/popup-view.service';
import { ApiValidatorService } from '../../../../core/services/api-validator.service';
import { RouterHelperService } from '../../../../core/services/router-helper.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { shareReplay, switchMap, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { UserService } from '../../../../core/services/user.service';
import { User } from '../../../../interfaces/user';
import { observableWithCatchError } from '../../../../utils/utils';
import { DriversService } from '../../../../core/services/drivers.service';
import { VehiclesGroupsService } from '../../../../core/services/vehicles-groups.service';
import { DriversGroupsService } from '../../../../core/services/drivers-groups.service';
import { PERMISSIONS, PermissionService } from '../../../../core/services/permission.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent extends NewEditClass {
  protected editPermission = PERMISSIONS.USER_UPDATE;
  protected newPermission = PERMISSIONS.USER_CREATE;
  public updateUserRolesPermission = PERMISSIONS.USER_UPDATE_ROLES;

  public user: User;
  public drivers$ = this.driverService.list$().pipe(shareReplay());
  public driversGroups$ = this.driverGroupService.list();
  public vehiclesGroups$ = this.vehicleGroups.list$();
  public roles$ = this.roleService.list();

  constructor(private route: ActivatedRoute,
              private userService: UserService,
              private roleService: RoleService,
              popupService: PopupViewService,
              private driverService: DriversService,
              private driverGroupService: DriversGroupsService,
              private vehicleGroups: VehiclesGroupsService,
              apiValidator: ApiValidatorService,
              private routerHelper: RouterHelperService,
              permissionService: PermissionService,
              fb: UntypedFormBuilder) {
    super(popupService, apiValidator, fb, permissionService);
  }


  buildForm() {
    const mainForm = {
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      avatarUrl: [null],
      email: [null, this.apiValidator.validateEmail],
      login: [null, Validators.required],
    };
    if (this.isNewView) {
      mainForm['password'] = [null, Validators.required];
    } else {
      mainForm['password'] = [null];
    }
    const groupObject = {
      vehicles: [],
      vehiclesGroups: [],
      drivers: [],
      driversGroups: [],
    };
    if (this.permission.hasPermission(PERMISSIONS.USER_UPDATE_ROLES)) {
      groupObject['roles'] = [];
    }
    this.forms = {
      main: this.fb.group(mainForm),
      groups: this.fb.group(groupObject)
    };
  }

  addRouteSub() {
    this.subscriptions.add(this.route.parent.data.subscribe(({user}) => {
      this.user = user;
      this.resetForm();
    }));
  }

  resetForm() {
    this.forms.main.patchValue(this.user);
    this.groups();
  }

  groups() {
    const keys = Object.keys((this.forms.groups as UntypedFormGroup).controls);
    forkJoin(keys.map(key => observableWithCatchError(this.userService[key](this.user.id))))
      .subscribe(res => {
        res.forEach((value, index) => {
          const key = keys[index];
          this.forms.groups.get(key).setValue(value.map(({id}) => id));
        });
      });
  }

  save() {
    let user;
    this.userService.add(this.forms.main.value).pipe(
      tap(res => user = res),
      switchMap((res) => this.syncAll(res.id))
    ).subscribe(() => {
      this.pending = false;
      this.routerHelper.navigatePopup(['user', user.id, 'details']);
    }, this.errorHandler.bind(this));
  }

  syncAll(userId) {
    const keys = Object.keys((this.forms.groups as UntypedFormGroup).controls).filter(key => {
      const touched = !!this.forms.groups.get(key).touched;
      return touched;
    });
    return keys.length ?
      forkJoin(keys.map(key => observableWithCatchError(this.userService[key + 'Sync'](userId, this.forms.groups.get(key).value || []))))
        .pipe(tap(x => this.forms.groups.markAsUntouched({onlySelf: false})))
      : of([]);
  }

  update() {
    this.userService.partialUpdate(this.user.id, this.forms.main.value).pipe(
      tap(res => Object.assign(this.user, res)),
      switchMap(() => this.syncAll(this.user.id))
    ).subscribe(res => {
      this.editSuccess();
    }, this.errorHandler.bind(this));
  }
}

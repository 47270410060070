<div class="d-flex justify-content-between align-items-stretch flex-column flex-fill h-100">
    <div class="overflow-auto p-xm">
        <div class="position-relative">
            <app-map height="500px" id="map_zone">
              <app-map-toolbox [icons]="toolboxIcons" (toggleIcon)="toggleToolbox($event)" style="top: 50px!important">
                <app-map-toolbox-search [isOpen]="activeIcon['search']" [enableRevIso]="false"
                  (suggestionSelected)="suggestionSelected($event)" ></app-map-toolbox-search>
              </app-map-toolbox>
              <ng-container *ngIf="showSearchResults">
                <app-map-marker-poi *ngIf="geocodedSuggestion" [coordinates]="geocodedSuggestion.coordinates"
                                      color="#233445"
                                      [name]="geocodedSuggestion.label"></app-map-marker-poi>
              </ng-container>
            </app-map>
            <app-map-polygon-edited [area]="zone?.area" [color]="color"
                                    (areaChange)="areaChange($event)"
                                    [editMode]="isEditMode || isNewView"></app-map-polygon-edited>
            <app-error-message [control]="forms?.main.get('area')"></app-error-message>
        </div>

        <div [formGroup]="forms?.main">
            <h3 class="form-header mt-xl" translate>BASIC_DATA</h3>
            <div class="row">
                <div class="col-8 mb-xm">
                    <app-form-group>
                        <label translate>NAME</label>
                        <input app-form formControlName="name" type="text">
                        <app-error-message [control]="forms?.main.get('name')"></app-error-message>
                    </app-form-group>
                </div>

                <div class="col-4 ">
                    <app-color-input formControlName="color">
                        <app-error-message [control]="forms?.main.get('color')"></app-error-message>
                    </app-color-input>


                </div>
            </div>
        </div>
    </div>
    <div class="p-xm d-flex justify-content-end align-items-center border-top">
        <button class="btn btn-danger btn-border px-xxl mr-m" (click)="deleteZone()" translate *ngIf="!isNewView && showButton">
            {{'DELETE'|translate}}
        </button>
        <button class="btn btn-primary btn-border px-xxl mr-m" (click)="cancel()" translate>
            {{(isNewView || !isEditMode ? 'EXIT' : 'CANCEL')|translate}}
        </button>
        <button class="btn btn-primary px-xxl" (click)="handlerSave()" [disabled]="pending" *ngIf="showButton">
            <app-spinner [showSpinner]="pending"></app-spinner>
            {{(isNewView || isEditMode ? 'SAVE' : 'EDIT')|translate}}
        </button>
    </div>
</div>






import { NgModule } from '@angular/core';

import { ReportsPopupRoutingModule } from './reports-popup-routing.module';
import { NewReportPopupComponent } from './new-report-popup/new-report-popup.component';
import { SharedModule } from '../../../shared/shared.module';
import { ReportFormComponent } from './new-report-popup/report-form/report-form.component';

@NgModule({
  declarations: [NewReportPopupComponent, ReportFormComponent],
  imports: [
    SharedModule,
    ReportsPopupRoutingModule
  ]
})
export class ReportsPopupModule { }

import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { Group } from '../../../../interfaces/group';

@Component({
  selector: 'app-tag-group',
  templateUrl: './tag-group.component.html',
  styleUrls: ['./tag-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagGroupComponent implements OnInit {
  @Input() tag: Group;
  @HostBinding('class')
  @Input() size: string;
  @Input() iconClass = 'icon-car';

  constructor() {
  }

  ngOnInit() {
  }

}

import { NgModule } from '@angular/core';

import { RidePopupRoutingModule } from './ride-popup-routing.module';
import { RidePopupComponent } from './ride-popup.component';
import { SharedModule } from '../../../shared/shared.module';
import { RidePopupHeaderComponent } from './ride-popup-header/ride-popup-header.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [RidePopupComponent, RidePopupHeaderComponent],
  exports: [
    RidePopupComponent
  ],
  imports: [
    SharedModule,
    RidePopupRoutingModule,
    NgbTooltipModule,
  ]
})
export class RidePopupModule {
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { ConfigService } from './config.service';
import { tap } from 'rxjs/operators';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';

export interface LoginData {
  password: string;
  username: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public redirectUrl: string;
  public isLoggedIn = false;
  public isLoggedIn$;
  private _MAX_DELAY = 2147483647;
  private _loginSubject: BehaviorSubject<boolean>;

  constructor(private http: HttpClient,
              private config: ConfigService,
              private router: Router,
              private jwtHelper: JwtHelperService) {
    if (this.loggedIn()) {
      this.scheduleRefresh(this.token);
      this.isLoggedIn = true;
    }
    this._loginSubject = new BehaviorSubject(this.isLoggedIn);
    this.isLoggedIn$ = this._loginSubject.asObservable();
  }

  get token() {
    return localStorage.getItem(this.config.tokenName);
  }

  set token(token) {
    localStorage.setItem(this.config.tokenName, token);
  }

  removeToken() {
    localStorage.removeItem(this.config.tokenName);
  }

  windowFocus() {
    if (this.loggedIn()) {
      this.scheduleRefresh(this.token);
    } else {
      this.logout();
    }
  }

  loggedIn() {
    return this.token ? !this.jwtHelper.isTokenExpired() : false;
  }


  get tokenInfo() {
    if (this.token) {
      return this.jwtHelper.decodeToken(this.token);
    }
    return null;
  }

  scheduleRefresh(token) {
    const now: any = new Date().valueOf();
    const jwtExp = this.jwtHelper.decodeToken(token).exp;
    const exp = new Date(0);
    exp.setUTCSeconds(jwtExp);
    let delay = exp.valueOf() - now - 10000;
    if (delay > this._MAX_DELAY) {
      delay = this._MAX_DELAY;
    }
    setTimeout(() => {
      this.refreshToken();
    }, delay);
  }

  logout(withNavigate = true) {
    localStorage.removeItem(this.config.tokenName);
    this.isLoggedIn = false;
    this._loginSubject.next(this.isLoggedIn);
    if (withNavigate) {
      this.router.navigate(['login']);
    }
  }

  resetPassword(data: {}) {
    return this.http.post(this.config.fullApiUrl('/auth/password-reset'), data);
  }

  resetPasswordConfirm(data: {}) {
    return this.http.put(this.config.fullApiUrl('/auth/password-reset/confirm'), data);
  }

  refreshToken() {
    return this.http.post(this.config.fullApiUrl('/auth/refresh-token'), {token: this.token})
      .subscribe((res: any) => {
        this.token = res.token;
        this.scheduleRefresh(res.token);
      });
  }

  login(loginData: LoginData) {
    return this.http.post(this.config.fullApiUrl('/auth/login'), loginData).pipe(
      tap((res: any) => {
        localStorage.clear();
        const token = res.token;
        this.token = token;
        // this.scheduleRefresh(token);
        this.isLoggedIn = true;
        this._loginSubject.next(this.isLoggedIn);
        return res;
      }));
  }

  getSpeechToken() {
    return this.http.get(this.config.fullApiUrl('/auth/speech-token'));
  }

  speak(speechToken: { token: string, region: string}, text: string) {
    const speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(speechToken.token, speechToken.region);
    speechConfig.speechSynthesisVoiceName = "Microsoft Server Speech Text to Speech Voice (pl-PL, MarekNeural)";
    speechConfig.speechSynthesisOutputFormat = 8 ;

    const player = new SpeechSDK.SpeakerAudioDestination();
    const  audioConfig  = SpeechSDK.AudioConfig.fromSpeakerOutput(player);

    const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);
    synthesizer.speakTextAsync(text.substring(0, 100));
  }
}

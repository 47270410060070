import { Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder,  Validators} from '@angular/forms';
import {ApiValidatorService} from '../../../../../core/services/api-validator.service';
import {NgbDatepicker} from '@ng-bootstrap/ng-bootstrap';
import { CardTransfer, CardTransferRequest } from 'src/app/interfaces/card-transfers';
import { Driver } from 'src/app/interfaces/driver';
import { CardTransferService } from 'src/app/core/services/card-transfers.service';

@Component({
  selector: 'app-driver-code-tracking-add',
  templateUrl: './driver-code-tracking-add.component.html',
  styleUrls: ['./driver-code-tracking-add.component.scss'],
})
export class DriverCodeTrackingAddComponent implements OnInit {
  @Input() cardTransfer: CardTransfer;
  @Input() driver: Driver;
  @Output() formUpdate = new EventEmitter();
  @ViewChild(NgbDatepicker) ngbDatepicker: NgbDatepicker;
  public form = this.buildForm();
  public pending = false;

  constructor(
    private fb: FormBuilder,
    private apiValidator: ApiValidatorService,
    private cardTransferService: CardTransferService) {
  }

  errorCallback() {
    this.pending = false;
    // this.formErrors = err;
    // this.cd.detectChanges();
  }
  ngOnInit() {
    this.buildForm();
    if (this.cardTransfer) {
      this.form.patchValue(this.cardTransfer);
      this.form.controls.cardNumber.disable();
      this.form.controls.returnDate.setValidators(Validators.required);
    }
  }

  buildForm() {
    return  this.fb.group({
      cardNumber: this.fb.control<string>(null, Validators.required),
      transferDate: [null],
      returnDate: [null],
    });
  }


  buildData(): CardTransfer {
    if (!this.driver) {
      return;
    }
    const data: Partial<CardTransferRequest> = {...this.form.value};
    data.driverId = this.driver.id;
    return data as CardTransfer;
  }


  save() {
    if (this.pending || !this.apiValidator.formIsValid(this.form)) {
      return;
    }
    this.pending = true;
    if (this.cardTransfer) {
      this.cardTransferService.partialUpdate(this.cardTransfer.id, {
        returnDate: this.buildData().returnDate
      }).subscribe(res => {
        this.formUpdate.emit(res);
      }, () => {
        this.pending = false;
      });
    } else {
      this.cardTransferService.add(this.buildData()).subscribe(res => {
        this.cardTransfer = res;
        this.formUpdate.emit(res);
      }, () => {
        this.pending = false;
      });
    }

  }

  handlerCancel() {
    this.formUpdate.emit();
  }

  stopEvents(input: HTMLInputElement) {
    input.blur();
  }

}

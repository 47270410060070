<div *ngIf="form">
  <div *ngIf="entites.length > 1">
    <h2 class="form-header mt-xl" translate>REPORT_FORM_HEADER.SELECT_DIMENSION</h2>
    <div class="row justify-content-start">
      <div class="col-auto" *ngFor="let item of entites">
          <app-form-group>
              <label>
                  <input type="radio"
                        name="dimension"
                        [value]="item.name"
                        [formControl]="form.controls['dimension']"
                        app-form>
                  <span></span>
                  {{item.name|translate}}
              </label>
          </app-form-group>

      </div>
      <div class="col-12">
          <!-- <app-error-message [control]="form.controls['isPeriodic']"
                            [apiError]="form?.isPeriodic"></app-error-message> -->
      </div>
  </div>
</div>
    <div *ngFor="let field of entites" [formGroup]="form">
      <div *ngIf="field.name == form.controls['dimension'].value">
        <h2 class="form-header mt-xl" translate>REPORT_FORM_HEADER.{{field.name}}</h2>

        <ng-container [ngSwitch]="field.name">

            <ng-container *ngSwitchCase="'VEHICLES'">
                <app-vehicles-select
                        [max]="4"
                        bindValue="id"
                        formControlName="{{field.name}}"
                >
                </app-vehicles-select>
            </ng-container>
            <ng-container *ngSwitchCase="'VEHICLE_GROUPS'">
                <app-select-input
                        bindLabel="name"
                        bindValue="id"
                        selectClass="w-50"
                        [max]="3"
                        [showTags]="true"
                        [searchable]="true"
                        formControlName="{{field.name}}"
                        [items]="vehiclesGroups$|async"
                >
                    <label translate>REPORT_FORM_LABEL.{{field.name}}</label>
                </app-select-input>
            </ng-container>
            <ng-container *ngSwitchCase="'DRIVERS'">
              <app-drivers-select
                        [max]="4"
                        bindValue="id"
                        formControlName="{{field.name}}"
                >
                </app-drivers-select>
          </ng-container>
            <ng-container *ngSwitchDefault>
                <app-form-group>
                    <label translate>REPORT_FORM_LABEL.{{field.name}}</label>
                    <input [type]="getType(field.type)" formControlName="{{field.name}}" app-form>
                </app-form-group>
            </ng-container>
        </ng-container>
        <app-error-message [control]="form.controls[field.name]"
                           [apiError]="formErrors ? formErrors[field.name] : null"></app-error-message>
      </div>
    </div>
</div>

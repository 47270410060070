import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-close-btn',
  templateUrl: './close-btn.component.html',
  styleUrls: ['./close-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloseBtnComponent {

  @HostBinding('class')
  @Input() position: 'left' | 'inside';

  @Output() toggle = new EventEmitter();

  @HostListener('click', ['$event']) onClick(event) {
    event.stopPropagation();
    event.preventDefault();
    this.toggle.emit();
  }
}

<div class="c-dropdown-card">
    <div class="c-dropdown__header">
        <div class="d-flex justify-content-between align-items-start c-dropdown__header-label">
            <h3>{{label}}</h3>
            <i class="icon-x" (click)="close.emit()"></i>
        </div>
        <ng-content select="[dropdown-card-header]"></ng-content>
    </div>
    <div class="c-dropdown__body">
        <ng-content></ng-content>
    </div>
    <div class="c-dropdown__footer">
        <ng-content select="[dropdown-card-footer]"></ng-content>
    </div>
</div>

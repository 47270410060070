import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PermissonMenu } from '../../../../classes/permission-menu.class';
import { Permission, PermissionService } from '../../../../core/services/permission.service';
import { Subscription } from 'rxjs';
import { ChildActivationEnd, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';


export interface PopupNavigationItem {
  label: string;
  badge?: number;
  exact?: boolean;
  route: string;
  permission?: Permission;
}

@Component({
  selector: 'app-popup-navigation',
  templateUrl: './popup-navigation.component.html',
  styleUrls: ['./popup-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupNavigationComponent extends PermissonMenu<PopupNavigationItem> implements OnInit, OnDestroy {
  @Input() items: PopupNavigationItem[];
  private routeSub$: Subscription;

  constructor(permission: PermissionService, private router: Router,
              private cd: ChangeDetectorRef) {
    super(permission);
  }

  ngOnDestroy(): void {
    this.routeSub$.unsubscribe();
  }

  ngOnInit(): void {
    this.routeSub$ = this.router.events.pipe(
      filter(evt => evt instanceof NavigationEnd ||  evt instanceof ChildActivationEnd)
    ).subscribe(() => {
      this.cd.detectChanges();
    });
  }

}

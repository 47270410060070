import { Injectable } from '@angular/core';
import { ResolveClass } from '../../../classes/resolver.class';
import { TelemetryRide } from '../../../interfaces/telemetry';
import { TelemetryService } from '../../../core/services/telemetry.service';

@Injectable()
export class RideResolve extends ResolveClass<TelemetryRide> {
  protected serviceGetter(id) {
    return (this.service as TelemetryService).getRideById(id) as any;
  }

  constructor(service: TelemetryService) {
    super(service);
  }
}

import { Component } from '@angular/core';
import { FilterCollection } from '../../../../../../classes/filters-collection.class';
import { UntypedFormBuilder } from '@angular/forms';
import { PoiGroupsService } from '../../../../../../views/dashboard/poi/poi-groups.service';

@Component({
  selector: 'app-poi-seelct-dropdown-filters',
  templateUrl: './poi-seelct-dropdown-filters.component.html',
  styleUrls: ['./poi-seelct-dropdown-filters.component.scss']
})
export class PoiSeelctDropdownFiltersComponent extends FilterCollection {
  public groups$ = this.service.list$();

  constructor(formBuilder: UntypedFormBuilder, public service: PoiGroupsService) {
    super(formBuilder);
  }

  selectChange($event) {
  }

  initForm(data = null) {
    this.filterForm = this.fB.group({
      search: [],
      poiGroupIds: [],
      limit: 50,
    });
  }

}

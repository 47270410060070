<div class="d-flex justify-content-between align-items-stretch">
    <app-form-group>
        <label for="" *ngIf="label">{{label}}</label>
        <input type="numbe" app-form [ngModel]="numberValue" [disabled]="isDisabled" (focus)="stopEvent(input)" #input/>
        <div class="d-flex flex-column justify-content-center align-items-stretch number-buttons"
             [ngClass]="{'disabled': isDisabled}">
            <a (click)="increment()"><i
                    class="icon-marker-arrow"></i></a>
            <a (click)="decrement()"><i
                    class="icon-marker-arrow icon-marker-arrow--down"></i></a>
        </div>

    </app-form-group>

</div>

<td class="toggle-arrow">
    <i [ngClass]="isOpen ? 'icon-chevron-up' : 'icon-chevron-down'"
       (click)="handlerRolloverToggle($event)" *ngIf="!aggregationRow"></i>
</td>
<td>
    <div class="d-inline-block">
        <app-from-to [showType]="aggregationRow ? 'days' : 'one_day'" [from]="data.RIDE_STARTDATE[0].v"
                     [small]="true"
                     [to]="data.PARK_STARTDATE[0].v"
        ></app-from-to>
    </div>
</td>
<td class="localization-row">
    <div>
        <app-localization type="start" [data]="data" [ellipsis]="true" class="mb-s d-block"></app-localization>

        <app-localization type="end" [data]="data" [ellipsis]="true"></app-localization>
    </div>
</td>
<td class="work-type">
    <p class="mb-s">
        {{data.SUM_WORK_RIDE_DURATION[0]|warehouseUnit}}
    </p>
    <p class="text-gray">
        {{data.SUM_NONWORK_RIDE_DURATION[0]|warehouseUnit}}
    </p>
</td>
<td class="work-type work-type--time">
    <p class="mb-s">
        {{ data.SUM_WORK_RIDE_DISTANCE[0]|warehouseUnit}}
    </p>
    <p class="text-gray">
        {{data.SUM_NONWORK_RIDE_DISTANCE[0]|warehouseUnit}}
    </p>
</td>
<td class="nowrap">
    <span class="nowrap">{{data|avgSpeed}}</span><br>
    <span class="nowrap hide-by-theme">{{data|maxSpeed}}</span>
</td>
<td class="hide-by-theme">
    <app-status-bar [max]="10" [value]="(fuelDimmension === 'DURATION' ? data.AVG_FUEL_CONSUMPTION_H[0] : data.AVG_FUEL_CONSUMPTION[0])|warehouseUnit">
        {{(fuelDimmension === 'DURATION' ? data.AVG_FUEL_CONSUMPTION_H[0] : data.AVG_FUEL_CONSUMPTION[0])|warehouseUnit}}
    </app-status-bar>
    <br *ngIf="data.SUM_PARK_FUEL_CHANGE_LITRES[0].v > 0" >
    <p *ngIf="data.SUM_PARK_FUEL_CHANGE_LITRES[0].v > 0" >
      <app-icon-bg iconClass="icon-petrol" label="sum"></app-icon-bg>
      {{data.SUM_PARK_FUEL_CHANGE_LITRES[0]|warehouseUnit:1:true}} {{vehicleFuelType|fuelUnit}}
    </p>

</td>
<td class="hide-by-theme">
    <app-status-bar [max]="10" [value]="data.AVG_ECO_SCORE[0]|warehouseUnit">
        {{data.AVG_ECO_SCORE[0]|warehouseUnit}}
    </app-status-bar>

</td>
<td class="hide-by-theme">
    <app-badge color="maya" [value]="data.SUM_CUSTOMER_WORK_PARK_COUNT|warehouseValue" [showIfZero]="true"
               size="lg"></app-badge>
</td>
<td>
    <app-route-avatar-switch [isDriver]="isDriver" [data]="data"></app-route-avatar-switch>
</td>

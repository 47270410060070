import { NotificationNORM, NotificationT, NotificationType } from './../../../../interfaces/notificationT';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Poi } from '../../../../interfaces/poi';
import { PopupNavigationItem } from '../../../../shared/components/popup-view/popup-navigation/popup-navigation.component';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss']
})
export class NotificationPopupComponent implements OnInit {

  public navItems: PopupNavigationItem[] = [
    {label: 'DETAILS', route: './', exact: true},
  ];
  public notification: NotificationT;
  public notification$: Observable<NotificationT> = this.route.data
    .pipe(
      map(({notification}: { notification: NotificationT }) => {
        this.notification = notification;
        return notification;
      })
    );


  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
  }

  get headerLabel() {
    const data = this.notification.data[this.notification.type];
    switch (this.notification.type) {
      case NotificationType.NORM_ADHOC:
        const dataDirect = <NotificationNORM>data;
        return `${dataDirect.vehicleName} - ${dataDirect.normConfigurationName}`;
      default:
        return data.normConfigurationName;
    }
  }
}

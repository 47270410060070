import { animate, style, transition, trigger } from '@angular/animations';

export const routeFadeStateTrigger = trigger('routeFadeState', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'translateY(-30px)'
    }),
    animate('0.5s ease-in-out')
  ]),
]);

import { Component } from '@angular/core';

@Component({
  selector: 'app-zone-popup-new',
  templateUrl: './zone-popup-new.component.html',
  styleUrls: ['./zone-popup-new.component.scss']
})
export class ZonePopupNewComponent {

  constructor() {
  }

}

<div class="pl-3">
  <div class="d-flex justify-content-start align-items-center">
    <h3 class="m-0">Status: </h3>
    <div class="pl-2">
      <app-select-input [multiple]="false" [items]="availableStatuses$|async" bindLabel="name" bindValue="id"
                        placeholder="Brak"
                        [formControl]="selectedStatus"
                        [allowClear]="false"
      ></app-select-input>
    </div>
  </div>
  <div *ngIf="lastStatus" class="mt-2">
    <p class="m-0">Zmieniono: <strong>{{lastStatus?.startDate|date:'HH:mm dd.MM.yyyy'}}</strong></p>
    <p class="m-0">Przez:<strong>{{lastStatus?.createdUserName}}</strong></p>
  </div>


</div>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-map-toolbox',
  templateUrl: './map-toolbox.component.html',
  styleUrls: ['./map-toolbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapToolboxComponent implements OnInit {
  @Input() icons: { icon: string; value: string, activeOnInit?: boolean }[];
  @Output() toggleIcon = new EventEmitter();
  active = {};

  constructor() {
  }

  openToggle(value) {
    if (this.active[value]) {
      delete this.active[value];
    } else {
      this.active[value] = true;
    }
    this.toggleIcon.emit(this.active);
  }

  ngOnInit() {
    if (this.icons) {
      this.icons.filter(f => f.activeOnInit).forEach(icon => this.active[icon.value] = true);
    }
  }

}

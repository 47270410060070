import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PopupNavigationItem } from '../../../shared/components/popup-view/popup-navigation/popup-navigation.component';
import { Vehicle } from '../../../interfaces/vehicle';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PERMISSIONS } from '../../../core/services/permission.service';

@Component({
  selector: 'app-vehicle-popup',
  templateUrl: './vehicle-popup.component.html',
  styleUrls: ['./vehicle-popup.component.scss'],
})
export class VehiclePopupComponent {
  public navItems: PopupNavigationItem[] = [
    {label: 'DASHBOARD', route: 'statistics', permission: PERMISSIONS.BI_VIEW},
    {label: 'ROUTES', route: 'history'},
    {label: 'VEHICLE_DETAILS', route: 'details'},
    {label: 'ASSIGNED_ADDRESSES', route: 'poi', permission: PERMISSIONS.VEHICLE_UPDATE},
    {label: 'SERVICE', route: 'service', permission: PERMISSIONS.REMINDER_VIEW},
    {label: 'FUEL', route: 'fuel', permission: PERMISSIONS.FUEL_DETAILS},
    {label: 'CHARTS', route: 'chart', permission: PERMISSIONS.VEHICLE_CHARTS},
  ];
  public vehicle$: Observable<Vehicle> = this.route.data
    .pipe(
      map(({vehicle}: { vehicle: Vehicle }) => vehicle)
    );

  constructor(private route: ActivatedRoute) {
  }
}

@Component({
  selector: 'app-partner-vehicle-popup',
  templateUrl: './vehicle-popup.component.html',
  styleUrls: ['./vehicle-popup.component.scss'],
})
export class PartnerVehiclePopupComponent {

  public navItems: PopupNavigationItem[] = [
    // {label: 'VEHICLE_DETAILS', route: 'details'},
    {label: 'EXTENSIONS', route: 'extensions'},
  ];
  public vehicle$: Observable<Vehicle> = this.route.data
    .pipe(
      map(({vehicle}: { vehicle: Vehicle }) => vehicle)
    );

  constructor(private route: ActivatedRoute) {
  }

}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import {TranslationWidth} from "@angular/common";



@Component({
  selector: 'app-date-range-filter-calendar',
  templateUrl: './date-range-filter-calendar.component.html',
  styleUrls: ['./date-range-filter-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeFilterCalendarComponent implements OnInit {
  @Output() select = new EventEmitter();
  @Input() fromDate: NgbDate;
  @Input() toDate: NgbDate;
  hoveredDate: NgbDate;
  maxDate = this.calendar.getToday();
  minDate = this.ngbDateParserFormatter.parse('2018-01-01');
  public weekShort = TranslationWidth;
  constructor(private calendar: NgbCalendar,
              private ngbDateParserFormatter: NgbDateParserFormatter,
              private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate
      && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

}

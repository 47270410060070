import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class WikipediaService {
  constructor(private http: HttpClient) {}


  WIKI_URL = 'https://en.wikipedia.org/w/api.php';
  PARAMS = new HttpParams({
    fromObject: {
      action: 'opensearch',
      format: 'json',
      origin: '*'
    }
  });
  search(term: string) {
    if (term === '') {
      return of([]);
    }

    return this.http
      .get<[any, string[]]>(this.WIKI_URL, {params: this.PARAMS.set('search', term)}).pipe(
        map(response => response[1])
      );
  }
}

import { AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { Input, OnDestroy, OnInit, Directive } from '@angular/core';
import { PopupViewService } from '../shared/components/popup-view/popup-view.service';
import { Subscription } from 'rxjs';
import { ApiValidatorService } from '../core/services/api-validator.service';
import { PERMISSIONS, PermissionService } from '../core/services/permission.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DeleteModalComponent} from '../shared/components/delete-modal/delete-modal.component';
import { HttpErrorResponse } from '@angular/common/http';

@Directive()
export abstract class NewEditClass implements OnInit, OnDestroy {
  @Input() isNewView = false;
  protected abstract editPermission: PERMISSIONS;
  protected abstract newPermission: PERMISSIONS;
  showButton = false;

  constructor(protected popupService: PopupViewService,
              protected apiValidator: ApiValidatorService,
              protected fb: UntypedFormBuilder,
              protected permission: PermissionService,
              protected modal?: NgbModal
  ) {

  }

  public isEditMode = false;
  public pending = false;
  public formErrors: any;
  public forms: { [key: string]: AbstractControl } = {};
  protected subscriptions: Subscription = new Subscription();

  abstract addRouteSub();

  abstract buildForm();

  resetForm() {
  }

  ngOnInit() {
    this.showButton = this.isNewView && this.permission.hasPermission(this.newPermission)
      || !this.isNewView && this.permission.hasPermission(this.editPermission);
    this.buildForm();
    if (!this.isNewView) {
      this.addRouteSub();
      this.toggleDisableForm();
    } else {
      this.isEditMode = true;
    }
  }


  get showButtons() {
    return this.isEditMode || this.isNewView;
  }

  destroyFn() {
  }

  initFn() {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.destroyFn();
  }


  formIsNotValid() {
    let isNotValid = false;
    Object.keys(this.forms).forEach(key => {
      if (!this.apiValidator.formIsValid(this.forms[key] as any)) {
        isNotValid = true;
      }
    });
    return isNotValid;
  }

  abstract update();

  abstract save();


  handlerSave() {
    if (!this.isNewView && !this.isEditMode) {
      this.isEditMode = true;
      this.toggleDisableForm();
      return;
    }
    if (this.pending || this.formIsNotValid()) {
      return;
    }
    this.pending = true;
    this.formErrors = {};
    if (this.isNewView) {
      this.save();
    } else {
      this.update();
    }
  }

  errorHandler(response: HttpErrorResponse) {
    this.formErrors = response.error;
    this.pending = false;
  }

  toggleFormState(form, options) {
    if (this.isEditMode) {
      form.enable(options);
    } else {
      form.disable(options);
    }
  }

  editSuccess() {
    this.isEditMode = false;
    this.pending = false;
    this.toggleDisableForm();
  }

  toggleDisableForm() {
    const options = {onlySelf: false, emitEvent: false};
    Object.keys(this.forms).forEach(key => {
      this.toggleFormState(this.forms[key], options);
    });
  }

  cancel() {
    if (this.isEditMode && !this.isNewView) {
      this.isEditMode = false;
      this.toggleDisableForm();
      this.resetForm();
    } else {
      this.popupService.close();
    }
  }

  deleteElement(id: any, message: { header: string, body: string }, deleteFunction?) {
    const modal = this.modal.open(DeleteModalComponent);
    modal.componentInstance.text = message;
    modal.componentInstance.deleteFunc = () => {
      return deleteFunction ? deleteFunction(id) : this.deleteFunction(id);
    };
    return modal.result;
  }

  deleteFunction(id) {
  }
}


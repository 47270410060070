export enum NormFieldsType {
  VEHICLES = 'vehicles',
  VEHICLE_GROUPS = 'vehicle_groups',
  NUMBER = 'number',
  SELECT = 'select',
  PERIOD_SELECTOR = 'period_selector'
}


export type NormTrigger = 'PERIOD' | 'ADHOC';
export type NormTemplateType = 'WorkStart' | 'Mileage' | 'ZoneCheck';
export type NormClass = 'warehouse' | 'zones';

export interface NormTemplateQueryParams {
  trigger?: NormTrigger;
  class?: NormClass;
  type?: NormTemplateType | NormTemplateType[];
  zone?: string;
  vehicle?: string;
}

export interface NormTemplate {
  id: string;
  name: string;
  desc: string;
  imgUrl: string;
  sections?: NormSections[];
  type?: string;
}

export interface NormTemplateWithConfig extends NormTemplate {
  configurations: NormTemplateConfiguration[];
}

export interface NormSections {
  label: string;
  type: string;
  fields: NormFields[];
}

export interface NormFields {
  label: string;
  name: string;
  type: NormFieldsType;
  multiselect?: boolean;
  default?: any;
  options?: NormFieldOptionsOptions;
  validation?: NormValidation;
}

interface NormValidation {
  some: string;
}

interface NormFieldOptionsOptions {
  name: string;
  value: string;
}

export interface NormTemplateConfiguration {
  id: string;
  templateId: string;
  name: string;
  validBefore: string;
  fields: NormTemplateConfigurationFields;
  isAggregated: boolean;
}

interface NormTemplateConfigurationFields {
  [key: string]: any;
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output
} from '@angular/core';
import { WorkVehicleWarehouse } from '../../../../core/services/vehicles.service';
import { WarehouseService } from '../../../../core/services/warehouse.service';
import { TelemetryService, VehicleStatuses } from 'src/app/core/services/telemetry.service';

@Component({
  selector: '[app-route-history-table-row-ride]',
  templateUrl: './route-history-table-row-ride.component.html',
  styleUrls: ['./route-history-table-row-ride.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteHistoryTableRowRideComponent implements AfterViewInit {
  @Input() data: WorkVehicleWarehouse;
  @HostBinding('class.is-selected')
  @Input() isSelected = false;
  @Input() isDriver = false;
  @Input() fuelDimmension = 'DISTANCE';
  @Output() rideTypeChange = new EventEmitter();
  trElementRef!: HTMLElement;
  @HostBinding('class.is-override') get isOverride() {
    return this.data && this.data.RIDE_IS_OVERRIDE && this.data.RIDE_IS_OVERRIDE[0].v;
  }

  constructor(protected warehouseService: WarehouseService, protected elementRef: ElementRef,
    protected cd: ChangeDetectorRef, protected telemetryService: TelemetryService) {

  }
  ngAfterViewInit(): void {
    this.trElementRef = this.elementRef.nativeElement;
    this.cd.detectChanges();
  }

  get rideStatus() {
    return this.warehouseService.rideStatus(this.data);
  }

  changeRideStatus($event: MouseEvent) {
    $event.preventDefault();
    this.telemetryService.updateRide(this.data.ID[0].v, {
      ride: {
        isWork: this.getNewIsWorkStatus()
      }
    }).subscribe(res => {
    this.rideTypeChange.emit();
    });
  }

  getNewIsWorkStatus() {
    const status = this.rideStatus;
    return status !== VehicleStatuses.BUSINESS_RIDE;
  }

  getNewIsWorkStatusLabel() {
    return this.getNewIsWorkStatus() ? 'CHANGE_TO_BUSINESS' : 'CHANGE_TO_PRIVATE';
  }

  stopPropagation($event: MouseEvent) {
    $event.stopPropagation();
  }
}

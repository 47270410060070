import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import ApiServiceClass from './api-service.class';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';


export class ResolveClass<T> implements Resolve<T> {
  protected params = {};


  constructor(protected service: ApiServiceClass<T>) {
  }

  protected serviceGetter(id) {
    return this.service.get(id, this.params);
  }

  resolve(route: ActivatedRouteSnapshot) {
    const id = route.paramMap.get('id');
    return this.serviceGetter(id).pipe(
      map(item => {
        return item || null;
      }),
      catchError((err: any) => {
        return of(null);
      }));
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

import {
  FormGroupComponent, FormControlDirective, FormControlInlineDirective,
  FormControlLgDirective, FormControlWhiteDirective, FormSelectTagsDirective
} from './form-group.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
  ],
  exports: [
    FormGroupComponent,
    FormControlDirective,
    FormControlLgDirective,
    FormControlInlineDirective,
    NgSelectModule,
    FormControlWhiteDirective,
    FormSelectTagsDirective
  ],
  declarations: [FormSelectTagsDirective, FormGroupComponent, FormControlLgDirective, FormControlDirective, FormControlInlineDirective, FormControlWhiteDirective]
})
export class FormGroupModule {
}

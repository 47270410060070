import { DevicesService } from './../../../core/services/devices.service';
import { Device } from './../../../interfaces/device';
import { Injectable } from '@angular/core';
import { ResolveClass } from '../../../classes/resolver.class';

@Injectable()
export class DeviceResolve extends ResolveClass<Device> {
  protected params = {
    include: 'vehicles',
  };

  constructor(service: DevicesService) {
    super(service);
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-map-toolbox-custom',
  templateUrl: './map-toolbox-custom.component.html',
  styleUrls: ['./map-toolbox-custom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapToolboxCustomComponent implements OnInit {
  @Input() icon: string;
  @Input() label: string;
  @Input() isOpen: boolean;

  constructor() {
  }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { PopupNavigationItem } from '../../../shared/components/popup-view/popup-navigation/popup-navigation.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../../interfaces/user';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user-popup',
  templateUrl: './user-popup.component.html',
  styleUrls: ['./user-popup.component.scss']
})
export class UserPopupComponent implements OnInit {
  public navItems: PopupNavigationItem[] = [
    {label: 'USER_DETAILS', route: 'details'},

  ];
  public user$: Observable<User> = this.route.data
    .pipe(
      map(({user}: { user: User }) => user)
    );

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Driver } from '../../../../interfaces/driver';

@Component({
  selector: 'app-driver-history',
  templateUrl: './driver-history.component.html',
  styleUrls: ['./driver-history.component.scss']
})
export class DriverHistoryComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  public driver: Driver;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.subscriptions.add(this.route.parent.data.subscribe(({driver}) => {
      this.driver = driver;
    }));
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { ResolveClass } from '../../../classes/resolver.class';
import { Vehicle } from '../../../interfaces/vehicle';
import { VehiclesService } from '../../../core/services/vehicles.service';
import { PartnerVehiclesService } from 'src/app/core/services/partner-vehicles.service';

@Injectable()
export class VehicleResolve extends ResolveClass<Vehicle> {
  protected serviceGetter(id) {
    return (this.service as any).vehicleWithTelemetryAndRide(id);
  }

  constructor(service: VehiclesService) {
    super(service);
  }
}

@Injectable()
export class PartnerVehicleResolve extends ResolveClass<Vehicle> {
  protected serviceGetter(id) {
    return (this.service as any).get(id, { include: ['extensions', 'noCacheExtensions']});
  }


  constructor(service: PartnerVehiclesService) {
    super(service);
  }
}

<svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg"
     xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 52.6 (67491) - http://www.bohemiancoding.com/sketch -->
    <title>Avatar</title>
    <desc>Created with Sketch.</desc>
    <defs>
        <path d="M0.6,7.53488372 L15.7115479,1.8189894e-12 L34.1247664,5.02325581 C31.6016686,10.9709874 30.0126706,14.8403367 29.3577724,16.6313039 C28.7028743,18.422271 28.3357001,19.9025167 28.25625,21.0720408 L36,36 L13.0016447,34.3255814 L4.2875,25.1162791 L0.6,7.53488372 Z"
              id="path-1"></path>
        <mask id="mask-2" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="35.4"
              height="36" fill="white">
            <use xlink:href="#path-1"></use>
        </mask>
        <mask id="mask-4" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="35.4"
              height="36" fill="white">
            <use xlink:href="#path-1"></use>
        </mask>
    </defs>
    <g id="Bitmap-Copy-2">
        <mask id="mask-3" fill="white">
            <use xlink:href="#path-1"></use>
        </mask>
        <g id="Mask" attr.stroke="{{fillColor}}" mask="url(#mask-2)" stroke-width="4" fill-opacity="0.2"
           attr.fill="{{fillColor}}" fill-rule="nonzero" stroke-dasharray="3">
            <use mask="url(#mask-4)" xlink:href="#path-1"></use>
        </g>
    </g>
</svg>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DriverGroup } from '../../../../../interfaces/group';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-driver-group-poi',
  templateUrl: './driver-group-poi.component.html',
  styleUrls: ['./driver-group-poi.component.scss']
})
export class DriverGroupPoiComponent implements OnInit, OnDestroy {
  protected subscriptions = new Subscription();
  public driverG: DriverGroup;

  constructor(protected route: ActivatedRoute) {
  }


  ngOnInit() {
    this.routeSub();
  }

  protected routeSub() {
    this.subscriptions.add(this.route.parent.data.subscribe(({driverGroup}) => {
      this.driverG = driverGroup;
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'app-overflow-spinner',
  templateUrl: './overflow-spinner.component.html',
  styleUrls: ['./overflow-spinner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverflowSpinnerComponent implements OnInit, AfterViewInit {
  @HostBinding('class.overflow-spinner') spinerClass = true;
  @HostBinding('class.overflow-spinner--fixed') get isFixed(){
   return  this.type === 'fixed';
  };
  @HostBinding('class.overflow-spinner--show')
  @Input() showSpinner = false;
  @Input() type: 'absolute' | 'fixed' = 'absolute';

  constructor(private elRef: ElementRef) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.type === 'absolute') {
      this.elRef.nativeElement.parentElement.classList.add('overfolw-spiner__wrapper');
    }
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { AuthGuardService } from '../../core/guards/auth-guard.service';
import { DashboardViewComponent } from './dashboard-view/dashboard-view.component';
import { PermissionsGuard } from '../../core/guards/permissions.guard';
import { PERMISSIONS } from '../../core/services/permission.service';
import { NoAccessComponent } from './no-access/no-access.component';
import { PermissionsRedirectGuard } from '../../core/guards/permissions_redirect.guard';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'map',

      },
      {
        path: 'dashboard',
        component: DashboardViewComponent,
        canActivate: [PermissionsRedirectGuard],
        data: {
          permissions: [PERMISSIONS.WEB_DASHBOARD, PERMISSIONS.BI_VIEW]
        }
      },
      {
        path: 'vehicles',
        loadChildren: () => import('./vehicles/vehicles-view.module').then(m => m.VehiclesViewModule),
        canActivate: [PermissionsGuard],
        data: {
          permissions: [PERMISSIONS.WEB_VEHICLES]
        }
      },
      // connected boat
      {
        path: 'boats',
        loadChildren: () => import('./vehicles/vehicles-view.module').then(m => m.VehiclesViewModule),
        canActivate: [PermissionsGuard],
        data: {
          permissions: [PERMISSIONS.WEB_VEHICLES]
        }
      },
      {
        path: 'drivers',
        loadChildren: () => import('./drivers/drivers-view.module').then(m => m.DriversViewModule),
        canActivate: [PermissionsGuard],
        data: {
          permissions: [PERMISSIONS.WEB_DRIVERS]
        }
      },
      {
        path: 'driving-style',
        loadChildren: () => import('./driving-style/driving-style-view.module').then(m => m.DrivingStyleViewModule),
        canActivate: [PermissionsGuard],
        data: {
          permissions: [PERMISSIONS.WEB_DRIVING_STYLE]
        }
      },
      {
        path: 'fuel',
        loadChildren: () => import('./fuel/fuel-view.module').then(m => m.FuelViewModule),
        canActivate: [PermissionsGuard],
        data: {
          permissions: [PERMISSIONS.WEB_FUEL]
        }
      },
      {
        path: 'zones',
        loadChildren: () => import('./zones/zones-view.module').then(m => m.ZonesViewModule),
        canActivate: [PermissionsGuard],
        data: {
          permissions: [PERMISSIONS.WEB_ZONES]
        }
      },
      {
        path: 'notifications',
        loadChildren: () => import('./notifications/notifications-view.module').then(m => m.NotificationsViewModule),
        canActivate: [PermissionsGuard],
        data: {
          permissions: [PERMISSIONS.WEB_NOTIFICATIONS]
        }
      },
      {
        path: 'account',
        loadChildren: () => import('./account/account-view.module').then(m => m.AccountViewModule),
        canActivate: [PermissionsGuard],
        data: {
          permissions: [PERMISSIONS.WEB_ACCOUNT]
        }
      },

      {
        path: 'map',
        loadChildren: () => import('./map/map-view.module').then(m => m.MapViewModule),
        canActivate: [PermissionsGuard],
        data: {
          permissions: [PERMISSIONS.WEB_MAP]
        }
      },
      {
        path: 'work',
        loadChildren: () => import('./work/work-view.module').then(m => m.WorkViewModule),
        canActivate: [PermissionsGuard],
        data: {
          permissions: [PERMISSIONS.WEB_WORK]
        }
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        canActivate: [PermissionsGuard],
        data: {
          permissions: [PERMISSIONS.WEB_REPORTS]
        }
      },
      {
        path: 'no-access',
        component: NoAccessComponent,
      },
      {
        path: 'poi',
        loadChildren: () => import('./poi/poi.module').then(m => m.PoiModule),
        canActivate: [PermissionsGuard],
        data: {
          permissions: [PERMISSIONS.WEB_POI]
        }
      },
      {
        path: 'balance',
        loadChildren: () => import('./balance/balance-view.module').then(m => m.BalanceViewModule),
        canActivate: [PermissionsGuard],
        data: {
          permissions: [PERMISSIONS.WEB_BALANCE]
        }
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin-view.module').then(m => m.AdminViewModule),
        canActivate: [PermissionsGuard],
        data: {
          permissions: [PERMISSIONS.WEB_ADMIN]
        }
      },
    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {
}

<app-popup-view class="d-flex flex-fill justify-content-start align-items-stretch flex-column">
    <div class="flex-grow-0">
        <app-ride-popup-header [ride]="ride" *ngIf="ride"></app-ride-popup-header>
        <div class="border-bottom"></div>
    </div>
    <div class="p-xm flex-grow-1">
        <div class="d-flex justify-content-between align-items-center mb-xm"  >
            <h3 class="form-header" translate>BASIC_DATA</h3>
            <button class="btn-primary btn btn-icon" (click)="addPoi()" ngbTooltip="{{'ADD_POI'|translate}}" placement="left" *ngIf="isNewView || isEditMode"><i
                    class="icon-plus"></i></button>
        </div>
        <div class="d-flex justify-content-between align-items-start" [formGroup]="forms.ride">
            <div>
                <app-poi-select [max]="1" [multi]="false" formControlName="poi"
                ></app-poi-select>
            </div>
            <div>
                <app-select-input
                        [max]="1"
                        [items]="statuses"
                        [multiple]="false"
                        bindLabel="label"
                        formControlName="status"
                        bindValue="value"
                ><label for="">Status</label></app-select-input>
            </div>
        </div>

    </div>
    <div class="p-xm d-flex justify-content-end align-items-center border-top">
        <button class="btn btn-primary btn-border px-xxl mr-m" (click)="cancel()" translate>
            {{(isEditMode ? 'CANCEL' : 'EXIT')|translate}}
        </button>
        <button class="btn btn-primary px-xxl" (click)="handlerSave()" [disabled]="pending" *ngIf="showButton">
            <app-spinner [showSpinner]="pending"></app-spinner>
            {{(isNewView || isEditMode ? 'SAVE' : 'EDIT')|translate}}
        </button>
    </div>
</app-popup-view>

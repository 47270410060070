<div class="form-group">
    <div class="form-grou__wrapper">
        <ng-content></ng-content>
        <div class="d-flex justify-content-start align-items-center flex-fill flex-row">
            <ng-container *ngIf="!only || only ==='gt'">
                <span translate>FROM</span>
                <input type="number" [ngModel]="this.inputValue.gt" [disabled]="isDisabled"
                       (ngModelChange)="modelChange($event, 'gt')" [ngModelOptions]="{updateOn: 'change'}">
            </ng-container>
            <ng-container *ngIf="!only || only ==='lt'">
                <span translate>TO</span>
                <input type="number" [ngModel]="this.inputValue.lt" [disabled]="isDisabled"
                       (ngModelChange)="modelChange($event, 'lt')" [ngModelOptions]="{updateOn: 'change'}" >
            </ng-container>

        </div>
        <i class="icon-x" (click)="clear()" *ngIf="showClear"></i>
    </div>
</div>

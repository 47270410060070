<div class="d-flex justify-content-between align-items-stretch flex-column flex-fill h-100">
    <div [ngSwitch]="notification.type" >
      <ng-container *ngSwitchCase="notificationType.NORM_ADHOC">
        <app-map height="600px">
          <app-map-marker-poi *ngIf="notificationCoordinates !== null" [coordinates]="notificationCoordinates"></app-map-marker-poi>
      </app-map>
      </ng-container>
      <ng-container *ngSwitchCase="notificationType.NORM">
        <app-notification-norm [notification]="notification.data.NORM"></app-notification-norm>
      </ng-container>
      <ng-container *ngSwitchCase="notificationType.ZONE_CHANGE">
        <app-notification-norm [notification]="notification.data.ZONE_CHANGE"></app-notification-norm>
      </ng-container>
      <ng-container *ngSwitchCase="notificationType.REMINDER">
        <app-notification-norm [notification]="notification.data.REMINDER"></app-notification-norm>
      </ng-container>
      <ng-container *ngSwitchCase="notificationType.NORM_AGGREGATE">
        <div>
          <span>Pojazdy, które spełniły normę</span>
          <ul>
            <li *ngFor="let v of vehiclesFulfilled">{{ v.name }}</li>
          </ul>
          <span>Pojazdy, które nie spełniły normy</span>
          <ul>
            <li *ngFor="let v of vehiclesNotFulfilled">{{ v.name }}</li>
          </ul>
        </div>
      </ng-container>
      <span *ngSwitchDefault>nie mam typu notyfikacji</span>
  </div>
</div>





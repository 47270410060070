import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../../../core/guards/auth-guard.service';
import { RidePopupComponent } from './ride-popup.component';
import { RideResolve } from './ride.resolve';

const routes: Routes = [
  {
    path: 'ride/:id',
    outlet: 'popup',
    canActivate: [AuthGuardService],
    component: RidePopupComponent,
    resolve: {
      ride: RideResolve,
    }
  },
  {
    path: 'ride/:id',
    outlet: 'inside',
    canActivate: [AuthGuardService],
    component: RidePopupComponent,
    resolve: {
      ride: RideResolve,
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [RideResolve],
  exports: [RouterModule]
})
export class RidePopupRoutingModule {
}

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {MarkerClass} from '../../marker.class';
import {RideType} from '../../../../../interfaces/ride';
import {MapService} from '../../map.service';

@Component({
  selector: 'app-map-marker-poi',
  templateUrl: './map-marker-poi.component.html',
  styleUrls: ['./map-marker-poi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MapMarkerPoiComponent extends MarkerClass implements OnDestroy, OnChanges {
  @Input() coordinates: any;
  @Input() type: RideType;
  @Input() name: string;
  @Input() color = '#F07D7D';
  @Input() radius: number;
  @Input() showCircle = false;
  @Input() autoZoom: boolean;
  @HostBinding('class.is-hover')
  @Input() isHover = false;
  @HostBinding('class.show-label')
  @Input() showLabel = false;
  markerGroupName: string = 'MapMarkerPoiComponent';
  constructor(mapService: MapService, el: ElementRef) {
    super(mapService, el);
  }

  markerHover() {
    this.hover.emit();
  }

  markerHoverLeave() {
    this.leaveHover.emit();
  }

  markerClickEvent() {
    this.markerClick.emit();
  }

  domIconOptions() {
    return {
      onAttach: (clonedElement, domIcon, domMarker) => {
        clonedElement.addEventListener('mouseenter', this.markerHover.bind(this));
        clonedElement.addEventListener('click', this.markerClickEvent.bind(this));
        clonedElement.addEventListener('mouseleave', this.markerHoverLeave.bind(this));
      },
      onDetach: (clonedElement, domIcon, domMarker) => {
        clonedElement.removeEventListener('mouseenter', this.markerHover.bind(this));
        clonedElement.removeEventListener('click', this.markerClickEvent.bind(this));
        clonedElement.removeEventListener('mouseleave', this.markerHoverLeave.bind(this));
      }
    };
  }
  getCoords() {
    return this.coordinates;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.coordinates && this.marker) {
      this.marker.setGeometry(this.getCoords());
      if (changes.name && this.marker) {
        this.doUpdate = true;
      }
    } else if ((changes.color || changes.isHover || changes.showLabel) && this.marker) {
      this.doUpdate = true;
    }
  }

}

<div class="d-flex justify-content-between align-items-stretch flex-column flex-fill h-100">
    <div class="overflow-auto flex-grow-1">
        <div [formGroup]="forms?.main" class="p-xm">
            <h3 class="form-header mt-xl" translate>BASIC_DATA</h3>
            <div class="row">
                <div class="col-4">
                    <app-form-group>
                        <label translate>FIRST_NAME</label>
                        <input app-form formControlName="firstName" type="text">
                        <app-error-message [control]="forms?.main.get('firstName')"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4">
                    <app-form-group>
                        <label translate>LAST_NAME</label>
                        <input app-form formControlName="lastName" type="text">
                        <app-error-message [control]="forms?.main.get('lastName')"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4">
                    <app-form-group>
                        <label translate>PHONE_NUMBER</label>
                        <input app-form formControlName="phoneNumber" type="text">
                        <app-error-message [control]="forms?.main.get('phoneNumber')"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4  mt-m">
                    <app-form-group>
                        <label translate>EMAIL</label>
                        <input app-form formControlName="email" type="text">
                        <app-error-message [control]="forms?.main.get('email')"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-8 mt-m">
                    <app-form-group>
                        <label translate>Avatar</label>
                        <input app-form formControlName="avatarUrl" type="text">
                        <app-error-message [control]="forms?.main.get('avatarUrl')"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4 mt-m">
                  <app-form-group>
                      <label translate>CARD_ID</label>
                      <input app-form formControlName="cardId" type="text">
                      <app-error-message [control]="forms?.main.get('cardId')"></app-error-message>
                  </app-form-group>
              </div>
            </div>
        </div>
        <div class="p-xm" *ngIf="!isNewView">
            <h3 class="form-header mt-xl" translate>DRIVERS_GROUPS</h3>
            <app-select-input
                    [items]="groups$|async"
                    [multiple]="true"
                    [max]="2"
                    selectClass="w-50"
                    [allowClear]="true"
                    [showTags]="true"
                    [searchable]="true"
                    bindLabel="name"
                    bindValue="id"
                    [formControl]="forms?.group"
            >
                <label translate>DRIVER_FORM.GROUPS</label>
            </app-select-input>
            <app-error-message [control]="forms?.group"></app-error-message>
        </div>
        <div class="p-xm" *ngIf="!isNewView && permissionService.hasPermission(permissions.USER_VIEW)">
          <h3 class="form-header mt-xl" translate>USER</h3>
          <app-select-input
                  [items]="users$|async"
                  [multiple]="false"
                  selectClass="w-50"
                  [allowClear]="true"
                  [searchable]="true"
                  bindLabel="login"
                  bindValue="id"
                  [allowClear]="true"
                  [formControl]="forms?.main.get('userId')"
          >
              <label translate>USERS</label>
          </app-select-input>
          <app-error-message [control]="forms?.group"></app-error-message>
      </div>
    </div>
    <div class="p-xm d-flex justify-content-end align-items-center border-top">
        <button class="btn btn-primary btn-border px-xxl mr-m" (click)="cancel()" translate>
            {{(isEditMode ? 'CANCEL' : 'EXIT')|translate}}
        </button>
        <button class="btn btn-primary px-xxl" (click)="handlerSave()" [disabled]="pending" *ngIf="showButton">
            <app-spinner [showSpinner]="pending"></app-spinner>
            {{(isNewView || isEditMode ? 'SAVE' : 'EDIT')|translate}}
        </button>
    </div>
</div>


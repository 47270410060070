import { Injectable } from '@angular/core';
import { ConfigService } from '../../../core/services/config.service';
import { HttpClient } from '@angular/common/http';
import { PoiGroup } from '../../../interfaces/group';
import { PoiSyncType } from './poi.service';
import { ApiServiceWithSub } from '../../../classes/api-service-with-sub';
import { AuthService } from '../../../core/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PoiGroupsService extends ApiServiceWithSub<PoiGroup> {
  protected inludeList = ['count-pois', 'example-pois'];
  constructor(config: ConfigService,
              authService: AuthService,
              http: HttpClient) {
    super('/poi-groups', config, http, authService);
    this.initList();
  }

  syncPoiGroup(type: PoiSyncType, id: string, groupIds: string[]) {
    return this.http.post(`${this.config.apiUrl}/${type}/${id}/poi-groups`, {groupIds});
  }

  listPoiGroupByType(type: PoiSyncType, id: string) {
    return this.http.get(`${this.config.apiUrl}/${type}/${id}/poi-groups`);
  }
}


import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { modalFadeTrigger } from '../../../core/animations';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
  animations: [modalFadeTrigger]
})
export class DeleteModalComponent implements OnInit {
  @HostBinding('@routeFadeState')
  @HostBinding('class.modal-info') true;
  public pending = false;
  public formErrors: any;
  @Input() text;
  @Input() deleteFunc: Function;
  private _txt = {
    header: 'DELETE',
    body: 'DELETE',
    submit: 'DELETE'
  };
  public txt;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.txt = Object.assign({}, this._txt, this.text);
  }

  onDeleteClick() {
    this.formErrors = null;
    if (this.deleteFunc) {
      this.pending = true;
      this.deleteFunc().subscribe(() => {
          this.activeModal.close(true);
        },
        (err) => {
          this.pending = false;
          this.formErrors = err;
        });
    }
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { MapService } from '../map.service';
import { Permission, PermissionService } from '../../../../core/services/permission.service';
import { TranslateService } from '@ngx-translate/core';


export interface ContextMenuItem {
  label: string;
  type: string;
  permission?: Permission;
}

export interface ContextMenuItemEvent {
  type: string;
  point: H.geo.Point;
}

@Component({
  selector: 'app-map-contextmenu',
  templateUrl: './map-contextmenu.component.html',
  styleUrls: ['./map-contextmenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MapContextmenuComponent implements OnInit, OnDestroy {
  @Input() items: ContextMenuItem[];
  @Input() disabled = false;
  @Output() itemClick: EventEmitter<ContextMenuItemEvent> = new EventEmitter();
  protected subsciption: Subscription = new Subscription();
  private mapInstance;

  constructor(private mapService: MapService,
    private permissionService: PermissionService,
    private translateService: TranslateService) {
  }

  ngOnInit() {
    this.subsciption.add(this.mapService.mapIsLoad.subscribe(() => {
      this.mapInstance = this.mapService.mapInstance;
      this.initContext();
    }));
  }

  initContext() {
    if (this.mapInstance) {
      this.mapInstance.addEventListener('contextmenu', this.handlerContextMenuEvent.bind(this));
    }
  }

  handlerContextMenuEvent(e) {
    if (this.disabled) {
      return;
    }
    const point = this.mapService.mapInstance.screenToGeo(e.viewportX, e.viewportY);
    e.items.push(...this.items.filter(value => value.permission ? this.permissionService.hasPermission(value.permission) : true).map(item => new H.util.ContextItem({
      label: this.translateService.instant(item.label),
      callback: (event) => {
        this.itemClick.emit({
          point,
          type: item.type,
        });
      }
    })));
  }

  ngOnDestroy(): void {
    this.subsciption.unsubscribe();
    if (this.mapService.mapInstance) {
      this.mapService.mapInstance.removeEventListener('contextmenu', this.handlerContextMenuEvent.bind(this));
    }
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PopupViewService } from '../../../shared/components/popup-view/popup-view.service';
import { NormsService } from '../../../core/services/norms.service';
import { switchMap, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NormTemplate, NormTemplateConfiguration } from '../../../interfaces/norms';

@Component({
  selector: 'app-norms-popup',
  templateUrl: './norms-popup.component.html',
  styleUrls: ['./norms-popup.component.scss']
})
export class NormsPopupComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  public pending = true;
  public norm: NormTemplate;
  public configuration: NormTemplateConfiguration;

  constructor(private activeRoute: ActivatedRoute,
              private popupService: PopupViewService,
              private service: NormsService) {
  }

  ngOnInit() {
    this.subscription = this.activeRoute.params.pipe(
      tap(() => this.pending = true),
      switchMap(({id, configId}) => this.service.templateWithConfiguration(id, configId)),
    ).subscribe(res => {
      this.pending = false;
      const {
        template,
        configuration
      } = res;
      this.norm = template;
      this.configuration = configuration;
    }, () => {
      this.pending = false;
    });
  }

  cancel() {
    this.popupService.close();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

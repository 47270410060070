import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { PoiService, PoiSyncType } from '../../../../views/dashboard/poi/poi.service';
import { PopupViewService } from '../../../components/popup-view/popup-view.service';
import { PoiGroupsService } from '../../../../views/dashboard/poi/poi-groups.service';
import { observableWithCatchError } from '../../../../utils/utils';

@Component({
  selector: 'app-poi-sync',
  templateUrl: './poi-sync.component.html',
  styleUrls: ['./poi-sync.component.scss']
})
export class PoiSyncComponent implements OnInit, OnChanges {
  public form: UntypedFormGroup;

  public poi$ = this.poiService.list();
  public poiGroup$ = this.poiGroupService.list();
  public pending = false;

  @Input() type: PoiSyncType = 'vehicles';
  @Input() id: string;

  constructor(protected popupService: PopupViewService,
              protected poiService: PoiService,
              protected poiGroupService: PoiGroupsService,
              protected fb: UntypedFormBuilder) {
  }

  fetchSelected() {
    forkJoin(observableWithCatchError(this.poiService.listPoiByType(this.type, this.itemId)),
      observableWithCatchError(this.poiGroupService.listPoiGroupByType(this.type, this.itemId)))
      .subscribe(res => {
        this.form.patchValue({
          poi: res[0].map(({id}) => id),
          poi_group: res[1].map(({id}) => id),
        });
      });
  }

  ngOnInit() {
    this.form = this.fb.group({
        poi_group: [],
        poi: [],
      }
    );
  }

  sync() {
    this.pending = true;
    const values = this.form.value;
    forkJoin(this.poiService.syncPoi(this.type, this.itemId, values.poi || []),
      this.poiGroupService.syncPoiGroup(this.type, this.itemId, values.poi_group || []))
      .subscribe(res => {
        this.pending = false;
      }, err => {
        this.pending = false;
      });
  }

  cancel() {
    this.popupService.close();
  }

  get itemId() {
    return this.id;
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.id) {
      this.fetchSelected();
    }
  }
}

<div class="d-flex justify-content-end align-items-center">
    <div class="date-range">
        <app-date-range-filter (changeRange)="dateFilterChange($event)" [date]="dateFilters"
                              [eventOnInit]="false"></app-date-range-filter>
    </div>
</div>
<div class="scroll-wrapper position-relative pt-0">
  <ngx-charts-line-chart
    [view]="view"
    [legend]="legend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxis]="xAxis"
    [yAxis]="yAxis"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [timeline]="timeline"
    [results]="multi"
    [xAxisTickFormatting]='formatAxisLabel'
    >
    <ng-template #seriesTooltipTemplate let-model="model">
        <p>{{model[0].extras.eventDate| date : 'yyyy-MM-dd HH:mm'}}</p>
        <p>{{ 'FUEL_LEVEL' | translate }}: {{model[0].value}} l</p>
        <p>{{ 'TOTAL_DISTANCE' | translate }}: {{model[0].name}} km</p>
    </ng-template>

    <ng-template #tooltipTemplate let-model="model">
      <p>{{model.extras.eventDate| date : 'yyyy-MM-dd HH:mm'}}</p>
      <p>{{ 'FUEL_LEVEL' | translate }}: {{model.value}} l</p>
      <p>{{ 'TOTAL_DISTANCE' | translate }}: {{model.name}} km</p>
  </ng-template>
  </ngx-charts-line-chart>
</div>
<div class="col-4" *ngIf="showCalibration">
  <input [(ngModel)]="calibrationString" >
  <button class="btn btn-primary px-xxl" (click)="recalibrateChart()" >
    Przelicz
  </button>
  <app-switch label="Pokaż napięcie"  [ngModel]="showVoltageValue"
                    (ngModelChange)="changeShowVoltage()"></app-switch>
</div>
<app-overflow-spinner [showSpinner]="pending"></app-overflow-spinner>

import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CollectionUpdateEvent } from '../../../../classes/api-service.class';
import { Subscription } from 'rxjs';
import { RouterHelperService } from '../../../../core/services/router-helper.service';
import { TelemetryService } from '../../../../core/services/telemetry.service';
import { UserService } from '../../../../core/services/user.service';
import { User } from '../../../../interfaces/user';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss']
})
export class UserHeaderComponent implements OnInit {
  @Input() user: User;
  private sub: Subscription;

  constructor(private router: RouterHelperService,
              private service: UserService,
              private telemetryService: TelemetryService,
              private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.sub = this.service.collectionUpdate$.subscribe((res: CollectionUpdateEvent<User>) => {
      if (res.event === 'update' && (res.item as User).id === this.user.id) {
        this.user = Object.assign({}, this.user, res.item);
      }
      this.cd.detectChanges();
    });

  }

}

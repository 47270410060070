<div class="d-flex justify-content-between align-items-stretch flex-column flex-fill h-100">
    <div class="overflow-auto flex-grow-1">
        <div [formGroup]="forms?.main" class="p-xm">
            <h3 class="form-header mt-xl" translate>USER_DETAILS</h3>
            <div class="row">
                <div class="col-4">
                    <app-form-group>
                        <label translate>FIRST_NAME</label>
                        <input app-form formControlName="firstName" type="text" id="firstName">
                        <app-error-message [control]="forms?.main.get('firstName')"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4">
                    <app-form-group>
                        <label translate>LAST_NAME</label>
                        <input app-form formControlName="lastName" type="text" id="lastName">
                        <app-error-message [control]="forms?.main.get('lastName')"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4">
                    <app-form-group>
                        <label translate>EMAIL</label>
                        <input app-form formControlName="email" type="email" id="email">
                        <app-error-message [control]="forms?.main.get('email')"
                          [apiError]="formErrors" name="ENTITY_ALREADY_EXISTS" context="email"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4 mt-m">
                    <app-form-group>
                        <label translate>LOGIN</label>
                        <input app-form formControlName="login" type="text" id="login">
                        <app-error-message [control]="forms?.main.get('login')"
                        [apiError]="formErrors" name="ENTITY_ALREADY_EXISTS" context="login"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4 mt-m" *ngIf="forms?.main.get('password')">
                    <app-form-group>
                        <label translate>PASSWORD</label>
                        <input app-form formControlName="password" type="password" id="password">
                        <app-error-message [control]="forms?.main.get('password')"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-8 mt-m">
                    <app-form-group>
                        <label translate>Avatar</label>
                        <input app-form formControlName="avatarUrl" type="text" id="avatarUrl">
                        <app-error-message [control]="forms?.main.get('avatarUrl')"></app-error-message>
                    </app-form-group>
                </div>
            </div>
        </div>
        <div [formGroup]="forms?.groups">
            <div class="p-xm">
                <h3 class="form-header mt-xl" translate>VEHICLES</h3>
                <app-vehicles-select
                        bindValue="id"
                        formControlName="vehicles"
                >
                </app-vehicles-select>
                <h3 class="form-header mt-xl" translate>VEHICLE_GROUP</h3>
                <app-select-input
                        [items]="vehiclesGroups$|async"
                        [multiple]="true"
                        [max]="1"
                        selectClass="w-50"
                        [allowClear]="true"
                        [showTags]="true"
                        [searchable]="true"
                        bindLabel="name"
                        bindValue="id"
                        formControlName="vehiclesGroups"
                >
                    <label translate>VEHICLE_GROUPS</label>
                </app-select-input>
            </div>
            <div class="p-xm">
                <h3 class="form-header mt-0" translate>DRIVERS</h3>
                <app-select-input
                        [items]="drivers$|async"
                        [multiple]="true"
                        [max]="1"
                        selectClass="w-50"
                        [allowClear]="true"
                        [showTags]="true"
                        [searchable]="true"
                        bindValue="id"
                        bindLabel="fullName"
                        formControlName="drivers"
                >
                    <label translate>DRIVER</label>
                </app-select-input>
                <h3 class="form-header mt-xl" translate>DRIVERS_GROUPS</h3>
                <app-select-input
                        [items]="driversGroups$|async"
                        [multiple]="true"
                        [max]="1"
                        selectClass="w-50"
                        [allowClear]="true"
                        [showTags]="true"
                        [searchable]="true"
                        bindLabel="name"
                        bindValue="id"
                        formControlName="driversGroups"
                >
                    <label translate>DRIVERES_GROUPS</label>
                </app-select-input>
                <div *hasPermission="updateUserRolesPermission">
                  <h3 class="form-header mt-xl" translate>ROLES</h3>
                  <app-select-input
                          [items]="roles$|async"
                          [multiple]="true"
                          [max]="1"
                          selectClass="w-50"
                          [allowClear]="true"
                          [showTags]="true"
                          [searchable]="false"
                          bindLabel="name"
                          bindValue="id"
                          formControlName="roles"
                  >
                      <label translate>ROLES</label>
                  </app-select-input>
                </div>
            </div>
        </div>
    </div>
    <div class="p-xm d-flex justify-content-end align-items-center border-top">
        <button class="btn btn-primary btn-border px-xxl mr-m" (click)="cancel()" translate>
            {{(isEditMode ? 'CANCEL' : 'EXIT')|translate}}
        </button>
        <button class="btn btn-primary px-xxl" (click)="handlerSave()" [disabled]="pending" *ngIf="showButton">
            <app-spinner [showSpinner]="pending"></app-spinner>
            {{(isNewView || isEditMode ? 'SAVE' : 'EDIT')|translate}}
        </button>
    </div>
</div>


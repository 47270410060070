<app-popup-view>
    <div class="d-flex flex-column justify-content-between align-items-stretch" style="height:100%">
        <div class="flex-grow-0">
            <div class="p-xm">
                <app-user-header [user]="(user$|async)"></app-user-header>
            </div>
            <app-popup-navigation [items]="navItems"></app-popup-navigation>
        </div>
        <div class="flex-grow-1 u-route-popup-wrapper">
            <router-outlet></router-outlet>
        </div>
    </div>
</app-popup-view>

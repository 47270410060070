import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StickyDirective } from './sticky.directive';
import { RoutePopupDirective } from './route-popup.directive';
import { HeightFromTopDirective } from './height-from-top.directive';
import { HasPermissionDirective } from './has-permission.directive';
import {ResizerDirective} from './resizer.directive';

@NgModule({
  declarations: [StickyDirective, RoutePopupDirective, HeightFromTopDirective, HasPermissionDirective, ResizerDirective],
  imports: [
    CommonModule
  ],
  exports: [StickyDirective, RoutePopupDirective, HeightFromTopDirective, HasPermissionDirective, ResizerDirective]
})
export class DirectivesModule {
}

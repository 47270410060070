import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { VehicleWithTelemetryAndRide } from '../../../interfaces/telemetry';

@Component({
  selector: 'app-header-location-status',
  templateUrl: './header-location-status.component.html',
  styleUrls: ['./header-location-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderLocationStatusComponent implements OnInit {
  @Input() vehicle: VehicleWithTelemetryAndRide;

  constructor() {
  }

  ngOnInit() {
  }

}

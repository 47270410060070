<div class="position-relative" ngbDropdown placement="bottom-right">
    <a ngbDropdownToggle class="dropdown-toggle m-0 pointer">
       {{selectedLanguage|uppercase}}
    </a>
    <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
        <li>
            <a class="dropdown-item" *ngFor="let language of languages" translate="" (click)="setLanguage(language)">{{'LANGUAGES.'+language}}</a>
        </li>
    </ul>
</div>

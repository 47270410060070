import { Component } from '@angular/core';
import { NewEditClass } from '../../../../../classes/new-edit.class';
import { DriverGroup } from '../../../../../interfaces/group';
import { ActivatedRoute } from '@angular/router';
import { PopupViewService } from '../../../../../shared/components/popup-view/popup-view.service';
import { ApiValidatorService } from '../../../../../core/services/api-validator.service';
import { RouterHelperService } from '../../../../../core/services/router-helper.service';
import { DriversGroupsService } from '../../../../../core/services/drivers-groups.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { FullNamePipe } from '../../../../../shared/pipes/full-name.pipe';
import { DriversService } from '../../../../../core/services/drivers.service';
import { PERMISSIONS, PermissionService } from '../../../../../core/services/permission.service';

@Component({
  selector: 'app-driver-group-details',
  templateUrl: './driver-group-details.component.html',
  styleUrls: ['./driver-group-details.component.scss'],
  providers: [FullNamePipe],
})
export class DriverGroupDetailsComponent extends NewEditClass {
  protected editPermission = PERMISSIONS.DRIVER_GROUP_UPDATE;
  protected newPermission = PERMISSIONS.DRIVER_GROUP_CREATE;
  public driverGroup: DriverGroup;
  public drivers$ = this.driverService.list$()
    .pipe(map(res => res.map(driver => ({...driver, fullName: this.fullName.transform(driver)}))), shareReplay());

  constructor(private route: ActivatedRoute,
              popupService: PopupViewService,
              apiValidator: ApiValidatorService,
              private routerHelper: RouterHelperService,
              private service: DriversGroupsService,
              private fullName: FullNamePipe,
              permissionService: PermissionService,
              private driverService: DriversService,
              fb: UntypedFormBuilder) {
    super(popupService, apiValidator, fb, permissionService);
  }

  addRouteSub() {
    this.subscriptions.add(
      this.route.parent.data
        .subscribe(res => {
          this.driverGroup = res.driverGroup;
          this.resetForm();
          this.pending = false;
        }));
  }

  resetForm() {
    this.forms.main.patchValue(this.driverGroup);
    this.formGroup();
  }

  buildForm() {
    this.forms.main = this.fb.group({
      name: [null, Validators.required]
    });
  }

  formGroup() {
    this.forms['group'] = this.fb.control(null);
    this.toggleDisableForm();
    this.service.driversInGroup(this.driverGroup.id)
      .subscribe(res => {
        this.forms.group.setValue(res.map(({id}) => id));
      });
  }

  save() {
    this.service.add(this.forms.main.value).subscribe(() => {
      this.pending = false;
      this.popupService.close();
    }, this.errorHandler.bind(this));
  }

  update() {
    this.service.sync(this.driverGroup.id, this.forms.group.value || [])
      .pipe(switchMap(res => this.service.partialUpdate(this.driverGroup.id, this.forms.main.value)))
      .subscribe((res) => {
        this.pending = false;
        this.isEditMode = false;
        this.toggleDisableForm();
      }, this.errorHandler.bind(this));
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../../../core/guards/auth-guard.service';
import { NormsPopupComponent } from './norms-popup.component';

const routes: Routes = [
  {
    path: 'norms/:id',
    component: NormsPopupComponent,
    outlet: 'popup',
    canActivate: [AuthGuardService],
  },
  {
    path: 'norms/:id/:configId',
    component: NormsPopupComponent,
    outlet: 'popup',
    canActivate: [AuthGuardService],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NormsPopupRoutingModule {
}

<app-popup-view>
    <div class="d-flex flex-fill justify-content-between align-items-stretch flex-column h-100">
        <div class="p-xm c-norm__header" *ngIf="workSchedule">
            <div class="d-flex justify-content-start align-items-start">
                <div class="c-norm____header-desc">
                    <h4 translate="">WORK_SCHEDULE</h4>
                    <h2>{{workSchedule?.name}}</h2>
                </div>
            </div>

        </div>
        <div class="flex-grow-1 border-top p-xm overflow-auto">
            <div [formGroup]="forms?.main">
                <h3 class="form-header" translate>WORK_SCHEDULE_DATA</h3>
                <div class="row mb-xm">
                    <div class="col-9">
                        <app-form-group>
                            <label translate>NAME</label>
                            <input app-form formControlName="name" type="text" id="name">
                            <app-error-message [control]="forms?.main.get('name')"></app-error-message>
                        </app-form-group>
                    </div>
                    <div class="col-3  mb-xm">
                        <app-number-input formControlName="priority" minValue="1" [label]="'PRIORITY'|translate"></app-number-input>
                        <app-error-message [control]="forms?.main.get('priority')"></app-error-message>
                    </div>
                </div>
                <div class="row mb-xm">
                    <div class="col-3">
                        <app-form-group (click)="d.open()" [noFocus]="true" iconPosition="right" icon="icon-calendar">
                            <label translate>FROM</label>
                            <input formControlName="dateFrom"
                                   outsideDays="collapsed"
                                   #inputFrom
                                   [maxDate]="dateToNgb(inputTo.value)"
                                   app-form
                                   (focus)="stopEvents(inputFrom)"
                                   autoClose="outside"
                                   placement="bottom-left"
                                   ngbDatepicker #d="ngbDatepicker"/>
                            <app-error-message [control]="forms?.main.get('dateFrom')"></app-error-message>
                        </app-form-group>
                    </div>
                    <div class="col-3">
                        <app-form-group (click)="d2.open()" [noFocus]="true" iconPosition="right" icon="icon-calendar">
                            <label translate>TO</label>
                            <input formControlName="dateTo"
                                   outsideDays="collapsed"
                                   #inputTo
                                   app-form
                                   [minDate]="dateToNgb(inputFrom.value)"
                                   (focus)="stopEvents(inputTo)"
                                   autoClose="outside"
                                   placement="bottom-left"
                                   ngbDatepicker #d2="ngbDatepicker"/>
                            <app-error-message [control]="forms?.main.get('dateTo')"></app-error-message>
                        </app-form-group>
                    </div>


                    <div class="col-3">
                        <app-select-input
                                [items]="types"
                                [multiple]="false"
                                bindLabel="name"
                                bindValue="id"
                                [max]="1"
                                selectClass="w-100"
                                [allowClear]="false"
                                formControlName="timeType"
                        >
                            <label translate>TYPE</label>
                        </app-select-input>
                    </div>
                    <div class="col-3">
                        <app-select-input
                                [items]="entityTypesKeys"
                                [multiple]="false"
                                [max]="1"
                                bindLabel="name"
                                bindValue="id"
                                selectClass="w-100"
                                [allowClear]="false"
                                formControlName="entityType"
                        >
                            <label translate>ENTITY_TYPE</label>
                        </app-select-input>
                    </div>

                </div>
                <div [ngSwitch]="this.forms.main.get('entityType').value" class="mb-xm">
                    <div *ngSwitchCase="entityTypes.VEHICLE">
                        <h3 class="form-header mt-xl" translate>VEHICLES</h3>
                        <app-vehicles-select
                                bindValue="id"
                                [multi]="false"
                                formControlName="entityId"
                        >
                        </app-vehicles-select>
                    </div>
                    <div *ngSwitchCase="entityTypes.VEHICLE_GROUP" class="mb-xm">
                        <app-select-input
                                [items]="vehiclesGroups$|async"
                                [multiple]="false"
                                [max]="1"
                                selectClass="w-100"
                                [allowClear]="false"
                                [searchable]="true"
                                bindLabel="name"
                                bindValue="id"
                                formControlName="entityId"
                        >
                            <label translate>VEHICLE_GROUPS</label>
                        </app-select-input>
                    </div>

                    <div *ngSwitchCase="entityTypes.DRIVER">
                        <app-select-input
                                [items]="drivers$|async"
                                [multiple]="false"
                                [max]="1"
                                selectClass="w-100"
                                [allowClear]="false"
                                [searchable]="true"
                                bindValue="id"
                                bindLabel="fullName"
                                formControlName="entityId"
                        >
                            <label translate>DRIVER</label>
                        </app-select-input>
                    </div>
                    <div *ngSwitchCase="entityTypes.DRIVER_GROUP">
                        <app-select-input
                                [items]="driversGroups$|async"
                                [multiple]="false"
                                [max]="1"
                                selectClass="w-100"
                                [allowClear]="false"
                                [searchable]="true"
                                bindLabel="name"
                                bindValue="id"
                                formControlName="entityId"
                        >
                            <label translate>DRIVERES_GROUPS</label>
                        </app-select-input>
                    </div>
                    <app-error-message [control]="forms?.main.get('entityId')"></app-error-message>
                </div>
                <div *ngIf="forms.main" formArrayName="timetable" class="mb-xm row">
                    <div class="col-6 mb-xm"
                         *ngFor="let hour of timetable.controls; let i = index;">
                        <app-hour-range-input [formControlName]="i"
                                              [dayNumber]="i"
                        ></app-hour-range-input>
                        <app-error-message [control]="hour"></app-error-message>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-xm d-flex justify-content-end align-items-center border-top">
            <button class="btn btn-primary btn-border px-xxl mr-m" (click)="cancel()" translate>
                {{(isEditMode ? 'CANCEL' : 'EXIT')|translate}}
            </button>
            <button class="btn btn-primary px-xxl" (click)="handlerSave()" [disabled]="pending" *ngIf="showButton">
                <app-spinner [showSpinner]="pending"></app-spinner>
                {{(isNewView || isEditMode ? 'SAVE' : 'EDIT')|translate}}
            </button>
        </div>
    </div>

    <app-overflow-spinner [showSpinner]="pending"></app-overflow-spinner>
</app-popup-view>

import { FuelType, VehicleExtensions } from './../../../../interfaces/vehicle';
import { TelemetryVehicle } from './../../../../interfaces/telemetry';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Vehicle } from '../../../../interfaces/vehicle';
import { DateRangeService, DateRageValue, DateRangeValueType } from '../../../../shared/modules/date-range-filter/date-range.service';
import { TelemetryService } from '../../../../core/services/telemetry.service';
import { debounceTime, map, switchMap, tap, distinctUntilChanged } from 'rxjs/operators';
import { PerspectiveMenuService } from '../../../../shared/modules/perspective-menu/perspective-menu.service';
import { arrayValueFilter, arrayValueFilterByRange } from 'src/app/utils/array-value-filter';
import { DatePipe } from '@angular/common';
import { getAnalogFuelPercentage } from 'src/app/utils/fuel-analog';
import { VehiclesService } from 'src/app/core/services/vehicles.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MapService } from 'src/app/shared/modules/map/map.service';

interface IChartFilter {
  propertyName: string[];
  axisDimmension: string;
}

interface ISensorProperty {
  propertyName: string;
  name: string;
  noDataValue?: number;
  multiplier?: number;
}


@Component({
  selector: 'app-vehicle-chart',
  templateUrl: './vehicle-chart.component.html',
  styleUrls: ['./vehicle-chart.component.scss'],
  providers: [DatePipe, MapService]
})
export class VehicleChartComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  private vehicle: Vehicle;

  public pending = true;
  public dateFilters: DateRageValue = this.dateFilterService.queryToDateRange(this.route.snapshot.queryParams, true)
    || this.dateFilterService.queryToDateRange(this.route.snapshot.queryParams)
    || this.dateFilterService.dateRangeValueByType(DateRangeValueType.today);

  public allSensorProperties: ISensorProperty[] = [];
  public defaultSensorNames: string[] = ['fuelLevelPercents', 'voltageAnalog2'];

  public sensorProperties: ISensorProperty[] = [];

  public dimmensions: string[] = ['DURATION', 'MILAGE'];
  public selectedDimmention: string;

  multi: any[];
  view: any[] = [1160, 500];
  private frames: TelemetryVehicle[];
  public selectedFrame: TelemetryVehicle;

  // options
  legend = true;
  legendTitle = this.translate.instant('LEGEND');
  showLabels = true;
  animations = true;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  xAxisLabel = '';
  yAxisLabel = '';
  timeline = true;

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  public chartForm: UntypedFormGroup = null;
  formSubscription$: Subscription;

  constructor(private route: ActivatedRoute,
              private dateFilterService: DateRangeService,
              private perspectiveMenu: PerspectiveMenuService,
              private datePipe: DatePipe,
              private translate: TranslateService,
              private fb: UntypedFormBuilder,
              public mapService: MapService,
              private vehicleService: VehiclesService,
              private telemetryService: TelemetryService) {
  }

  ngOnInit(): void {
    this.perspectiveMenu.popupSizeType = 'large';
    this.buildForms();
    this.subscription = this.route.parent.data.subscribe(({vehicle}) => {
      this.vehicle = vehicle;
      this.loadAllSensorPropertiesByVehicle();
      this.loadFuelChartData();
    });
  }

  loadAllSensorPropertiesByVehicle() {
    this.allSensorProperties = [
      { propertyName: 'temp1', name: this.translate.instant('TEMPERATURE') + ' 1 (°C)', },
      { propertyName: 'temp2', name: this.translate.instant('TEMPERATURE') + ' 2 (°C)', },
      { propertyName: 'temp3', name: this.translate.instant('TEMPERATURE') + ' 3 (°C)', },
      { propertyName: 'temp4', name: this.translate.instant('TEMPERATURE') + ' 4 (°C)', },
      { propertyName: 'humidity1', name: this.translate.instant('HUMIDITY') + ' 1 (%)', },
      { propertyName: 'humidity2', name: this.translate.instant('HUMIDITY') + ' 2 (%)', },
      { propertyName: 'humidity3', name: this.translate.instant('HUMIDITY') + ' 3 (%)', },
      { propertyName: 'humidity4', name: this.translate.instant('HUMIDITY') + ' 4 (%)', },
      { propertyName: 'outputPower', name: this.translate.instant('OUTPUT_POWER') },
      { propertyName: 'fuelLevelPercents',
          name: (this.vehicle.fuelType === FuelType.electric ?
             this.translate.instant('BATTERY_LEVEL') :
             this.translate.instant('FUEL_LEVEL')) + ' (%)' },
      { propertyName: 'fuelLevelLitres',
          name: (this.vehicle.fuelType === FuelType.electric ?
             this.translate.instant('BATTERY_LEVEL') :
             this.translate.instant('FUEL_LEVEL')) + ' (L)' },
      { propertyName: 'voltageAnalog2', name: this.translate.instant('VOLTAGE') },
      { propertyName: 'accelerator', name: this.translate.instant('FIELDS.ACCELERATOR'), noDataValue: 0, },
      { propertyName: 'adBlueLevelLitres', name: this.translate.instant('FIELDS.ADBLUE_LEVEL_LITRES'), noDataValue: 0, },
      { propertyName: 'adBlueLevelPercents', name: this.translate.instant('FIELDS.ADBLUE_LEVEL_PERCENTS'), noDataValue: 0, },
      { propertyName: 'axle1Load', name: this.translate.instant('FIELDS.AXLE_LOAD') + ' 1' },
      { propertyName: 'axle2Load', name: this.translate.instant('FIELDS.AXLE_LOAD') + ' 2' },
      { propertyName: 'axle3Load', name: this.translate.instant('FIELDS.AXLE_LOAD') + ' 3' },
      { propertyName: 'axle4Load', name: this.translate.instant('FIELDS.AXLE_LOAD') + ' 4' },
      { propertyName: 'axle5Load', name: this.translate.instant('FIELDS.AXLE_LOAD') + ' 5' },
      { propertyName: 'loadWeight', name: this.translate.instant('FIELDS.LOAD_WEIGHT') },
      { propertyName: 'engineOilTemperature', name: this.translate.instant('FIELDS.ENGINE_OIL_TEMPERATURE'), noDataValue: 0, },
      { propertyName: 'engineTemperature', name: this.translate.instant('FIELDS.ENGINE_TEMPERATURE'), noDataValue: 0, },
      { propertyName: 'engineSpeed', name: this.translate.instant('ENGINE_SPEED') + ' (RPM/100)', noDataValue: 0, multiplier: 0.01 },
      { propertyName: 'speed', name: this.translate.instant('SPEED') + ' (km/h)', noDataValue: 0, },
    ];
  }

  chartClick(event) {
    const dimmension: string = (this.chartForm.value as IChartFilter).axisDimmension;
    const key: any = dimmension === 'DURATION' ? new Date(event.name).getTime() : parseInt(event.name, 10);

    const frame: TelemetryVehicle = this.frames.find(
      f => dimmension === 'DURATION' ?
        new Date(f.eventDate).getTime() === key :
        Math.round(f.totalDistance) === key);
    // console.log(`key: ${key} dimmension: ${dimmension} found: ${frame.eventDate} `);
    if (!this.selectedFrame) {
      // if first click
      this.mapService.mapInstance.setZoom(15);
    }
    if (!frame) {
      this.selectedFrame = null;
      return;
    }
    this.selectedFrame = frame;
    this.mapService.mapInstance.setCenter(this.coordinates, true);
  }

  filterSensorProperties() {
    if (this.vehicle && this.vehicle.noCacheExtensions && this.vehicle.noCacheExtensions.CHART_PROPERTIES) {
      this.defaultSensorNames = this.vehicle.noCacheExtensions.CHART_PROPERTIES.properties;
    } else {
      // filter by data in frames
      this.defaultSensorNames = this.getSensorPropertiesFromFrames();
    }

    this.sensorProperties = this.allSensorProperties.filter(f => this.defaultSensorNames.includes(f.propertyName));
  }

  getSensorPropertiesFromFrames() {
    const sensorNamesInFrames: { [key: string]: number } = {};
    this.frames.forEach(f => {
      this.allSensorProperties.forEach(sf => {
        if (f.hasOwnProperty(sf.propertyName) && f[sf.propertyName] !== null) {
          // init property in hashmap
          if (!sensorNamesInFrames.hasOwnProperty(sf.propertyName)) {
            sensorNamesInFrames[sf.propertyName] = 0;
          }
          sensorNamesInFrames[sf.propertyName] += 1;
        }
      });
    });
    return Object.keys(sensorNamesInFrames);
  }

  buildForms(): void {
    this.chartForm = this.fb.group({
      propertyName: [null, Validators.required],
      axisDimmension: [null, Validators.required],
    });

    this.chartForm.patchValue({
      propertyName: this.sensorProperties.length > 0 ? [this.sensorProperties[0].propertyName] : [],
      axisDimmension: 'DURATION',
    });

    if (this.chartForm) {
      this.formSubscription$ = this.chartForm.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged()
      ).subscribe(val => {
        if (this.chartForm.valid) {
          this.filterChanged(val);
        }
      });
    }
  }

  filterChanged(value): void {
    this.loadFuelChartData(false);
    console.log(`value changed!!! ${JSON.stringify(value)}`);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.destroyFilter();
  }

  destroyFilter() {
    if (this.formSubscription$) {
      this.formSubscription$.unsubscribe();
    }
  }

  loadFuelChartData(reload: boolean = true): void {
    this.pending = true;
    this.multi = [];
    if (reload) {
      this.vehicleService.getExtensions(this.vehicle.id).subscribe(ext => {
        this.getTelemeteryFrames(this.vehicle.id).subscribe(res => {
          console.log(`fuel chart data length: ${res.length}`);

          this.frames = res;
          this.filterSensorProperties();
          this.buildChartData();
          this.pending = false;
        });
      });
    } else {
      this.buildChartData();
      this.pending = false;
    }
  }

  // tslint:disable-next-line: max-line-length
  getDataSeries(frames: TelemetryVehicle[], sensorPropertyName: string, name: string, dimmension:
      string, noDataValue: number, multiplier: number): any {
    let tempFrames: TelemetryVehicle[] = noDataValue !== undefined ?
      frames : frames.filter(f => f[sensorPropertyName]);

    console.log(`tempFrames before: ${tempFrames.length} property: ${sensorPropertyName} multiplier: ${multiplier} noDataValue: ${noDataValue}`);
    if (dimmension === 'DISTANCE') {
      tempFrames = tempFrames.filter((item, index) =>
           // if ignition on take only frames by 1km
          (!!item.ignition && index > 0 && Math.abs(tempFrames[index].totalDistance - tempFrames[index - 1].totalDistance) > 1) ||
          // or every with ignition off
          !item.ignition);
    }

    // if (dimmension === 'DURATION' && tempFrames.length > 1000) {
    //   tempFrames = tempFrames.filter((item, index) =>
    //     (!item.ignition && index > 0 && this.dateStringDiff(tempFrames[index - 1].eventDate, item.eventDate) > 1800) ||
    //     (!!item.ignition && index > 0 && this.dateStringDiff(tempFrames[index - 1].eventDate, item.eventDate) > 120)
    //   );
    //   console.log(`filter duration`);
    // }

    console.log(`tempFrames after: ${tempFrames.length}`);

    if (!tempFrames || tempFrames.length === 0) {
      return null;
    }

    return {
      'name': name,
      'series': tempFrames
        .map(item => ({
          name: dimmension === 'DURATION' ? new Date(item.eventDate) : item.totalDistance,
          // if no value or value is null and noDataValue is available
          value: ((!item.hasOwnProperty(sensorPropertyName) || item[sensorPropertyName] === null) && noDataValue !== undefined) ?
            noDataValue :
            item[sensorPropertyName] * multiplier,
          extras: {
            eventDate: item.eventDate,
            totalDistance: item.totalDistance,
            label: name,
          },
        })),
    };
  }

  dateStringDiff(date1: string, date2: string) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    return Math.round(Math.abs(d2.getTime() - d1.getTime()) / 1000);
  }

  buildChartData() {
    const tempMulti: any[] = [];

    const filterData = this.chartForm.value as IChartFilter;
    this.selectedDimmention = filterData.axisDimmension;
    this.sensorProperties.filter(w => filterData.propertyName.indexOf(w.propertyName) > -1).forEach(f => {
      const dataSerie: any = this.getDataSeries(
        this.frames,
        f.propertyName,
        f.name,
        filterData.axisDimmension,
        f.noDataValue,
        f.multiplier ? f.multiplier : 1,
      );
      if (dataSerie) {
        tempMulti.push(dataSerie);
      }
    });

    this.multi = tempMulti;
  }

  getTelemeteryFrames(vehicleId: string): Observable<TelemetryVehicle[]> {
    let zoomIndex = 0;
    const {start, end} = this.dateFilters;
    const telemetry = this.telemetryService.vehiclesTemperaturePositions(vehicleId, {
      from: start,
      to: end,
    }).pipe(
      tap(() => this.pending = false),
      // tslint:disable-next-line: max-line-length
      map(res => res.filter((item, index) =>
        item.totalDistance > 0
        // && (
        //   // if ignition on take only frames by 1km
        //   (!!item.ignition && index > 0 && Math.abs(res[index].totalDistance - res[index - 1].totalDistance) > 1) ||
        //   // or every with ignition off
        //   !item.ignition
        // )
        // !!item.ignition  &&
        // filter incorect frames when distance between is grater than threshol
        // (index > 0 && Math.abs(res[index].totalDistance - res[index - 1].totalDistance) < 1000)
      )),
      tap(res => res.forEach(item => {
        item.zoomIndex = zoomIndex++;
        if (zoomIndex > 11) {
          zoomIndex = 0;
        }
      }))
    );
    return telemetry;
  }

  dateFilterChange($event) {
     this.dateFilters = $event;
     this.dateFilterService.dateRangeToQuery($event, this.route, true);
    this.loadFuelChartData();
  }

  formatAxisLabel = (value) => {
    //const axisLabel = `${value.toLocaleString('pl')}`;
    const axisLabel = this.selectedDimmention === 'DURATION' ?
      this.datePipe.transform(value, 'dd.MM HH:mm') :
      value;
    // const sufix = this.multi[0].series.find(f => f.name === value);
    // if (sufix && sufix.extras && sufix.extras.eventDate) {
    //   axisLabel += ` (${this.datePipe.transform(sufix.extras.eventDate, 'yy-MM-dd HH:mm')})`;
    // }

    return axisLabel;
  }

  get coordinates() {
    if (!this.selectedFrame) {
      return null;
    }
    return {
      lat: this.selectedFrame.position.coordinates[1],
      lng: this.selectedFrame.position.coordinates[0],
    };
  }

  get mapLabel() {
    if (!this.selectedFrame) {
      return null;
    }
    return this.datePipe.transform(this.selectedFrame.eventDate, 'yyyy-MM-dd HH:mm');
  }

}

import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { ngIfEnterTrigger } from '../../../core/animations';
import { CdkPortal } from '@angular/cdk/portal';
import { UiUtilsService } from '../../../core/services/ui-utils.service';

@Component({
  selector: 'app-filters-panel',
  templateUrl: './filters-panel.component.html',
  styleUrls: ['./filters-panel.component.scss'],
  animations: [ngIfEnterTrigger],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersPanelComponent implements OnInit {
  @Input() panelLabel: string;
  @Input() popupView = false;
  @Input() selected;
  @Input() showPanel = true;
  @HostBinding('class.c-filters--no-margin')
  @Input() withoutMargin = false;
  @ViewChild(CdkPortal, { static: true }) portal;
  @HostBinding('class.c-filters--open')
  public isOpen = false;

  constructor(private cd: ChangeDetectorRef,
              private uiSerice: UiUtilsService) {
  }

  get selectedLength() {
    return this.selected ? Object.keys(this.selected).length : 0;
  }

  panelToggle() {
    this.isOpen = !this.isOpen;
    this.cd.markForCheck();
    if (this.popupView) {
      return;
    }
    if (this.isOpen) {
      this.uiSerice.setPortal(this.portal);
    } else {
      this.uiSerice.setPortal(undefined);
    }
  }

  ngOnInit() {
  }

}

import { Component } from '@angular/core';
import { NewEditClass } from '../../../../classes/new-edit.class';
import { PoiGroup } from '../../../../interfaces/group';
import { ActivatedRoute } from '@angular/router';
import { PopupViewService } from '../../../../shared/components/popup-view/popup-view.service';
import { ApiValidatorService } from '../../../../core/services/api-validator.service';
import { RouterHelperService } from '../../../../core/services/router-helper.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { PoiGroupsService } from '../../../dashboard/poi/poi-groups.service';
import { PERMISSIONS, PermissionService } from '../../../../core/services/permission.service';

@Component({
  selector: 'app-poi-groups-popup',
  templateUrl: './poi-groups-popup.component.html',
  styleUrls: ['./poi-groups-popup.component.scss']
})
export class PoiGroupsPopupComponent extends NewEditClass {
  isNewView = !this.route.snapshot.params.id;
  public poiGroup: PoiGroup;
  protected editPermission = PERMISSIONS.POI_GROUP_UPDATE;
  protected newPermission = PERMISSIONS.POI_GROUP_CREATE;

  constructor(private route: ActivatedRoute,
              popupService: PopupViewService,
              apiValidator: ApiValidatorService,
              private routerHelper: RouterHelperService,
              permissionService: PermissionService,
              private poiGroupService: PoiGroupsService,
              fb: UntypedFormBuilder) {
    super(popupService, apiValidator, fb, permissionService);
  }

  addRouteSub() {
    this.subscriptions.add(
      this.route.params.pipe(
        map(({id}) => id),
        filter(id => !!id),
        tap(() => this.pending = true),
        switchMap(id => this.poiGroupService.get(id)))
        .subscribe(res => {
          this.poiGroup = res;
          this.resetForm();
          this.pending = false;
        }));
  }

  resetForm() {
    this.forms.main.patchValue(this.poiGroup);
  }

  buildForm() {
    this.forms.main = this.fb.group({
      name: [null, Validators.required],
      showOnMap: [null],
      radius: [1, [Validators.required, Validators.min(1)]],
      color: [null, Validators.required],
    });
  }

  save() {
    this.poiGroupService.add(this.forms.main.value).subscribe(() => {
      this.pending = false;
      this.popupService.close();
    }, this.errorHandler.bind(this));
  }

  update() {
    this.poiGroupService.partialUpdate(this.poiGroup.id, this.forms.main.value).subscribe((res) => {
      this.pending = false;
      Object.assign(this.poiGroup, res);
      this.isEditMode = false;
      this.toggleDisableForm();
    }, this.errorHandler.bind(this));
  }

}

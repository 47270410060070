<section class="d-flex justify-content-between align-items-center py-xs px-xm ">
    <h3 class="m-0" translate="">ZONE_RULES</h3>
    <button class="btn btn-primary" routerLink="new" translate>NEW_RULES</button>
</section>
<div class="p-xm scroll-wrapper">
    <table class="table">
        <thead>
        <tr>
            <th colspan="2" translate>NAME</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let norm of norms">
            <td [routerLink]="norm.id" style="width: 100%;">{{norm.name}}</td>
            <td><button class="btn btn-danger btn-icon btn-sm mr-xm" (click)="remove(norm)"><i class="icon-trash"></i></button></td>
        </tr>
        </tbody>
    </table>
</div>



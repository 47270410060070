import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';


export const UTC_OFFSET = new Date().getTimezoneOffset() / 60 * -1;


export function localHour(hour: string): string {
  if (!hour) {
    return hour;
  }
  const timeArray = hour.split(':');
  timeArray[0] = withZero(parseInt(timeArray[0], 10) + UTC_OFFSET).toString();
  return timeArray.join(':');
}

export function minutesFormat(value: number, minFormat: string = 'm'): string {
  const hours = Math.floor(value / 60);
  // const days = Math.floor(quotient / 24);
  const remainder = value % 60;
  // const hours = quotient % 24;
  return `${(hours > 0) ? hours + 'h ' : ''} ${remainder}${minFormat}`;
}

export function withZero(value: number): string {
  return value > 9 ? value.toString() : '0' + value;
}

export function observableWithCatchError(obs: Observable<any>, returnValue = {}) {
  return obs.pipe(catchError(() => of(returnValue)));
}

export function isEqual(array1: string[], array2: string[]) {
  return array1.sort(sortStrings).join() === array2.sort(sortStrings).join();
}

export function sortStrings(a: string, b: string) {
  return a.localeCompare(b);
}

export function hexToRgba(hex, alpha = 1): string {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha})`
    : null;
}

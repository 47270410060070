<app-popup-view>
    <div class="d-flex flex-column justify-content-between align-items-stretch" style="height:100%">
        <div class="flex-grow-0">
            <div class="p-l pt-0">
                <h3 translate="">ADD_NEW</h3>
            </div>
        </div>
        <div class="flex-grow-1 u-route-popup-wrapper">
            <app-poi-popup-basic [isNewView]="true"></app-poi-popup-basic>
        </div>
    </div>
</app-popup-view>

<div class="d-flex justify-content-between align-items-stretch flex-column flex-fill h-100">
    <div class="overflow-auto flex-grow-1 c-table p-xm">
        <table class="table">
            <thead>
            <tr>
                <th translate>NAME</th>
                <th translate>DESCRIPTION</th>
                <th translate>CRITERIA</th>
                <th class="text-right">
                    <button class="btn btn-secondary btn-icon btn-border btn-sm" (click)="handlerAdd()" *ngIf="!addNew && !editId && canEdit" >
                        <i class="icon-plus"></i>
                    </button>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="addNew && !editId && canEdit">
                <td colspan="4">
                    <app-vehicle-service-add [vehicle]="vehicle"
                                             (formUpdate)="addUpdate($event)"></app-vehicle-service-add>
                </td>

            </tr>
            <tr app-vehicle-sevice-reminder [reminder]="reminder"
                [edited]="editId === reminder.id"
                [vehicle]="vehicle"
                (updated)="reminderHasUpdate($event, reminder)"
                (action)="handlerAction($event)"
                *ngFor="let reminder of reminders$|async">

            </tr>

            </tbody>
        </table>
    </div>
</div>

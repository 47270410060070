import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { VehicleGroup } from '../../../../../interfaces/group';

@Component({
  selector: 'app-vehicle-group-poi',
  templateUrl: './vehicle-group-poi.component.html',
  styleUrls: ['./vehicle-group-poi.component.scss']
})
export class VehicleGroupPoiComponent implements OnInit, OnDestroy {
  protected subscriptions = new Subscription();
  public vehicleG: VehicleGroup;

  constructor(protected route: ActivatedRoute) {
  }


  ngOnInit() {
    this.routeSub();
  }

  protected routeSub() {
    this.subscriptions.add(this.route.parent.data.subscribe(({vehicleGroup}) => {
      this.vehicleG = vehicleGroup;
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

// export interface Ride {
// }


export enum RideType {
  START = 'start',
  PARK = 'park',
  END = 'end',
  RIDE = 'ride',
}

import { PERMISSIONS, PermissionService } from 'src/app/core/services/permission.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, Output,
  SimpleChanges
} from '@angular/core';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationSetting, NotificationTemplateType, NotificationType } from '../../../../interfaces/notificationT';
import { NormFields, NormFieldsType, NormTemplate, NormTemplateConfiguration } from '../../../../interfaces/norms';
import { finalize, shareReplay, switchMap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { VehiclesGroupsService } from '../../../../core/services/vehicles-groups.service';
import { ApiValidatorService } from '../../../../core/services/api-validator.service';
import { NotificationsService } from '../../../../core/services/notifications.service';
import { UserService } from '../../../../core/services/user.service';
import { PopupViewService } from '../../../../shared/components/popup-view/popup-view.service';
import { NormsService } from '../../../../core/services/norms.service';
import { isEqual, withZero } from '../../../../utils/utils';
import { Zone } from 'src/app/interfaces/zone';

@Component({
  selector: 'app-norm-template',
  templateUrl: './norm-template.component.html',
  styleUrls: ['./norm-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NormTemplateComponent implements OnInit, OnDestroy, OnChanges {
  private subscription: Subscription = new Subscription();
  public pending = false;
  public fieldsForm: UntypedFormGroup;
  public normForm: UntypedFormGroup;
  public formErrors: any;
  public saving = false;
  public channelForm = this.fb.control([]);
  public notificationSettings: NotificationSetting;
  public notificationGlobalSettings: NotificationSetting;
  @Input() norm: NormTemplate;
  @Input() zone: Zone;
  @Input() configuration: NormTemplateConfiguration;
  @Output() cancel = new EventEmitter();
  @Output() added = new EventEmitter();
  public vehiclesGroups$ = this.vehicleGroup.list$().pipe(shareReplay());

  readonly PERMISSIONS = PERMISSIONS;

  constructor(private activeRoute: ActivatedRoute,
              private fb: UntypedFormBuilder,
              private cd: ChangeDetectorRef,
              private vehicleGroup: VehiclesGroupsService,
              private validator: ApiValidatorService,
              private notificationService: NotificationsService,
              private permissionService: PermissionService,
              private userService: UserService,
              private popupService: PopupViewService,
              private service: NormsService) {
  }

  ngOnInit() {

  }

  get canEditOrUpdate() {
    if (this.configuration) {
      return this.permissionService.hasPermission(PERMISSIONS.NORM_UPDATE);
    } else {
      return this.permissionService.hasPermission(PERMISSIONS.NORM_CREATE);
    }
  }

  setNotificationFormValue() {
    const values = this.notificationSettings || this.notificationGlobalSettings;
    if (values) {
      this.channelForm.setValue(values.channels);
    }
  }

  getNotificationSettings() {
    this.notificationService.settings().subscribe(res => {
      this.notificationGlobalSettings = res.find(item => item.type === this.getNotificationType() && !item.related);
      if (this.configuration) {
        this.notificationSettings = res.find(item => item.type === this.getNotificationType() && item.related
          && item.related.id === this.configuration.id);
      }
      this.setNotificationFormValue();
      this.cd.detectChanges();
    });
  }

  get normType() {
    return NormFieldsType;
  }

  vehicleValidator = (vehicleForm: UntypedFormControl) => {
    const vehicle = vehicleForm.value;
    const vehicleGroup = this.fieldsForm && this.fieldsForm.get('VEHICLE_GROUPS');
    const vehicleGroupValue = vehicleGroup && vehicleGroup.value || null;
    return vehicle || vehicleGroupValue && vehicleGroupValue.length ? null : {
      [vehicleGroup ? 'requredVehicleGroup' : 'required']: {
        valid: false
      }
    };
  }

  fieldValidator(field: NormFields) {
    const validators = [];
    if (field.validation) {
      Object.keys(field.validation).forEach(key => {
        validators.push(Validators[key](field.validation[key]));
      });
    }
    switch (field.type) {
      case NormFieldsType.VEHICLES:
        validators.push(this.vehicleValidator);
        break;
    }
    return validators;
  }

  handlerCancel() {
    if (this.cancel.observers.length > 0) {
      this.cancel.emit();
    } else {
      this.popupService.close();
    }
  }

  fieldClass(field: NormFields, length) {
    switch (field.type) {
      case NormFieldsType.SELECT:
      case NormFieldsType.NUMBER:
        const max = Math.floor(12 / length);
        return length <= 1 ? 'col-4' : `col-${max <= 2 ? 3 : max}`;
      default:
        return 'col-12';
    }
  }

  errorCallback(err) {
    this.pending = false;
    this.formErrors = err;
    this.cd.detectChanges();
  }

  updateNotification() {
    if (this.channelForm.dirty && (this.notificationSettings
      || !isEqual(this.channelForm.value, this.notificationGlobalSettings.channels))) {
      this.pending = true;
      const body = {
        channels: this.channelForm.value,
        related: {
          id: this.configuration.id,
          type: NotificationTemplateType.NORM,
        },
        type: this.getNotificationType(),
      };
      this.notificationService.updateSettings(body).subscribe(res => {
        this.pending = false;
        if (!this.notificationSettings) {
          this.notificationSettings = {
            availableChannels: this.notificationGlobalSettings.availableChannels,
            ...body
          };
        }
        this.cd.detectChanges();
      }, this.errorCallback.bind(this));
    }
  }

  save() {
    if (!this.formIsValid() || this.pending) {
      return;
    }
    this.pending = true;
    const formValues = {
      ...this.normForm.getRawValue(),
      fields: this.fieldsForm.getRawValue(),
    };
    if (formValues.fields.HOURS_RANGE) {
      formValues.fields.HOURS_RANGE = (formValues.fields.HOURS_RANGE as any[]).map(value => (value ? {
        from: value.from,
        to: value.to,
      } : null));
    }
    this.norm.sections.forEach(section => {
      section.fields.filter(f => f.type === NormFieldsType.NUMBER).forEach(field => {
        if (formValues.fields.hasOwnProperty(field.name)) {
          formValues.fields[field.name] = parseFloat(formValues.fields[field.name]);
        }
      });
    });

    if (this.configuration) {
      this.service.updateTemplateConfiguration(this.norm.id, this.configuration.id, formValues)
        .pipe(finalize(this.handlerFinalize))
        .subscribe((res) => {
          this.updateNotification();
          this.handlerCancel();
        }, this.errorCallback.bind(this));
    } else {
      this.service.saveTemplateConfiguration(this.norm.id, formValues)
        .pipe(finalize(this.handlerFinalize))
        .subscribe((res) => {
          this.configuration = res;
          this.updateNotification();
          this.addRecipent(res);
          this.added.emit(res);
          this.handlerCancel();
        }, this.errorCallback.bind(this));
    }
  }

  handlerFinalize = () => {
    this.pending = false;
    this.cd.detectChanges();
  }

  addRecipent(res) {
    this.userService.user$
      .pipe(
        switchMap((user) => this.notificationService.setRecipients(
          this.getNotificationType(),
          {
            id: res.id,
            type: 'norm_template_configuration',
          },
          [user.id]
        ))).subscribe();
  }

  getNotificationType() {
    return this.zone ? NotificationType.ZONE_CHANGE : NotificationType.NORM;

  }

  formIsValid() {
    return this.validator.formIsValid(this.fieldsForm) && this.validator.formIsValid(this.normForm);
  }

  periodValue(value) {
    return `${withZero(value)}:00`;
  }

  periodArrray(field: NormFields) {
    return this.fb.array(new Array(7)
      .fill(null)
      .map((value, index) => this.fb.control(field.default
        ? {
          from: this.periodValue(field.default[index].from),
          to: this.periodValue(field.default[index].to),
        } : null, this.validator.periodValidator)));
  }

  buildForms() {
    if (!this.norm) {
      return;
    }
    this.normForm = this.fb.group({
      name: [this.configuration ? this.configuration.name : null, Validators.required],
      isAggregated: [this.configuration ? this.configuration.isAggregated : false]
    });
    const formConfigObject = {};
    this.norm.sections.forEach(section => {
      section.fields.forEach(field => {
        formConfigObject[field.name] = field.type === NormFieldsType.PERIOD_SELECTOR
          ? this.periodArrray(field) : [field.default, this.fieldValidator(field)];
      });
    });
    this.fieldsForm = this.fb.group(formConfigObject);
    if (this.configuration) {
      this.fieldsForm.patchValue(this.configuration.fields);
    } else if (this.zone) {
      this.fieldsForm.patchValue({ZONES: this.zone.id});
    }
    const vehicle_group = this.fieldsForm.get('VEHICLE_GROUPS');
    if (vehicle_group) {
      this.subscription.add(
        vehicle_group.valueChanges.subscribe(() => {
          this.fieldsForm.get('VEHICLES').updateValueAndValidity();
        })
      );
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getNotificationSettings();
    this.buildForms();
  }
}

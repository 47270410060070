<div class="d-flex justify-content-between align-items-stretch flex-column flex-fill h-100">
    <div class="overflow-auto" *ngIf="forms.poiForm">
        <div class="p-xm" [formGroup]="forms.poiForm">
            <h3 class="form-header mt-xl" translate>BASIC_DATA</h3>
            <div class="row">
                <div class="col-4 p-m">
                    <app-form-group>
                        <label translate>NAME</label>
                        <input app-form formControlName="name" type="text">
                        <app-error-message [control]="forms.poiForm.get('name')"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4 p-m">
                    <app-select-input
                            [multiple]="false"
                            formControlName="type"
                            [allowClear]="false"
                            [items]="poiTypes"
                    ><label translate>TYPE</label></app-select-input>
                    <app-error-message [control]="forms.poiForm.get('type')"></app-error-message>
                </div>
                <div class="col-4 p-m">
                  <app-form-group>
                      <label translate>Id</label>
                      <input app-form formControlName="externalId" type="text">
                      <app-error-message [control]="forms.poiForm.get('externalId')"></app-error-message>
                  </app-form-group>
              </div>
            </div>
            <h3 class="form-header mt-xl" translate>CONTACT_PERSON</h3>
            <div class="row">
                <div class="col-4 p-m" *ngFor="let control of poiContact|keyvalue">
                    <app-form-group>
                        <label translate>CONTACT.{{control.key.toUpperCase()}}</label>
                        <input app-form [formControl]="control.value" type="text">
                        <app-error-message [control]="control.value"></app-error-message>
                    </app-form-group>
                </div>
            </div>
            <h3 class="form-header mt-xl" translate>POI</h3>
            <div [formGroup]="forms.addessForm" class="row">
                <div class="p-m" [ngClass]="addressFormCol[control]"
                     *ngFor="let control of addessFormControls">
                    <app-form-group>
                        <label translate>ADDRESS.{{control.toUpperCase()}}</label>
                        <input app-form [formControlName]="control" type="text">
                        <app-error-message [control]="forms.addessForm.get(control)"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4 p-m" *ngIf="showButtons">
                    <button class="btn w-100 btn-primary btn-border" (click)="findOnMap()">
                        <app-spinner [showSpinner]="geoPending"></app-spinner>
                        {{'FIND_ON_MAP'|translate}}
                    </button>

                </div>

            </div>
            <h3 translate>LOCATION</h3>
            <div [formGroup]="forms.circleForm" class="row">
                <div class="col-2 p-m" *ngFor="let control of circleFormControls|keyvalue">
                    <app-form-group>
                        <label translate>POI_FORM_CIRCLE.{{control.key.toUpperCase()}}</label>
                        <input app-form [formControlName]="control.key" type="text">
                        <app-error-message [control]="control.value"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4 p-m" *ngIf="showButtons">
                    <button class="btn w-100 btn-primary btn-border" (click)="updateAddress()"
                            [disabled]="isUpdateAddressDisabled">
                        <app-spinner [showSpinner]="addressPending"></app-spinner>
                        {{'UPDATE_ADDRESS'|translate}}
                    </button>

                </div>

            </div>
        </div>
        <app-map height="300px" id="map_poi">
            <app-map-polygon [area]="poi.area" *ngIf="showPolygon && poi && !forms.circleForm.valid"></app-map-polygon>
            <app-map-circle [coordinates]="forms.circleForm.value" *ngIf="forms.circleForm.valid" #circleMarker></app-map-circle>
            <app-map-marker-poi [coordinates]="forms.circleForm.value"></app-map-marker-poi>

            <app-map-contextmenu [items]="customeMenuItems" (itemClick)="handlerContexMenu($event)"
                                 [disabled]="!isEditMode && !isNewView"></app-map-contextmenu>
        </app-map>
        <div class="p-xm">
            <app-select-input
                    [items]="poiGroups$|async"
                    [multiple]="true"
                    [max]="1"
                    selectClass="w-25"
                    [allowClear]="false"
                    [showTags]="true"
                    [searchable]="true"
                    bindLabel="name"
                    bindValue="id"
                    [formControl]="forms.groupsForm"
            >
                <label translate>GROUPS</label>
            </app-select-input>
            <app-error-message [control]="forms.groupsForm"></app-error-message>
        </div>
    </div>
    <div class="p-xm d-flex justify-content-end align-items-center border-top">
        <button class="btn btn-primary btn-border px-xxl mr-m" (click)="cancel()" translate>
            {{(isEditMode ? 'CANCEL' : 'EXIT')|translate}}
        </button>
        <button class="btn btn-primary px-xxl" (click)="handlerSave()" [disabled]="pending" *ngIf="showButton">
            <app-spinner [showSpinner]="pending"></app-spinner>
            {{(isNewView || isEditMode ? 'SAVE' : 'EDIT')|translate}}
        </button>
    </div>
</div>





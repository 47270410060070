import { NgModule } from '@angular/core';
import { FavoriteInputComponent } from './favorite/favorite.component';
import { ComponentsModule } from '../../components/components.module';
import { SelectInputComponent } from './select-input/select-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { VehiclesSelectComponent, DriversSelectComponent, PartnerVehiclesSelectComponent } from './vehicles-select/vehicles-select.component';
import { VehicleSelectDropdownComponent } from './vehicles-select/vehicle-select-dropdown/vehicle-select-dropdown.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDropdownModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
// tslint:disable-next-line: max-line-length
import { VehicleSeelctDropdownFiltersComponent } from './vehicles-select/vehicle-select-dropdown/vehicle-seelct-dropdown-filters/vehicle-seelct-dropdown-filters.component';
import { FormGroupModule } from '../form-group/form-group.module';
import { HoursRangeInputComponent } from './hours-range-input/hours-range-input.component';
import { HourRangeInputComponent } from './hours-range-input/hour-range-input/hour-range-input.component';
import { RangeInputComponent } from './range-input/range-input.component';
import { PoiSyncComponent } from './poi-sync/poi-sync.component';
import { OverflowSpinnerModule } from '../overflow-spinner/overflow-spinner.module';
import { ChannelsInputComponent } from './channels-input/channels-input.component';
import { SwitchComponent } from './switch/switch.component';
import { ColorInputComponent } from './color-input/color-input.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { PoiSelectComponent } from './poi-select/poi-select.component';
import { PoiSelectDropdownComponent } from './poi-select/poi-select-dropdown/poi-select-dropdown.component';
// tslint:disable-next-line: max-line-length
import { PoiSeelctDropdownFiltersComponent } from './poi-select/poi-select-dropdown/poi-seelct-dropdown-filters/poi-seelct-dropdown-filters.component';
import { NumberInputComponent } from './number-input/number-input.component';

@NgModule({
  declarations: [FavoriteInputComponent, SelectInputComponent,
    VehiclesSelectComponent, VehicleSelectDropdownComponent, PoiSyncComponent,
    PartnerVehiclesSelectComponent,
    DriversSelectComponent,
    VehicleSeelctDropdownFiltersComponent,
    PoiSeelctDropdownFiltersComponent,
    PoiSelectComponent, PoiSelectDropdownComponent,
    HoursRangeInputComponent, HourRangeInputComponent,
    RangeInputComponent, ChannelsInputComponent, SwitchComponent, ColorInputComponent, NumberInputComponent],
  exports: [FavoriteInputComponent,
    SelectInputComponent,
    PoiSyncComponent,
    RangeInputComponent,
    ChannelsInputComponent,
    ColorInputComponent,
    PoiSelectDropdownComponent,
    PoiSeelctDropdownFiltersComponent,
    SwitchComponent,
    NumberInputComponent,
    PoiSelectComponent,
    DriversSelectComponent, PartnerVehiclesSelectComponent,
    VehiclesSelectComponent, HourRangeInputComponent, HoursRangeInputComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ColorPickerModule,
    NgbDropdownModule,
    NgbTimepickerModule,
    OverflowSpinnerModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    FormGroupModule,
  ]
})
export class CustomInputsModule {
}

<app-popup-view class="d-flex flex-fill justify-content-between align-items-stretch flex-column">
    <div class="p-xm c-new-report__header">
        <div class="d-flex justify-content-start align-items-start">
            <div class="c-new-report__image">
                <img src="{{report?.imgUrl}}" alt="" width="100%">
            </div>
            <div class="c-new-report__desc">
                <h2>{{report?.name}}</h2>
                <p>{{report?.desc}}</p>
            </div>
        </div>

    </div>
    <div class="p-xm flex-grow-1 overflow-auto">
        <ng-container *ngIf="fieldsForm">
          <div *hasPermission="permissions.REPORT_PERIODIC">
            <h2 class="form-header" translate>REPORT_TYPE</h2>
            <div class="row justify-content-start">
                <div class="col-auto" *ngFor="let item of types">
                    <app-form-group>
                        <label>
                            <input type="radio"
                                   name="isPeriodic"
                                   [value]="item.value"
                                   [formControl]="fieldsForm.controls['isPeriodic']"
                                   app-form>
                            <span></span>
                            {{item.label|translate}}
                        </label>
                    </app-form-group>

                </div>
                <div class="col-12">
                    <app-error-message [control]="fieldsForm.controls['isPeriodic']"
                                       [apiError]="formErrors?.isPeriodic"></app-error-message>
                </div>
            </div>
          </div>
            <h2 class="form-header mt-5" translate>REPORT_NAME</h2>
            <div class="w-50">
                <app-form-group>
                    <label translate>REPORT_OR_TEMPLATE_NAME</label>
                    <input type="text" [formControl]="fieldsForm.controls['name']" app-form>
                    <app-error-message [control]="fieldsForm.controls['name']"
                                       [apiError]="formErrors?.new_password1"></app-error-message>
                </app-form-group>
            </div>
            <h2 class="form-header mt-5" translate>TIME_RANGE</h2>
            <div class="w-50" *ngIf="!fieldsForm.controls['isPeriodic'].value">
                <app-date-range-filter (changeRange)="filterChange($event)" [eventOnInit]="true" [date]="date" placement="bottom-left"></app-date-range-filter>
            </div>
            <div *ngIf="fieldsForm.controls['isPeriodic'].value">
              <app-form-group>
                <label>{{ 'EMAIL'|translate }}</label>
                <input app-form [formControl]="fieldsForm.controls['recipients']" type="text">
              </app-form-group>
              <br/>
                <app-select-input
                  [multiple]="false"
                  [allowClear]="false"
                  [formControl]="fieldsForm.controls['cycle']"
                  [items]="periodReportCycleItems"
                >
                  <label for="" translate>ITERATION</label>
                </app-select-input>
              <ng-container *ngIf="fieldsForm.controls['cycle'].value == periodReportCycle.DAY">
                  <div class="mb-xm row">
                    <div class="col-6 mb-xm"
                         *ngFor="let hour of cycleRange.controls; let i = index;">
                        <app-hour-range-input [formControl]="hour"
                                              [dayNumber]="i"
                        ></app-hour-range-input>
                        <app-error-message [control]="hour"></app-error-message>
                    </div>
                </div>
              </ng-container>
          </div>
        </ng-container>

        <app-report-form [form]="fieldsForm" [entites]="report?.fields.entities"></app-report-form>
        <!-- <app-report-form [form]="customeFieldsForm" [entites]="report?.customFields"></app-report-form> -->
        <ng-container *ngIf="fieldsForm && report?.availableOutputFormats.length > 1">
          <h2 class="form-header mt-5" translate>REPORT_FORMAT</h2>
          <div class="w-50">
            <app-select-input
                    [formControl]="fieldsForm.controls['outputFormat']"
                    [multiple]="false"
                    [items]="report?.availableOutputFormats"
            ><label translate>REPORT_FORMAT</label></app-select-input>
          </div>
      </ng-container>
    </div>
    <div class="p-xm d-flex flex-row justify-content-end align-items-center border-top">
        <button class="btn btn-primary  btn-border mr-m px-xxl" translate (click)="cancel()" [disabled]="pending">
            CANCEL
        </button>
        <button class="btn btn-secondary mr-m px-xxl" translate (click)="save()" [disabled]="pending">
            <app-spinner [showSpinner]="saving"></app-spinner>
            {{this.configuration ? 'UPDATE' : 'SAVE'}}
        </button>
        <button *ngIf="fieldsForm && !fieldsForm.controls['isPeriodic'].value" class="btn btn-primary  px-xxl" translate (click)="generate()">GENERATE</button>
    </div>
    <app-overflow-spinner [showSpinner]="pending"></app-overflow-spinner>
</app-popup-view>

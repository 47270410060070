import { Inject, Injectable, NgZone, OnDestroy } from "@angular/core";
import { ConfigService } from "../../../core/services/config.service";
import { BehaviorSubject, fromEvent, Observable, Subscription } from "rxjs";
import supercluster, { Options as SuperclusterOptions } from "supercluster";
import {
  Position,
  TelemetryVehicle,
  VehicleWithTelemetry,
} from "../../../interfaces/telemetry";
import { debounceTime, delay, filter, map } from "rxjs/operators";
import { parse } from "wellknown";

import { Area } from "../../../interfaces/poi";
import { HttpClient } from "@angular/common/http";
import { SpeedMarker } from "./map-markers/map-marker-speed/map-marker-speed.component";
import {
  ContextMenuItem,
  ContextMenuItemEvent,
} from "./map-contextmenu/map-contextmenu.component";
import { PERMISSIONS } from "../../../core/services/permission.service";
import { RouterHelperService } from "../../../core/services/router-helper.service";
import { UserSettingsService } from "src/app/core/services/user-settings.service";
import { DOCUMENT } from "@angular/common";
import { decodeFlexipolyline } from "./flexpolyline";

export enum EMapTilesLanguage {
  pl = "pl",
  en = "en",
  mt = "pl",
}

export enum EMapUILanguage {
  pl = "pl-PL",
  en = "en-US",
  mt = "pl-PL",
}

export interface ISuggestion {
  address: { label: string};
  matchLevel: string;
}

export interface IAutoCompleteSearchResponse {
  items: ISuggestion[];
}

export interface IGeocodedSuggestion extends ISuggestion {
  coordinates: {
    lat: number;
    lng: number;
  };
}

export enum EMatchLevelZoom {
  country = 7,
  city = 10,
  district = 12,
  street = 14,
  postalCode = 12,
  houseNumber = 18,
}

export const DEFAULT_CONTEXMENU_ITEMS: ContextMenuItem[] = [
  { label: "ADD_POI", type: "add_address", permission: PERMISSIONS.POI_CREATE },
  { label: "COPY_GPS", type: "copy_gps", permission: PERMISSIONS.MAP_COPY },
];

@Injectable({
  providedIn: "root",
})
export class MapService implements OnDestroy {
  private platform: H.service.Platform;

  private mapIsLoad$ = new BehaviorSubject(null);
  private defaultLayers: H.service.DefaultLayers;
  public mapInstance: H.Map;
  public behavior: H.mapevents.Behavior;
  public markersAdded = 0;
  private resizeSub: Subscription;
  private markerGroups: Record<string, H.map.Group> = {};
  readonly defaultContexMenuItems = DEFAULT_CONTEXMENU_ITEMS;

  private defaultOptions: H.Map.Options = {
    zoom: 10,
    noWrap: true,
    center: { lat: 50, lng: 50 },

    pixelRatio: window.devicePixelRatio || 1,
    // @ts-ignore
    engineType: H.map.render.RenderEngine.EngineType.P2D,
  };

  // tslint:disable-next-line: max-line-length
  constructor(
    private config: ConfigService,
    public zone: NgZone,
    private http: HttpClient,
    @Inject(DOCUMENT) private document,
    private routeHelper: RouterHelperService,
    private userSettings: UserSettingsService,
  ) {
    this.initMap();
    this.resizeSub = fromEvent(window, "resize")
      .pipe(debounceTime(300), delay(1))
      .subscribe(() => {
        this.resizeMap();
      });
  }

  getPpi() {
    return window.devicePixelRatio >= 1.7 ? 400 : 100;
  }

  readGeoJSON(data: Area, style) {
    const reader = new H.data.geojson.Reader(null, {
      disableLegacyMode: true,
      style: (mapObject) => {
        if (mapObject instanceof H.map.Polygon) {
          mapObject.setStyle(style);
        }
      },
    });
    reader.parseData(data);
    return reader.getParsedObjects();
  }

  public resizeMap() {
    if (this.mapInstance) {
      try {
        const viewPort = this.mapInstance.getViewPort();
        if (viewPort) {
          viewPort.resize();
        }
      } finally {
      }
    }
  }

  private initMap() {
    this.platform = new H.service.Platform({
      apikey: this.config.mapApiKey,
      useHTTPS: location.protocol === "https:",
    });
    this.defaultLayers = this.platform.createDefaultLayers();
  }

  supercluster(options: SuperclusterOptions = {}) {
    const defaultOptions: SuperclusterOptions = {
      radius: 40,
      maxZoom: 16,
    };
    return new supercluster({ ...defaultOptions, ...options });
  }

  get mapIsLoad() {
    return this.mapIsLoad$.asObservable().pipe(filter((res) => !!res));
  }

  setNewMap() {
    this.mapInstance = null;
  }

  geoJsonFromVehicle(vehicles: VehicleWithTelemetry[]) {
    return vehicles
      .filter((vehicle) => !!vehicle.telemetry)
      .map((vehicle) => ({
        geometry: vehicle.telemetry.position,
        properties: vehicle,
      }));
  }

  newMap(element: HTMLElement, options: H.Map.Options, mapId: string) {
    // @ts-ignore

    // @ts-ignore
    const satellTileService = this.platform.getRasterTileService({
      format: "jpeg",
      queryParams: {
        lang: EMapTilesLanguage[this.userSettings.language],
        ppi: this.getPpi(),
        style: "explore.satellite.day",
        // features:
        //   "pois:all,environmental_zones:all,congestion_zones:all,vehicle_restrictions:active_and_inactive",
      },
    });
    // @ts-ignore
    const satellTileProvider = new H.service.rasterTile.Provider(
      satellTileService,
      {
        engineType: H.Map.EngineType.P2D,
        tileSize: 512,
      },
    );
    const satellTileLayer = new H.map.layer.TileLayer(satellTileProvider);

    // @ts-ignore
    const terrainTileService = this.platform.getRasterTileService({
      format: "jpeg",
      queryParams: {
        lang: EMapTilesLanguage[this.userSettings.language],
        ppi: this.getPpi(),
        style: "logistics.day",
      },
    });
    // @ts-ignore
    const terrainTileProvider = new H.service.rasterTile.Provider(
      terrainTileService,
      {
        engineType: H.Map.EngineType.P2D,
        tileSize: 512,
      },
    );
    const terrainTileLayer = new H.map.layer.TileLayer(terrainTileProvider);

    // @ts-ignore
    const rasterTileService = this.platform.getRasterTileService({
      format: "jpeg", //or png, png8
      queryParams: {
        lang: EMapTilesLanguage[this.userSettings.language],
        ppi: this.getPpi(),
        style: "lite.day",
        // features: 'pois:all,environmental_zones:all,congestion_zones:all,vehicle_restrictions:active_and_inactive'
      },
    });
    // @ts-ignore
    const rasterTileProvider = new H.service.rasterTile.Provider(
      rasterTileService,
      {
        engineType: H.Map.EngineType.P2D,
        tileSize: 512,
      },
    );
    let rasterTileLayer = new H.map.layer.TileLayer(rasterTileProvider);

    if (this.config.isCbTheme()) {
      rasterTileLayer = this.getCbTileLayer();
    }


    this.mapInstance = new H.Map(
      element,
      // selectedLayer,
      rasterTileLayer,
      Object.assign({}, this.defaultOptions, options),
    );
    this.markerGroups = {};
    const mapEvents = new H.mapevents.MapEvents(this.mapInstance);

    this.behavior = new H.mapevents.Behavior(mapEvents);
    //@ts-ignore
    this.behavior.disable(H.mapevents.Behavior.Feature.FRACTIONAL_ZOOM);
    this.markersAdded = 0;
    this.mapIsLoad$.next(this.mapInstance);
    this.defaultLayers.raster.normal.map = rasterTileLayer;
    this.defaultLayers.raster.normal.base = rasterTileLayer;
    this.defaultLayers.raster.satellite.map = satellTileLayer;
    this.defaultLayers.raster.satellite.base = satellTileLayer;
    this.defaultLayers.raster.terrain.base = terrainTileLayer;
    this.defaultLayers.raster.terrain.map = terrainTileLayer;
    this.setMapUi();
    this.mapInstance.addEventListener("baselayerchange", (e) =>
      this.baseLayerChanged(e, this.defaultLayers, mapId),
    );

    return this.mapInstance;
  }

  getCbTileLayer() {
    // https://developer.here.com/documentation/examples/maps-js/data/custom-tile-overlay
    var tileProvider = new H.map.provider.ImageTileProvider({
      getURL: function (column, row, zoom) {
        return `https://c.tile.openstreetmap.org/${zoom}/${column}/${row}.png`
      },
      crossOrigin: false,
    });
    // Unless you own the map tile source,
    // you need to comply with the licensing agreement of the map tile provider.
    // Often this means giving attribution or copyright acknowledgment to the owner,
    // even if the tiles are offered free of charge.
    tileProvider.getCopyrights = function (bounds, level) {
      // We should return an array of objects that implement H.map.ICopyright interface
      return [{
        label: "OpenStreeMaps",
        alt: 'OpenStreetMaps'
      }];
    };
    // Now let's create a layer that will consume tiles from our provider
    var overlayLayer = new H.map.layer.TileLayer(tileProvider, {
      // Let's make it semi-transparent
      opacity: 1
    });

    return overlayLayer;
  }

  setMapUi() {
    const ui = H.ui.UI.createDefault(
      this.mapInstance,
      this.defaultLayers,
      EMapUILanguage[this.userSettings.language],
    );

    ui.removeControl("zoom");
    const controlEl = ui.getControl("mapsettings").getElement();
    controlEl
      .querySelectorAll(".H_grp.H_el, .H_separator.H_el")
      .forEach((value) => value.remove());
    ui.addControl(
      "zoom",
      new H.ui.ZoomControl({
        // @ts-ignore
        fractionalZoom: false,
        alignment: H.ui.LayoutAlignment.RIGHT_BOTTOM,
      }),
    );
  }
  baseLayerChanged(ev, defaultLayers, mapId) {
    Object.keys(defaultLayers).forEach((f) => {
      if (defaultLayers[f].map === ev.newValue) {
        console.log(`match ${f} mapId: ${mapId}`);
        const settings: Object = {};
        settings[mapId] = f;
        this.userSettings.updateSettings(settings).subscribe(() => {});
      }
    });
  }

  boundMapToPoints(positions: Position[]) {
    if (this.mapInstance && positions.length > 0) {
      const multiPoint = new H.geo.MultiPoint(
        positions.map(
          ({ coordinates }) => new H.geo.Point(coordinates[1], coordinates[0]),
        ),
      );
      this.setViewBounds(multiPoint.getBoundingBox());
    }
  }

  boundMapToPolygons(positions: Area[]) {
    // @ts-ignore
    const multiPoly = new H.geo.MultiPolygon(
      positions.map((area) => {
        const newLineString = new H.geo.LineString();
        area.coordinates[0].forEach((coordinates) => {
          newLineString.pushPoint({ lng: coordinates[0], lat: coordinates[1] });
        });
        // @ts-ignore
        return new H.geo.Polygon(newLineString);
      }),
    );
    this.setViewBounds(multiPoly.getBoundingBox());
  }

  //
  zoomToCluster(coords, zoom) {
    this.mapInstance.setZoom(zoom, true);
    this.mapInstance.setCenter(new H.geo.Point(coords[1], coords[0]));
  }

  isInMapView(geoPoint: H.geo.Point) {
    const mapBounce = this.mapInstance
      .getViewModel()
      .getLookAtData()
      .bounds.getBoundingBox();
    return mapBounce.containsPoint(geoPoint);
  }

  addMarker(marker: H.map.DomMarker, group: string) {
    return this.zone.runOutsideAngular(() => {
      if (this.mapInstance) {
        //  this.mapInstance.addObject(marker);
        if (!this.markerGroups[group]) {
          this.markerGroups[group] = new H.map.Group();
          this.mapInstance.addObject(this.markerGroups[group]);
        }
        this.markerGroups[group].addObject(marker);
        this.markersAdded++;
      }
    });
  }

  getViewBounds() {
    return this.mapInstance.getViewModel().getLookAtData().bounds;
  }

  destroyMap() {
    this.mapInstance = null;
    this.mapIsLoad$.next(null);
  }

  geoToGeoJson(geo: H.geo.AbstractGeometry | H.geo.Rect): Area {
    const WKTString = geo.toString() as string;
    return parse(WKTString) as Area;
  }

  geoToGeoJsonWithFix(geo: H.geo.AbstractGeometry | H.geo.Rect): Area {
    const WKTString = geo.toString() as string;
    const area = parse(WKTString) as Area;
    if (area.coordinates[0]) {
      area.coordinates = [[...area.coordinates[0].reverse()]];
    }
    return area;
  }

  telemetryToGpx(data: TelemetryVehicle[]): string {
    return `<?xml version="1.0"?>
<gpx version="1.0"
xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
xmlns="http://www.topografix.com/GPX/1/0"
xsi:schemaLocation="http://www.topografix.com/GPX/1/0 http://www.topografix.com/GPX/1/0/gpx.xsd">
  <trk>
    <trkseg>
        ${data.map(
          (item) =>
            `<trkpt lat="${item.position.coordinates[1]}" lon="${
              item.position.coordinates[0]
            }"><speed>${item.speed / 3.6}</speed></trkpt>`,
        )}
    </trkseg>
  </trk>
</gpx>`;
  }

  findRoute(body: string): Observable<any> {
    const params = {
      routeMatch: "1",
      mode: "fastest;car;traffic:disabled",
      attributes:
        "SPEED_LIMITS_FCn(FROM_REF_SPEED_LIMIT,TO_REF_SPEED_LIMIT),LINK_ATTRIBUTE_FCn(ISO_COUNTRY_CODE,ROUTE_TYPES)",
      apiKey: this.config.mapApiKey,
    };
    return this.http.post(
      "https://fleet.api.here.com/v8/match/routelinks",
      body,
      {
        params,
      },
    );
  }

  getSpeedMarkers(response, onlySpeeding = true): SpeedMarker[] {
    const markers: SpeedMarker[] = [];
    if (response) {
      response.waypoint.forEach((item) => {
        const link = response.leg[0].link[item.routeLinkSeqNrMatched];
        const speed = Math.round(item.speedMps * 3.6);
        const limit = link.attributes.SPEED_LIMITS_FCN
          ? parseInt(
              parseInt(link.linkId, 10) > 0
                ? link.attributes.SPEED_LIMITS_FCN[0].FROM_REF_SPEED_LIMIT
                : link.attributes.SPEED_LIMITS_FCN[0].TO_REF_SPEED_LIMIT,
              10,
            )
          : 0;
        if (limit && (!onlySpeeding || speed - limit > 0)) {
          markers.push({
            speed,
            limit,
            coords: item.mappedPosition,
          });
        }
      });
    }
    return markers;
  }

  autocompleteSearch(searchTerm) {
    return this.http.get<IAutoCompleteSearchResponse>(
      `${this.config.apiUrl}/${this.config.autoCompleteUrl}`,
      {
        params: {
          apiKey: this.config.mapApiKey,
          limit: "5",
          lang: "pl",
          q: searchTerm,
        },
      },
    );
  }

  reverseIsoline(
    coordinates: { lat: number; lng: number },
    rangeType: string,
    rangeValue: number,
    traffic: boolean,
  ) {
    let trafficParam = {};
    if (!traffic) {
      trafficParam = {
        arrivalTime: 'any',
      };
    }

    return this.http
      .get<any>(`${this.config.apiUrl}/${this.config.reverseIsolineUrl}`, {
        params: {
          ...trafficParam,
          apiKey: this.config.mapApiKey,
          destination: `${coordinates.lat},${coordinates.lng}`,
          //mode: `fastest;car;traffic:${traffic ? "enabled" : "disabled"}`,
          transportMode: 'car',
          routingMode: 'fast',
          'range[type]': rangeType,
          'range[values]': `${
            rangeType === "time" ? rangeValue * 60 : rangeValue * 1000
          }`,
          //linkattributes: "sh",
        },
      })
      .pipe(
        map((res) => {
          if (
            res.isolines &&
            res.isolines.length
          ) {
            const shape2 = decodeFlexipolyline(res.isolines[0].polygons[0].outer);
            const coords = shape2.polyline.map(m => [m[1], m[0]]);
            return {
              type: "Polygon",
              coordinates: [coords],
            };
          }
          return null;
        }),
      );
  }

  ngOnDestroy(): void {
    this.resizeSub.unsubscribe();
  }

  contexMenuClick($event: ContextMenuItemEvent) {
    switch ($event.type) {
      case "add_address":
        this.routeHelper.navigatePopup(["poi"], {
          state: {
            point: $event.point,
          },
        });
        break;
      case "copy_gps":
        this.copyMessage(
          `https://maps.google.com/?q=${$event.point.lat},${$event.point.lng}`,
        );
        console.log($event.point.lat);
        break;
    }
  }

  copyMessage(val: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    // tslint:disable-next-line: deprecation
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  setViewBounds(rect: H.geo.Rect) {
    this.mapInstance.getViewModel().setLookAtData({
      bounds: rect,
    });
  }
}

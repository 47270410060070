import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { MarkerClass } from '../../marker.class';
import { MapService } from '../../map.service';

export interface SpeedMarker {
  speed: number;
  limit: number;
  coords: {
    latitude: number;
    longitude: number;
  };
}

export const MARKER_SPEED_COLORS = [
  {
    limit: 0,
    color: '#84CA50',
  },
  {
    limit: 10,
    color: '#ffbe0a',
  },
  {
    limit: 20,
    color: '#F07D02',
  },

  {
    limit: 30,
    color: '#ff0000',
  },
  {
    limit: 40,
    color: '#9E1313',
  },
  {
    limit: 50,
    color: '#661010',
  },
  {
    limit: 60,
    color: '#0E0E0E',
  }

];

@Component({
  selector: 'app-map-marker-speed',
  templateUrl: './map-marker-speed.component.html',
  styleUrls: ['./map-marker-speed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapMarkerSpeedComponent extends MarkerClass {
  @Input() speedMarker: SpeedMarker;
  addEvents = false;
  markerGroupName: string = 'MapMarkerSpeedComponent';
  constructor(mapService: MapService, el: ElementRef) {
    super(mapService, el);
  }

  get color() {
    const limit = this.speedMarker.speed - this.speedMarker.limit;
    return MARKER_SPEED_COLORS.find(limits => limit <= limits.limit || limits.limit === 60).color;
  }

  getCoords(): number[] | H.geo.IPoint {
    return [this.speedMarker.coords.longitude, this.speedMarker.coords.latitude];
  }

}

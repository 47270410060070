import { NotificationPopupModule } from './notification-popup/notification-popup.module';
import { NgModule } from '@angular/core';
import { VehiclePopupModule } from './vehicle-popup/vehicle-popup.module';
import { ReportsPopupModule } from './reports-popup/reports-popup.module';
import { PoiPopupModule } from './poi-popup/poi-popup.module';
import { NormsPopupModule } from './norms-popup/norms-popup.module';
import { GroupsPopupModule } from './groups-popup/groups-popup.module';
import { DriversPopupModule } from './drivers-popup/drivers-popup.module';
import { UserPopupModule } from './user-popup/user-popup.module';
import { ZonePopupModule } from './zone-popup/zone-popup.module';
import { RidePopupModule } from './ride-popup/ride-popup.module';
import { WorkSchedulesPopupModule } from './work-schedules-popup/work-schedules-popup.module';
import { NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { DevicePopupModule } from './device-popup/device-popup.module';

@NgModule({
  imports: [],
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}],
  exports: [VehiclePopupModule,
    ReportsPopupModule,
    DriversPopupModule,
    UserPopupModule,
    WorkSchedulesPopupModule,
    ZonePopupModule,
    RidePopupModule,
    NotificationPopupModule,
    DevicePopupModule,
    PoiPopupModule, NormsPopupModule, GroupsPopupModule]
})
export class PopupsModule {
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Group } from '../../../interfaces/group';


@Component({
  selector: 'app-tags-group',
  templateUrl: './tags-group.component.html',
  styleUrls: ['./tags-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsGroupComponent implements OnInit {
  @Input() tags: Group[] = [];
  @Input() visible = 2;
  @Input() size: string;
  @Input() iconClass = 'icon-car';

  get hasMore() {
    return this.visible && this.tags && this.tags.length > this.visible;
  }

  get more() {
    return this.tags ? {name: `+${this.tags.length - this.visible}`} : 0;
  }

  get maxTags() {
    return this.visible && this.tags ? this.tags.slice(0, this.visible) : [];
  }

  constructor() {
  }

  ngOnInit() {
  }

}

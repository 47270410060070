<div class="d-flex justify-content-between align-items-stretch flex-column flex-fill h-100">
    <div class="overflow-auto flex-grow-1">
        <div [formGroup]="forms?.main" class="p-xm">
            <h3 class="form-header mt-xl" translate>VEHICLE_SPECIFICATION</h3>
            <div class="row">
                <div class="col-4">
                    <app-form-group>
                        <label translate>NAME</label>
                        <input app-form formControlName="name" type="text">
                        <app-error-message [control]="forms?.main.get('name')"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4">
                    <app-form-group>
                        <label translate>MARK</label>
                        <input app-form formControlName="mark" type="text">
                        <app-error-message [control]="forms?.main.get('mark')"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4">
                    <app-form-group>
                        <label translate>MODEL</label>
                        <input app-form formControlName="model" type="text">
                        <app-error-message [control]="forms?.main.get('model')"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4 mt-m">
                    <app-form-group>
                        <label translate>NUMBER_PLATE</label>
                        <input app-form formControlName="numberPlate" type="text">
                        <app-error-message [control]="forms?.main.get('numberPlate')"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4 mt-m">
                    <app-form-group>
                        <label translate>VIN</label>
                        <input app-form formControlName="vin" type="text">
                        <app-error-message [control]="forms?.main.get('vin')"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4 mt-m">
                    <app-form-group>
                        <label translate>Avatar</label>
                        <input app-form formControlName="avatarUrl" type="text">
                        <app-error-message [control]="forms?.main.get('avatarUrl')"></app-error-message>
                    </app-form-group>
                    <!-- <app-select-input
                            [max]="1"
                            [multiple]="false"
                            formControlName="avatarUrl"
                            [showTags]="false"
                            [items]="avatars"
                            bindLabel="label"
                            bindValue="value"
                    ><label translate>AVATAR</label></app-select-input> -->
                </div>
                <div class="col-4 mt-m">
                    <app-form-group>
                        <label translate>ENGINE_CAPACITY</label>
                        <input app-form formControlName="engineCapacity" type="number">
                        <app-error-message [control]="forms?.main.get('engineCapacity')"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4 mt-m">
                    <app-form-group>
                        <label translate>MILAGE</label>
                        <input app-form formControlName="totalMileage" type="number">
                        <app-error-message [control]="forms?.main.get('totalMileage')"></app-error-message>
                    </app-form-group>
                </div>


                <div class="col-4 mt-m">
                    <app-form-group (click)="d.open()" [noFocus]="true" iconPosition="right" icon="icon-calendar">
                        <label translate>PRODUCTION_DATE</label>
                        <input formControlName="productionDate"
                               outsideDays="collapsed"
                               #input
                               app-form
                               [maxDate]="maxDate"
                               (focus)="stopEvents(input)"
                               autoClose="outside"
                               placement="bottom-right"
                               ngbDatepicker #d="ngbDatepicker"/>
                    </app-form-group>
                </div>
                <div class="col-4 mt-m">
                    <app-form-group (click)="d2.open()" [noFocus]="true" iconPosition="right" icon="icon-calendar">
                        <label translate>UTILIZATION_STARTDATE</label>
                        <input formControlName="utilizationStartDate"
                               navigation="arrows"
                               outsideDays="collapsed"
                               #utilizationStartDate
                               app-form
                               (focus)="stopEvents(utilizationStartDate)"
                               autoClose="outside"
                               placement="bottom-left"
                               ngbDatepicker #d2="ngbDatepicker"/>
                    </app-form-group>
                </div>
                <div class="col-4 mt-m">
                    <app-form-group>
                        <label translate>FUEL_TANK_SIZE</label>
                        <input app-form formControlName="fuelTankSize" type="number">
                        <app-error-message [control]="forms?.main.get('fuelTankSize')"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4 mt-m">

                    <app-select-input
                            [max]="1"
                            [multiple]="false"
                            formControlName="fuelType"
                            [showTags]="false"
                            [items]="fuelTypes"
                    ><label translate>FUEL_TYPE</label></app-select-input>
                    <app-error-message [control]="forms?.main.get('fuelType')"></app-error-message>
                </div>
            </div>
        </div>
        <div class="p-xm" *ngIf="!partnerView">
            <h3 class="form-header mt-xm" translate>ASSIGNED_DRIVER</h3>
            <app-select-input
                    [items]="drivers$|async"
                    [multiple]="false"
                    [max]="1"
                    selectClass="w-50"
                    [allowClear]="true"
                    [showTags]="false"
                    [searchable]="true"
                    bindLabel="fullName"
                    bindValue="id"
                    [formControl]="forms?.driver"
            >
                <label translate>DRIVER</label>
            </app-select-input>
            <app-error-message [control]="forms?.driver"></app-error-message>
        </div>
        <div class="p-xm"  *ngIf="!partnerView">
            <h3 class="form-header mt-xl" translate>VEHICLE_GROUP</h3>
            <app-select-input
                    [items]="groups"
                    [multiple]="true"
                    [max]="2"
                    selectClass="w-50"
                    [allowClear]="true"
                    [showTags]="true"
                    [searchable]="true"
                    bindLabel="name"
                    bindValue="id"
                    [formControl]="forms?.group"
            >
                <label translate>GROUPS</label>
            </app-select-input>
            <app-error-message [control]="forms?.group"></app-error-message>
        </div>


        <div [formGroup]="forms?.main" class="p-xm">
            <h3 class="form-header mt-xl" translate>ADDITIONAL_DATA</h3>
            <div class="row">
                <div class="col-12 mt-m">
                    <app-form-group>
                        <label translate>DESCRIPTION</label>
                        <textarea app-form formControlName="description"></textarea>
                        <app-error-message [control]="forms?.main.get('description')"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4 mt-m">
                    <app-form-group>
                        <label translate>WEIGHT</label>
                        <input app-form formControlName="weight" type="number" [min]="0">
                        <app-error-message [control]="forms?.main.get('weight')"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4 mt-m">
                    <app-form-group>
                        <label translate>LENGTH</label>
                        <input app-form formControlName="length" type="number" [min]="0">
                        <app-error-message [control]="forms?.main.get('length')"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4 mt-m">
                    <app-form-group>
                        <label translate>WIDTH</label>
                        <input app-form formControlName="width" type="number" [min]="0">
                        <app-error-message [control]="forms?.main.get('width')"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4 mt-m">
                    <app-form-group>
                        <label translate>HEIGHT</label>
                        <input app-form formControlName="height" type="number" [min]="0">
                        <app-error-message [control]="forms?.main.get('height')"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4 mt-m">
                    <app-form-group>
                        <label translate>FRONT_AXLE_PRESSURE</label>
                        <input app-form formControlName="frontAxlePressure" type="number" [min]="0">
                        <app-error-message [control]="forms?.main.get('frontAxlePressure')"></app-error-message>
                    </app-form-group>
                </div>
                <div class="col-4 mt-m">
                    <app-form-group>
                        <label translate>REAR_AXLE_PRESSURE</label>
                        <input app-form formControlName="rearAxlePressure" type="number" [min]="0">
                        <app-error-message [control]="forms?.main.get('rearAxlePressure')"></app-error-message>
                    </app-form-group>
                </div>
            </div>
        </div>

        <div [formGroup]="forms?.main" class="p-xm">
          <h3 class="form-header mt-xl" translate>MAINTENANCE</h3>
          <div class="row">
            <div class="col-4 mt-m">
              <app-select-input
                      [max]="1"
                      [multiple]="false"
                      formControlName="status"
                      [showTags]="false"
                      [items]="vehicleStatuses"
                      bindLabel="label"
                      bindValue="value"
              ><label translate>STATUS</label></app-select-input>
              <app-error-message [control]="forms?.main.get('status')"></app-error-message>
            </div>
          </div>
      </div>
    </div>

    <div class="p-xm d-flex justify-content-end align-items-center border-top">
        <button class="btn btn-primary btn-border px-xxl mr-m" (click)="cancel()" translate>
            {{(isEditMode ? 'CANCEL' : 'EXIT')|translate}}
        </button>
        <button class="btn btn-primary px-xxl" (click)="handlerSave()" [disabled]="pending" *ngIf="showButton">
            <app-spinner [showSpinner]="pending"></app-spinner>
            {{(isNewView || isEditMode ? 'SAVE' : 'EDIT')|translate}}
        </button>
    </div>
</div>

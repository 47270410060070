import { animate, group, query, stagger, state, style, transition, trigger } from '@angular/animations';

/**
 * Created by lukaszgrzywa on 26.10.2017.
 */
const CUBIC = 'cubic-bezier(.25, .8, .25, 1)';

export const modalFadeTrigger = trigger('routeFadeState', [
  transition(':enter', [
    style({
      transform: 'translateY(50%)'
    }),
    animate(`.6s ${CUBIC}`)
  ]),
]);

export const enterFromTopTrigger = trigger('enterFromTop', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'translateY(-100%)'
    }),
    animate(`.6s ${CUBIC}`)
  ]),
]);

export const enterFromLeftTrigger = trigger('enterFromLeft', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'translateX(-100%)'
    }),
    animate(`.6s ${CUBIC}`)
  ]),
]);

export const tableButtonsAnimationTrigger = trigger('tableButtonsAnimation', [
  transition(':enter', [
    query(':leave', [
      style({
        opacity: 0,
        transform: 'translateX(100%)'
      }),
      stagger(500, [
        animate(`.6s ${CUBIC}`)
      ]),
    ]),
  ]),
  transition(':leave', [
    animate(`.6s ${CUBIC}`, style({
      opacity: 0,
      transform: 'translateX(100%)'
    }))
  ]),
]);


export const ngIfEnterTrigger = trigger('ngIfEnter', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'translateX(100%)'
    }),
    animate(`.6s ${CUBIC}`)
  ]),
  transition(':leave', [
    animate(`.6s ${CUBIC}`, style({
      opacity: 0,
      transform: 'translateX(100%)'
    }))
  ]),
]);

export const ngIfEnterWidthTrigger = trigger('ngIfEnterWidth', [
  transition(':enter', [
    style({
      opacity: 0,
      width: '0px',
    }),
    animate(`.3s ease-in-out`, style({
      opacity: 1,
      width: '*'
    }))
  ]),
  transition(':leave', [
    animate(`.3s ease-in-out`, style({
      opacity: 0,
      width: '0px',
    }))
  ]),
]);
export const ngIfEnterHeightTrigger = trigger('ngIfEnterHeight', [
  transition(':enter', [
    style({
      opacity: 0,
      height: '0px',
      overflow: 'hidden'
    }),
    animate(`.4s ease-in-out`, style({
      overflow: 'hidden',
      opacity: 1,
      height: '*'
    }))
  ]),
  transition(':leave', [
    style({overflow: 'hidden'}),
    animate(`.4s ease-in-out`, style({
      opacity: 0,
      height: '0px',
      overflow: 'hidden'
    }))
  ]),
]);
export const ngForFadeInOutTrigger = trigger('ngForFadeInOut', [
  transition('* => *', [
    query(':leave', [
      style({
        transform: 'translateX(0px)',
        opacity: 1,
      }),
      stagger(
        100, [
          animate('0.5s ease-in-out', style({
            transform: 'translateX(40px)',
            opacity: 0,
          }))
        ]
      )
    ], {optional: true}),
    query(':enter', [
      style({
        transform: 'translateX(40px)',
        opacity: 0,
      }),
      stagger(100, [
        animate(`0.5s ${CUBIC}`, style({
          transform: 'translateX(0px)',
          opacity: 1,
        }))
      ])
    ], {optional: true})
  ])
]);

export const slideInOutTrigger = trigger('slideInOut', [
  transition(':enter', [
    style({
      height: '0px',
      overflow: 'hidden',
    }),
    animate(`.6s ${CUBIC}`, style({
      height: '*',
      overflow: 'hidden',
    }))
  ]),
  transition(':leave', [
    animate(`.6s ${CUBIC}`, style({
      height: '0px',
    }))
  ]),
]);
export const showErrorTrigger = trigger('showError', [
  state('in', style({
    transform: 'translateY(0)',
    opacity: 1,
    height: '*',
  })),
  state('void', style({
    transform: 'translateY(-50%)',
    opacity: 0,
    height: '0px',
  })),
  transition('* <=> *', [
    animate('0.5s cubic-bezier(.25, .8, .25, 1)')
  ]),
]);

export const routerTransition = trigger('routerTransition', [
  transition('* <=> *', [
    query(':enter, :leave', style({position: 'fixed'}), {optional: true}),
    group([
      query(':enter', [
        style({transform: 'translateX(100%)', opacity: 0}),
        animate(`0.6s ${CUBIC}`, style({transform: 'translateX(0%)', opacity: 1}))
      ], {optional: true}),
      query(':leave', [
          animate(`0.6s ${CUBIC}`, style({transform: 'translateX(100%)', opacity: 0}))],
        {optional: true}),
    ])
  ])
]);

import { Injectable } from '@angular/core';
import ApiServiceClass from '../../classes/api-service.class';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { NormTemplate, NormTemplateConfiguration, NormTemplateQueryParams, NormTemplateWithConfig } from '../../interfaces/norms';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NormsService extends ApiServiceClass<NormTemplate> {
  private updateSubject = new BehaviorSubject(null);
  private templateIdsToSkip: string[] = ['5d7a1c12443660944eab396b', '5dd6abc2443660944ef6637b', '5d7a09f8b4e4d9a4de627b2f', '5dd6a4b9b4e4d9a4de9e7d6f'];
  public wasUpdate$ = this.updateSubject.asObservable();

  constructor(config: ConfigService,
              http: HttpClient) {
    super('/norms/templates', config, http);
  }

  templates(urlParams: NormTemplateQueryParams = {}): Observable<NormTemplate[]> {
    const params = this.httpParams(urlParams);
    // connectedboat
    return this.http.get<NormTemplate[]>(`${this.baseUrl}`, {params})
      .pipe(map(templates => {
        if (this.config.isCbTheme()) {
          return templates.filter(f => !this.templateIdsToSkip.includes(f.id));
        }
        return templates;
      }));
  }

  templatesConfigurations(urlParams: NormTemplateQueryParams = {}): Observable<NormTemplateConfiguration[]> {
    const params = this.httpParams(urlParams);
    return this.http.get<NormTemplateConfiguration[]>(`${this.baseUrl}/configurations`, {params});
  }

  templateConfiguration(templateId: string, configurationId: string): Observable<NormTemplateConfiguration> {
    return this.http.get<NormTemplateConfiguration>(`${this.baseUrl}/${templateId}/configurations/${configurationId}`);
  }

  saveTemplateConfiguration(templateId: string, template: NormTemplateConfiguration): Observable<NormTemplateConfiguration> {
    return this.http.post<NormTemplateConfiguration>(`${this.baseUrl}/${templateId}/configurations`, template)
      .pipe(tap(res => this.updateSubject.next(res)));
  }

  updateTemplateConfiguration(templateId: string,
                              configurationId: string, template: NormTemplateConfiguration): Observable<NormTemplateConfiguration> {
    return this.http.patch<NormTemplateConfiguration>(`${this.baseUrl}/${templateId}/configurations/${configurationId}`, template)
      .pipe(tap(res => this.updateSubject.next(res)));
  }

  deleteTemplateConfiguration(templateId: string, configurationId: string): Observable<any> {
    return this.http.delete<NormTemplateConfiguration>(`${this.baseUrl}/${templateId}/configurations/${configurationId}`);
  }

  templateWithConfigurations(params: NormTemplateQueryParams = {}): Observable<NormTemplateWithConfig[]> {
    return this.templates(params).pipe(
      switchMap(templates => this.templatesConfigurations().pipe(
        map(configurations => configurations.reduce((a, c) => ({
          ...a,
          [c.templateId]: a[c.templateId] ? [...a[c.templateId], c] : [c]
        }), {})),
        map(configurations => templates.map(template => ({...template, configurations: configurations[template.id] || []}))))));
  }

  templateWithConfiguration(templateId: string, configurationId?: string):
    Observable<{ template: NormTemplate, configuration?: NormTemplateConfiguration }> {
    if (configurationId) {
      return forkJoin(this.get(templateId), this.templateConfiguration(templateId, configurationId))
        .pipe(map(([template, configuration]) => ({template, configuration})));
    } else {
      return this.get(templateId).pipe(map(res => ({template: res})));
    }
  }


  zoneTemplateId() {
    return this.templates({type: 'ZoneCheck'})
      .pipe(map(res => {
        if (res && res[0]) {
          return res[0].id;
        } else {
          throw new Error('Valid token not returned');
        }
      }));
  }

  zoneTemplateDetails() {
    return this.zoneTemplateId().pipe(switchMap(id => this.templateWithConfiguration(id)));
  }

  zoneTemplateWithConfiguration(configId?) {
    return this.zoneTemplateId().pipe(
      switchMap(id => this.templateWithConfiguration(id, configId)));
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Portal } from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root'
})
export class UiUtilsService {
  private portalSubject: BehaviorSubject<Portal<any>> = new BehaviorSubject(undefined);

  get portal$() {
    return this.portalSubject.asObservable();
  }

  constructor() {
  }

  public setPortal(portal: Portal<any>) {
    this.portalSubject.next(portal);
  }
}

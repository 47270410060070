import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { RouterHelperService } from '../../../core/services/router-helper.service';
import { FILTERS_VIEWS, StorageAndQueryService } from '../../../core/services/storage_and_query.service';

export const enum DateRangeValueType {
  today = 'today',
  yesterday = 'yesterday',
  this_week = 'this_week',
  last_week = 'last_week',
  current_month = 'current_month',
  previous_month = 'previous_month',
  selected = 'selected',
}

export interface DateRageValue {
  start: string;
  end: string;
  type: DateRangeValueType;
}

export interface DateRangeItem {
  label: string;
  value: DateRangeValueType;
}

@Injectable()
export class DateRangeService {

  constructor(private routerHelper: RouterHelperService, private sotrage: StorageAndQueryService) {
  }

  queryToDateRange(query, popup = false): DateRageValue | null {
    const sufix = popup ? 'Popup' : '';
    if (query['dateType' + sufix]) {
      return this.dateRangeValueByType(query['dateType' + sufix], {
        fromDate: query['fromDate' + sufix] ? DateTime.fromISO(query['fromDate' + sufix]) : null,
        toDate: query['toDate' + sufix] ? DateTime.fromISO(query['toDate' + sufix]) : null,
      });
    }
    return null;
  }

  getInitFilters(query, popup = false, filterId?: FILTERS_VIEWS) {
    return this.queryToDateRange(query, popup) || this.sotrage.getItem(filterId + '_DATES');
  }

  resetQuery(route, popup) {
    const sufix = popup ? 'Popup' : '';
    this.routerHelper.queryParams(route, {['dateType' + sufix]: null, ['fromDate' + sufix]: null, ['toDate' + sufix]: null});
  }

  saveDate(filterId: FILTERS_VIEWS, date: DateRageValue) {
    this.sotrage.setItem(filterId + '_DATES', date);
  }

  dateRangeToQuery(date: DateRageValue, route, popup = false) {
    this.routerHelper.queryParams(route,
      this.dateQuery(date, popup)
    );
  }

  dateQuery(date: DateRageValue, isPopup = false) {
    const sufix = isPopup ? 'Popup' : '';
    return date.type === DateRangeValueType.selected ? {
      ['fromDate' + sufix]: date.start,
      ['toDate' + sufix]: date.end,
      ['dateType' + sufix]: date.type
    } : {['dateType' + sufix]: date.type, ['fromDate' + sufix]: null, ['toDate' + sufix]: null};
  }

  dateRangeValueByType(selectedDate: DateRangeValueType, rangeDate?: {
    fromDate: any,
    toDate: any,
  }): DateRageValue {
    let start;
    let end;
    switch (selectedDate) {
      case DateRangeValueType.selected :
        if (rangeDate && rangeDate.fromDate && rangeDate.toDate) {
          start = rangeDate.fromDate.startOf('day');
          end = rangeDate.toDate.endOf('day');
        }
        break;
      case DateRangeValueType.yesterday :
        start = DateTime.local().startOf('day').plus({days: -1});
        end = DateTime.local().endOf('day').plus({days: -1});
        break;
      case DateRangeValueType.current_month :
        start = DateTime.local().startOf('month');
        end = DateTime.local();
        break;
      case DateRangeValueType.previous_month :
        start = DateTime.local().startOf('month').plus({month: -1});
        end = DateTime.fromISO(start.toISO()).endOf('month');
        break;
      case DateRangeValueType.this_week :
        start = DateTime.local().startOf('week');
        end = DateTime.local();
        break;
      case DateRangeValueType.last_week :
        start = DateTime.local().startOf('week').plus({days: -7});
        end = DateTime.local().endOf('week').plus({days: -7});
        break;
      default :
        start = DateTime.local().startOf('day');
        end = DateTime.local();
        break;
    }
    return {
      start: start.toUTC().toString(),
      end: end.toUTC().toString(),
      type: selectedDate,
    };
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../../../core/guards/auth-guard.service';
import { ZoneResolve } from './zone.resolve';
import { ZoneBasicComponent } from './zone-details/zone-basic.component';
import { ZonePopupNewComponent } from './zone-popup-new/zone-popup-new.component';
import { ZonePopupDetailsComponent } from './zone-popup-details/zone-popup-details.component';
import { ZoneRulesComponent } from './zone-rules/zone-rules.component';
import { ZonesNewRulesComponent } from './zones-new-rules/zones-new-rules.component';

const routes: Routes = [
  {
    path: 'zone',
    outlet: 'popup',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: ZonePopupNewComponent,
      },
      {
        path: ':id',
        component: ZonePopupDetailsComponent,
        resolve: {
          zone: ZoneResolve,
        },
        children: [{
          path: '',
          pathMatch: 'full',
          component: ZoneBasicComponent,
        },
          {
            path: 'rules',
            children: [
              {
                path: '',
                pathMatch: 'full',
                component: ZoneRulesComponent,
              },
              {
                path: 'new',
                component: ZonesNewRulesComponent,
              },
              {
                path: ':id',
                component: ZonesNewRulesComponent,
              }
            ]
          },
        ]
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [ZoneResolve],
})
export class ZonePopupRoutingModule {
}

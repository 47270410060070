import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges, Output,
  SimpleChanges, ViewChild
} from '@angular/core';
import { WorkVehicleWarehouse } from '../../../../core/services/vehicles.service';
import { Poi } from '../../../../interfaces/poi';
import { AddressPipe } from '../../../pipes/address.pipe';
import { animate, style, transition, trigger } from '@angular/animations';
import { TelemetryRide } from '../../../../interfaces/telemetry';

@Component({
  selector: 'app-localization',
  templateUrl: './localization.component.html',
  styleUrls: ['./localization.component.scss'],
  providers: [AddressPipe],
  animations: [
    trigger('enterFromLeft', [
      transition(':enter', [
        style({
          opacity: 0,
          transform: 'translateX(-20px)'
        }),
        animate(`.35s ease-in-out`)
      ]),
      transition(':leave', [
        animate(`.35s ease-in-out`, style({
          opacity: 0,
          transform: 'translateX(-20px)'
        }))
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocalizationComponent implements OnChanges {

  @Input() data: WorkVehicleWarehouse | TelemetryRide;
  @Input() ellipsis: boolean;
  @Input() position: 'left' | 'right';
  @ViewChild('wrapper', { static: true }) el: ElementRef;
  @HostBinding('class.has-poi')
  public poi: Poi;
  @Input()
  public localization: string;
  public showTooltip = false;
  @Input() type: 'start' | 'end' | 'park' | 'telemetry' = 'start';
  @Input() break = false;
  @Output() poiClick = new EventEmitter();
  public parkIsOverride = false;

  @HostListener('mouseenter', ['$event']) onMouseEnter() {
    if (this.ellipsis && this.el.nativeElement.offsetWidth < this.el.nativeElement.scrollWidth) {
      this.showTooltip = true;
      this.cd.detectChanges();
    }
  }

  @HostListener('mouseleave', ['$event']) onMouseLeave() {
    this.showTooltip = false;
    this.cd.detectChanges();
  }

  constructor(private addresPipe: AddressPipe, private cd: ChangeDetectorRef) {
  }

  localizationFromPoi(poi: Poi, address?: string) {
    this.poi = poi;
    return `<strong>${poi.name}</strong>,${this.break ? '<br/>' : ' '}${address || this.addresPipe.transform(poi.address)}`;
  }

  localizationClick($event) {
    if ($event.target.nodeName === 'STRONG' && this.poi) {
      this.poiClick.emit(this.poi);
    }
  }

  telemetryLocalization(data: TelemetryRide) {
    if (data.parkData && data.parkData.poi && (data.parkData.poi.userPoi || data.parkData.poi.framePoi)) {
      const poi = data.parkData.poi.userPoi || data.parkData.poi.framePoi;
      if (this.parkIsOverride && data.parkData.revgeo) {
        return this.localizationFromPoi(poi, this.addresPipe.transform(data.parkData.revgeo));
      } else {
        return this.localizationFromPoi(poi);
      }
    }
    if (data.parkData) {
      return data.parkData.revgeo ? data.parkData.revgeo.label : '-';
    }
    return data.rideData && data.rideData.startRevgeo ? data.rideData.startRevgeo.label : '-';
  }

  startLocalization(data: WorkVehicleWarehouse) {
    if (data.RIDE_START_POI && data.RIDE_START_POI[0].v) {
      return this.localizationFromPoi(data.RIDE_START_POI[0].v);
    }
    return data.RIDE_START_REVGEO && data.RIDE_START_REVGEO[0].v
      ? data.RIDE_START_REVGEO[0].v : '-';
  }

  setLocalization() {
    this.poi = null;
    if (!this.data) {
      return '-';
    }


    if (this.type === 'telemetry') {
      this.setParkIsOverrideTelemetry(this.data as TelemetryRide);
      this.localization = this.telemetryLocalization(this.data as TelemetryRide);
    } else if (this.type === 'start') {
      this.localization = this.startLocalization(this.data as WorkVehicleWarehouse);
    } else {
      this.setParkIsOverride(this.data as WorkVehicleWarehouse);
      this.localization = this.endLocalization(this.data as WorkVehicleWarehouse);
    }
  }

  setParkIsOverrideTelemetry(data: TelemetryRide) {
    if (data.parkData && data.parkData.poi && data.parkData.poi.override && data.parkData.poi.override.isOverride) {
      this.parkIsOverride = true;
    }
  }

  setParkIsOverride(data: WorkVehicleWarehouse) {
    if (data.PARK_POI && data.PARK_POI[0].v &&
       data.PARK_IS_OVERRIDE && data.PARK_IS_OVERRIDE[0].v) {
         this.parkIsOverride = true;
       }
  }

  endLocalization(data: WorkVehicleWarehouse) {
    if (data.PARK_POI && data.PARK_POI[0].v) {
      if (this.parkIsOverride) {
        return this.localizationFromPoi(data.PARK_POI[0].v, data.PARK_REVGEO && data.PARK_REVGEO[0].v ? data.PARK_REVGEO[0].v : '');
      } else {
        return this.localizationFromPoi(data.PARK_POI[0].v);
      }
    } else {
      return data.PARK_REVGEO && data.PARK_REVGEO[0].v
        ? data.PARK_REVGEO[0].v : '-';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data) {
      this.setLocalization();
    }
  }

}

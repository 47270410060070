import { FuelUnitPipe } from './fuel-unit.pipe';
import { MaxSpeedPipe } from './max-speed.pipe';
import { PoDPipe } from './pod.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WarehouseUnitPipe } from './warehouse-unit.pipe';
import { InitialsPipe } from './initials.pipe';
import { FullNamePipe } from './full-name.pipe';
import { WarehouseValuePipe } from './warehouse-value.pipe';
import { AddressPipe } from './address.pipe';
import { AvgSpeedPipe } from './avg-speed.pipe';
import { ContactPipe } from './contact.pipe';
import { FromTimePipe } from './from-time.pipe';
import { LocalHourPipe } from './local-hour.pipe';

@NgModule({
  declarations: [WarehouseUnitPipe, InitialsPipe, FullNamePipe, WarehouseValuePipe, AddressPipe, FuelUnitPipe,
    AvgSpeedPipe, ContactPipe, FromTimePipe, LocalHourPipe, PoDPipe, MaxSpeedPipe],
  exports: [WarehouseUnitPipe, InitialsPipe, FullNamePipe, WarehouseValuePipe, FuelUnitPipe,
    AddressPipe, AvgSpeedPipe, ContactPipe, FromTimePipe, LocalHourPipe, PoDPipe, MaxSpeedPipe],
  imports: [
    CommonModule
  ]
})
export class PipesModule {
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerspectiveMenuComponent } from './perspective-menu.component';
import { PerspectiveMenuViewComponent } from './perspective-menu-view/perspective-menu-view.component';
import { PerspectiveFiltersDirective } from './perspective-filters.directive';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PerspectiveMenuDirective } from './perspective-menu.directive';

@NgModule({
  declarations: [
    PerspectiveMenuViewComponent,
    PerspectiveMenuComponent,
    PerspectiveFiltersDirective,
    PerspectiveMenuDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule
  ],
  providers: [],
  exports: [
    PerspectiveMenuViewComponent,
    PerspectiveMenuComponent,
    PerspectiveFiltersDirective,
    PerspectiveMenuDirective
  ]
})
export class PerspectiveMenuModule {
}

<div class="position-relative c-date-range-filter" ngbDropdown [placement]="placement" [autoClose]="true">
    <app-form-group (click)="toggleOpen()" icon="icon-calendar" form-lg iconPosition="right" ngbDropdownAnchor>
        <label for="" translate>SELECT_DATE_PERIOD</label>
        <input app-form class="dropdown-toggle m-0 pointer" disabled="disabled"
               [value]="calendarValue|translate"/>
    </app-form-group>
    <div ngbDropdownMenu class="dropdown-menu">
        <app-dropdown-card (close)="toggleOpen()" label="{{'SELECT_DATE_PERIOD' | translate }}">
            <ng-container dropdown-card-header>
                <div class="d-flex justify-content-start align-items-center flex-wrap">
                    <div *ngFor="let item of dates" class="mb-s mr-s">
                        <app-form-group form-white>
                            <label>
                                <input type="radio" name="date" [value]="item.value" [ngModel]="dateType"
                                       (ngModelChange)="dateTypeChange($event)" app-form>
                                <span></span>
                                {{item.label|translate}}
                            </label>
                        </app-form-group>
                    </div>
                </div>
            </ng-container>
            <ng-container dropdown-card-footer>
                <div class="p-s text-right border-top">
                    <button class="btn btn-primary btn-sm btn-border mr-m" translate (click)="close()">CANCEL</button>
                    <button class="btn btn-primary btn-sm" translate (click)="save()">APPLY</button>
                </div>
            </ng-container>
            <div *ngIf="calendarIsOpen">
                <app-date-range-filter-calendar (select)="onDateSelection($event)"
                                                [fromDate]="rangeDateTmp?.fromDate"
                                                [toDate]="rangeDateTmp?.toDate"
                ></app-date-range-filter-calendar>
            </div>


        </app-dropdown-card>
    </div>

</div>

import ApiServiceClass from './api-service.class';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigService } from '../core/services/config.service';
import { AuthService } from '../core/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { filter, tap } from 'rxjs/operators';

export class ApiServiceWithSub<T> extends ApiServiceClass<T> {
  private listSub: BehaviorSubject<T[]> = new BehaviorSubject(null);
  protected inludeList = [];

  constructor(url: string,
              config: ConfigService,
              http: HttpClient,
              protected authService: AuthService,
  ) {
    super(url, config, http);
  }

  list$() {
    return this.listSub.asObservable().pipe(filter(res => !!res));
  }

  list(parameters = {}): Observable<T[]> {
    const params = this.httpParams({include: this.inludeList, ...parameters});
    return this.http.get<T[]>(this.baseUrl, {params});
  }

  updateListSub() {
    return this.list().pipe(tap(res => this.listSub.next(res)));
  }

  updateList() {
    this.updateListSub().subscribe();
  }

  initList(): void {
    this.authService.isLoggedIn$.subscribe((isLogged) => {
      if (isLogged) {
        this.updateListSub().subscribe();
      } else {
        this.listSub.next([]);
      }
    });
  }
}

import {Injectable} from '@angular/core';
import ApiServiceClass from '../../classes/api-service.class';
import {ConfigService} from './config.service';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, interval, Observable, Subscription} from 'rxjs';
import {
  NotificationCount,
  NotificationRequest,
  NotificationSetting,
  NotificationT, NotificationTemplateType,
  NotificationType
} from '../../interfaces/notificationT';
import {AuthService} from './auth.service';
import {startWith, switchMap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends ApiServiceClass<NotificationT> {

  public countInterval: Subscription;
  public countSubject: BehaviorSubject<NotificationCount> = new BehaviorSubject(null);
  private counterData: NotificationCount;

  constructor(config: ConfigService,
              protected authService: AuthService,
              private toastr: ToastrService,
              private translateService: TranslateService,
              http: HttpClient) {
    super('/notifications', config, http);
    this.authService.isLoggedIn$.subscribe((isLogged) => {
      if (isLogged) {
        this.countInterval = interval(60000).pipe(
          startWith(0),
          switchMap(() => this.fetchCount())
        ).subscribe(res => {
          if (this.counterData && res.new > this.counterData.new) {
            this.toastr.success(`Ilość nowych powiadomień: ${this.counterData.new - res.new}`, 'Nowe powiadomienia');
          }
          this.counterData = res;
          this.countSubject.next(this.counterData);
        });
      } else {
        this.counterData = null;
        if (this.countInterval) {
          this.countInterval.unsubscribe();
        }
        this.countSubject.next(this.counterData);
      }
    });
  }

  get count$() {
    return this.countSubject.asObservable();
  }

  setRecipients(type: NotificationType, related, users = [], emails = []) {
    return this.http.post(`${this.baseUrl}/recipients`, {
      type,
      related,
      recipients: {
        emails,
        users,
      }
    });
  }

  getRecipients(type: NotificationType, relatedId: string, relatedType: NotificationTemplateType) {
    return this.http.get(`${this.baseUrl}/recipients`, {
      params: {
        type,
        relatedId,
        relatedType
      }
    });
  }

  settings(): Observable<NotificationSetting[]> {
    return this.http.get<NotificationSetting[]>(`${this.baseUrl}/settings`);
  }

  updateSettings(body: NotificationRequest): Observable<NotificationSetting> {
    return this.http.patch<NotificationSetting>(`${this.baseUrl}/settings`, body);
  }


  viewed() {
    return this.http.post(`${this.baseUrl}/viewed`, {})
      .pipe(switchMap(() => this.fetchCount()))
      .subscribe(res => {
        this.countSubject.next(res);
      });
  }

  fetchCount(): Observable<NotificationCount> {
    return this.http.get<NotificationCount>(`${this.baseUrl}/count`);
  }

  getLabel(notification: NotificationT): string {
    if (notification.type === NotificationType.ZONE_CHANGE) {
      const zoneChangeTemplateName: string = `NOTIFICATION_TEMPLATES_SPEECH.NORM_ZONE_CHANGE_${notification.data[NotificationType.ZONE_CHANGE].state.toUpperCase()}`;
      return this.translateService.instant(zoneChangeTemplateName, notification.data[notification.type]);
    }
    // <span [innerHTML]="'NOTIFICATION_TEMPLATES.NORM_ADHOC'|translate:notification"></span>
    return this.translateService.instant(this.getTranslateTemplateByType(notification.type), notification.data[notification.type]);
  }

  getTranslateTemplateByType(type: NotificationType) {
    const templatePrefix: string = 'NOTIFICATION_TEMPLATES_SPEECH.';
    switch (type) {
      case NotificationType.NORM:
        return templatePrefix + 'NORM';
      case NotificationType.NORM_AGGREGATE:
        return templatePrefix + 'NORM_AGGREGATE';
      case NotificationType.NORM_ADHOC:
        return templatePrefix + 'NORM_ADHOC';
      default:
      return templatePrefix + 'NORM';
    }
  }
}

/**
 * Created by lukaszgrzywa on 13.06.2017.
 */
import { NgModule } from '@angular/core';
import { OverflowSpinnerComponent } from './overflow-spinner.component';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './spinner/spinner.component';
import { CircleSpinnerComponent } from './circle-spinner/circle-spinner.component';


@NgModule({
  imports: [CommonModule],
  exports: [OverflowSpinnerComponent, SpinnerComponent, CircleSpinnerComponent],
  declarations: [OverflowSpinnerComponent, SpinnerComponent, CircleSpinnerComponent],
})
export class OverflowSpinnerModule {
}

import { Component, HostBinding, OnDestroy } from '@angular/core';
import { routeFadeStateTrigger } from '../authentication.animations';
import { AuthService } from '../../../core/services/auth.service';
import { ApiValidatorService } from '../../../core/services/api-validator.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormController } from '../../../classes/form-controller.class';

const NEW_ACCOUNT_MSG = {
  HERO: 'Wybierz nowe hasło',
  SUCCESS: 'Hasło zostało nadane, możesz już zalogować się do platformy.'
};

@Component({
  selector: 'app-password-reset-confirm',
  templateUrl: './password-reset-confirm.component.html',
  styleUrls: ['./password-reset-confirm.component.scss'],
  animations: [routeFadeStateTrigger],
})
export class PasswordResetConfirmComponent extends FormController implements OnDestroy {
  @HostBinding('@routeFadeState') true;
  isSend = false;
  MSG = {
    HERO: 'Zmiana hasła',
    SUCCESS: 'Hasło zostało zmienione, możesz już zalogować się do platformy.'
  };
  private tokens: { resetKey: string };
  private routeSub: Subscription = new Subscription();

  constructor(private authService: AuthService,
              private fB: UntypedFormBuilder,
              private activeR: ActivatedRoute,
              aV: ApiValidatorService) {
    super(aV);
  }


  runOnInit() {
    this.routeSub.add(this.activeR.params.subscribe(
      (params: any) => this.tokens = {
        resetKey: params['resetKey'],
      }
    ));
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  buildForm() {
    this.form = this.fB.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      password2: ['', [Validators.required, Validators.minLength(8)]],
    }, {validator: this.aV.validatePasswords});
  }

  processSubmit(formValues: any) {
    this.authService.resetPasswordConfirm({
      ...this.form.value,
      ...this.tokens
    })
      .subscribe(
        (response: Response) => {
          this.formPending = false;
          this.isSend = true;
        },
        this.errorHandler.bind(this)
      );
  }
}

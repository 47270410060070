import { DevicesService } from './../../../../core/services/devices.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Device } from 'src/app/interfaces/device';
import { filter, switchMap } from 'rxjs/operators';
import { ICommand } from 'src/app/interfaces/command';

@Component({
  selector: 'app-device-commands',
  templateUrl: './device-commands.component.html',
  styleUrls: ['./device-commands.component.scss']
})
export class DeviceCommandsComponent implements OnInit, OnDestroy {
  public device: Device;
  protected subscriptions = new Subscription();

  private commandsSub = new BehaviorSubject(null);
  public commands$ = this.commandsSub.asObservable().
    pipe(filter(res => !!res), switchMap((id) => this.devicesService.getCommands(id)));

  public form: UntypedFormGroup;

  constructor(protected route: ActivatedRoute,
      private devicesService: DevicesService,
      private fb: UntypedFormBuilder) {
    this.buildForm();
  }


  ngOnInit() {
    this.routeSub();
  }

  buildForm() {
    this.form = this.fb.group({
      commandText: [null, Validators.required]
    });
  }

  protected routeSub() {
    this.subscriptions.add(this.route.parent.data.subscribe(({device}) => {
      this.device = device;
      this.commandsSub.next(this.device.id);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  sendCommand(): void {
    const commandText: string = this.form.value.commandText;
    if (commandText) {
      this.devicesService.sendCommand(this.device.id, commandText).subscribe((res) => {
        this.form.patchValue({ commandText: null });
        this.commandsSub.next(this.device.id);
      });
    }
  }

  refreshCommands(): void {
    this.commandsSub.next(this.device.id);
  }

  getCommandLabel(cmd: ICommand): string {
    return `${cmd.updatedAt} - ${this.getCmdTypeDescription(cmd.type)} - ${cmd.data}`;
  }

  getCmdTypeDescription(type: number): string {
    switch (type) {
      case 0: return 'Z';
      case 1: return 'O';
    }

    return 'N/A';
  }
}

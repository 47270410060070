import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class PopupViewService {
  private closeSubject = new Subject<any>();
  public close$ = this.closeSubject.asObservable();

  constructor() {
  }

  public close() {
    this.closeSubject.next(null);
  }
}

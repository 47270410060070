export function arrayValueFilter<T>(item: T, array: T[], windowSize: number, valueGetter: (item: T) => number ) {
  // must be odd
  const index = array.indexOf(item);

  const oddWindowSize = windowSize % 2 === 1 ? windowSize : windowSize + 1;

  const windowStartIndex = Math.max(index - ((oddWindowSize - 1) / 2), 0);
  const windowEndIndex = Math.min(index + ((oddWindowSize - 1) / 2), array.length - 1);

  const finalWindowSize = windowEndIndex - windowStartIndex + 1;

  let sum = 0;
  for (let i = windowStartIndex; i <= windowEndIndex; i++) {
    sum += valueGetter(array[i]);
  }

  return sum / finalWindowSize;
}

export function arrayValueFilterByRange<T>(item: T, array: T[], startIndex: number, endIndex: number, valueGetter: (item: T) => number ) {
  const index = array.indexOf(item);


  const windowStartIndex = Math.max(startIndex, 0);
  const windowEndIndex = endIndex !== -1 ? Math.min(endIndex, array.length - 1) : array.length - 1;

  const finalWindowSize = windowEndIndex - windowStartIndex + 1;

  let sum = 0;
  for (let i = windowStartIndex; i <= windowEndIndex; i++) {
    sum += valueGetter(array[i]);
  }

  return sum / finalWindowSize;
}
export function chunkArray<T>(array: T[], itemsPerChunk = 300): T[][]{
  return array.reduce((all,one,i) => {
    const ch = Math.floor(i/itemsPerChunk);
    all[ch] = [].concat((all[ch]||[]),one);
    return all
  }, [])
}

import { ControlValueAccessor } from '@angular/forms';
import { ChangeDetectorRef, Input, OnDestroy, OnInit, ViewChild, Directive } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbDropdown, Placement } from '@ng-bootstrap/ng-bootstrap';
import ApiServiceClass from '../../../classes/api-service.class';


@Directive()
export abstract class CustomeInput<T, S> implements ControlValueAccessor, OnInit, OnDestroy {
  public isDisabled = false;
  protected subscription: Subscription;
  public items: ({ id: string } & T)[];
  protected abstract items$;
  public selected: ({ id: string } & T)[] = [];
  @ViewChild(NgbDropdown, { static: true }) dropDown: NgbDropdown;
  public isOpen = false;
  protected valueOnLoad;
  @Input() max = 5;
  @Input() inline = false;
  @Input() multi = true;
  @Input() bindValue;
  @Input() label;
  @Input() placement: Placement = 'bottom-left';

  constructor(protected service: S & ApiServiceClass<({ id: string } & T)>, protected cd: ChangeDetectorRef) {
  }

  onChange: any = () => {
  }
  onTouch: any = () => {
  }


  removeItem(item: ({ id: string } & T)) {
    this.setValue(this.selected.filter(({id}) => id !== item.id));
  }

  close() {
    this.dropDown.close();
  }

  removeAll() {
    this.value = null;
    this.emitChanges();
  }

  get left() {
    return this.selected ? this.selected.length - this.max : 0;
  }

  get selectedArray() {
    return this.selected ? this.selected : [];
  }

  setValue(val) {
    this.selected = val;
    this.emitChanges();
  }

  emitChanges() {
    let emitValue;
    if (this.bindValue && this.selected) {
      emitValue = this.selected.map(vehicle => vehicle[this.bindValue]);
    } else {
      emitValue = this.selected;
    }
    emitValue = this.selected && this.selected.length ? emitValue : null;
    this.onChange(emitValue);
    this.onTouch(emitValue);
  }

  @Input()
  set value(val) {
    if (this.bindValue && this.items) {
      this.selected = this.items.filter(value1 => {
        const keyValue = value1[this.bindValue];
        if (keyValue) {
          return Array.isArray(val) ? (val as Array<string>).indexOf(keyValue) !== -1 : val === keyValue;
        }
        return false;
      });
    } else if (this.bindValue) {
      this.valueOnLoad = val;
    } else {
      this.selected = val;
    }
    // this.emitChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.detectChanges();
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.detectChanges();
  }

  detectChanges() {
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }

  ngOnInit(): void {
    this.subscription = this.items$.subscribe(res => {
      this.items = res;
      if (this.valueOnLoad) {
        this.value = this.valueOnLoad;
        this.valueOnLoad = null;
        this.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  toggleOpen() {
    if (this.dropDown.isOpen()) {
      this.dropDown.close();
    } else {
      this.dropDown.open();
    }
  }
}


import { Injectable } from '@angular/core';
import { Driver } from '../../interfaces/driver';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { DriverGroup, VehicleGroup } from '../../interfaces/group';
import { map } from 'rxjs/operators';
import { ApiServiceWithSub } from '../../classes/api-service-with-sub';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class DriversService extends ApiServiceWithSub<Driver> {
  protected inludeList = ['vehicle', 'groups'];

  constructor(config: ConfigService,
              authService: AuthService,
              http: HttpClient) {
    super('/drivers', config, http, authService);
    this.initList();
  }

  getWithGroups(id): Observable<Driver> {
    return forkJoin(this.get(id, {include: ['vehicle', 'groups']})).pipe(map((res) => {
      //  res[0].groups = res[1];
      return res[0];
    }));
  }

  list(parameters = {}): Observable<Driver[]> {
    const params = this.httpParams({include: this.inludeList, ...parameters});
    return this.http.get<Driver[]>(this.baseUrl, {params}).pipe(map(res => res.map(driver => ({
      ...driver,
      fullName: driver.firstName + ' ' + driver.lastName,
      name: driver.firstName + ' ' + driver.lastName,
    }))));
  }

  updateGroup(id: string, groupIds: string[]) {
    return this.http.post<VehicleGroup[]>(`${this.baseUrl}/${id}/groups`, {groupIds});
  }

  driverGroups(id): Observable<DriverGroup[]> {
    return this.http.get<DriverGroup[]>(`${this.baseUrl}/${id}/groups`);
  }
}

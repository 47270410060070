import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { NotificationPopupRoutingModule } from './notification-popup-routing.module';
import { NotifcationPopupBasicComponent } from './notification-popup/notification-popup-basic/notification-popup-basic.component';
import { NotificationPopupComponent } from './notification-popup/notification-popup.component';

@NgModule({
  declarations: [NotificationPopupComponent, NotifcationPopupBasicComponent ],
  imports: [
    SharedModule,
    NotificationPopupRoutingModule,
  ]
})
export class NotificationPopupModule {
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { WorkVehicleWarehouse } from '../../../../core/services/vehicles.service';
import { LAYERS_TYPE } from '../route-history-map/route-history-map.component';
import { MARKER_SPEED_COLORS } from '../../map/map-markers/map-marker-speed/map-marker-speed.component';
import { WarehouseOutputField } from '../../../../interfaces/warehouse';

@Component({
  selector: 'app-route-stats-mapbox',
  templateUrl: './route-stats-mapbox.component.html',
  styleUrls: ['./route-stats-mapbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RouteStatsMapboxComponent implements OnInit {
  @Input() rideData: WorkVehicleWarehouse;
  @Input() fuelLimits;
  @Input() layer: LAYERS_TYPE;
  public speeds = MARKER_SPEED_COLORS;
  public headers: WarehouseOutputField[] = [
    WarehouseOutputField.TOTAL_NO_SPEEDING,
    WarehouseOutputField.TOTAL_UP_TO_10,
    WarehouseOutputField.TOTAL_UP_TO_20,
    WarehouseOutputField.TOTAL_UP_TO_30,
    WarehouseOutputField.TOTAL_UP_TO_40,
    WarehouseOutputField.TOTAL_UP_TO_50,
    WarehouseOutputField.TOTAL_OVER_50,
    WarehouseOutputField.SUM_URBAN_OVER_50,
  ];

  constructor() {
  }

  ngOnInit() {
  }

  get layerTypes() {
    return LAYERS_TYPE;
  }

  fuelHeader(index) {
    return index === 0 ? `< ${this.fuelLimits[index].limit} l/100km`
      : index === this.fuelLimits.length - 1 ? ` > ${this.fuelLimits[index].limit - 1} l/100km` : `${this.fuelLimits[index - 1].limit} - ${this.fuelLimits[index].limit} l/100km`;
  }

  headerTrans(trans: WarehouseOutputField): string {
    return trans.replace(/UP_TO_\d0/g, 'UP_TO');
  }
}

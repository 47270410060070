import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../../interfaces/address';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(value: Address, args?: any): string {
    const {
      city,
      houseNumber,
      street,
    } = value;
    return [city, [street, houseNumber].join(' ')].filter(item => !!item).join(', ');
  }

}

import { Component } from '@angular/core';
import { FilterCollection } from '../../../../classes/filters-collection.class';
import { UntypedFormBuilder } from '@angular/forms';
import { VehiclesGroupsService } from '../../../../core/services/vehicles-groups.service';
import { StorageAndQueryService } from '../../../../core/services/storage_and_query.service';

@Component({
  selector: 'app-route-filters',
  templateUrl: './route-filters.component.html',
  styleUrls: ['./route-filters.component.scss']
})
export class RouteFiltersComponent extends FilterCollection {
  public groups$ = this.service.list$();
  public ignitions = ['ON', 'OFF'];
  public vehicleState = ['PARK', 'RIDE'];
  public rideStatsType = [{label: 'PRIVATE', value: 0}, {label: 'BUSINESS', value: 1}];
  public parkType = [
    {label: 'CUSTOMER_PARK', value: { SUM_CUSTOMER_WORK_PARK_COUNT: 1 }},
    {label: 'BRANCH_WORK_PARK', value: { SUM_BRANCH_WORK_PARK_COUNT: 1 }},
    {label: 'OTHER', value: { SUM_OTHER_WORK_PARK_COUNT: 1 }},
    {label: 'PRIVATE', value: { SUM_NONWORK_PARK_COUNT: 1 }}
  ];

  constructor(formBuilder: UntypedFormBuilder,
              public service: VehiclesGroupsService, storageService: StorageAndQueryService) {
    super(formBuilder, storageService);
  }

  selectChange($event) {
  }

  initForm(data = null) {
    this.filterForm = this.fB.group({
      SUM_WORK_RIDE_COUNT: [],
      SUM_RIDE_DISTANCE: [],
      SUM_PARK_DURATION: [],
      TOTAL_MAX_SPEED: [],
      AVG_FUEL_CONSUMPTION: [],
      PARK_TYPE: [],
    });
  }

  customMapFilters(filters) {
    if (filters.SUM_PARK_DURATION) {
      filters.SUM_PARK_DURATION = Object.keys(filters.SUM_PARK_DURATION)
        .reduce((p, c) => ({...p, [c]: filters.SUM_PARK_DURATION[c] * 60}), {});
    }

    if (filters.PARK_TYPE) {
      const parkFilter = { ...filters.PARK_TYPE };

      // replace PARK_TYPE (its calculated column and can't be filtered)
      // with column from parkType array
      delete filters.PARK_TYPE;
      filters = { ...filters, ...parkFilter};
    }
    return filters;
  }
}

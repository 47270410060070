import {EntityStatus, EntityStatusHistory, EntityStatusType} from './../../interfaces/entityStatus';
import {Injectable} from '@angular/core';
import {Driver} from '../../interfaces/driver';
import {ConfigService} from './config.service';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable, of} from 'rxjs';
import {DriverGroup, VehicleGroup} from '../../interfaces/group';
import {map, tap} from 'rxjs/operators';
import {ApiServiceWithSub} from '../../classes/api-service-with-sub';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class EntityStatusService {
  baseUrl: string;
  private statusesCache: { [key in EntityStatusType]?: EntityStatus[] } = {};

  constructor(private config: ConfigService,
              authService: AuthService,
              private http: HttpClient) {
    this.baseUrl = this.config.fullApiUrl('/status');
  }

  getLastStatus(entityType: string, entityId: string): Observable<EntityStatusHistory> {
    return this.http.get<EntityStatusHistory>(`${this.baseUrl}/${entityType}/${entityId}`);
  }

  getAvailableStatuses(entityType: EntityStatusType): Observable<EntityStatus[]> {
    if (this.statusesCache[entityType]) {
      return of(this.statusesCache[entityType]);
    }
    return this.http.get<EntityStatus[]>(`${this.baseUrl}/${entityType}`).pipe(tap(x => this.statusesCache[entityType] = x));
  }

  changeStatus(entityType: EntityStatusType, entityId: string, statusId: string): Observable<EntityStatusHistory> {
    return this.http.post<EntityStatusHistory>(`${this.baseUrl}/${entityType}/${entityId}`, {
      statusId,
    });
  }
}

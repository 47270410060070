import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Vehicle } from '../../../../interfaces/vehicle';
import { PowerBiService } from '../../../../core/services/power-bi.service';
import { DateRangeService } from '../../../../shared/modules/date-range-filter/date-range.service';

@Component({
  selector: 'app-vehicle-statistic',
  templateUrl: './vehicle-statistic.component.html',
  styleUrls: ['./vehicle-statistic.component.scss']
})
export class VehicleStatisticComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  public powerBiFilters;
  private vehicle: Vehicle;


  constructor(private route: ActivatedRoute,
              private dateFilterService: DateRangeService,
              private powerBiService: PowerBiService) {
  }

  ngOnInit(): void {
    // this.filters = this.dateFilterService.queryToDateRange(this.route.snapshot.queryParams)
    //   || this.dateFilterService.dateRangeValueByType(DateRangeValueType.today);
    this.subscription = this.route.parent.data.subscribe(({vehicle}) => {
      this.vehicle = vehicle;
      this.setPowerBiFilters();
    });
  }

  setPowerBiFilters() {
    if (this.vehicle) {
      setTimeout(() => {
        this.powerBiFilters = this.powerBiService.vehivleFilters(this.vehicle.id);
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}

<app-data-grid-row (mouseup)="dragEnd()" (mousemove)="dragElement($event)" cdkDropList
                   (cdkDropListDropped)="drop($event)" cdkDropListOrientation="horizontal" type="header">
  <app-data-grid-cell *ngFor="let header of headers; let i = index" [ngSwitch]="getType(header)"
                      [width]="header.width"
                      type="header"
                      cdkDrag #tableRow>
    <ng-container  *ngSwitchCase="'template'">
      <ng-container *ngTemplateOutlet="template; context: {$implicit: header}"></ng-container>
    </ng-container>

    <app-table-sort-btn
      [value]="header.sort"
      *ngSwitchCase="'sort'"
      [activeSort]="activeSort"
      (sortChange)="sortChange.emit($event)"
      [label]="(getLabel(header))|translate"
    ></app-table-sort-btn>
    <span *ngSwitchDefault [innerHTML]="(getLabel(header))|translate"></span>
    <span class="width-divider"
          (mousedown)="dragStart($event, i)"
    ></span>
  </app-data-grid-cell>
  <app-data-grid-cell *ngIf="actionButtons" [width]="30"></app-data-grid-cell>
</app-data-grid-row>

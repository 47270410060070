import { Subscription } from 'rxjs';
import { MapService } from './map.service';
import { AfterViewChecked, AfterViewInit, ElementRef, EventEmitter, OnDestroy, Output, Directive } from '@angular/core';
import {take} from "rxjs/operators";

@Directive()
export abstract class MarkerClass implements AfterViewInit, OnDestroy, AfterViewChecked {
  protected marker: H.map.DomMarker;
  protected icon: H.map.DomIcon;
  protected addEvents = true;
  protected doUpdate = false;
  abstract markerGroupName: string;
  @Output() markerAdded = new EventEmitter();
  @Output() hover = new EventEmitter();
  @Output() leaveHover = new EventEmitter();
  @Output() markerClick = new EventEmitter();
  @Output() markerDblClick = new EventEmitter();

  constructor(protected mapService: MapService, protected el: ElementRef) {

  }


  ngAfterViewInit(): void {
   this.mapService.mapIsLoad.pipe(take(1)).subscribe(() => {
      this.initMarker();
    });
  }

  updateMarker() {
    if (this.marker) {
      if(this.icon){
        delete this.icon;
      }
      this.mapService.zone.runOutsideAngular(() => {
        this.icon = new H.map.DomIcon(this.el.nativeElement, this.addEvents ? this.domIconOptions() : null);
        this.marker.setIcon(this.icon);
      })
    }
  }

  domIconOptions() {
    return {
      onAttach: (clonedElement, domIcon, domMarker) => {},
      onDetach: (clonedElement, domIcon, domMarker) => {}
    };
  }

  abstract getCoords(): number[] | H.geo.IPoint;

  initMarker() {
    this.mapService.zone.runOutsideAngular(() => {
      const cords = this.getCoords();
      if (cords) {
        this.icon = new H.map.DomIcon(this.el.nativeElement, this.addEvents ? this.domIconOptions() : null);
        this.marker = new H.map.DomMarker(Array.isArray(cords) ? new H.geo.Point(cords[1], cords[0]) : cords as H.geo.IPoint, {
          icon: this.icon,
        });
        this.mapService.addMarker(this.marker, this.markerGroupName);
      }

    })
    this.markerAdded.emit();
  }

  ngOnDestroy(): void {
    if (this.marker) {
      this.marker.dispose();
      this.marker = undefined;
    }
  }

  ngAfterViewChecked(): void {
    if (this.doUpdate) {

      this.updateMarker();
      this.doUpdate = false;
    }
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { VehicleWithTelemetryAndRide } from '../../../../interfaces/telemetry';
import { CollectionUpdateEvent } from '../../../../classes/api-service.class';
import { Subscription } from 'rxjs';
import { VehiclesService } from '../../../../core/services/vehicles.service';
import { Vehicle } from '../../../../interfaces/vehicle';
import { RouterHelperService } from '../../../../core/services/router-helper.service';
import { ActivatedRoute } from '@angular/router';
import { PERMISSIONS } from 'src/app/core/services/permission.service';

@Component({
  selector: 'app-vehicle-header',
  templateUrl: './vehicle-header.component.html',
  styleUrls: ['./vehicle-header.component.scss'],
})
export class VehicleHeaderComponent implements OnInit, OnDestroy {
  @Input() vehicle: VehicleWithTelemetryAndRide;
  @Input() isFavorite: boolean;
  @Output() favoriteChange = new EventEmitter();
  sub: Subscription;
  readonly PERMISSIONS = PERMISSIONS;

  constructor(private service: VehiclesService, private routeHelper: RouterHelperService, private active: ActivatedRoute) {
  }

  ngOnInit() {
    this.sub = this.service.collectionUpdate$.subscribe((res: CollectionUpdateEvent<Vehicle>) => {
      if (res.event === 'manual_update' && (res.item as Vehicle).id === this.vehicle.id) {
        this.vehicle = Object.assign({}, this.vehicle, res.item);
      }
    });
  }

  driver() {
    this.routeHelper.navigatePopupToOpen(
      this.active,
      ['driver', (this.vehicle.ride && this.vehicle.ride.driver ? this.vehicle.ride.driver.id : this.vehicle.driver.id)],
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}

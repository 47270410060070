import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {CardTransfer} from '../../../../../interfaces/card-transfers';
import {Driver} from '../../../../../interfaces/driver';

@Component({
  selector: '[app-driver-code-tracking-row]',
  templateUrl: './driver-code-tracking-row.component.html',
  styleUrls: ['./driver-code-tracking-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverCodeTrackingRowComponent implements OnInit {
  @Input() card: CardTransfer;
  @Input() edited: boolean;
  @Input() driver: Driver;
  @Output() edit = new EventEmitter<CardTransfer>();
  @Output() updated = new EventEmitter();

  ngOnInit() {
  }

  onEdit() {
    this.edit.emit(this.card);
  }
}

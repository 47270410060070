import { DriverCodeTrackingComponent } from './driver-code-tracking/driver-code-tracking.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../../../core/guards/auth-guard.service';
import { DriverNewComponent } from './driver-new/driver-new.component';
import { DriverDetailsComponent } from './driver-details/driver-details.component';
import { DriverPopupComponent } from './driver-popup.component';
import { DriverResolve } from './driver.resolve';
import { DriverHistoryComponent } from './driver-history/driver-history.component';
import { DriverPoiComponent } from './driver-poi/driver-poi.component';
import { PERMISSIONS } from '../../../core/services/permission.service';
import { PermissionsGuard } from '../../../core/guards/permissions.guard';

const routes: Routes = [
  {
    path: 'driver',
    outlet: 'popup',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: DriverNewComponent,
        canActivate: [PermissionsGuard],
        data: {
          permissions: [PERMISSIONS.DRIVER_CREATE, ]
        }
      },
      {
        path: ':id',
        component: DriverPopupComponent,
        resolve: {
          driver: DriverResolve,
        },
        children: [
          {
            path: 'details',
            component: DriverDetailsComponent
          },
          {
            path: 'history',
            component: DriverHistoryComponent
          },
          {
            path: 'poi',
            component: DriverPoiComponent,
          },
          {
            path: 'identification',
            component: DriverCodeTrackingComponent,
            canActivate: [PermissionsGuard],
            data: {
              permissions: [PERMISSIONS.DRIVER_ID, ]
            }
          },
          {
            path: '**',
            pathMatch: 'full',
            redirectTo: 'details'
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'details'
          },
        ]
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DriversPopupRoutingModule {
}

import { Component } from '@angular/core';
import { PopupNavigationItem } from '../../../../shared/components/popup-view/popup-navigation/popup-navigation.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Zone } from 'src/app/interfaces/zone';

@Component({
  selector: 'app-zone-popup-details',
  templateUrl: './zone-popup-details.component.html',
  styleUrls: ['./zone-popup-details.component.scss']
})
export class ZonePopupDetailsComponent {
  public navItems: PopupNavigationItem[] = [
    {label: 'DETAILS', route: './', exact: true},
    {label: 'RULES', route: 'rules'},
  ];
  public zone$: Observable<Zone> = this.route.data
    .pipe(
      map(({zone}: { zone: Zone }) => zone)
    );

  constructor(private route: ActivatedRoute) {
  }


}

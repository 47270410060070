import {TelemetryRide} from './../../../interfaces/telemetry';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {VehicleWithTelemetryAndRide} from '../../../interfaces/telemetry';
import {Subscription} from 'rxjs';
import {Poi} from './../../../interfaces/poi';
import {RouterHelperService} from '../../../core/services/router-helper.service';
import {MapGeoService} from "../../modules/map/map-geo.service";

@Component({
  selector: 'app-vehicle-location',
  templateUrl: './vehicle-location.component.html',
  styleUrls: ['./vehicle-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleLocationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() vehicle: VehicleWithTelemetryAndRide;
  @Input() ellipsis = false;
  @Input() position;
  @Input() multi = false;
  private subscription: Subscription;
  public location: string;
  public ride: TelemetryRide;

  constructor(
    private mapGeoService: MapGeoService,
    private cd: ChangeDetectorRef,
    private router: RouterHelperService) {
  }

  ngOnInit() {
    // this.setLocation();
    // this.location = 'ulica Jana Kapicy 10A, Siemianowice Śląskie';
  }

  removeSub() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private setLocation() {
    if (this.vehicle.ride && this.vehicle.ride.parkData) {
      this.ride = this.vehicle.ride;
    } else if (!!this.vehicle.telemetry) {
      if (this.multi) {
        this.multiRrevgeo();
      } else {
        this.revgeo();

      }

    } else {
      this.location = 'NO_LOCATION';
    }
  }

  revgeo() {
    this.mapGeoService
      .reverseGeo(this.vehicle.telemetry.position.coordinates)
      .subscribe(res => {
        if (!this.cd['destroyed']) {
          this.location = res;
          this.cd.detectChanges();
        }
      });
  }

  multiRrevgeo() {
    this.mapGeoService
      .reverseGeoById()
      .subscribe(res => {
        if (!this.cd['destroyed']) {
          this.location = res[this.vehicle.id] || 'NO_LOCATION';
          this.cd.detectChanges();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.removeSub();
    this.setLocation();
  }

  ngOnDestroy(): void {
    this.removeSub();
  }

  openPoiPopup(poi: Poi) {
    console.log(poi);

    this.router.navigatePopup(['poi', poi.id, 'dashboard']);
  }
}

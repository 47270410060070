import { Component } from '@angular/core';
import { NewEditClass } from '../../../../classes/new-edit.class';
import { ActivatedRoute } from '@angular/router';
import { PopupViewService } from '../../../../shared/components/popup-view/popup-view.service';
import { ApiValidatorService } from '../../../../core/services/api-validator.service';
import { RouterHelperService } from '../../../../core/services/router-helper.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { EMatchLevelZoom, IGeocodedSuggestion, MapService } from '../../../../shared/modules/map/map.service';
import { ZONE_DEFAULT_COLOR, ZonesService } from '../../../dashboard/zones/zones.service';
import { Zone } from 'src/app/interfaces/zone';
import { tap } from 'rxjs/operators';
import { Area } from '../../../../interfaces/poi';
import { PERMISSIONS, PermissionService } from '../../../../core/services/permission.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-zone-details',
  templateUrl: './zone-basic.component.html',
  styleUrls: ['./zone-basic.component.scss'],
  providers: [MapService],
})
export class ZoneBasicComponent extends NewEditClass {

  public zone: Zone;
  public area: Area;
  protected editPermission = PERMISSIONS.ZONE_UPDATE;
  protected newPermission = PERMISSIONS.ZONE_CREATE;

  public toolboxIcons = [
    {icon: 'icon-search', value: 'search'},
  ];
  public activeIcon = {};
  public geocodedSuggestion: IGeocodedSuggestion = null;

  constructor(private route: ActivatedRoute,
              popupService: PopupViewService,
              apiValidator: ApiValidatorService,
              private mapService: MapService,
              private routerHelper: RouterHelperService,
              private service: ZonesService,
              permissio: PermissionService,
              fb: UntypedFormBuilder,
              private translate: TranslateService,
              modal: NgbModal
              ) {
    super(popupService, apiValidator, fb, permissio, modal);
  }

  toggleToolbox($event) {
    this.activeIcon = $event;
  }

  get showSearchResults() {
    return this.activeIcon.hasOwnProperty('search');
  }

  suggestionSelected(geo: IGeocodedSuggestion) {
    this.geocodedSuggestion = geo;
    this.mapService.zoomToCluster(
      [geo.coordinates.lng, geo.coordinates.lat],
      EMatchLevelZoom[geo.matchLevel] ? EMatchLevelZoom[geo.matchLevel] : 16
    );
  }

  deleteZone() {
    this.deleteElement(this.zone.id, {
      header: 'ZONE',
      body: this.translate.instant('DELETE_PROMPT', { name: this.zone.name})
    },
      (id) => this.service.delete(id)
    ).then(res => {
      // this.getCollection();
      this.popupService.close();
    }, () => {

    });
  }





  addRouteSub() {
    this.subscriptions.add(this.route.parent.data.subscribe(({zone}) => {
      this.zone = zone;
      this.area = this.zone.area;
      this.resetForm();
    }));
  }

  resetForm() {
    this.forms.main.patchValue(Object.assign({color: ZONE_DEFAULT_COLOR}, this.zone));
    this.zone.area = {...this.zone.area};
  }

  get color() {
    return this.forms.main.get('color').value;
  }

  buildForm() {
    this.forms = {
      main: this.fb.group({
        name: [null, Validators.required],
        area: [null, Validators.required],
        color: [null, Validators.required],
      })
    };
  }

  save() {
    this.service.add(this.forms.main.value).subscribe((res) => {
      this.pending = false;
      this.routerHelper.navigatePopup(['zone', res.id]);
    }, this.errorHandler.bind(this));
  }

  areaChange(area) {
    this.forms.main.get('area').setValue(area);
    this.forms.main.get('area').markAsDirty();
  }

  update() {
    this.service.partialUpdate(this.zone.id, this.forms.main.value).pipe(
      tap(res => Object.assign(this.zone, res)),
    ).subscribe(res => {
      this.pending = false;
      this.isEditMode = false;
      this.toggleDisableForm();
    }, this.errorHandler.bind(this));
  }
}

import * as Raven from 'raven-js';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ViewsModule } from './views/views.module';
import { ConfigService, SENTRY_KEY } from './core/services/config.service';
import { HttpErrorInterceptor } from './core/http.interceptor';
import { environment } from '../environments/environment';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import {NgbDatepickerConfig, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import { CustomDatepickerI18n } from './core/services/custome-datepicker-i18n.service';
import { ServiceWorkerModule } from '@angular/service-worker';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    try {
      Raven.captureException(err instanceof Error ? err : new Error(JSON.stringify(err)));
    } catch (e) {
      Raven.captureException(err);
    }
  }
}

export function jwtOptionsFactory(config: ConfigService) {
  return {
    allowedDomains: [config.apiUrl.replace(/(^\w+:|^)\/\//, '')],
    tokenGetter: () => {
      return localStorage.getItem(config.tokenName);
    }
  };
}


export function provideErrorHandler() {
  if (environment.production || environment.stage) {
    Raven
      .config(SENTRY_KEY, {
        environment: environment.production ? 'prod' : 'stage'
      });
    Raven.install();
    return new RavenErrorHandler();
  } else {
    return new ErrorHandler();
  }
}


@NgModule({
  declarations: [
    AppComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    ViewsModule,
    HttpClientJsonpModule,
    NgxChartsModule,
    PerfectScrollbarModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [ConfigService]
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: ErrorHandler, useFactory: provideErrorHandler},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

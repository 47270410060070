import { Injectable } from '@angular/core';
import { ResolveClass } from '../../../classes/resolver.class';
import { PoiService } from '../../dashboard/poi/poi.service';
import { Poi } from '../../../interfaces/poi';

@Injectable()
export class PoiResolve extends ResolveClass<Poi> {
  protected params = {
    include: 'groups',
  };

  constructor(service: PoiService) {
    super(service);
  }
}

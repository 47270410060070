import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { Driver } from '../../../interfaces/driver';


@Component({
  selector: 'app-driver-details',
  templateUrl: './driver-details.component.html',
  styleUrls: ['./driver-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverDetailsComponent implements OnInit {

  constructor() {
  }

  @Input() driver: Driver;

  @Input()
  @HostBinding('class.is-driver-overriden')
  public isDriverOverriden = true;

  ngOnInit() {
  }

}

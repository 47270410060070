import { PartnerVehiclesService } from './../core/services/partner-vehicles.service';
import { VehiclesService } from '../core/services/vehicles.service';
import { Group } from '../interfaces/group';
import { Driver } from '../interfaces/driver';
import { Client } from '../interfaces/client';
import { TelemetryRide, TelemetryVehicle } from '../interfaces/telemetry';
import { WarehouseDefaultResponse } from '../interfaces/warehouse';
import { Device } from '../interfaces/device';

export enum EVehicleStatus {
  INACTIVE = 0,
  ACTIVE = 1,
}

export class Vehicle {
  ride?: TelemetryRide | null;
  telemetry?: TelemetryVehicle | null;
  warehouse?: WarehouseDefaultResponse | null;
  avatarUrl: string;
  client: Client;
  createdAt: Date;
  driver: Driver;
  groups: Group[];
  id: string;
  mark: string;
  model: string;
  name: string;
  numberPlate: string;
  updatedAt: Date;
  clientId?: string;
  device?: Device;
  status: EVehicleStatus;

  constructor(data: Vehicle, private service: VehiclesService = null) {
    Object.assign(this, data);
  }

  isFavorite() {
    return this.service ? this.service.isFavorite(this.id) : false;
  }


}

import { Client } from './../../interfaces/client';
import { Injectable } from '@angular/core';
import { Driver } from '../../interfaces/driver';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiServiceWithSub } from '../../classes/api-service-with-sub';
import { AuthService } from './auth.service';
import { Device } from 'src/app/interfaces/device';

@Injectable({
  providedIn: 'root'
})
export class ClientsService extends ApiServiceWithSub<Client> {
  protected inludeList = [];

  constructor(config: ConfigService,
              authService: AuthService,
              http: HttpClient) {
    super('/clients', config, http, authService);
    this.initList();
  }

  me(): Observable<Client> {
    return this.http.get<Client>(`${this.baseUrl}/me`);
  }
}

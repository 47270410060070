<div class="d-flex flex-fill justify-content-between align-items-stretch flex-column h-100">
  <div class="flex-grow-1 overflow-auto">
    <div class="p-xm" [formGroup]="forms?.main">
      <h3 class="form-header mt-xl" translate>BASIC_DATA</h3>
      <div class="row">
        <div class="col-8">
          <app-form-group>
            <label translate>NAME</label>
            <input app-form formControlName="name" type="text">
            <app-error-message [control]="forms?.main.get('name')"></app-error-message>
          </app-form-group>
        </div>
      </div>
    </div>
    <div class="p-xm" *ngIf="forms?.group">
      <h3 class="form-header mt-xl" translate>VEHICLES_IN_GROUPS</h3>
      <app-vehicles-select [formControl]="forms?.group" bindValue="id"></app-vehicles-select>
    </div>
  </div>
  <div class="p-xm d-flex justify-content-end align-items-center border-top">
    <button class="btn btn-primary btn-border px-xxl mr-m" (click)="cancel()" translate>
      {{(isEditMode ? 'CANCEL' : 'EXIT')|translate}}
    </button>
    <button class="btn btn-primary px-xxl" (click)="handlerSave()" [disabled]="pending" *ngIf="showButton">
      <app-spinner [showSpinner]="pending"></app-spinner>
      {{(isNewView || isEditMode ? 'SAVE' : 'EDIT')|translate}}
    </button>
  </div>
  <app-overflow-spinner [showSpinner]="pending"></app-overflow-spinner>
</div>

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ZONE_DEFAULT_COLOR } from '../../../views/dashboard/zones/zones.service';

@Component({
  selector: 'app-zone-icon',
  templateUrl: './zone-icon.component.html',
  styleUrls: ['./zone-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZoneIconComponent implements OnInit {
  @Input() color;

  constructor() {
  }
  get fillColor() {
    return this.color || ZONE_DEFAULT_COLOR;
  }
  ngOnInit() {
  }

}

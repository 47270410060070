import { DeviceCommandsComponent } from './device-commands/device-commands.component';
import { DeviceExtensionsComponent } from './device-extensions/device-extensions.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../../../core/guards/auth-guard.service';
import { DeviceNewComponent } from './device-new/device-new.component';
import { DevicePopupComponent } from './device-popup.component';
import { DeviceResolve } from './device.resolve';
import { PERMISSIONS } from '../../../core/services/permission.service';
import { PermissionsGuard } from '../../../core/guards/permissions.guard';
import { DeviceDetailsComponent } from './device-details/device-details.component';

const routes: Routes = [
  {
    path: 'device',
    outlet: 'popup',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: DeviceNewComponent,
        canActivate: [PermissionsGuard],
        data: {
          permissions: [PERMISSIONS.DEVICE_CREATE, ]
        }
      },
      {
        path: ':id',
        component: DevicePopupComponent,
        resolve: {
          device: DeviceResolve,
        },
        children: [
          {
            path: 'details',
            component: DeviceDetailsComponent
          },
          {
            path: 'extensions',
            component: DeviceExtensionsComponent
          },
          {
            path: 'commands',
            component: DeviceCommandsComponent
          },
          {
            path: '**',
            pathMatch: 'full',
            redirectTo: 'details'
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'details'
          },
        ]
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DevicePopupRoutingModule {
}

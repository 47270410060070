<ng-container [ngSwitch]="edited">
    <td *ngSwitchCase="true" colspan="4">
        <app-vehicle-service-add [vehicle]="vehicle"
                                 [reminder]="reminder"
                                 (formUpdate)="updated.emit($event)"></app-vehicle-service-add>
    </td>
    <ng-container *ngSwitchDefault>
        <td>
            {{reminder.name}}
        </td>
        <td style="width: 40%">
            {{reminder.description}}
        </td>
        <td>
            <app-vehicle-service-reminder-criteria
                    [criteria]="reminder.criteria"></app-vehicle-service-reminder-criteria>
        </td>
        <td>
            <button class="btn btn-danger btn-icon btn-sm mr-xm" (click)="remove()" *ngIf="canUpdateOrDelete"><i class="icon-trash"></i></button>
            <button class="btn btn-primary btn-icon btn-sm" (click)="edit()" *ngIf="canUpdateOrDelete"><i class="icon-edit"></i></button>
        </td>
    </ng-container>
</ng-container>


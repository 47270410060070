<ngb-datepicker #dp (dateSelect)="select.emit($event)"
                [maxDate]="maxDate"
                [minDate]="minDate"
                [displayMonths]="2"
                navigation="arrows"
                [startDate]="toDate || maxDate"
                [dayTemplate]="t"
                [weekdays]="weekShort.Short"
                outsideDays="collapsed">
</ngb-datepicker>
<ng-template #t let-date let-focused="focused" let-disabled="disabled" let-today="today">
  <span class="custom-day"
        [class.focused]="focused"
        [class.disabled]="disabled"
        [class.today]="today"
        [class.range]="!disabled && isRange(date)"
        [class.faded]="!disabled && (isHovered(date) || isInside(date))"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>

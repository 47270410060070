import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NotificationZONE_CHANGE } from '../../../../../../interfaces/notificationT';

@Component({
  selector: 'app-notification-zone',
  templateUrl: './notification-zone.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationZoneComponent implements OnInit {
  @Input() notification: NotificationZONE_CHANGE;

  constructor() {
  }

  ngOnInit() {
  }

}

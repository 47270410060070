export interface ICalibrationPoint {
  voltage: number;
  percentage: number;
}

export interface ICalibrationRange {
  start: ICalibrationPoint;
  end: ICalibrationPoint;
}

export class CalibrationFunction {
  private readonly range: ICalibrationRange;

  // f(x) = ax + b; linear function
  private readonly A: number;
  private readonly B: number;

  constructor(range: ICalibrationRange) {
    this.range = range;
    this.A = this.calculateA();
    this.B = this.calculateB();
  }

  public getPercentage(voltage: number): number {
    const percentage: number = this.A * voltage + this.B;

    return Math.round(Math.max(Math.min(percentage, 100), 0));
  }

  private calculateA(): number {
    return (this.range.end.percentage - this.range.start.percentage) / (this.range.end.voltage - this.range.start.voltage);
  }

  private calculateB(): number {
    return this.range.start.percentage - (this.A * this.range.start.voltage);
  }

}

export function getAnalogFuelPercentage(voltage: number, calibrationRawPoints: number[]): number {
  // at least two voltage-percentage pairs, must be even
  if (calibrationRawPoints.length < 4 || calibrationRawPoints.length % 2 !== 0) {
    return null;
  }

  const calibration: ICalibrationPoint[] = mapCalibrationPoints(calibrationRawPoints);

  return mapAnalogFuel(voltage, calibration);
}

export function mapCalibrationPoints(calibrationRawPoints: number[]): ICalibrationPoint[] {
  const calibration: ICalibrationPoint[] = [];

  for (let i = 0; i < calibrationRawPoints.length - 1; i = i + 2) {
    calibration.push({
      voltage: calibrationRawPoints[i],
      percentage: calibrationRawPoints[i + 1],
    });
  }

  return calibration;
}

export function mapAnalogFuel(voltage: number, calibrationPoints: ICalibrationPoint[]): number {
  const calibrationRange: ICalibrationRange = getCalibrationRange(voltage, calibrationPoints);

  const calibrationFunction: CalibrationFunction = new CalibrationFunction(calibrationRange);

  return calibrationFunction.getPercentage(voltage);
}

export function getCalibrationRange(voltage: number, calibrationPoints: ICalibrationPoint[]): ICalibrationRange {
  const sortedPoints: ICalibrationPoint[] = calibrationPoints.sort((a, b) => a.voltage - b.voltage);

  for (let i: number = 0; i < sortedPoints.length - 1; i = i + 1) {
    const start: ICalibrationPoint = sortedPoints[i];
    const end: ICalibrationPoint = sortedPoints[i + 1];

    if (i === 0 && voltage < start.voltage) {
      return {
        start,
        end,
      };
    }

    // last pair
    if (i === sortedPoints.length - 2 && voltage > end.voltage) {
      return {
        start,
        end,
      };
    }

    if (voltage >= start.voltage && voltage <= end.voltage) {
      return {
        start,
        end,
      };
    }

  }
  throw new Error(`[fuelAnalog] Unable to find calibration range. Voltage: ${voltage} Calibration: ${JSON.stringify(calibrationPoints)}`);
}


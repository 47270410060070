<ng-container [ngSwitch]="edited">
    <td *ngSwitchCase="true" colspan="4">
        <app-driver-code-tracking-add [cardTransfer]="card" (formUpdate)="updated.emit($event)" [driver]="driver"/>
    </td>
    <ng-container *ngSwitchDefault>
      <td>{{card.cardNumber}}</td>
      <td>{{card.transferDate | date: 'yyyy-MM-dd'}}</td>
      <td>{{card.returnDate | date: 'yyyy-MM-dd'}}</td>
      <td>
        <!-- <button class="btn btn-danger btn-icon btn-sm mr-xm" ><i class="icon-trash"></i></button> -->
        <button class="btn btn-primary btn-icon btn-sm" *ngIf="card.returnDate === null" (click)="edit.emit()"><i class="icon-edit"></i></button>
      </td>
    </ng-container>
</ng-container>


<app-popup-view class="d-flex flex-fill justify-content-between align-items-stretch flex-column">
    <div class="p-xm c-norm__header">
        <div class="d-flex justify-content-start align-items-start">
            <div class="c-norm__image">
                <img src="{{norm?.imgUrl}}" alt="" width="100%">
            </div>
            <div class="c-norm____header-desc">
                <h4 translate="">NORM</h4>
                <h2>{{norm?.name}}</h2>
                <p class="c-norm__desc">{{norm?.desc}}</p>
            </div>
        </div>

    </div>
    <app-norm-template class="flex-grow-1 overflow-hidden d-block" [configuration]="configuration"
                       [norm]="norm"></app-norm-template>
    <app-overflow-spinner [showSpinner]="pending"></app-overflow-spinner>
</app-popup-view>

import { FuelType } from 'src/app/interfaces/vehicle';
import { Pipe, PipeTransform } from '@angular/core';
import { minutesFormat } from '../../utils/utils';
import { WarehouseResponseData } from '../../interfaces/warehouse';

@Pipe({
  name: 'fuelUnit'
})
export class FuelUnitPipe implements PipeTransform {

  transform(data: FuelType): string {
    if (!data) {
      return 'L';
    }
    if (data === FuelType.electric) {
      return 'kWh';
    }
    return 'L';
  }

}

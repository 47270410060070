import { PermissionService } from 'src/app/core/services/permission.service';
// tslint:disable-next-line: max-line-length
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import {FuelPolyline, Polyline, RideMarker} from '../route-history.component';
import {WorkVehicleWarehouse} from '../../../../core/services/vehicles.service';
import {map} from 'rxjs/operators';
import {Area, Poi} from '../../../../interfaces/poi';
import {PoiService} from '../../../../views/dashboard/poi/poi.service';
import {RouterHelperService} from '../../../../core/services/router-helper.service';
import {EMatchLevelZoom, IGeocodedSuggestion, MapService} from '../../map/map.service';
import {PERMISSIONS} from '../../../../core/services/permission.service';
import {Subscription} from 'rxjs';
import { DateTime } from 'luxon';
import { ZonesService } from 'src/app/views/dashboard/zones/zones.service';
import { FuelType, Vehicle } from 'src/app/interfaces/vehicle';


export enum LAYERS_TYPE {
  MARKERS = 'markers',
  SPEED = 'speed',
  FUEL = 'fuel',
}


@Component({
  selector: 'app-route-history-map',
  templateUrl: './route-history-map.component.html',
  styleUrls: ['./route-history-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteHistoryMapComponent implements OnInit, OnDestroy {
  @Input() rideTelemetry: Polyline[];
  @Input() fuelTelemetry: FuelPolyline[];
  @Input() polylineMarkers: RideMarker[];
  @Input() modulo: number;
  @Input() zoom: number;
  @Input() fuelLimits;
  @Input() selectedRideData: WorkVehicleWarehouse | null;
  @Input() markers: WorkVehicleWarehouse[];
  @Input() speedMarkers: WorkVehicleWarehouse[];
  @Output() polyLoaded = new EventEmitter();
  @Output() closeMap = new EventEmitter();
  @Input() vehicle: Vehicle;
  public poiCollection: Poi[];
  private poiSubscription: Subscription;
  selectedLayers = LAYERS_TYPE.MARKERS;
  public mapLayers = [];

  public toolboxIcons = [
    {icon: 'icon-search', value: 'search'},
    {icon: 'icon-settings', value: 'layers', activeOnInit: true},
    {icon: 'icon-info', value: 'stats'}];
  public activeIcon = {
    layers: true,
  };
  showPoi = false;
  showPark = true;
  showParkOrder = false;
  showLabel = false;
  showZones = false;
  readonly PERMISSIONS = PERMISSIONS;
  public geocodedSuggestion: IGeocodedSuggestion = null;
  public reverseIsoline: Area = null;
  public rideDataAvailable = true;
  public zones$ = this.zoneService.list();

  constructor(private cd: ChangeDetectorRef, private poiService: PoiService,
              private permissionService: PermissionService,
              private zoneService: ZonesService,
              private ngZone: NgZone, private routeHelper: RouterHelperService, public mapService: MapService) {
  }

  initTraceAvailable() {
    this.rideDataAvailable = !this.permissionService.hasPermission(PERMISSIONS.RIDES_NODATA);
    if (this.rideDataAvailable) {
      this.mapLayers = [
        {label: 'ROUTE', value: LAYERS_TYPE.MARKERS},
        {label: 'SPEED', value: LAYERS_TYPE.SPEED},
      ];
      if (!this.vehicle || this.vehicle.fuelType !== FuelType.electric) {
        this.mapLayers.push({label: 'FUEL_CONSUMPTION', value: LAYERS_TYPE.FUEL});
      }
    } else {
      this.showParkOrder = true;
    }
  }

  ngOnInit() {
    this.initTraceAvailable();
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes.markers) {
  //     const tempLine =  new H.geo.LineString();
  //     this.markers.forEach(m => tempLine.pushPoint({
  //       lng: 20, lat: 50
  //     }));
  //     this.mapService.mapInstance.setViewBounds(tempLine.getBounds());
  //   }
  // }


  get selectedLayersType() {
    return LAYERS_TYPE;
  }

  showMarker(zoomIndex: number) {
    return zoomIndex % this.modulo === 0;
  }

  get areParksManyDays() {
    const daysMap = {};
    for (const m of this.markers) {
      if (!m.PARK_STARTDATE[0].v) {
        continue;
      }

      const format = DateTime.DATE_SHORT;
      const date = DateTime.fromISO(m.PARK_STARTDATE[0].v).toLocaleString(format);
      daysMap[date] = true;
    }

    return Object.keys(daysMap).length > 1;
  }

  switchChange(event) {
    this.selectedLayers = event;
    setTimeout(() => {
      this.cd.detectChanges();
    }, 10);
    this.cd.detectChanges();
  }

  toggleToolbox($event) {
    this.activeIcon = $event;
    this.cd.detectChanges();
  }

  get showCircle() {
    return this.zoom > 10;
  }

  poiSwitchChange(event) {
    this.showPoi = !this.showPoi;
    if (this.showPoi) {
      this.getPois();
    } else {
      this.removePoiSub();
    }
    this.cd.detectChanges();
  }

  get telemetryArray() {
    return this.selectedLayers === LAYERS_TYPE.FUEL ? this.fuelTelemetry : this.rideTelemetry;
  }

  getPois() {
    this.poiSubscription = this.poiService.poiFromMapBound$(this.mapService.mapInstance, {include: ['groups']})
      .pipe(map(res => res.map(poi => this.poiService.addColorToPoi(poi))))
      .subscribe(res => {
        this.poiCollection = res;
        this.cd.detectChanges();
      });
  }

  markerClick(marker: WorkVehicleWarehouse) {
    // temporary block non-finished parks (currently unable to edit)
    if (marker && marker.PARK_ENDDATE && marker.PARK_ENDDATE[0] && marker.PARK_ENDDATE[0].v) {
      this.ngZone.run(() => {
        this.routeHelper.navigatePopupExtra(['ride', marker.ID[0].v]);
      });
    }
  }

  poiClick(poi: Poi) {
    this.ngZone.run(() => {
      this.routeHelper.navigatePopupExtra(['poi', poi.id]);
    });
  }

  removePoiSub() {
    if (this.poiSubscription) {
      this.poiSubscription.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.removePoiSub();
  }

  showParkOrderChange($event: any) {
    this.showParkOrder = !this.showParkOrder;
    this.cd.detectChanges();
  }

  showLabelChange($event: any) {
    this.showLabel = !this.showLabel;
    this.cd.detectChanges();
  }

  showZonesChange($event: any) {
    this.showZones = !this.showZones;
    this.cd.detectChanges();
  }

  suggestionSelected(geo: IGeocodedSuggestion) {
    this.geocodedSuggestion = geo;
    this.mapService.zoomToCluster(
      [geo.coordinates.lng, geo.coordinates.lat],
      EMatchLevelZoom[geo.matchLevel] ? EMatchLevelZoom[geo.matchLevel] : 16
    );
  }

  get showSearchResults() {
    return this.activeIcon.hasOwnProperty('search');
  }

  reverseIsolineLoaded(area: Area) {
    this.reverseIsoline = area;
    if (this.reverseIsoline) {
      this.mapService.boundMapToPolygons([area]);
    }
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import {EntityStatusService} from 'src/app/core/services/entity-status.service';
import {EntityStatus, EntityStatusHistory, EntityStatusType} from 'src/app/interfaces/entityStatus';
import {Observable, of, Subscription} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {UntypedFormControl} from '@angular/forms';
import { PERMISSIONS, PermissionService } from 'src/app/core/services/permission.service';

@Component({
  selector: 'app-entity-status',
  templateUrl: './entity-status.component.html',
  styleUrls: ['./entity-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityStatusComponent implements OnInit, OnDestroy, OnChanges {
  readonly PERMISSIONS = PERMISSIONS;

  @Input() entityId: string;
  @Input() entityType: EntityStatusType = 'VEHICLE';
  public lastStatus: EntityStatusHistory = null;
  public availableStatuses$: Observable<EntityStatus[]> = this.entityStatusService.getAvailableStatuses(this.entityType);
  selectedStatus = new UntypedFormControl(null);
  private subscription: Subscription;

  constructor(private entityStatusService: EntityStatusService,
    protected permissionService: PermissionService,
    private cd: ChangeDetectorRef, private toastr: ToastrService) {
  }

  public get canUpdate() {
    return this.permissionService.hasPermission(PERMISSIONS.STATUS_UPDATE);
  }

  ngOnInit() {
  }

  init() {
    this.entityStatusService.getLastStatus(this.entityType, this.entityId)
    .subscribe(res => {
      this.lastStatus = res;
      this.initSelectedStatus();
    }, err => {
      this.initSelectedStatus();
      console.log(JSON.stringify(err));
    });
  }

  initSelectedStatus() {
    this.selectedStatus.setValue(this.lastStatus ? this.lastStatus.statusId : null);
    this.setFormEvent();
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.cleanUp();
  }

  cleanUp(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.lastStatus = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(`changes ${JSON.stringify(changes)}`);
    this.cleanUp();
    this.init();
  }

  private setFormEvent() {
    if (!this.canUpdate) {
      return;
    }
    this.subscription = this.selectedStatus.valueChanges
      .pipe(
        switchMap(value => this.entityStatusService.changeStatus(this.entityType, this.entityId, value).pipe(catchError(err => {
          this.toastr.error('ups, nie udało się zmienić statusu!', 'Błąd');
          this.selectedStatus.setValue(this.lastStatus ? this.lastStatus.statusId : null, {
            emitEvent: false
          });
          return of(this.lastStatus);
        }))))
      .subscribe(value => {
        this.lastStatus = value;
        this.cd.detectChanges();
      });
  }
}

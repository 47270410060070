import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { fromEvent, Observable, Subscription } from 'rxjs';
import {
  WarehouseDateAggregation,
  WarehouseDimensionAggregation, WarehouseDimensionName,
  WarehouseOutputField, WarehouseResponse,
  WarehouseResponseData
} from '../../../interfaces/warehouse';
import { TelemetryService, VehicleStatuses } from '../../../core/services/telemetry.service';
import { Position, TelemetryVehicle } from '../../../interfaces/telemetry';
import { RideType } from '../../../interfaces/ride';
import { MapService } from '../map/map.service';
import { ngIfEnterTrigger } from '../../../core/animations';
import { DateRageValue, DateRangeService, DateRangeValueType } from '../date-range-filter/date-range.service';
import { Vehicle } from '../../../interfaces/vehicle';
import { WorkVehicleWarehouse } from '../../../core/services/vehicles.service';
import { Driver } from '../../../interfaces/driver';
import { WarehouseService } from '../../../core/services/warehouse.service';
import {PerspectiveMenuService, PopupSizeType} from '../perspective-menu/perspective-menu.service';
import { DateTime } from 'luxon';
import { MARKER_SPEED_COLORS, SpeedMarker } from '../map/map-markers/map-marker-speed/map-marker-speed.component';
import { RouterHelperService } from '../../../core/services/router-helper.service';
import { ReportsService } from '../../../core/services/reports.service';
import { ConfigService } from 'src/app/core/services/config.service';


enum WorkRequestFields {
  RIDE_STARTDATE = WarehouseOutputField.RIDE_STARTDATE,
  RIDE_ENDDATE = WarehouseOutputField.RIDE_ENDDATE,
  PARK_STARTDATE = WarehouseOutputField.PARK_STARTDATE,
  PARK_ENDDATE = WarehouseOutputField.PARK_ENDDATE,
  SUM_WORK_DURATION = WarehouseOutputField.SUM_WORK_DURATION,
  SUM_NONWORK_DURATION = WarehouseOutputField.SUM_NONWORK_DURATION,
  SUM_WORK_RIDE_DISTANCE = WarehouseOutputField.SUM_WORK_RIDE_DISTANCE,
  SUM_WORK_RIDE_DURATION = WarehouseOutputField.SUM_WORK_RIDE_DURATION,
  SUM_NONWORK_RIDE_DISTANCE = WarehouseOutputField.SUM_NONWORK_RIDE_DISTANCE,
  SUM_CUSTOMER_PARK_COUNT = WarehouseOutputField.SUM_CUSTOMER_PARK_COUNT,
  SUM_WORK_PARK_COUNT = WarehouseOutputField.SUM_WORK_PARK_COUNT,
  SUM_PARK_DURATION = WarehouseOutputField.SUM_PARK_DURATION,
  SUM_NONWORK_RIDE_DURATION = WarehouseOutputField.SUM_NONWORK_RIDE_DURATION,
  SUM_RIDE_DISTANCE = WarehouseOutputField.SUM_RIDE_DISTANCE,
  SUM_RIDE_DURATION = WarehouseOutputField.SUM_RIDE_DURATION,
  PARK_POI = WarehouseOutputField.PARK_POI,
  SUM_CUSTOMER_WORK_PARK_COUNT = WarehouseOutputField.SUM_CUSTOMER_WORK_PARK_COUNT,
  PARK_POSITION = WarehouseOutputField.PARK_POSITION,
  RIDE_ENDPOSITION = WarehouseOutputField.RIDE_ENDPOSITION,
  RIDE_STARTPOSITION = WarehouseOutputField.RIDE_STARTPOSITION,
  RIDE_START_POI = WarehouseOutputField.RIDE_START_POI,
  DRIVER = WarehouseOutputField.DRIVER,
  VEHICLE = WarehouseOutputField.VEHICLE,
  PARK_REVGEO = WarehouseOutputField.PARK_REVGEO,
  RIDE_START_REVGEO = WarehouseOutputField.RIDE_START_REVGEO,
  SUM_NONWORK_PARK_COUNT = WarehouseOutputField.SUM_NONWORK_PARK_COUNT,
  SUM_WORK_RIDE_COUNT = WarehouseOutputField.SUM_WORK_RIDE_COUNT,
  SUM_NONWORK_RIDE_COUNT = WarehouseOutputField.SUM_NONWORK_RIDE_COUNT,
  SUM_BRANCH_WORK_PARK_COUNT = WarehouseOutputField.SUM_BRANCH_WORK_PARK_COUNT,
  SUM_OTHER_WORK_PARK_COUNT = WarehouseOutputField.SUM_OTHER_WORK_PARK_COUNT,
  AVG_FUEL_CONSUMPTION = WarehouseOutputField.AVG_FUEL_CONSUMPTION,
  AVG_FUEL_CONSUMPTION_H = WarehouseOutputField.AVG_FUEL_CONSUMPTION_H,
  AVG_ECO_SCORE = WarehouseOutputField.AVG_ECO_SCORE,
  SUM_RIDE_FUEL = WarehouseOutputField.SUM_RIDE_FUEL,
  RIDE_ID = WarehouseOutputField.RIDE_ID,
  RIDE_IS_OVERRIDE = WarehouseOutputField.RIDE_IS_OVERRIDE,
  PARK_IS_OVERRIDE = WarehouseOutputField.PARK_IS_OVERRIDE,
  TOTAL_MAX_SPEED = WarehouseOutputField.TOTAL_MAX_SPEED,
  TOTAL_NO_SPEEDING = WarehouseOutputField.TOTAL_NO_SPEEDING,
  TOTAL_UP_TO_10 = WarehouseOutputField.TOTAL_UP_TO_10,
  TOTAL_UP_TO_20 = WarehouseOutputField.TOTAL_UP_TO_20,
  TOTAL_UP_TO_30 = WarehouseOutputField.TOTAL_UP_TO_30,
  TOTAL_UP_TO_40 = WarehouseOutputField.TOTAL_UP_TO_40,
  TOTAL_UP_TO_50 = WarehouseOutputField.TOTAL_UP_TO_50,
  TOTAL_OVER_50 = WarehouseOutputField.TOTAL_OVER_50,
  SUM_URBAN_OVER_50 = WarehouseOutputField.SUM_URBAN_OVER_50,
  SUM_REFUELS = WarehouseOutputField.SUM_REFUELS,
  SUM_PARK_FUEL_CHANGE_LITRES = WarehouseOutputField.SUM_PARK_FUEL_CHANGE_LITRES,
  PARK_POD = WarehouseOutputField.PARK_POD,
  PARK_ENGINE_ON = WarehouseOutputField.PARK_ENGINE_ON,
  LAST_PARK_STARTTEMP1 = WarehouseOutputField.LAST_PARK_STARTTEMP1,
}

type WorkHistoryRequestResponse = {
  [key in WarehouseOutputField]: WarehouseResponseData[]
};


export interface VehicleHistoryCollection {
  start: WorkHistoryRequestResponse;
  days: WorkHistoryRequestResponse[];
}

export interface RideMarker {
  type: RideType;
  status: VehicleStatuses;
  data: TelemetryVehicle;
}


export interface Polyline {
  rideTelemetry: TelemetryVehicle[];
  color?: string;
  startIndex?: number;
  endIndex?: number;
  shape?: number[][];
}

export interface FuelPolyline extends Polyline {
  fuel: number;
}

@Component({
  selector: 'app-route-history',
  templateUrl: './route-history.component.html',
  styleUrls: ['./route-history.component.scss'],
  providers: [MapService],
  animations: [ngIfEnterTrigger]
})
export class RouteHistoryComponent implements OnInit, OnDestroy, OnChanges {
  public dateFilters: DateRageValue = this.dateFilterService.queryToDateRange(this.route.snapshot.queryParams, true)
    || this.dateFilterService.queryToDateRange(this.route.snapshot.queryParams)
    || this.dateFilterService.dateRangeValueByType(DateRangeValueType.today);
  @Input() vehicle: Vehicle;
  @Input() driver: Driver;
  @Input() popupSize: PopupSizeType = 'large';
  public pending = true;
  public filters = {};
  public hasQuery = false;
  public activeRow: string | null;
  public boundPoly = true;
  public isParkSelected = false;
  public selectedRideData: WorkVehicleWarehouse;
  public speedMarkers: SpeedMarker[];
  public markers: WorkVehicleWarehouse[];
  public polylineMarkers: RideMarker[];
  private subscriptions: Subscription = new Subscription();
  public collection: VehicleHistoryCollection[];
  public aggregationSum: WorkVehicleWarehouse;
  private indexModulo: number;
  public showMap = false;
  private polylineGroup: H.geo.MultiLineString;
  private polylineAdded = 0;
  private sortedWarehouseResponse: WorkVehicleWarehouse[];
  private weightedMean = [
    {k: WorkRequestFields.AVG_ECO_SCORE, w: WorkRequestFields.SUM_RIDE_DISTANCE},
    {k: WorkRequestFields.AVG_FUEL_CONSUMPTION, w: WorkRequestFields.SUM_RIDE_DISTANCE},
    {k: WarehouseOutputField.TOTAL_NO_SPEEDING, w: WorkRequestFields.SUM_RIDE_DISTANCE},
    {k: WarehouseOutputField.TOTAL_UP_TO_10, w: WorkRequestFields.SUM_RIDE_DISTANCE},
    {k: WarehouseOutputField.TOTAL_UP_TO_20, w: WorkRequestFields.SUM_RIDE_DISTANCE},
    {k: WarehouseOutputField.TOTAL_UP_TO_30, w: WorkRequestFields.SUM_RIDE_DISTANCE},
    {k: WarehouseOutputField.TOTAL_UP_TO_40, w: WorkRequestFields.SUM_RIDE_DISTANCE},
    {k: WarehouseOutputField.TOTAL_UP_TO_50, w: WorkRequestFields.SUM_RIDE_DISTANCE},
    {k: WarehouseOutputField.TOTAL_OVER_50, w: WorkRequestFields.SUM_RIDE_DISTANCE},
    {k: WarehouseOutputField.AVG_FUEL_CONSUMPTION_H, w: WorkRequestFields.SUM_RIDE_DURATION},
  ];
  private sumOutputs = [
    WorkRequestFields.SUM_WORK_RIDE_DURATION,
    WorkRequestFields.SUM_WORK_RIDE_DISTANCE,
    WorkRequestFields.SUM_NONWORK_RIDE_DURATION,
    WorkRequestFields.SUM_NONWORK_RIDE_DISTANCE,
    WorkRequestFields.SUM_CUSTOMER_WORK_PARK_COUNT,
    WorkRequestFields.SUM_RIDE_DURATION,
    WorkRequestFields.SUM_RIDE_FUEL,
    WorkRequestFields.SUM_RIDE_DISTANCE,
    WorkRequestFields.SUM_PARK_FUEL_CHANGE_LITRES,
    WorkRequestFields.SUM_URBAN_OVER_50,
  ];
  private maxOutputs = [WarehouseOutputField.TOTAL_MAX_SPEED];
  public rideTelemetry: Polyline[];
  public fuelTelemetry: FuelPolyline[];
  public fuelLimits: { limit: number, color: string }[];

  constructor(private route: ActivatedRoute,
              private warehouseService: WarehouseService,
              private mapService: MapService,
              private routeHelper: RouterHelperService,
              private cd: ChangeDetectorRef,
              private perspectiveMenu: PerspectiveMenuService,
              private telemetryService: TelemetryService,
              private reportService: ReportsService,
              private configService: ConfigService,
              private dateFilterService: DateRangeService) {
  }

  ngOnInit() {
    this.perspectiveMenu.popupSizeType = this.popupSize;
    this.subscriptions.add(
      this.mapService.mapIsLoad.pipe(
        switchMap(() => fromEvent(this.mapService.mapInstance, 'mapviewchangeend').pipe(
          debounceTime(300),
        ))).subscribe(() => {
        this.setIndeXModulo();
      }));
  }

  setIndeXModulo() {
    const zoom = this.mapZoom;
    if (zoom > 13) {
      this.indexModulo = 1;
    } else if (zoom > 11) {
      this.indexModulo = 2;
    } else if (zoom > 8) {
      this.indexModulo = 3;
    } else if (zoom > 7) {
      this.indexModulo = 6;
    } else {
      this.indexModulo = 12;
    }
  }

  get mapZoom() {
    return this.mapService.mapInstance && this.mapService.mapInstance.getZoom() || 0;
  }

  showMarker(index) {
    return index % this.indexModulo === 0;
  }

  getLastPosition(res: WorkHistoryRequestResponse) {
    const position = res.PARK_POSITION[0].v.lng ? res.PARK_POSITION : res.RIDE_ENDPOSITION;
    return position[0].v.lng ? position : null;
  }

  handlerShowAllRide(work) {
    this.mapView();
    this.getAndShowRide(work, this.driver).subscribe(res => {
      this.getTelemetryAndMatchRoute(res, this.sortedWarehouseResponse);
      this.markers = this.sortedWarehouseResponse;
    });
  }


  polyLoaded(polyline: H.geo.LineString) {
    if (this.polylineGroup && this.boundPoly) {
      this.polylineGroup.push(polyline);
      ++this.polylineAdded;
      if (this.polylineAdded >= this.rideTelemetry.length && this.boundPoly) {
        this.mapService.setViewBounds(this.polylineGroup.getBoundingBox());
      }
    }
  }

  handlerShowDailyRide(work: VehicleHistoryCollection) {
    this.mapView();
    this.getAndShowRide(work.start, this.driver).subscribe(res => {
      this.getTelemetryAndMatchRoute(res, work.days);
      this.markers = work.days;
    });
  }

  handlerDaily({item, type, park}) {
    switch (type) {
      case 'oneRide':
        this.handlerShowOneRide(item, park);
        break;
      default :
        this.handlerShowDailyRide(item);
    }
  }


  getTelemetryAndMatchRoute(res: TelemetryVehicle[], works: WorkVehicleWarehouse[], addRideMArkers = false) {
    const filtered: TelemetryVehicle[] = res.filter(f => !this.isEmptyPosition(f.position));
    console.log(`res: ${res.length} filtered: ${filtered.length}`);
    this.rideTelemetry = this.telemetryByState(filtered, works, addRideMArkers);
    this.fuelTelemetry = this.telemetryByFuel(res);
    this.telematicsRouteMatching(res, this.rideTelemetry);
  }

  isEmptyPosition(position: Position): boolean {
    if (!position || !position.coordinates || position.coordinates.length !== 2) {
      return true;
    }
    const [ coord1, coord2] = position.coordinates;

    if (coord1 && coord2 && coord1 !== 0 && coord2 !== 0) {
      return false;
    }
    return true;
  }


  addLinkToTelemetry(waypoints, link, polylines) {
    polylines.forEach(poly => {
      const shape = [];
      const lineStartIndex = waypoints[poly.startIndex].routeLinkSeqNrMatched;
      const lineEndIndex = waypoints[poly.endIndex].routeLinkSeqNrMatched;
      for (let i = lineStartIndex; i <= lineEndIndex; i++) {
        shape.push(...link[i].shape
          .reduce((previousValue, currentValue, currentIndex) => {
            const newArray = [...previousValue];
            if (currentIndex % 2 === 1) {
              newArray[Math.floor(currentIndex / 2)].push(currentValue);
            } else {
              newArray.push([currentValue]);
            }
            return newArray;
          }, []));
      }
      poly.shape = shape;
    });
  }

  telematicsRouteMatching(data: TelemetryVehicle[], polylines?: Polyline[]) {
    if (this.configService.isCbTheme()) {
      return;
    }
    if (this.vehicle && this.vehicle.noCacheExtensions &&  this.vehicle.noCacheExtensions.MAP &&
      this.vehicle.noCacheExtensions.MAP.routeMatching === false) {
        return;
      }
    this.mapService.findRoute(this.mapService.telemetryToGpx(data))
      .subscribe(({response}) => {
        this.boundPoly = false;
        this.speedMarkers = this.mapService.getSpeedMarkers(response.route[0]);
        const waypoints = response.route[0].waypoint;
        const link = response.route[0].leg[0].link;
        if (polylines) {
          this.addLinkToTelemetry(waypoints, link, polylines);
        }
        this.addLinkToTelemetry(waypoints, link, this.fuelTelemetry);
        this.rideTelemetry = [...this.rideTelemetry];
        this.fuelTelemetry = [...this.fuelTelemetry];
      });
  }

  setFuelLimits() {
    const agggregationFuelAvg = Math.round(this.aggregationSum.AVG_FUEL_CONSUMPTION[0].v * 2) / 2;
    const limits = [-1.5, -0.5, 0.5, 1.5, 2.5, 3.5, 4.5];
    this.fuelLimits = MARKER_SPEED_COLORS.map((value, index) => ({
      limit: agggregationFuelAvg + limits[index],
      color: value.color,
    }));
    console.log(this.fuelLimits);
  }

  telemetryByFuel(res: TelemetryVehicle[]): FuelPolyline[] {
    if (!res || !res.length) {
      return [];
    }
    const tmpFuel: FuelPolyline[] = [];
    this.setFuelLimits();
    let distance = 0;
    let fuelAvg = 0;
    let startIndex = 0;
    let fuelTotal = 0;
    let newLoop = false;
    let telemetry: TelemetryVehicle[] = [];
    const MAX_DISTANCE = 1;
    const returnArray: FuelPolyline[] = [];
    for (let i = 0, max = res.length; i < max; i++) {
      if (i === 0 || newLoop) {
        startIndex = i === 0 ? 0 : i - 1;
        newLoop = false;
        telemetry = i ? [res[i - 1], res[i]] : [res[i]];
        distance = res[i].totalDistance;
        fuelTotal = res[i].totalFuel;
      } else if (res[i].totalDistance - distance > MAX_DISTANCE || (i === res.length - 1 && res[i].totalDistance - distance !== 0)) {
        newLoop = true;
        telemetry.push(res[i]);
        fuelAvg = ((res[i].totalFuel - fuelTotal) / 10) / (res[i].totalDistance - distance);
        const fuelItem = {
          endIndex: i,
          rideTelemetry: telemetry,
          startIndex,
          fuel: fuelAvg,
        };
        tmpFuel.push(fuelItem);
      } else {
        telemetry.push(res[i]);
      }
    }


    let currentLimit;
    let currentIndex = 0;
    for (let i = 0; i < tmpFuel.length; i++) {
      const limit = this.fuelLimits.find((value, index) => (tmpFuel[i].fuel < value.limit && (index === 0
        || tmpFuel[i].fuel >= this.fuelLimits[index - 1].limit)) || index === this.fuelLimits.length - 1);
      if (!currentLimit || limit.limit !== currentLimit.limit) {
        currentLimit = limit;
        tmpFuel[i].color = currentLimit.color;
        currentIndex = returnArray.push(tmpFuel[i]) - 1;
      } else {
        returnArray[currentIndex].endIndex = tmpFuel[i].endIndex;
        returnArray[currentIndex].rideTelemetry.push(...tmpFuel[i].rideTelemetry);
      }
    }
    return returnArray;
  }

  telemetryByState(res: TelemetryVehicle[], works: WorkVehicleWarehouse[], addRideMArkers = false): Polyline[] {
    if (!res || !res.length || !works || !works.length) {
      return [];
    }
    this.polylineMarkers = [];
    let status = null;
    let currentIndex = 0;
    let workIndex = 0;
    const returnArray: Polyline[] = [];
    for (let i = 0, max = res.length; i < max; i++) {
      const eventDate = new Date(res[i].eventDate);
      const workIndexTmp = this.getWorkIndexByDate(works, eventDate, workIndex);
      if (workIndexTmp !== -1 && workIndexTmp !== workIndex || workIndexTmp === 0) {
        const statusTmp = this.warehouseService.rideStatus(works[workIndexTmp]);
        if (status !== statusTmp || i === 0 || workIndex !== workIndexTmp) {
          currentIndex = returnArray.push({
            color: this.warehouseService.colorByState(statusTmp),
            rideTelemetry: i > 0 ? [res[i - 1], res[i]] : [res[i]],
            startIndex: i,
            endIndex: i,
          }) - 1;
          status = statusTmp;
          workIndex = workIndexTmp;
        } else if (returnArray[currentIndex]) {
          returnArray[currentIndex].rideTelemetry.push(res[i]);
          returnArray[currentIndex].endIndex++;
        }
      } else {
        returnArray[currentIndex].rideTelemetry.push(res[i]);
        returnArray[currentIndex].endIndex++;
      }
      if (i === 0) {
        this.polylineMarkers.push({
          status: status,
          type: RideType.START,
          data: res[i]
        });
      } else if (i === max - 1 && works[workIndex]) {
        this.polylineMarkers.push({
          status: status,
          type: works[workIndex].PARK_STARTDATE[0].v ? RideType.PARK : RideType.END,
          data: res[i]
        });
      } else if (addRideMArkers) {
        this.polylineMarkers.push({
          status: status,
          type: RideType.RIDE,
          data: res[i]
        });
      }
    }
    return returnArray;
  }

  getWorkIndexByDate(works: WorkVehicleWarehouse[], date: Date, index: number): number {
    const work = works[index];
    if (!work) {
      return -1;
    }
    const from = new Date(work.RIDE_STARTDATE[0].v), to = this.getToTimeFromWork(work);
    if (date >= from && date <= to) {
      return index;
    } else {
      return this.getWorkIndexByDate(works, date, ++index);
    }
  }


  getToTimeFromWork(work: WorkVehicleWarehouse): Date {
    const date = work.PARK_ENDDATE[0].v || work.PARK_STARTDATE[0].v || work.RIDE_ENDDATE[0].v;
    return date ? new Date(date) : new Date();
  }

  filtersChange(filters = {}) {
    this.filters = Object.keys(filters).reduce((previousValue, currentValue) => {
      let value = filters[currentValue];
      if (value.hasOwnProperty && (value.hasOwnProperty('lt') || value.hasOwnProperty('gt'))) {
        value = Object.keys(value).reduce((prev, curr) => ({...prev, ['$' + curr]: value[curr]}), {});
      }
      return {...previousValue, [currentValue]: value};
    }, {});
    this.getDataFromWarehouseApi();
  }

  handlerShowOneRide(work: WorkVehicleWarehouse, park) {
    this.mapView();
    if (!park) {
      this.getAndShowRide(work).subscribe(res => {
        const status = this.warehouseService.rideStatus(work);
        this.rideTelemetry = [
          {
            color: this.warehouseService.colorByState(status),
            rideTelemetry: res.filter(f => !this.isEmptyPosition(f.position)),
            startIndex: 0,
            endIndex: res.length - 1,
          }
        ];
        this.fuelTelemetry = this.telemetryByFuel(res);
        this.telematicsRouteMatching(res, this.rideTelemetry);
        this.polylineMarkers = res.map((data, index) => {
          return {
            type: index === 0 ? RideType.START : (index === res.length - 1)
              ? work.PARK_STARTDATE[0].v
                ? RideType.PARK : RideType.END : RideType.RIDE,
            status,
            data,
          };
        });
        this.markers = [work];
      });
    } else {
      this.rideTelemetry = [];
      this.fuelTelemetry = [];
      this.selectedRideData = work;
      this.isParkSelected = true;
      this.polylineMarkers = [];
      this.markers = [work];
      if (work.PARK_POSITION[0].v) {
        setTimeout(() => {
          this.mapService.zoomToCluster([work.PARK_POSITION[0].v.lng, work.PARK_POSITION[0].v.lat], 16);
        }, 1);
      }
    }

  }

  mapView() {
    this.showMap = true;
    this.resetQuery();
  }

  getAndShowRide(work: WorkVehicleWarehouse, driver: Driver = null): Observable<TelemetryVehicle[]> {
    this.pending = true;
    this.selectedRideData = work;
    this.isParkSelected = false;
    let zoomIndex = 0;
    const functionName = driver ? 'driversPositions' : 'vehiclesPositions';
    const entityId = driver ? driver.id : work.VEHICLE[0].v.id;
    const telemetry = this.telemetryService[functionName](entityId, {
      from: work.RIDE_STARTDATE[0].v,
      to: this.getToTimeFromWork(work).toISOString(),
    }).pipe(
      tap(() => this.pending = false),
      tap(this.resetMapObject.bind(this)),
      map(res => res.filter((item, index) => !!item.ignition || index === res.length - 1)),
      tap(res => res.forEach(item => {
        item.zoomIndex = zoomIndex++;
        if (zoomIndex > 11) {
          zoomIndex = 0;
        }
      }))
    );
    return telemetry;
  }

  dateFilterChange($event) {
    this.dateFilters = $event;
    this.dateFilterService.dateRangeToQuery($event, this.route, true);
    this.getDataFromWarehouseApi();
  }

  resetQuery() {
    if (this.hasQuery) {
      this.routeHelper.queryParams(this.route, {
        ride: null,
        park: null,
      });
      this.activeRow = null;
      this.hasQuery = false;
    }
  }

  resetMapObject() {
    this.rideTelemetry = [];
    this.fuelTelemetry = [];
    this.speedMarkers = [];
    this.markers = [];
    this.boundPoly = true;
    this.polylineMarkers = [];
    this.polylineGroup = new H.geo.MultiLineString([]);
    this.polylineAdded = 0;
  }

  getWarehouseDimensions() {
    return this.driver ? {
      name: WarehouseDimensionName.DRIVER,
      aggregation: WarehouseDimensionAggregation.NONE,
      range: [this.driver.id],
    } : {
      name: WarehouseDimensionName.VEHICLE,
      aggregation: WarehouseDimensionAggregation.NONE,
      range: [this.vehicle.id],
    };
  }

  getDataFromWarehouseApi() {
    if (!this.dateFilters) {
      return;
    }
    this.pending = true;
    const {start, end} = this.dateFilters;
    this.warehouseService.getData<WorkRequestFields, WorkHistoryRequestResponse>({
      dimensions: [this.getWarehouseDimensions()],
      conditions: {
        post: this.filters,
      },
      date: {
        aggregation: WarehouseDateAggregation.NONE,
        range: {
          start,
          end
        },
      },
      output: {
        fields: Object.keys(WorkRequestFields).map((k: string) => ({name: WorkRequestFields[k]})),
      }
    }).pipe(tap(() => this.pending = false))
      .subscribe(res => {
        this.aggregateData(res);
        if (this.selectedRideData) {
          let id: string = this.selectedRideData.ID[0].v;
          if (id === 'sum_id') {
            this.handlerShowAllRide(this.aggregationSum);
          } else {
            id = id.indexOf('day_') !== -1 ? id : `day_${DateTime.fromISO(this.selectedRideData.RIDE_STARTDATE[0].v).ordinal}`;
            const searchDay = this.collection.find(item => item.start.ID[0].v === id);
            if (searchDay) {
              this.handlerShowAllRide(searchDay.start);
            } else {
              this.selectedRideData = null;
              this.closeMap();
            }
          }
          if (this.hasQuery) {
            this.resetQuery();
          }
        } else {
          this.checkIfHasRouteInQuery();
        }
      });
  }

  checkIfHasRouteInQuery() {
    const params = this.route.snapshot.queryParams;
    if ((params.ride || params.park) && this.collection.length === 1) {
      const id = params.ride || params.park;
      this.selectedRideData = this.collection[0].days.find(value => value.ID[0].v === id);
      if (this.selectedRideData) {
        this.activeRow = this.collection[0].start.ID[0].v;
        this.isParkSelected = !!params.park;
        this.handlerShowOneRide(this.selectedRideData, this.isParkSelected);
        this.hasQuery = true;
      }
    }
  }

  closeMap() {
    this.showMap = false;
    this.resetMapObject();
    this.selectedRideData = null;
  }

  copyFields(fields) {
    return Object.keys(fields)
      .reduce((prev, current) => ({
        ...prev, [current]: [...fields[current]]
          .map(item => ({...item}))
      }), {}) as WorkVehicleWarehouse;
  }

  sumObject(): { numerator: number, denominator: number } {
    return {
      denominator: 0,
      numerator: 0,
    };
  }

  aggregateData(res: WarehouseResponse<WorkHistoryRequestResponse>) {
    const collection: VehicleHistoryCollection[] = [];
    let aggregationSum = null;

    let currentDayOfYear = 0;
    let currentIndex = -1;
    let daySum = {};
    const weightedMean = this.weightedMean.reduce((prev, cur) => ({
      [cur.k]: {
        day: this.sumObject(),
        all: this.sumObject(),
      }, ...prev
    }), {});
    res.data.sort((a, b) => new Date(b.RIDE_STARTDATE[0].v).getTime() - new Date(a.RIDE_STARTDATE[0].v).getTime());

    res.data.forEach((value, index) => {
      if (index === 0) {
        aggregationSum = this.copyFields(value);
      } else if (index === res.data.length - 1) {
        aggregationSum.RIDE_STARTDATE = value.RIDE_STARTDATE;
        aggregationSum.RIDE_START_POI = value.RIDE_START_POI;
        aggregationSum.RIDE_STARTPOSITION = value.RIDE_STARTPOSITION;
        aggregationSum.RIDE_START_REVGEO = value.RIDE_START_REVGEO;
      }
      const dayOfYear = DateTime.fromISO(value.RIDE_STARTDATE[0].v).ordinal;
      this.weightedMean.forEach(key => {
        const val = value[key.k][0].v || 0;
        const weight = value[key.w][0].v;
        const numerator = val * weight;
        weightedMean[key.k].all.numerator += numerator;
        weightedMean[key.k].all.denominator += weight;
        if (!daySum[key.k]) {
          daySum[key.k] = [{...value[key.k][0]}];
        }
        if (dayOfYear !== currentDayOfYear && index !== 0) {
          daySum[key.k][0].v = weightedMean[key.k].day.numerator / weightedMean[key.k].day.denominator;
          weightedMean[key.k].day.numerator = 0;
          weightedMean[key.k].day.denominator = 0;
        }
        weightedMean[key.k].day.numerator += numerator;
        weightedMean[key.k].day.denominator += weight;
        if (index === res.data.length - 1) {
          daySum[key.k][0].v = weightedMean[key.k].day.numerator / weightedMean[key.k].day.denominator;
        }
      });
      if (dayOfYear !== currentDayOfYear) {
        this.copyStartRide(currentIndex, daySum, collection);
        daySum = {};

        const start = this.copyFields(value);
        start.ID = [{v: 'day_' + dayOfYear}];
        currentIndex = collection.push({
          days: [value],
          start,
        }) - 1;
      } else {
        collection[currentIndex].days.unshift(value);
      }
      this.sumOutputs.forEach(header => {
        if (!daySum[header]) {
          daySum[header] = [{...value[header][0]}];
        } else {
          daySum[header][0].v += value[header][0].v;
        }
        if (index !== 0) {
          aggregationSum[header][0].v += value[header][0].v;
        }
      });
      this.maxOutputs.forEach(header => {
        if (!daySum[header]) {
          daySum[header] = [{...value[header][0]}];
        } else if (value[header][0].v > daySum[header][0].v) {
          daySum[header][0].v = value[header][0].v;
        }
        if (index !== 0 && aggregationSum[header][0].v < value[header][0].v) {
          aggregationSum[header][0].v = value[header][0].v;
        }
      });
      currentDayOfYear = dayOfYear;
    });
    if (res.data.length) {
      this.weightedMean.forEach(key => {
        aggregationSum[key.k][0].v = weightedMean[key.k].all.numerator / weightedMean[key.k].all.denominator;
      });
    }
    this.copyStartRide(currentIndex, daySum, collection);
    this.sortedWarehouseResponse = res.data.reverse();
    this.collection = collection
      .sort((a, b) => new Date(a.start.RIDE_STARTDATE[0].v).getTime() - new Date(b.start.RIDE_STARTDATE[0].v).getTime());
    this.aggregationSum = aggregationSum;
    if (this.aggregationSum) {
      this.aggregationSum.ID = [{v: 'sum_id'}];
    }
  }

  copyStartRide(currentIndex, daySum, collection) {
    if (currentIndex > -1) {
      const {
        RIDE_STARTDATE,
        RIDE_STARTPOSITION,
        RIDE_START_REVGEO,
        RIDE_START_POI,
      } = collection[currentIndex].days[0];
      Object.assign(collection[currentIndex].start, daySum, {
        RIDE_STARTDATE,
        RIDE_STARTPOSITION,
        RIDE_START_REVGEO,
        RIDE_START_POI,
      });
      const lastDay: WorkHistoryRequestResponse = collection[currentIndex].days[collection[currentIndex].days.length - 1];
      if (lastDay) {
        if (lastDay.RIDE_ENDDATE && lastDay.RIDE_ENDDATE[0].v === null) {
          lastDay.SUM_RIDE_DURATION[0].v = this.addCurremntTime(lastDay.RIDE_STARTDATE[0].v);
        } else if (lastDay.PARK_ENDDATE && lastDay.PARK_ENDDATE[0].v === null) {
          lastDay.SUM_PARK_DURATION[0].v = this.addCurremntTime(lastDay.PARK_STARTDATE[0].v);
        }
      }
    }
  }

  addCurremntTime(from: string) {
    return DateTime.fromISO(from).diffNow('second').seconds * -1;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.perspectiveMenu.popupSizeType = 'normal';
    // this.dateFilterService.resetQuery(this.route, true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((!this.driver && changes.vehicle && !changes.vehicle.firstChange) || (changes.driver && !changes.driver.firstChange)) {
      this.getDataFromWarehouseApi();
    }
  }

  generateReport() {
    this.subscriptions.add(
      this.reportService.templates().subscribe(res => {
        const ridesAndStopoversReport = res.find(r => r.name === 'Raport przejazdów z postojami');

        if (ridesAndStopoversReport) {
          this.routeHelper.navigatePopup(['report', ridesAndStopoversReport.id, 'storage'], {
            state: {
              report: {
                dateFilters: this.dateFilters,
                fields: {
                  entities: [{
                    name: 'VEHICLES',
                    value: [this.vehicle.id]
                  }]
                }
              }
            }
          });
        }
      })
    );
  }
}

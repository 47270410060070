<div class="d-flex justify-content-between align-items-stretch flex-column flex-fill h-100">
    <div class="overflow-auto flex-grow-1 c-table p-xm">
        <table class="table">
            <thead>
            <tr>
                <th translate>CODE</th>
                <th translate>TRANSFER_DATE</th>
                <th translate>RETURN_DATE</th>
                <th class="text-right">
                    <button class="btn btn-secondary btn-icon btn-border btn-sm" (click)="handlerAdd()" *ngIf="!addNew && !editId && lastCardReturned">
                        <i class="icon-plus"></i></button>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="addNew && !editId">
              <td colspan="4">
                <app-driver-code-tracking-add [driver]="driver"
                                         (formUpdate)="addUpdate($event)"></app-driver-code-tracking-add>
            </td>

            </tr>
            <tr
                (updated)="reminderHasUpdate($event, card)"
                (edit)="editId = card.id"
                [driver]="driver"
                app-driver-code-tracking-row
                [edited]="editId=== card.id"
                [card]="card"
                *ngFor="let card of reminders$|async">
            </tr>

            </tbody>
        </table>
    </div>
  <app-overflow-spinner [showSpinner]="pending"></app-overflow-spinner>
</div>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import {MarkerClass} from '../../marker.class';
import {MapService} from '../../map.service';
import {VehicleStatusesColors} from '../../../../../core/services/telemetry.service';
import {WorkVehicleWarehouse} from '../../../../../core/services/vehicles.service';
import {WarehouseService} from '../../../../../core/services/warehouse.service';
import {DateTime} from 'luxon';
import {MAX_MARKER_CIRCLE_TIME} from '../map-marker-vehicle.component';

@Component({
  selector: 'app-map-marker-park',
  templateUrl: './map-marker-park.component.html',
  styleUrls: ['./map-marker-park.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapMarkerParkComponent extends MarkerClass implements OnDestroy, OnChanges {
  @Input() data: WorkVehicleWarehouse;
  @Input() index = 0;
  @Input() showParkOrder;
  addEvents = true;
  @HostBinding('class.showLabel')
  @Input() showLabel = false;
  @Input() showDate = false;
  markerGroupName: string = 'MapMarkerParkComponent';
  @HostBinding('style.background')
  get color() {
    return VehicleStatusesColors[this.warehouseService.parkStatus(this.data)];
  }

  public circleSize = 0;

  constructor(mapService: MapService, el: ElementRef, private warehouseService: WarehouseService, private cd: ChangeDetectorRef) {
    super(mapService, el);
  }

  markerClickEvent(e) {
    this.markerClick.emit(e);
  }
  domIconOptions() {
    return {
      onAttach: (clonedElement, domIcon, domMarker) => {
        clonedElement.addEventListener('click', this.markerClickEvent.bind(this));
      },
      onDetach: (clonedElement, domIcon, domMarker) => {
        clonedElement.removeEventListener('click', this.markerClickEvent.bind(this));
      }
    };
  }
  setCircleSize() {
    if (this.data && this.data.PARK_STARTDATE[0].v) {
      const duration = DateTime.fromISO(this.data.PARK_STARTDATE[0].v).diff(this.data.PARK_ENDDATE[0].v
        ? DateTime.fromISO(this.data.PARK_ENDDATE[0].v) : DateTime.local(), 'minutes').minutes * -1;
      if (duration >= MAX_MARKER_CIRCLE_TIME) {
        this.circleSize = 200;
      } else if (duration > 10) {
        this.circleSize = duration / MAX_MARKER_CIRCLE_TIME * 100 + 100;
      } else {
        this.circleSize = 0;
      }
    }
  }

  getCoords() {
    return this.data.PARK_POSITION[0].v && this.data.PARK_POSITION[0].v.lat ? this.data.PARK_POSITION[0].v : null;
  }

  get address() {
    return this.data.PARK_POI && this.data.PARK_POI[0].v ? this.data.PARK_POI[0].v.name
      : this.data.PARK_REVGEO && this.data.PARK_REVGEO[0].v
        ? this.data.PARK_REVGEO[0].v : '-';
  }

  get temp1() {
    return this.data.LAST_PARK_STARTTEMP1 && this.data.LAST_PARK_STARTTEMP1[0] && this.data.LAST_PARK_STARTTEMP1[0].v ?
    ` (${this.data.LAST_PARK_STARTTEMP1[0].v} °C)` : '';
  }

  get time() {
    if (!this.data.PARK_STARTDATE[0].v) {
      return '';
    }
    const format = DateTime.TIME_24_SIMPLE;
    const startTime = DateTime.fromISO(this.data.PARK_STARTDATE[0].v).toLocaleString(format);
    return this.data.PARK_ENDDATE && this.data.PARK_ENDDATE[0].v
      ? `${this.date}${startTime} - ${DateTime.fromISO(this.data.PARK_ENDDATE[0].v).toLocaleString(format)}, `
      : `${this.date}${startTime}, `;
  }

  get date() {
    if (this.showDate) {
      const format = DateTime.DATE_SHORT;
      const startDate = DateTime.fromISO(this.data.PARK_STARTDATE[0].v).toLocaleString(format);
      return `${startDate}, `;
    } else {
      return '';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.data) {
      this.setCircleSize();
    }
    if (this.marker) {
      this.doUpdate = true;
    }
    if (changes && changes.showParkOrder && !changes.showParkOrder.firstChange) {
      setTimeout(() => {
        this.updateMarker();
      });
    }
  }
}

import { NotificationNormAdhocComponent } from "./notification-bell/notification/notification-norm-adhoc/notification-norm-adhoc.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ToolboxComponent } from "./toolbox/toolbox.component";
import { TopPanelMenuComponent } from "./top-panel-menu/top-panel-menu.component";
import { LogoComponent } from "./logo/logo.component";
import { UserDropDownComponent } from "./user-drop-down/user-drop-down.component";
import { LanguageSwitcherComponent } from "./language-switcher/language-switcher.component";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ComponentsModule } from "../../components/components.module";
import { PipesModule } from "../../pipes/pipes.module";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { NotificationBellComponent } from "./notification-bell/notification-bell.component";
import { NotificationComponent } from "./notification-bell/notification/notification.component";
import { OverflowSpinnerModule } from "../overflow-spinner/overflow-spinner.module";
import { NotificationNormComponent } from "./notification-bell/notification/notification-norm/notification-norm.component";
import { NotificationNormAggregateComponent } from "./notification-bell/notification/notification-norm-aggregate/notification-norm-aggregate.component";
import { NotificationZoneComponent } from "./notification-bell/notification/notification-zone/notification-zone.component";
import { NotificationReminderComponent } from "./notification-bell/notification/notification-reminder/notification-reminder.component";

@NgModule({
  declarations: [
    ToolboxComponent,
    TopPanelMenuComponent,
    LogoComponent,
    UserDropDownComponent,
    LanguageSwitcherComponent,
    NotificationBellComponent,
    NotificationComponent,
    NotificationNormComponent,
    NotificationNormAdhocComponent,
    NotificationNormAggregateComponent,
    NotificationZoneComponent,
    NotificationReminderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    OverflowSpinnerModule,
    NgbDropdownModule,
    PipesModule,
    TranslateModule,
    ComponentsModule,
  ],
  exports: [
    ToolboxComponent,
    LogoComponent,
    TopPanelMenuComponent,
    NotificationNormComponent,
    NotificationComponent,
    NotificationNormAdhocComponent,
    NotificationNormAggregateComponent,
    NotificationZoneComponent,
    NotificationReminderComponent,
  ],
})
export class LayoutModule {}

import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { TableCellType } from '../data-grid.module';

@Component({
  selector: 'app-data-grid-cell',
  templateUrl: './data-grid-cell.component.html',
  styleUrls: ['./data-grid-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataGridCellComponent implements OnInit {
  @HostBinding('style.width.px')
  @Input() width: number;
  @HostBinding('class')
  @Input() type: TableCellType = 'body';

  constructor(public el: ElementRef) {
  }

  ngOnInit() {
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Driver } from '../../../../interfaces/driver';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-driver-poi',
  templateUrl: 'driver-poi.component.html',
  styleUrls: ['./driver-poi.component.scss']
})
export class DriverPoiComponent implements OnInit, OnDestroy {
  public driver: Driver;
  protected subscriptions = new Subscription();

  constructor(protected route: ActivatedRoute) {
  }


  ngOnInit() {
    this.routeSub();
  }

  protected routeSub() {
    this.subscriptions.add(this.route.parent.data.subscribe(({driver}) => {
      this.driver = driver;
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { WarehouseDefaultResponse } from '../../interfaces/warehouse';

@Pipe({
  name: 'avgSpeed'
})
export class AvgSpeedPipe implements PipeTransform {

  transform(value: WarehouseDefaultResponse, args?: any): any {
    return (
      value.SUM_RIDE_DISTANCE &&
      value.SUM_RIDE_DURATION &&
      value.SUM_RIDE_DISTANCE[0].v > 0 &&
      value.SUM_RIDE_DURATION[0].v > 0
      ? (value.SUM_RIDE_DISTANCE[0].v / (value.SUM_RIDE_DURATION[0].v / 60 / 60)).toFixed(0)
      : '-') + ' km/h';
  }

}

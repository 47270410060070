<div class="d-flex justify-content-between align-items-center">
    <app-logo></app-logo>
    <div>
        <ng-container *ngTemplateOutlet="menu|async"></ng-container>
    </div>
    <div class="d-flex justify-content-end align-items-center c-top-panel--margins">
        <a class="text-m p-s"><app-notification-bell></app-notification-bell></a>
        <app-language-switcher></app-language-switcher>
        <app-user-drop-down></app-user-drop-down>
    </div>
</div>

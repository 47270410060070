import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {ControlValueAccessor, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NotificationChannel} from '../../../../interfaces/notificationT';
import {Subscription} from 'rxjs';
import { ConfigService } from 'src/app/core/services/config.service';

@Component({
  selector: 'app-channels-input',
  templateUrl: './channels-input.component.html',
  styleUrls: ['./channels-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChannelsInputComponent),
      multi: true
    }
  ]
})
export class ChannelsInputComponent implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {
  private isDisabled = false;
  @Input() channels: NotificationChannel[];
  @Input() allButton = true;
  @Input() showEmailInput = false;
  allSelected;
  formArray: UntypedFormArray = this.fb.array([]);
  value = [];
  emailsModel: any;
  valueSub: Subscription;

  constructor(private fb: UntypedFormBuilder, public configService: ConfigService, private cd: ChangeDetectorRef) {
  }

  get hasEmail() {
    const values: NotificationChannel[] = this.value || [];
    return values.indexOf('EMAIL') !== -1;
  }

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  unsubscribe() {
    if (this.valueSub) {
      this.valueSub.unsubscribe();
    }
  }

  ngOnInit() {
  }

  allToggle() {
    if (this.allSelected) {
      this.value = [];
    } else {
      this.value = [...this.channels];
    }
    this.setValues();
    this.emitChange();
    this.cd.detectChanges();
  }


  emitChange() {
    let value: any = this.value;
    if (this.showEmailInput) {
      value = {
        channels: this.value,
        emails: !!this.emailsModel ? this.emailsModel
          .replace(/\s/g, '')
          .split(',') : null,
      };
    }
    this.onTouch(value);
    this.onChange(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  setValues(emitEvent = false) {
    this.formArray.controls.forEach((item: UntypedFormControl, index) => {
      item.setValue(this.value.indexOf(this.channels[index]) !== -1, {emitEvent: false});
    });
    this.allSelected = this.value.length === this.channels.length && this.value.length;
  }

  emailsToString(emails) {
    return emails && Array.isArray(emails) ? emails.join(',') : emails;
  }

  writeValue(obj: any): void {
    console.log(obj);
    if (this.showEmailInput) {
      this.value = [...obj.channels];
      this.emailsModel = this.emailsToString(obj.emails);
      this.cd.detectChanges();
    } else {
      this.value = [...obj];
    }

    if (this.channels) {
      this.setValues();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.channels) {
      this.formArray = this.fb.array(this.channels.map(item => this.value.indexOf(item) !== -1));
      this.unsubscribe();
      this.valueSub = this.formArray.valueChanges.subscribe(res => {
        this.value = this.channels.filter((value1, index) => res[index]);
        this.allSelected = this.value.length === this.channels.length && this.value.length;
        this.emitChange();
        this.cd.detectChanges();
      });
    }
  }

  showChannel(channel: NotificationChannel) {
    if (!this.configService.isCbTheme())
      return true;
    console.log(`channel: ${channel}`);
    return channel !== 'PUSH';
  }

  ngOnDestroy(): void {
  }

  emailChanges() {
    this.emitChange();
  }
}

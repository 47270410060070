<ng-container [ngSwitch]="layer" *ngIf="rideData">
    <ul *ngSwitchCase="layerTypes.SPEED" class="speed-list">
        <li *ngFor="let speed of speeds;let i = index">
            <span class="speed-circle" [style.background]="speed.color">
                {{rideData[headers[i]][0].v|number:'1.0-0'}}%
            </span>
            <span translate [translateParams]="speed">SPEED_LIMITS.{{headerTrans(headers[i])}}</span> <strong></strong>
        </li>
        <li>
            <span class="urban-circle">
                {{rideData.SUM_URBAN_OVER_50 ? rideData.SUM_URBAN_OVER_50[0].v : '-'}}
            </span>
            <span translate>SPEED_LIMITS.URBAN_OVER_50</span> <strong></strong>
        </li>
    </ul>
    <div *ngSwitchCase="layerTypes.FUEL" class="speed-list">
        <ul>
            <li *ngFor="let fuelLimit of fuelLimits;let i = index">
            <span class="speed-circle" [style.background]="fuelLimit.color">
            </span>
                <strong> {{fuelHeader(i)}}</strong>
            </li>
        </ul>
    </div>

    <ul class="row" *ngSwitchDefault>
        <li class="col-6">
            <app-icon-bg iconClass="icon-meet"></app-icon-bg>
            {{rideData.SUM_CUSTOMER_WORK_PARK_COUNT ? rideData.SUM_CUSTOMER_WORK_PARK_COUNT[0].v : 0}}
        </li>
        <li class="col-6">
            <app-icon-bg iconClass="icon-distance"></app-icon-bg>
            {{rideData.SUM_RIDE_DISTANCE[0]|warehouseUnit}}
        </li>
        <li class="col-6">
            <app-icon-bg iconClass="icon-speedometer" label="avg"></app-icon-bg>
            {{rideData|avgSpeed}}
        </li>
        <li class="col-6">
            <app-icon-bg iconClass="icon-speedometer" label="max"></app-icon-bg>
            {{rideData.TOTAL_MAX_SPEED[0]|warehouseUnit}} km/h
        </li>

        <li class="col-6">
            <app-icon-bg iconClass="icon-petrol" label="sum"></app-icon-bg>
            {{rideData.SUM_RIDE_FUEL[0]|warehouseUnit}}
        </li>
        <li class="col-6">
            <app-icon-bg iconClass="icon-petrol" label="avg"></app-icon-bg>
            {{rideData.AVG_FUEL_CONSUMPTION[0]|warehouseUnit}}/100km
        </li>
        <li class="col-6">
            <app-icon-bg iconClass="icon-petrol" label="eco"></app-icon-bg>
            {{rideData.AVG_ECO_SCORE[0]|warehouseUnit}} pkt
        </li>
        <li class="col-6">
          <app-icon-bg iconClass="icon-petrol" label="avg"></app-icon-bg>
          {{rideData.AVG_FUEL_CONSUMPTION_H[0]|warehouseUnit}}/h
      </li>

        <!--    <li class="col-6">-->
        <!--        <app-user-avatar [initials]="rideData.DRIVER[0].v.name|initials"-->
        <!--                         [avatar]="rideData.DRIVER[0].v.avatarUrl"-->
        <!--                         [round]="true"-->
        <!--                         [size]="20"></app-user-avatar>-->
        <!--    </li>-->

    </ul>
</ng-container>

import {Injectable} from '@angular/core';
import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Info} from 'luxon';
import {TranslationWidth} from "@angular/common";

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor() {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return Info.weekdays('short')[weekday - 1];
  }

  getMonthShortName(month: number): string {
    return Info.months('short')[month - 1];
  }

  getMonthFullName(month: number): string {
    return Info.months()[month - 1];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return Info.weekdays(width === TranslationWidth.Short ? 'short' : 'narrow')[weekday  -1 ];
  }
}

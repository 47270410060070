import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NotificationREMINDER } from '../../../../../../interfaces/notificationT';

@Component({
  selector: 'app-notification-reminder',
  templateUrl: './notification-reminder.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationReminderComponent implements OnInit {
  @Input() notification: NotificationREMINDER;

  constructor() {
  }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Settings } from 'luxon';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfigService } from './config.service';

export interface UserSettings {
  favoriteVehicles: string[];
  [key: string]: any;
}

export enum Languages {
  EN = 'en',
  PL = 'pl',
}


@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {
  private selectedLanguage: Languages = Languages.PL;

  private userId;

  public get language() {
    return this.selectedLanguage;
  }

  private settings: UserSettings;
  get settingsSnapshot(): UserSettings {
    return this.settings;
  }

  constructor(private translate: TranslateService, private user: UserService, private configService: ConfigService) {
    const changeLanguageSubscription = this.user.settings$.subscribe((settings) => {
      this.settings = settings;
      // connectedboat
      if (settings) {
        if (this.configService.isCbTheme() ) {
          this.selectedLanguage = <any>'mt';
        } else {
          if (settings.language) {
          // only known languages
          Object.keys(Languages).forEach(key => {
            if (Languages[key] === settings.language) {
              this.selectedLanguage = settings.language;
            }
          });
        }
      }

      this.translate.setDefaultLang(this.selectedLanguage);
      this.translate.use(this.selectedLanguage);
      Settings.defaultLocale = this.selectedLanguage;
      console.log('language set');
      changeLanguageSubscription.unsubscribe();

      }
    });

    this.user.settings$.subscribe((settings) => {
      this.settings = settings;
    });
  }

  changeLanguage(language: Languages) {
    this.selectedLanguage = language;
    this.translate.use(language);
    Settings.defaultLocale = this.selectedLanguage;
    this.updateSettings({language}).subscribe();
  }

  get settings$(): Observable<UserSettings> {
    return this.user.settings$;
  }

  updateSettings(data: Partial<UserSettings>) {
    return this.user.updateSettings(data);
  }
}

import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DateTime } from 'luxon';
import { DateRageValue } from '../../modules/date-range-filter/date-range.service';
import { withZero } from '../../../utils/utils';

@Component({
  selector: 'app-from-to',
  templateUrl: './from-to.component.html',
  styleUrls: ['./from-to.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FromToComponent implements OnChanges {

  @Input() from;
  @Input() to;
  @Input() range: DateRageValue;

  @HostBinding('class')
  @Input() showType: 'range' | 'one_day' | 'days' | 'hours' = 'hours';
  @Input() moreDaysType: 'range' | 'days' = 'days';
  @HostBinding('class.small')
  @Input() small = false;

  private fromDateTime: Date | null;
  private toDateTime: Date | null;

  constructor() {
  }


  dateLabel(type, returnIfNull = '-') {
    const date = this[type + 'DateTime'];
    return date ? `${date.getDate()}.${withZero(date.getMonth() + 1)}.${date.getFullYear()}` : returnIfNull;
  }

  getTime(type, returnIfNull = '-') {
    const date = this[type + 'DateTime'];
    return date ? `${date.getHours()}:${withZero(date.getMinutes())}` : returnIfNull;
  }

  getDateByFormat(type: 'from' | 'to', format, returnIfNull = '-') {
    const key = type + 'DateTime';
    return this[key] ? this[key].toFormat(format) : returnIfNull;
  }

  getContext(type) {
    return {
      $implicit: {
        first: this.dateLabel(type),
        second: this.getTime(type),
      }
    };
  }

  getContextRange(type: 'from' | 'to') {
    if (this.range && this[type]) {
      const rangeDate = DateTime.fromISO(type === 'from' ? this.range.start : this.range.end);
      const date = DateTime.fromJSDate(this[type + 'DateTime']);
      return {
        $implicit: {
          first: this.getTime(type),
          second: `${date.toFormat('d.LL.yy')} <span class="text-gray">(${date.toFormat('ccc')})</span>`,
        }
      };
    }
    return {
      $implicit: {
        first: this.getTime(type),
        second: this.dateLabel(type, ''),
      }
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fromDateTime = this.from ? new Date(this.from) : null;
    this.toDateTime = this.to ? new Date(this.to) : null;
    if (this.showType === 'one_day' && this.fromDateTime && this.toDateTime && !this.isSameDay(this.fromDateTime, this.toDateTime)) {
      this.showType = this.moreDaysType;
    }
  }

  private isSameDay(fromDateTime: Date, toDateTime: Date) {
    return fromDateTime.getFullYear() === toDateTime.getFullYear() &&
      fromDateTime.getMonth() === toDateTime.getMonth() &&
      fromDateTime.getDate() === toDateTime.getDate();
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../../../core/guards/auth-guard.service';
import { VehicleGroupPopupComponent } from './vehicle-group-popup/vehicle-group-popup.component';
import { GroupsPopupComponent } from './groups-popup.component';
import { PoiGroupsPopupComponent } from './poi-groups-popup/poi-groups-popup.component';
import { DriverGroupPopupComponent } from './driver-group-popup/driver-group-popup.component';
import { VehicleGroupDetailsComponent } from './vehicle-group-popup/vehicle-group-details/vehicle-group-details.component';
import { VehicleGroupPoiComponent } from './vehicle-group-popup/vehicle-group-poi/vehicle-group-poi.component';
import { VehicleGroupResolve } from './vehicle-group-popup/vehicle-group.resolve';
import { VehicleGroupNewComponent } from './vehicle-group-popup/vehicle-group-new/vehicle-group-new.component';
import { DriverGroupResolve } from './driver-group-popup/driver-group.resolve';
import { DriverGroupDetailsComponent } from './driver-group-popup/driver-group-details/driver-group-details.component';
import { DriverGroupPoiComponent } from './driver-group-popup/driver-group-poi/driver-group-poi.component';
import { DriverGroupNewComponent } from './driver-group-popup/driver-group-new/driver-group-new.component';
import { PermissionsGuard } from '../../../core/guards/permissions.guard';
import { PERMISSIONS } from '../../../core/services/permission.service';

const routes: Routes = [
  {
    path: 'groups',
    outlet: 'popup',
    canActivate: [AuthGuardService],
    component: GroupsPopupComponent,
    children: [
      {
        path: 'vehicle',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: VehicleGroupNewComponent,
            canActivate: [PermissionsGuard],
            data: {
              permissions: [PERMISSIONS.VEHICLE_GROUP_CREATE ]
            }
          },
          {
            path: ':id',
            resolve: {
              vehicleGroup: VehicleGroupResolve,
            },
            component: VehicleGroupPopupComponent,
            children: [
              {
                path: 'details',
                pathMatch: 'full',
                component: VehicleGroupDetailsComponent,
              },
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'details'
              },
              {
                path: 'poi',
                component: VehicleGroupPoiComponent,
              }

            ]
          }
        ]
      },
      {
        path: 'poi',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: PoiGroupsPopupComponent,
          },
          {
            path: ':id',
            component: PoiGroupsPopupComponent,
          }
        ]
      },
      {
        path: 'driver',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: DriverGroupNewComponent,
          },
          {
            path: ':id',
            component: DriverGroupPopupComponent,
            resolve: {
              driverGroup: DriverGroupResolve,
            },
            children: [
              {
                path: 'details',
                component: DriverGroupDetailsComponent,
              },
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'details'
              },
              {
                path: 'poi',
                component: DriverGroupPoiComponent,
              }

            ]
          }
        ]
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GroupsPopupRoutingModule {
}

<div class="row justify-content-start align-items-center">
    <div class="col-4">
        <div class="form-checkbox " (click)="toggleSelect()">
            <input id="seect-{{dayNumber}}" type="checkbox" [checked]="isSelect" [disabled]="disabled"
            /><span></span> {{dayLabel|titlecase}}
        </div>
    </div>
    <div class="col-4 form-group">

        <ngb-timepicker [ngModel]="timeModel?.from" size="small" ngClass="timepicker" (ngModelChange)="modelChange($event, 'from')"></ngb-timepicker>
            <!-- <label for="">Od</label> -->
    </div>
    <div class="col-4 form-group">
        <ngb-timepicker [ngModel]="timeModel?.to"  size="small"  ngClass="timepicker" (ngModelChange)="modelChange($event, 'to')"></ngb-timepicker>
            <!-- <label for="">Do</label> -->
    </div>

</div>

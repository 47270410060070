import { DriverCodeTrackingAddComponent } from './driver-code-tracking/driver-code-tracking-add/driver-code-tracking-add.component';
import { DriverCodeTrackingComponent } from './driver-code-tracking/driver-code-tracking.component';
import { NgModule } from '@angular/core';

import { DriversPopupRoutingModule } from './drivers-popup-routing.module';
import { DriverPopupComponent } from './driver-popup.component';
import { DriverNewComponent } from './driver-new/driver-new.component';
import { DriverDetailsComponent } from './driver-details/driver-details.component';
import { DriverResolve } from './driver.resolve';
import { SharedModule } from '../../../shared/shared.module';
import { DriverPopupHeaderComponent } from './driver-popup-header/driver-popup-header.component';
import { DriverHistoryComponent } from './driver-history/driver-history.component';
import { RouteHistoryModule } from '../../../shared/modules/route-history/route-history.module';
import { DriverPoiComponent } from './driver-poi/driver-poi.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import {DriverCodeTrackingRowComponent} from './driver-code-tracking/driver-code-tracking-row/driver-code-tracking-row.component';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [DriverPopupComponent, DriverNewComponent, DriverDetailsComponent, DriverCodeTrackingRowComponent,
    DriverPopupHeaderComponent, DriverHistoryComponent, DriverPoiComponent,
    DriverCodeTrackingComponent, DriverCodeTrackingAddComponent],
  providers: [DriverResolve],
  imports: [
    SharedModule,
    RouteHistoryModule,
    NgbDatepickerModule,
    DriversPopupRoutingModule
  ]
})
export class DriversPopupModule {
}

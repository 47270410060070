import {TableHeaders} from '../shared/modules/data-grid/data-grid-header/data-grid-header.component';

export enum WarehouseDimensionName {
  VEHICLE = 'VEHICLE',
  DRIVER = 'DRIVER',
  POI = 'POI',
  PARK_POI = 'PARK_POI',
  ZONE = 'ZONE',
}

export enum WarehouseDimensionAggregation {
  NONE = 'NONE',
}

export type WarehouseUnits = 'km' | 'minutes' | 'number' | 'sec';

export interface WarehouseDimension {
  name: WarehouseDimensionName;
  aggregation: WarehouseDimensionAggregation;
  range?: any[];
}

export interface WarehouseRange {
  start: string;
  end: string;
}

export enum WarehouseDateAggregation {
  NONE = 'NONE',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
  ALL = 'ALL'
}

export interface WarehouseDate {
  aggregation: WarehouseDateAggregation;
  range?: WarehouseRange;
  conditions?: any[];

}

export interface WarehouseField<T> {
  name: T;
}

export enum WarehouseFieldHeaderType {
  STATUS = 'STATUS',
  TRANSLATE = 'TRANSLATE',
  ICON = 'ICON'
}

export interface WarehouseFieldHeader<T> {
  name: T;
  hide?: boolean;
  label?: any;
  dontSend?: boolean;
  sort?: boolean;
  width?: number;
  labelType?: WarehouseFieldHeaderType;
}

export interface WarehouseTableHeaders<T> extends TableHeaders<T> {
  labelType?: WarehouseFieldHeaderType;
}

export interface WarehousePagination {
  limit: number;
  offset: number;
}

export interface WarehouseOutput<T> {
  fields: WarehouseField<T>[];
  pagination?: WarehousePagination;
  fillGaps?: boolean;
  sort?: { [key: string]: -1 | 1; } | {};
}

type WarehouseWhereConditions = '$gte' | '$lte' | '$eq' | '$gt' | '$lt';

export type WarehouseRequestConditionsPre = {
  [key in WarehouseWhereConditions]: number;
};

export interface WarehouseRequestConditions {
  pre?: any;
  post?: any;
}

export interface WarehouseRequest<T> {
  dimensions: WarehouseDimension[];
  date: WarehouseDate;
  conditions?: WarehouseRequestConditions;
  output: WarehouseOutput<T>;

}

export interface WarehouseResponseData {
  v: any;
  unit?: WarehouseUnits;
}

export interface WarehouseResponse<T> {
  data: T[];
}

export type WarehouseDefaultResponse = {
  [key in WarehouseOutputField]?: WarehouseResponseData[]
};

export enum WarehouseOutputField {
  DIM_VEHICLE_ID = 'DIM_VEHICLE_ID',
  DATE = 'DATE',
  ID = 'ID',
  DRIVER = 'DRIVER',
  DRIVER_NAME = 'DRIVER_NAME',
  DRIVER_ID = 'DRIVER_ID',
  VEHICLE = 'VEHICLE',
  VEHICLE_NAME = 'VEHICLE_NAME',
  VEHICLE_ID = 'VEHICLE_ID',
  DEVICE = 'DEVICE',
  DEVICE_ID = 'DEVICE_ID',
  PARK_POI = 'PARK_POI',
  PARK_POI_NAME = 'PARK_POI_NAME',
  PARK_POI_ID = 'PARK_POI_ID',
  RIDE_START_POI = 'RIDE_START_POI',
  RIDE_START_POI_NAME = 'RIDE_START_POI_NAME',
  RIDE_START_POI_ID = 'RIDE_START_POI_ID',
  SUM_WORK_DURATION = 'SUM_WORK_DURATION',
  AVG_RIDE_DURATION = 'AVG_RIDE_DURATION',
  SUM_RIDE_DURATION = 'SUM_RIDE_DURATION',
  SUM_RIDE_DISTANCE = 'SUM_RIDE_DISTANCE',
  AVG_RIDE_DISTANCE = 'AVG_RIDE_DISTANCE',
  DAILY_RIDE_DISTANCE = 'DAILY_RIDE_DISTANCE',
  SUM_NONWORK_DURATION = 'SUM_NONWORK_DURATION',
  AVG_PARK_DURATION = 'AVG_PARK_DURATION',
  SUM_PARK_DURATION = 'SUM_PARK_DURATION',
  SUM_CUSTOMER_PARK_DURATION = 'SUM_CUSTOMER_PARK_DURATION',
  SUM_NONCUSTOMER_PARK_DURATION = 'SUM_NONCUSTOMER_PARK_DURATION',
  SUM_WORK_RIDE_DURATION = 'SUM_WORK_RIDE_DURATION',
  SUM_WORK_PARK_DURATION = 'SUM_WORK_PARK_DURATION',
  SUM_NONWORK_PARK_DURATION = 'SUM_NONWORK_PARK_DURATION',
  SUM_CUSTOMER_PARK_COUNT = 'SUM_CUSTOMER_PARK_COUNT',
  DAILY_CUSTOMER_PARK_COUNT = 'DAILY_CUSTOMER_PARK_COUNT',
  SUM_WORK_PARK_COUNT = 'SUM_WORK_PARK_COUNT',
  SUM_NONWORK_PARK_COUNT = 'SUM_NONWORK_PARK_COUNT',
  SUM_WORK_RIDE_DISTANCE = 'SUM_WORK_RIDE_DISTANCE',
  SUM_NONWORK_RIDE_DISTANCE = 'SUM_NONWORK_RIDE_DISTANCE',
  SUM_WORK_RIDE_COUNT = 'SUM_WORK_RIDE_COUNT',
  SUM_NONWORK_RIDE_COUNT = 'SUM_NONWORK_RIDE_COUNT',
  SUM_NONWORK_RIDE_DURATION = 'SUM_NONWORK_RIDE_DURATION',
  SUM_CUSTOMER_WORK_PARK_COUNT = 'SUM_CUSTOMER_WORK_PARK_COUNT',
  SUM_CUSTOMER_NONWORK_PARK_COUNT = 'SUM_CUSTOMER_NONWORK_PARK_COUNT',
  SUM_BRANCH_WORK_PARK_COUNT = 'SUM_BRANCH_WORK_PARK_COUNT',
  SUM_BRANCH_NONWORK_PARK_COUNT = 'SUM_BRANCH_NONWORK_PARK_COUNT',
  SUM_OTHER_WORK_PARK_COUNT = 'SUM_OTHER_WORK_PARK_COUNT',
  SUM_OTHER_NONWORK_PARK_COUNT = 'SUM_OTHER_NONWORK_PARK_COUNT',
  SUM_CUSTOMER_WORK_PARK_DURATION = 'SUM_CUSTOMER_WORK_PARK_DURATION',
  SUM_BRANCH_WORK_PARK_DURATION = 'SUM_BRANCH_WORK_PARK_DURATION',
  SUM_OTHER_WORK_PARK_DURATION = 'SUM_OTHER_WORK_PARK_DURATION',
  FIRST_RIDE_STARTDATE = 'FIRST_RIDE_STARTDATE',
  LAST_RIDE_ENDDATE = 'LAST_RIDE_ENDDATE',
  FIRST_PARK_STARTDATE = 'FIRST_PARK_STARTDATE',
  LAST_PARK_ENDDATE = 'LAST_PARK_ENDDATE',
  LAST_PARK_STARTDATE = 'LAST_PARK_STARTDATE',
  FIRST_RIDE_STARTHOUR = 'FIRST_RIDE_STARTHOUR',
  LAST_PARK_ENDHOUR = 'LAST_PARK_ENDHOUR',
  RIDE_STARTDATE = 'RIDE_STARTDATE',
  RIDE_ENDDATE = 'RIDE_ENDDATE',
  PARK_STARTDATE = 'PARK_STARTDATE',
  PARK_ENDDATE = 'PARK_ENDDATE',
  RIDE_STARTPOSITION = 'RIDE_STARTPOSITION',
  RIDE_ENDPOSITION = 'RIDE_ENDPOSITION',
  PARK_POSITION = 'PARK_POSITION',
  RIDE_START_REVGEO = 'RIDE_START_REVGEO',
  PARK_REVGEO = 'PARK_REVGEO',
  VEHICLE_GROUPS = 'VEHICLE_GROUPS',
  DRIVER_GROUPS = 'DRIVER_GROUPS',
  SUM_RIDE_FUEL = 'SUM_RIDE_FUEL',
  AVG_FUEL_CONSUMPTION = 'AVG_FUEL_CONSUMPTION',
  AVG_FUEL_CONSUMPTION_H = 'AVG_FUEL_CONSUMPTION_H',
  AVG_ECO_SCORE = 'AVG_ECO_SCORE',
  SUM_REFUELS = 'SUM_REFUELS',
  SUM_DEFUELS = 'SUM_DEFUELS',
  SUM_PARK_FUEL_CHANGE_LITRES = 'SUM_PARK_FUEL_CHANGE_LITRES',
  PARK_FUEL_START_LITRES = 'PARK_FUEL_START_LITRES',
  PARK_FUEL_END_LITRES = 'PARK_FUEL_END_LITRES',
  PARK_FUEL_CHANGE_PERCENTS = 'PARK_FUEL_CHANGE_PERCENTS',
  PARK_FUEL_START_PERCENTS = 'PARK_FUEL_START_PERCENTS',
  PARK_FUEL_END_PERCENTS = 'PARK_FUEL_END_PERCENTS',
  RIDE_ID = 'RIDE_ID',
  RIDE_IS_OVERRIDE = 'RIDE_IS_OVERRIDE',
  RIDE_OVERRIDE_USER = 'RIDE_OVERRIDE_USER',
  RIDE_OVERRIDE_DATE = 'RIDE_OVERRIDE_DATE',
  PARK_IS_OVERRIDE = 'PARK_IS_OVERRIDE',
  PARK_OVERRIDE_USER = 'PARK_OVERRIDE_USER',
  PARK_OVERRIDE_DATE = 'PARK_OVERRIDE_DATE',
  URBAN_SHARE_SPEEDING = 'URBAN_SHARE_SPEEDING',
  NON_URBAN_SHARE_SPEEDING = 'NON_URBAN_SHARE_SPEEDING',
  HIGHWAY_SHARE_SPEEDING = 'HIGHWAY_SHARE_SPEEDING',
  SUM_URBAN_OVER_50 = 'SUM_URBAN_OVER_50',
  TOTAL_NO_SPEEDING = 'TOTAL_NO_SPEEDING',
  TOTAL_MAX_SPEED = 'TOTAL_MAX_SPEED',
  TOTAL_MAX_OVERSPEED = 'TOTAL_MAX_OVERSPEED',
  TOTAL_UP_TO_10 = 'TOTAL_UP_TO_10',
  TOTAL_UP_TO_20 = 'TOTAL_UP_TO_20',
  TOTAL_UP_TO_30 = 'TOTAL_UP_TO_30',
  TOTAL_UP_TO_40 = 'TOTAL_UP_TO_40',
  TOTAL_UP_TO_50 = 'TOTAL_UP_TO_50',
  TOTAL_OVER_50 = 'TOTAL_OVER_50',
  HIGHWAY_NO_SPEEDING = 'HIGHWAY_NO_SPEEDING',
  HIGHWAY_MAX_SPEED = 'HIGHWAY_MAX_SPEED',
  HIGHWAY_MAX_OVERSPEED = 'HIGHWAY_MAX_OVERSPEED',
  HIGHWAY_UP_TO_10 = 'HIGHWAY_UP_TO_10',
  HIGHWAY_UP_TO_20 = 'HIGHWAY_UP_TO_20',
  HIGHWAY_UP_TO_30 = 'HIGHWAY_UP_TO_30',
  HIGHWAY_UP_TO_40 = 'HIGHWAY_UP_TO_40',
  HIGHWAY_UP_TO_50 = 'HIGHWAY_UP_TO_50',
  HIGHWAY_OVER_50 = 'HIGHWAY_OVER_50',
  URBAN_NO_SPEEDING = 'URBAN_NO_SPEEDING',
  URBAN_MAX_SPEED = 'URBAN_MAX_SPEED',
  URBAN_MAX_OVERSPEED = 'URBAN_MAX_OVERSPEED',
  URBAN_UP_TO_10 = 'URBAN_UP_TO_10',
  URBAN_UP_TO_20 = 'URBAN_UP_TO_20',
  URBAN_UP_TO_30 = 'URBAN_UP_TO_30',
  URBAN_UP_TO_40 = 'URBAN_UP_TO_40',
  URBAN_UP_TO_50 = 'URBAN_UP_TO_50',
  URBAN_OVER_50 = 'URBAN_OVER_50',
  NON_URBAN_NO_SPEEDING = 'NON_URBAN_NO_SPEEDING',
  NON_URBAN_MAX_SPEED = 'NON_URBAN_MAX_SPEED',
  NON_URBAN_MAX_OVERSPEED = 'NON_URBAN_MAX_OVERSPEED',
  NON_URBAN_UP_TO_10 = 'NON_URBAN_UP_TO_10',
  NON_URBAN_UP_TO_20 = 'NON_URBAN_UP_TO_20',
  NON_URBAN_UP_TO_30 = 'NON_URBAN_UP_TO_30',
  NON_URBAN_UP_TO_40 = 'NON_URBAN_UP_TO_40',
  NON_URBAN_UP_TO_50 = 'NON_URBAN_UP_TO_50',
  NON_URBAN_OVER_50 = 'NON_URBAN_OVER_50',
  PARK_TYPE = 'PARK_TYPE',
  PARK_POD = 'PARK_POD',
  PARK_ENGINE_ON = 'PARK_ENGINE_ON',
  FIRST_RIDE_STARTTEMP1 = 'FIRST_RIDE_STARTTEMP1',
  LAST_PARK_STARTTEMP1 = 'LAST_PARK_STARTTEMP1',
  LAST_PARK_ENDTEMP1 = 'LAST_PARK_ENDTEMP1',
}

<div class="d-flex justify-content-between align-items-stretch flex-column flex-fill h-100">
  <div class="overflow-auto flex-grow-1">
      <div [formGroup]="forms?.ETOLL" class="p-xm">
          <h3 class="form-header mt-xl" translate>ETOLL</h3>
          <div class="row">
              <div class="col-4">
                <app-select-input
                      [multiple]="false"
                      formControlName="configId"
                      [allowClear]="false"
                      [items]="etollConfigIds$|async"
              ><label translate>Config Id</label></app-select-input>
              <app-error-message [control]="forms?.ETOLL.get('configId')"></app-error-message>
              </div>
              <div class="col-4">
                  <!-- <app-form-group> -->
                      <label translate>Aktywane</label>
                      <input app-form formControlName="enabled" type="checkbox">
                      <app-error-message [control]="forms?.ETOLL.get('enabled')"></app-error-message>
                  <!-- </app-form-group> -->
              </div>
              <div class="col-4">
                <app-form-group>
                    <label translate>Nr techniczny</label>
                    <input app-form formControlName="serialNumber" type="text">
                    <app-error-message [control]="forms?.ETOLL.get('serialNumber')"></app-error-message>
                </app-form-group>
            </div>
            <div class="col-4">
                <app-form-group>
                    <label translate>Nr biznesowy</label>
                    <input app-form formControlName="businessNumber" type="text">
                    <app-error-message [control]="forms?.ETOLL.get('businessNumber')"></app-error-message>
                </app-form-group>
            </div>
            <div class="col-4 p-m">
              <app-select-input
                      [multiple]="false"
                      formControlName="source"
                      [allowClear]="false"
                      [items]="etollSources"
              ><label translate>SOURCE</label></app-select-input>
              <app-error-message [control]="forms?.ETOLL.get('source')"></app-error-message>
          </div>
          </div>
      </div>
  </div>
  <div class="p-xm d-flex justify-content-end align-items-center border-top">
      <button class="btn btn-primary btn-border px-xxl mr-m" (click)="cancel()" translate>
          {{(isEditMode ? 'CANCEL' : 'EXIT')|translate}}
      </button>
      <button class="btn btn-primary px-xxl" (click)="handlerSave()" [disabled]="pending" *ngIf="showButton">
          <app-spinner [showSpinner]="pending"></app-spinner>
          {{(isNewView || isEditMode ? 'SAVE' : 'EDIT')|translate}}
      </button>
  </div>
</div>

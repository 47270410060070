import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Reminder, ReminderRequest} from '../../../../../interfaces/reminder';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Vehicle} from '../../../../../classes/vehicle';
import {ApiValidatorService} from '../../../../../core/services/api-validator.service';
import {ReminderService} from '../../../../../core/services/reminder.service';
import {NgbDatepicker} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../../../core/services/notifications.service';
import {NotificationSetting, NotificationTemplateType, NotificationType} from '../../../../../interfaces/notificationT';
import {UserService} from '../../../../../core/services/user.service';
import {switchMap} from 'rxjs/operators';
import {isEqual} from '../../../../../utils/utils';

@Component({
  selector: 'app-vehicle-service-add',
  templateUrl: './vehicle-service-add.component.html',
  styleUrls: ['./vehicle-service-add.component.scss'],
})
export class VehicleServiceAddComponent implements OnInit {
  @Input() reminder: Reminder;
  @Input() vehicle: Vehicle;
  @Output() formUpdate = new EventEmitter();
  @ViewChild(NgbDatepicker) ngbDatepicker: NgbDatepicker;
  public form: UntypedFormGroup;
  public channelForm = this.fb.control({emails: null, channels: []});
  public pending = false;
  public notificationSettings: NotificationSetting;
  public notificationGlobalSettings: NotificationSetting;

  constructor(
    private fb: UntypedFormBuilder,
    private apiValidator: ApiValidatorService,
    private reminderService: ReminderService,
    private notificationService: NotificationsService,
    private cd: ChangeDetectorRef,
    private userService: UserService) {
  }

  dateIterationValue = 'D';
  dateIterationType = this.reminderService.dateIterationType;
  reminderDescLegends = this.reminderService.descValues;
  reminderDescExamplesValue;
  reminderDescExamples = this.reminderService.reminderDescExamples;

  getNotificationSettings() {
    this.notificationService.settings().subscribe(res => {
      this.notificationGlobalSettings = res.find(item => item.type === NotificationType.REMINDER && !item.related);
      if (this.reminder) {
        this.notificationSettings = res.find(item => item.type === NotificationType.REMINDER && item.related
          && item.related.id === this.reminder.id);
      }
      this.setNotificationFormValue();
    });
  }

  setNotificationFormValue() {
    const values = this.notificationSettings || this.notificationGlobalSettings;
    if (values) {
      this.patchChannelsForm({channels: values.channels});
    }
    this.cd.detectChanges();
  }

  updateNotification() {
    const {channels = []} = this.channelForm.value;
    if (this.channelForm.dirty && (this.notificationSettings
      || !isEqual(channels, this.notificationGlobalSettings.channels))) {
      this.pending = true;
      const body = {
        channels,
        related: {
          id: this.reminder.id,
          type: NotificationTemplateType.REMINDER,
        },
        type: NotificationType.REMINDER,
      };
      this.notificationService.updateSettings(body).subscribe(res => {
        this.pending = false;
        if (!this.notificationSettings) {
          this.notificationSettings = {
            availableChannels: this.notificationGlobalSettings.availableChannels,
            ...body
          };
        }
      }, this.errorCallback.bind(this));
    }
  }

  errorCallback(err) {
    this.pending = false;
    // this.formErrors = err;
    // this.cd.detectChanges();
  }

  addRecipent(res) {
    const {emails = []} = this.channelForm.value;
    this.userService.user$
      .pipe(
        switchMap((user) => this.notificationService.setRecipients(
          NotificationType.REMINDER,
          {
            id: res.id,
            type: 'reminder_template_configuration',
          },
          [user.id],
          emails || []
        ))).subscribe();
  }

  ngOnInit() {
    this.buildForm();
    if (this.reminder) {
      this.form.patchValue(this.reminder);
      if (this.reminder.criteria.dateThreshold) {
        this.form.get('criteria').get('dateThreshold').patchValue(new Date(this.reminder.criteria.dateThreshold));
      }
      if (this.reminder.criteria.dateIteration) {
        const { value, type } = this.parseDateIteration(this.reminder.criteria.dateIteration);
        this.dateIterationValue = type;
        this.form.get('criteria').get('dateIteration').patchValue(value);
      }
      console.log(this.reminder);
      this.notificationService
        .getRecipients(NotificationType.REMINDER, this.reminder.id, NotificationTemplateType.REMINDER)
        .subscribe(({emails}: any) => {
          this.patchChannelsForm({emails});
        });
    } else {
      this.patchChannelsForm({emails: this.vehicle.driver ? [this.vehicle.driver.email] : null});
    }

    this.getNotificationSettings();
  }


  patchChannelsForm(data) {
    this.channelForm.patchValue({...this.channelForm.value, ...data});

  }

  buildForm() {
    this.form = this.fb.group({
      name: [null, Validators.required],
      description: [null, Validators.required],
      criteria: this.fb.group({
        mileageIteration: [null],
        mileageThreshold: [null],
        dateIteration: [null],
        dateThreshold: [null],
      })
    });
  }

  setDesc(value) {
    this.form.get('description').setValue(value);
  }

  buildData() {
    if (!this.vehicle) {
      return;
    }
    const data: ReminderRequest = {...this.form.value};
    let dateIteration;
    console.log(data);
    data.criteria = {...data.criteria};
    if (!!data.criteria.dateIteration) {
      dateIteration = this.getDateInterationString(data.criteria.dateIteration, this.dateIterationValue);
      data.criteria.dateIteration = dateIteration;
    }
    data.criteria = Object.keys(data.criteria).reduce((p, c) => data.criteria[c] === null || data.criteria[c] === '' ? {...p} : {
      ...p,
      [c]: data.criteria[c]
    }, {});
    data.entities = [
      {
        type: 'vehicle',
        fields: {
          id: this.vehicle.id
        }
      }
    ];
    data.type = 'carService';
    return data;
  }

  getDateInterationString(value: string, type: string) {
    let dateInterationString = '';

    // from year, month to day
    for (let i = this.dateIterationType.length - 1; i >= 0; i--) {
      dateInterationString += type === this.dateIterationType[i].value ? value : '0';
      dateInterationString += this.dateIterationType[i].value;
    }
    return dateInterationString;
  }

  parseDateIteration(dateIterationString: string):  { type: string, value: string } {
    const yIndex = dateIterationString.indexOf('Y');
    const mIndex = dateIterationString.indexOf('M');
    const dIndex = dateIterationString.indexOf('D');

    const yValue = dateIterationString.substring(0, yIndex);
    if (yValue !== '0') {
      return {
        value: yValue,
        type: 'Y',
      };
    }

    const mValue = dateIterationString.substring(yIndex + 1, mIndex);
    if (mValue !== '0') {
      return {
        value: mValue,
        type: 'M',
      };
    }

    const dValue = dateIterationString.substring(mIndex + 1, dIndex);
    if (dValue !== '0') {
      return {
        value: dValue,
        type: 'D',
      };
    }

    return {
      value: null,
      type: 'D',
    };
  }

  get isNewView() {
    return !this.reminder;
  }

  save() {
    if (this.pending || !this.apiValidator.formIsValid(this.form)) {
      return;
    }
    this.pending = true;
    if (this.reminder) {
      this.reminderService.partialUpdate(this.reminder.id, this.buildData() as any).subscribe(res => {
        this.updateNotification();
        this.addRecipent(res);
        this.formUpdate.emit(res);
      }, () => {
        this.pending = false;
      });
    } else {
      this.reminderService.add(this.buildData() as any).subscribe(res => {
        this.reminder = res;
        this.updateNotification();
        this.addRecipent(res);
        this.formUpdate.emit(res);
      }, () => {
        this.pending = false;
      });
    }

  }

  handlerCancel() {
    this.formUpdate.emit();
  }

  stopEvents(input: HTMLInputElement) {
    input.blur();
  }

}

import { EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from '../core/services/config.service';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';


export interface Collection<T> {
  total: number | null;
  collection: T[];
}

export interface CollectionUpdateEvent<T> {
  event: 'update' | 'new' | 'delete' | 'manual_update';
  item: T | string;
}

export default class ApiServiceClass<T> {
  protected baseUrl: string;
  public collectionUpdate$: EventEmitter<CollectionUpdateEvent<T>> = new EventEmitter();
  protected PAGE_SIZE = 20;

  constructor(url: string,
              protected config: ConfigService,
              protected http: HttpClient) {
    this.baseUrl = this.config.fullApiUrl(url);
  }

  add(data: T): Observable<T> {
    return this.http.post<T>(this.baseUrl, data).pipe(
      tap((res) => this.collectionUpdate$.emit({
        event: 'new',
        item: res,
      }))
    );
  }

  get(id: string, urlParams = {}) {
    const params = this.httpParams(urlParams);
    return this.http.get<T>(`${this.baseUrl}/${id}`, {params});
  }

  partialUpdate(id: string, data: Partial<T>) {
    return this.http.patch<T>(`${this.baseUrl}/${id}`, data).pipe(
      tap((res) => this.collectionUpdate$.emit({
        event: 'update',
        item: res,
      }))
    );
  }

  sendUpdateEvent(item) {
    this.collectionUpdate$.emit({
      event: 'manual_update',
      item,
    });
  }

  update(id, data: T) {
    return this.http.put<T>(`${this.baseUrl}/${id}`, data).pipe(
      tap((res) => this.collectionUpdate$.emit({
        event: 'update',
        item: res,
      }))
    );
  }

  delete(id: string) {
    return this.http.delete(`${this.baseUrl}/${id}`).pipe(
      tap((res) => this.collectionUpdate$.emit({
        event: 'delete',
        item: id,
      }))
    );
  }

  ajaxSearch(search) {
    const params = this.httpParams({
      search,
      page_size: 1000
    });
    return this.http.get(this.baseUrl, {params});
  }

  httpParams(objctParams): HttpParams {
    let params = new HttpParams();
    Object.keys(objctParams).forEach(key => {
      if (Array.isArray(objctParams[key])) {
        objctParams[key].forEach(item => {
          params = params.append(key, item);
        });
      } else {
        params = params.set(key, objctParams[key]);
      }

    });
    return params;
  }

  list(parameters = {}): Observable<T[]> {
    const params = this.httpParams(parameters);
    return this.http.get<T[]>(this.baseUrl, {params});
  }

  collection(parameters = {}, url = this.baseUrl): Observable<Collection<T>> {
    const params = this.httpParams(Object.assign({}, parameters));
    return this.http.get<T[]>(url, {observe: 'response', params}).pipe(
      map((response) => {
        const totalCount = response.headers.get('collection-total-count');
        return {
          total: totalCount ? parseInt(totalCount, 10) : null,
          collection: response.body,
        };
      }));
  }
}

export interface IThemeConfig {
  host: string;
  apiUrl: string;
  title: string;
  cssName?: string;
  favicon?: string;
}

export const BASE_CONF = {
  apiUrl: 'https://stage-fleet-api.fleetconnect.pl',
  maps: {
    apiKey: 'kGaiFlg9jCOO3SMk7UdJ44tRgURxDDXWDER-WjiSVQo',
    geoUrl: 'geocoder/v1/geocode',
    reverseGeoUrl: 'revgeocoder/v1/revgeocode',
    multiReverseGeoUrl: '/geocoder/v1/multi-revgeocode',
    autocompleteUrl: 'v1/autocomplete',
    reverseIsolineUrl: 'v8/isolines',
  },
  framesFromTableDate: '2022-06-01T00:00:00.000Z',
  themes: {
    mobiSystems: {
      host: 'stage.mobisystems.pl',
      apiUrl: 'https://stage-api.mobisystems.pl',
      cssName: 'localhost-theme',
      title: 'MobiSystems',
      favicon: 'mobi1_favicon',
    },
    connectedBoat: {
      host: 'localhost2',
      apiUrl: 'https://stage-api.connected.eu',
      cssName: 'connectedboat-theme',
      title: 'Connected Boat',
      favicon: 'cb1_favicon',
    },
    cbMarinaOjeju: {
      host: 'localhost',
      apiUrl: 'https://stage-api.connected.eu',
      cssName: 'connectedboat-theme;marinaojeju-theme',
      title: 'Marina Ojeju Monitoring',
      favicon: 'cb1_favicon',
    },
  },
};

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ReportTemplateEntity } from '../../../../../interfaces/reports';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { VehiclesGroupsService } from '../../../../../core/services/vehicles-groups.service';
import { VehiclesService } from '../../../../../core/services/vehicles.service';
import { DriversService } from 'src/app/core/services/drivers.service';

@Component({
  selector: 'app-report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportFormComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() entites: ReportTemplateEntity[];
  public vehicleFilters$ = new BehaviorSubject([]);
  public formErrors = {};
  private vehicleFilters = this.vehicleFilters$.asObservable();
  public vehicles$ = this.vehicleService.list$().pipe(
    switchMap(vehicles => this.vehicleFilters.pipe(
      map(groups => vehicles.filter(vehicle => {
        return vehicle.groups.filter(group => !!groups
          .find(value => value.id === group.id)).length === groups.length;
      }))
    )));

  public vehiclesGroups$ = this.vehicleGroups.list$().pipe(shareReplay());
  public drivers$ = this.driverService.list$().pipe(shareReplay());

  constructor(
    private vehicleService: VehiclesService,
    private driverService: DriversService,
    private vehicleGroups: VehiclesGroupsService) {
  }

  ngOnInit() {
  }

  filterChange($event) {
    this.vehicleFilters$.next($event);
  }

  getType(type: string) {
    switch (type) {
      case 'NUMBER_VALUE':
        return 'number';
      default:
        return 'text';
    }
  }
}

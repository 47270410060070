import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { RouteHistoryTableRowRideComponent } from './route-history-table-row-ride.component';
import { WarehouseService } from '../../../../core/services/warehouse.service';
import { FuelType } from 'src/app/interfaces/vehicle';
import { TelemetryService } from 'src/app/core/services/telemetry.service';
import { PoiType } from 'src/app/interfaces/poi';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: '[app-route-history-table-row-park]',
  templateUrl: './route-history-table-row-park.component.html',
  styleUrls: ['./route-history-table-row-ride.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteHistoryTableRowParkComponent extends RouteHistoryTableRowRideComponent implements OnInit {
  contextMenuItems: { label: string, isWork: boolean; type:PoiType }[] = [];
  @Input() vehicleFuelType: FuelType;
  @HostBinding('class.is-override') get isOverride() {
    return this.data && this.data.PARK_IS_OVERRIDE && this.data.PARK_IS_OVERRIDE[0].v;
  }

  constructor(warehouseService: WarehouseService, protected elementRef: ElementRef, protected translateService: TranslateService,
    protected cd: ChangeDetectorRef, protected telemetryService: TelemetryService) {
    super(warehouseService, elementRef, cd, telemetryService);
  }

  ngOnInit() {
    this.contextMenuItems.push({ label: this.getItemLabel('VEHICLE_STATUS.CUSTOMER_PARK'), isWork: true, type: PoiType.CUSTOMER});
    this.contextMenuItems.push({ label: this.getItemLabel('VEHICLE_STATUS.BRANCH_WORK_PARK'), isWork: true, type: PoiType.BRANCH});
    this.contextMenuItems.push({ label: this.getItemLabel('VEHICLE_STATUS.OTHER_WORK_PARK'), isWork: true, type: PoiType.OTHER});
    this.contextMenuItems.push({ label: this.getItemLabel('VEHICLE_STATUS.NONWORK_PARK'), isWork: false, type: PoiType.OTHER});
    
  }

  getItemLabel(type: PoiType | string) {
    return this.translateService.instant('CHANGE_TO') + ' ' + this.translateService.instant(type);
  }

  get parkStatus() {
    return this.warehouseService.parkStatus(this.data);
  }

  goToLink(url: string, event){
    window.open(url, '_blank');
    event.stopPropagation();
  }

  changeParkStatus(item: { label: string, isWork: boolean; type:PoiType }, $event: MouseEvent) {
    $event.preventDefault();
    this.telemetryService.updateRide(this.data.ID[0].v, {
      park: {
        isWork: item.isWork,
        poi: {
          type: item.type,
        },
      },
    }).subscribe(res => {
    this.rideTypeChange.emit();
    });
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Permission, PERMISSIONS, PermissionService} from '../../../../core/services/permission.service';
import {PermissonMenu} from '../../../../classes/permission-menu.class';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {RouterHelperService} from '../../../../core/services/router-helper.service';
import {Router} from '@angular/router';
import { ConfigService } from 'src/app/core/services/config.service';

interface ToolboxMenuItem {
  icon: string;
  link: string;
  label: string;
  permission?: Permission;
}

@Component({
  selector: 'app-toolbox',
  templateUrl: './toolbox.component.html',
  styleUrls: ['./toolbox.component.scss'],
  animations: [
    trigger('showList', [
      state('true', style({
        overflow: 'hidden',
        width: '*',
      })),
      state('false', style({
        overflow: 'hidden',
        width: '67px',
      })),
      transition('* => *', animate('300ms ease-in-out'))
    ])
  ]
})
export class ToolboxComponent extends PermissonMenu<ToolboxMenuItem> implements OnInit, OnDestroy {
  public links: ToolboxMenuItem[] = [
    {icon: 'icon-home', link: '/dashboard', label: 'DASHBOARD', permission: PERMISSIONS.WEB_DASHBOARD},
    {icon: 'icon-map', link: '/map', label: 'MAP', permission: PERMISSIONS.WEB_MAP},
    {icon: 'icon-work', link: '/work', label: 'WORK_MAINMENU', permission: PERMISSIONS.WEB_WORK},
    {icon: 'icon-car_nav', link: '/vehicles', label: 'VEHICLES', permission: PERMISSIONS.WEB_VEHICLES},
    {icon: 'icon-driver', link: '/drivers', label: 'DRIVERS', permission: PERMISSIONS.WEB_DRIVERS},
    {icon: 'icon-car-speed', link: '/driving-style', label: 'DRIVING_STYLE', permission: PERMISSIONS.WEB_DRIVING_STYLE},
    {icon: 'icon-petrol', link: '/fuel', label: 'FUEL', permission: PERMISSIONS.WEB_FUEL},
    {icon: 'icon-notification', link: '/notifications', label: 'STANDARDS_AND_NOTIFICATIONS', permission: PERMISSIONS.WEB_NOTIFICATIONS},
    {icon: 'icon-address_database', link: '/poi', label: 'ADDRESS_DATABASE', permission: PERMISSIONS.WEB_POI},
    {icon: 'icon-zones', link: '/zones', label: 'ZONES', permission: PERMISSIONS.WEB_ZONES},
    {icon: 'icon-reports', link: '/reports', label: 'REPORTS', permission: PERMISSIONS.WEB_REPORTS},
    {icon: 'icon-account', link: '/account', label: 'YOUR_ACCOUNT', permission: PERMISSIONS.WEB_ACCOUNT},
    {icon: 'icon-statistic', link: '/balance', label: 'BALANCE', permission: PERMISSIONS.WEB_BALANCE},
    {icon: 'icon-star', link: '/admin', label: 'ADMIN', permission: PERMISSIONS.WEB_ADMIN},
  ];
  isHover = false;

  constructor(permission: PermissionService, private routerHelper: RouterHelperService, private router: Router, private configService: ConfigService) {
    super(permission);
    this.adjustToolboxToCbTheme();
  }

  adjustToolboxToCbTheme() {
    // connectedboat
    if (!this.configService.isCbTheme()) {
      return;
    }
    const vehiclesItem: ToolboxMenuItem = this.links.find(f => f.label === 'VEHICLES');
    if (vehiclesItem) {
      vehiclesItem.link = '/boats';
      vehiclesItem.icon = 'icon-boat';
    }
    const mapItem: ToolboxMenuItem = this.links.find(f => f.label === 'MAP');
    if (mapItem) {
      mapItem.link = '/map/boats';
    }
    const driverItem: ToolboxMenuItem = this.links.find(f => f.label === 'DRIVERS');
    if (driverItem) {
      driverItem.icon = 'icon-sailor';
    }
  }

  mouseOver(b: boolean) {
    this.isHover = b;
  }

  ngOnDestroy(): void {
  }


  routeClick($event, link: string) {
    const visitedLink = this.routerHelper.checkIfVisitedModule(link);
    console.log(visitedLink);
    if (visitedLink && visitedLink !== './') {
      $event.preventDefault();
      $event.stopPropagation();
      this.router.navigate([link, visitedLink]);
    }
  }

  ngOnInit(): void {
  }
}

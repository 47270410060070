import {RangeInputValue} from '../shared/modules/custom-inputs/range-input/range-input.component';

type FilterType = 'TEXT' | 'NUMBER' | 'FAVORITE' | 'GROUPS' | 'SELECT' | 'RANGE';

export interface FiltersConfig {
  [key: string]: ((any, filterValue) => boolean) | {
    type: FilterType;
    fields: string[] | string;
    warehouse?: boolean;
  };
}

export class Filters {
  constructor(private filters: FiltersConfig) {
  }

  filterGroup(groups, activeGroups) {
    // activeGroups - selected groups
    // groups - entity's groups
    if (!groups) {
      return true;
    }
    if (Array.isArray(activeGroups)) {
      return groups.filter(group => !!activeGroups
        .find(value => value.id === group.id)).length === 0;
    } else if (activeGroups) {
      return !groups.find(value => value.id === activeGroups.id);
    }
    return true;
  }

  filterSelect(field, activeType) {
    if (Array.isArray(activeType)) {
      return activeType.filter(type => type === field).length !== activeType.length;
    } else if (activeType) {
      return field !== activeType;
    }
    return true;
  }

  isValueWithZero(field) {
    return field || field === 0;
  }

  filterRange(field, activeType: RangeInputValue) {
    let pass = this.isValueWithZero(field) ? false : true;
    if (activeType.gt !== null && !pass && field < activeType.gt) {
      pass = true;
    }
    if (activeType.lt !== null && !pass && field > activeType.lt) {
      pass = true;
    }
    return pass;
  }

  getValueByKey(key: string, value, isWarehouse = false) {
    const keySplit = key.split('.');
    const val = keySplit.reduce((o, i) => o && o[i] ? o[i] : null, value);
    return isWarehouse && val ? val[0].v : val;
  }

  filter(value, filterValues: { [key: string]: any }): boolean {
    if (!filterValues) {
      return true;
    }
    const keys = Object.keys(filterValues).filter(key => !!filterValues[key]);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const filter = this.filters[key] as any;
      if (filter && typeof filter === 'function' && !(filter as Function)(value, filterValues[key])) {
        return false;
      } else if (filter && filter.type && filter.type === 'TEXT') {
        const filterValue = (filterValues[key] as string).toUpperCase();
        if (filter.fields.filter((valueKey) => {
          const searchValue = this.getValueByKey(valueKey, value);
          return searchValue && searchValue.toUpperCase().indexOf(filterValue) !== -1;
        }).length === 0) {
          return false;
        }
      } else if (filter && filter.type && filter.type === 'GROUPS'
        && this.filterGroup(this.getValueByKey(filter.fields, value), filterValues[key])) {
        return false;
      } else if (filter && filter.type && filter.type === 'RANGE'
        && this.filterRange(this.getValueByKey(filter.fields, value, filter.warehouse), filterValues[key])) {
        return false;
      } else if (filter && filter.type && filter.type === 'SELECT'
        && this.filterSelect(value[filter.fields], filterValues[key])) {
        return false;
      }
    }
    return true;
  }
}

import { VehicleAvatar, VehicleExtensions } from './../../interfaces/vehicle';
import { EventEmitter, Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Vehicle as VehicleClass } from '../../classes/vehicle';
import { WarehouseResponseData, WarehouseOutputField } from '../../interfaces/warehouse';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TelemetryService } from './telemetry.service';
import { observableWithCatchError } from '../../utils/utils';
import { VehicleWithTelemetryAndRide } from '../../interfaces/telemetry';
import { AuthService } from './auth.service';
import { Vehicle } from 'src/app/classes/vehicle';
import { ApiServiceWithSub } from '../../classes/api-service-with-sub';
import { VehicleGroup } from '../../interfaces/group';
import { UserSettingsService } from './user-settings.service';

export type WorkVehicleWarehouse = {
  [key in WarehouseOutputField]: WarehouseResponseData[]
};

@Injectable({
  providedIn: 'root',
})
export class VehiclesService extends ApiServiceWithSub<Vehicle> {
  private favoriteSet: Set<string>;
  public favoriteChange = new EventEmitter();
  private activeFilter$ = new BehaviorSubject(null);
  private filterChange$ = new BehaviorSubject(null);
  protected inludeList = ['groups', 'driver'];

  private set favorite(favoriteVehicles) {
    this.userSettings.updateSettings({favoriteVehicles}).subscribe();
  }

  constructor(config: ConfigService,
              private telemetry: TelemetryService,
              private userSettings: UserSettingsService,
              authService: AuthService,
              http: HttpClient) {
    super('/vehicles', config, http, authService);
    this.setFavorite();
    this.initList();
  }

  isFavorite(id: string) {
    return this.favoriteSet && this.favoriteSet.has(id);
  }

  list(parameters = {}): Observable<Vehicle[]> {
    const params = this.httpParams({include: ['groups', 'driver', 'extensions'], ...parameters});
    return this.http.get<Vehicle[]>(this.baseUrl, {params})
      .pipe(map(res => res.map(veh => new VehicleClass(veh, this))));
  }

  favoriteToggle(id) {
    if (this.isFavorite(id)) {
      this.favoriteSet.delete(id);
    } else {
      this.favoriteSet.add(id);
    }
    this.favorite = Array.from(this.favoriteSet);
    this.favoriteChange.emit();
  }

  get filter() {
    return this.filterChange$.asObservable().pipe(filter(res => res !== null));
  }

  updateGroup(id: string, groupIds: string[]) {
    return this.http.post<VehicleGroup[]>(`${this.baseUrl}/${id}/groups`, {groupIds});
  }

  setDriver(vehicleId: string, driverId: string) {
    return this.http.patch(`${this.baseUrl}/${vehicleId}/driver/${driverId}`, {});
  }

  clearDriver(vehicleId: string) {
    return this.http.delete(`${this.baseUrl}/${vehicleId}/driver`, {});
  }

  vehicleWithTelemetryAndRide(id, range?): Observable<VehicleWithTelemetryAndRide> {
    return forkJoin(
      this.get(id, {include: ['groups', 'driver', 'extensions', 'noCacheExtensions']}),
      observableWithCatchError(this.telemetry.vehiclesLastPosition([id])),
      observableWithCatchError(this.telemetry.vehiclesLastRide({
        vehicleIds: [id],
      }))
    ).pipe(map(res => (
      {
        ride: res[2][id] || null,
        telemetry: res[1][id] || null,
        ...res[0],
      })));
  }

  private setFavorite() {
    this.userSettings.settings$.subscribe((settings) => {
      this.favoriteSet = new Set(settings && settings.favoriteVehicles || []);
      this.favoriteChange.emit();
    });
  }

  getExtensions(id: string): Observable<VehicleExtensions> {
    return this.http.get<VehicleExtensions>(`${this.baseUrl}/${id}/extensions`);
  }

  getVehicleAvatarList(): VehicleAvatar[] {
    return [
      { label: 'Maszyna', value: 'https://prodfcstorage.blob.core.windows.net/vehicle-thumbnails/bobcat.jpg'},
      { label: 'Motor', value: 'https://prodfcstorage.blob.core.windows.net/vehicle-thumbnails/dacia.jpg'},
    ];
  }
}

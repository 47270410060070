<div class="position-relative" ngbDropdown placement="bottom-right">
    <a ngbDropdownToggle class="dropdown-toggle m-0 pointer" *ngIf="(user$ | async) as user">
        <app-user-avatar size="40"
                         [avatar]="user.avatarUrl"
                         initials="{{user|fullName|initials}}"></app-user-avatar>
    </a>
    <ul ngbDropdownMenu class="dropdown-menu">
        <li>
            <a (click)="logout()" translate>LOGOUT</a>
        </li>
    </ul>
</div>

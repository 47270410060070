import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { PERMISSIONS, PermissionService } from 'src/app/core/services/permission.service';

@Component({
  selector: 'app-icon-vehicle',
  templateUrl: './icon-vehicle.component.html',
  styleUrls: ['./icon-vehicle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconVehicleComponent implements OnInit {
  @Input() color: string;
  @Input() ignition: boolean;
  @Input() size = 28;
  @Input() avatarUrl = null;

  readonly permissions = PERMISSIONS;

  constructor(protected permissionService: PermissionService) {
  }

  get iconStyle() {
    return {
      'font-size': 28 + 'px',
      color: this.color
    };
  }

  get showAvatar() {
    return this.permissionService.hasPermission(this.permissions.MAP_V_AVATAR);
  }

  ngOnInit() {
  }

}

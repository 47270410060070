import { Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CustomSelectDropdown } from '../../custom-select-dropdown';
import { Poi } from '../../../../../interfaces/poi';

@Component({
  selector: 'app-poi-select-dropdown',
  templateUrl: './poi-select-dropdown.component.html',
  styleUrls: ['./poi-select-dropdown.component.scss']
})
export class PoiSelectDropdownComponent extends CustomSelectDropdown<Poi> implements OnInit, OnChanges {
  @Output() newFilters = new EventEmitter();

  constructor() {
    super();
  }

  initFilters() {

  }

  ngOnInit() {
    console.log(this.items);
  }

  checkValue(event: any) {
    if (event) {
      this.selectChange.emit([...this.items]);
    } else {
      this.selectChange.emit(this.selected && this.selected.length > 0 ? this.selected.filter(({id}) => this.items
        .findIndex(value => value.id === id) === -1) : null);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  filtersChange(filters) {
    this.selectedFilters = filters;
    this.newFilters.emit(filters);
  }

}


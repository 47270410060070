import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Group } from '../../../interfaces/group';

@Component({
  selector: 'app-label-with-tags',
  templateUrl: './label-with-tags.component.html',
  styleUrls: ['./label-with-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelWithTagsComponent implements OnInit {
  @Input() label: string;
  @Input() tags: Group[];
  @Input() bold: boolean;
  @Output() labelClick = new EventEmitter();
  @Input() iconClass = 'icon-car';

  @HostBinding('class.has-outputs') get hasOutputs() {
    return this.labelClick.observers.length;
  }

  constructor() {
  }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../core/services/auth.service';
import { UserService } from '../../../../core/services/user.service';
import { Observable } from 'rxjs';
import { User } from '../../../../interfaces/user';

@Component({
  selector: 'app-user-drop-down',
  templateUrl: './user-drop-down.component.html',
  styleUrls: ['./user-drop-down.component.scss']
})
export class UserDropDownComponent implements OnInit {

  public user$: Observable<User> = this.userService.user$;

  constructor(private authService: AuthService, private userService: UserService) {
  }

  ngOnInit() {
  }

  logout() {
    this.authService.logout();
  }
}

import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MapService } from '../map.service';
import { Subscription } from 'rxjs';
import { hexToRgba } from '../../../../utils/utils';

@Component({
  selector: 'app-map-circle',
  templateUrl: './map-circle.component.html',
  styleUrls: ['./map-circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapCircleComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() coordinates: any;
  @Input() radius: number;
  @Input() autoZoom = true;
  @Input() color = '#F07D7D';
  public circle: H.map.Circle;
  protected subsciption: Subscription = new Subscription();

  constructor(private mapService: MapService) {
  }

  ngAfterViewInit(): void {
    this.subsciption.add(this.mapService.mapIsLoad.subscribe(() => {
      this.initCircle();
    }));
  }

  getCoords() {
    const {
      lat,
      lng
    } = this.coordinates;
    return lat ? {lat, lng} : {lat: this.coordinates[1], lng: this.coordinates[0]};
  }

  initCircle() {
    const cords = this.getCoords();
    this.circle = new H.map.Circle(cords, this.radius || this.coordinates.radius, {
      style: this.getStyle(),
      data: {}
    });
    this.mapService.mapInstance.addObject(this.circle);
    this.zoomCircle();
  }

  zoomCircle() {
    if (this.autoZoom) {
      this.mapService.setViewBounds(this.circle.getBoundingBox());
    }
  }

  getStyle(): any {
    return {
      lineWidth: 0,
      lineJoin: 'round',
      fillColor: hexToRgba(this.color, 0.4)
    };
  }

  ngOnDestroy(): void {
    this.subsciption.unsubscribe();
    if (this.circle) {
      this.circle.dispose();
      this.circle = undefined;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.coordinates || changes.radius) && this.circle) {
      this.circle.setRadius(this.radius || this.coordinates.radius);
      this.circle.setCenter(this.getCoords());
      this.zoomCircle();
    } else if (changes.color && this.circle) {
      this.circle.setStyle(this.getStyle());
    }
  }
}

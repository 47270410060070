<app-selected-tags [items]="selectedArray"
                   bindLabel="name"
                   [removable]="!isDisabled"
                   (removeItem)="removeItem($event)"
                   (removeAll)="removeAll()"
                   [max]="max"
>
</app-selected-tags>
<div ngbDropdown [placement]="placement" [autoClose]="'outside'">
    <div ngbDropdownAnchor class="c-vehicle-select__drop-anchor">
        <button class="btn btn-primary btn-border" translate (click)="toggleOpen()" [disabled]="isDisabled">
            SELECT_POI
        </button>
    </div>
    <app-poi-select-dropdown ngbDropdownMenu
                             [selected]="selected"
                             (close)="close()"
                             (newFilters)="filterChange($event)"
                             [items]="allItems"
                             [multi]="multi"
                             (selectChange)="setValue($event)"
                             class="dropdown-menu"></app-poi-select-dropdown>
</div>


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehicleStatusCardComponent } from './vehicle-status-card.component';
import { VehicleStatusCardHeaderComponent } from './vehicle-status-card-header/vehicle-status-card-header.component';
import { VehicleStatusCardDetailsComponent } from './vehicle-status-card-details/vehicle-status-card-details.component';
import { ComponentsModule } from '../../components/components.module';
import { PipesModule } from '../../pipes/pipes.module';
import { VehicleStatusWarehouseComponent } from './vehicle-status-warehouse/vehicle-status-warehouse.component';
import { DirectivesModule } from '../../directives/directives.module';
import { VehicleIndicatorsComponent } from './vehicle-indicators/vehicle-indicators.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [VehicleStatusCardComponent, VehicleStatusCardHeaderComponent, VehicleStatusCardDetailsComponent, VehicleStatusWarehouseComponent, VehicleIndicatorsComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    PipesModule,
    DirectivesModule,
    NgbTooltipModule,
    TranslateModule,
  ],
  exports: [
    VehicleStatusCardComponent,
    VehicleStatusWarehouseComponent,
  ]
})
export class VehicleStatusCardModule {
}
